/**
 * <span/> 封装，可以接收form表单数据
 */
import React, { Component } from 'react'
import Checkbox from 'antd/lib/checkbox'
import {getOptionArray} from '@utils'

const CheckboxGroup = Checkbox.Group
export default class OOCheckBoxGroup extends Component {
    constructor() {
        super()
        this.state = {
            checkAll: false, 
            indeterminate: false,
            value: [],
            options: [],
        }
    }
    componentDidMount() {
        this.init()
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const {value, options} = this.props
        if (nextProps.options !== options || nextProps.value !== value) {
            this.init(nextProps)
        }
    }
    init(props = this.props) {
        let {value, options} = props
        value = value || []
        if (typeof options === 'string') {
            options = getOptionArray(options)
        }
        const length = options.length
        const checkAll = value.length === length
        const indeterminate = value.length < length && value.length !== 0
        this.setState({value, checkAll, indeterminate, options})
    }
    onCheckAll(e) {
        const {options, indeterminate} = this.state
        let {value} = this.state
        // 全选或部分选中
        if (e.target.checked || indeterminate) {
            value = options.map(it => it.value)
            // 全选所有
            this.setState({value, checkAll: true, indeterminate: false})
        } else {
            value = []
            // 取消所有勾选
            this.setState({value, checkAll: false, indeterminate: false})
        }
        this.props.onChange && this.props.onChange(value)
    }
    onChange(value) {
        value = value || []
        const length = this.state.options.length
        const checkAll = value.length === length
        const indeterminate = value.length < length && value.length !== 0
        this.setState({value, checkAll, indeterminate})
        this.props.onChange && this.props.onChange(value)
    }

    render () {
        const {checkAll, indeterminate, value, options} = this.state
        return <span>
            <Checkbox
                indeterminate={indeterminate}
                checked={checkAll}
                onChange={this.onCheckAll.bind(this)}
            />
            <span>全选</span>
            <CheckboxGroup {...this.props} 
                value={value} 
                options={options}
                onChange={this.onChange.bind(this)}
            />
        </span>
    }
}