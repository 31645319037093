import React, { Component } from 'react'
import Icon from './Icon'
export default class ButtonIcon extends Component {
    render() {
        const {name, showName, type, style, ...restProps} = this.props
        return <span>
            <Icon 
                {...restProps} 
                type={type}
                style={Object.assign({margin: 4}, style || {})}
            />
            {(!global.isPhoneVertical || !type || showName) && name && <span>{name}</span>}
        </span>
    }
}