
const indexField = {"id":"index","isSystem":"1","name":"index","alias":"序号","width":100,"dataType":"NUMBER","isSort":"1","otherParams":{"min":1,"step":1,"autoincrease":"1"}}
const codeNameField = [
    {"id":"code","name":"code","alias":"编码","width":160,"dataType":"STRING"},
    {"id":"name","name":"name","alias":"名称","width":160,"dataType":"STRING"},
]
const codeField = {"id":"code","isSystem":"1","name":"code","alias":"编码","isRequire":"1","isUnique":"1","width":160,"dataType":"STRING"}
const nameField = {"id":"name","isSystem":"1","name":"name","alias":"名称","width":160,"dataType":"STRING"}
const sysCodeNameField = [ codeField, nameField ]
const userCodeNameField = [
    {"id":"code","isSystem":"1","name":"code","alias":"编码","isRequire":"1","isUnique":"1","width":160,"dataType":"STRING"},
    {"id":"name","isSystem":"1","name":"name","alias":"姓名","width":160,"dataType":"STRING"},
]
const sysCreateField = [
    {"id":"createuser","isSystem":"1","name":"createuser","alias":"创建人","isShowT":"0","isShowF":"1","isQuery":"0","isDisabled":"1","width":120,"dataType":"LISTMODULE","params":"staff"},
    {"id":"createdept","isSystem":"1","name":"createdept","alias":"创建人部门","isShowT":"0","isShowF":"1","isQuery":"0","isDisabled":"1","width":120,"dataType":"TREEMODULE","params":"dept"},
    {"id":"updateuser","isSystem":"1","name":"updateuser","alias":"修改人","isShowT":"0","isShowF":"1","isQuery":"0","isDisabled":"1","width":120,"dataType":"LISTMODULE","params":"staff"},
    {"id":"updatedept","isSystem":"1","name":"updatedept","alias":"修改人部门","isShowT":"0","isShowF":"1","isQuery":"0","isDisabled":"1","width":120,"dataType":"TREEMODULE","params":"dept"},
    {"id":"createtime","isSystem":"1","name":"createtime","alias":"创建时间","isShowT":"0","isShowF":"1","isQuery":"0","isDisabled":"1","width":160,"dataType":"TIME"},
    {"id":"updatetime","isSystem":"1","name":"updatetime","alias":"修改时间","isShowT":"0","isShowF":"1","isQuery":"0","isDisabled":"1","width":160,"dataType":"TIME"}
]
module.exports = {
    indexField,
    codeNameField,
    codeField, nameField,
    sysCodeNameField,
    userCodeNameField,
    sysCreateField,
    sysfields: codeNameField.concat(sysCreateField)
}