import React, { Component } from 'react'
import Checkbox from 'antd/lib/checkbox'
import ModalEx from './ModalEx'
import { createIcon } from './PageCreator'
import FieldsConfigOOTable from '../modules/module_config/FieldsConfigOOTable'

export default class SetTable extends Component {
    constructor() {
        super()
        this.state = {
            visible: false
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.hasFixCol !== nextProps.hasFixCol) {
            this.setState({visible: false})
        }
    }

    onChange(key, info) {
        if (info.target.checked) {
            let showKeys = this.props.showKeys.map(it => it)
            showKeys.push(key)
            this.props.onChange && this.props.onChange(showKeys)
        } else {
            let showKeys = this.props.showKeys.map(it => it)
            showKeys = showKeys.filter(it => it !== key)
            this.props.onChange && this.props.onChange(showKeys)
        }
    }
    onCancel() {
        this.setState({visible: false})
    }
    render() {
        let {showKeys, allKeyLabels, config} = this.props
        allKeyLabels = allKeyLabels || []
        showKeys = showKeys || []
        return  <span>
            <span onClick={() => this.setState({visible: !this.state.visible})} >
            {this.props.button ? this.props.button : 
                createIcon('gear', '列配置', null, {style: {...global.iconStyle, marginLeft: 8}})
            }</span>
            <ModalEx width={300} 
                title={config ? <FieldsConfigOOTable data={config}/> : ''}
                onClose={this.onCancel.bind(this)} 
                visible={this.state.visible} 
                maskClosable
            >
                <div style={{backgroundColor: 'white', width:260}}>
                    {allKeyLabels.map(item => {
                        return <div key={item.key}>
                            <Checkbox key={item.key} value={item.key} onChange={this.onChange.bind(this, item.key)}  checked={showKeys.indexOf(item.key) !== -1} >{item.label}</Checkbox>
                        </div>
                    })}
                </div>
            </ModalEx>
        </span>
    }
}