/**
 * 全局变量和函数
 */
import moment from 'moment'
import Modal from 'antd/lib/modal'
import notification from 'antd/lib/notification'
import '../config/module'
import '../config/route'
import './common/div2Excel'
import {getCookie} from './cookie'
import entApi from './api'
import platformApi from './platform_api'
import {v1} from 'uuid'
import * as o from './object'
import c from './constants'

const {
    datatransferList, orchestrateList, reportengineList, chartdesignList, ooMessageObj,
    forms, treeDataObj, pageInstObj
} = o
const {
    tables_config, code_scheme, 
    datatransfer, orchestrate, reportengine, chartdesign,
    todo, done, setting, 
    auth, staff, dept, menu, 
    server, db, enterprise, user, 
    // 动态生成表内置字段
    createtime, updatetime, createuser, createdept, updateuser, updatedept,
    // 空fid标记
    nofid, 
} = c

/***********************************************************************************
 * 平台内置模块
 **********************************************************************************/
// 服务器， 数据库， 企业， 用户
const platformMenu = [server, db, enterprise, user]

/***********************************************************************************
 * 企业系统内置模块
 **********************************************************************************/

// 没有操作权限， 只有菜单权限
const noAuthOperMenu = [todo, done]
function isNoAuthOperMenu(menu) {
    if (noAuthOperMenu.indexOf(menu) !== -1) {
        return true
    }
    return false
}

// 有操作权限和菜单权限， 字段配置固定
const fixedColMenu = [tables_config, code_scheme].concat(noAuthOperMenu)
function isFixedColMenu(menu) {
    return fixedColMenu.indexOf(menu) !== -1
}

// 员工与组织， 有操作权限和菜单权限， 字段配置开放， 不能删除系统预留字段
const sysMenu = [staff, dept, auth, menu, datatransfer, orchestrate, reportengine, chartdesign, setting]
.concat(fixedColMenu).concat(platformMenu)

// 系统内置模块，模块选择器中不可选择
const sysModules = [auth, menu, setting, datatransfer, orchestrate, reportengine, chartdesign].concat(fixedColMenu)
function isSysModule(moduelName) {
    return sysModules.indexOf(moduelName) !== -1
}

/***********************************************************************************
 * 系统菜单， 包括企业和平台的内置模块
 **********************************************************************************/
function isSysMenu(menu) {
    if (sysMenu.indexOf(menu) !== -1) {
        return true
    }
    return false
}

/***********************************************************************************
 * 系统内置字段，系统创建数据 包括创建时间、修改时间、创建人、创建人部门、修改人、修改人部门
 **********************************************************************************/
const sysFields = [createtime, updatetime, createuser, createdept, updateuser, updatedept]
function isSysFields(name) {
    if (!(typeof name === 'string')) {
        return false
    }
    for (let sysName of sysFields) {
        if (name === sysName) {
            return true
        }
    }
    return false
}

/***********************************************************************************
 * 系统预留字段，用户可以修改 包括 编码、名称
 **********************************************************************************/
function isNameCode(name) {
    return [c.code, c.name].indexOf(name) !== -1
}

const firstPage = {
	key: '/',
	title: '首页',
	closable: false
}

const personalPage = {
	key: '/personal',
	title: '个人信息',
}

/**
 * 判断前端访问平台及还是企业级API
 * @returns api
 */
function isPlatform() {
    if (getCookie('username') === 'oo') {
        return true
    }
    return false
}
global.isPlatform = isPlatform

/**
 * 判断前端访问平台及还是企业级API
 * @returns api
 */
function getApi() {
    let api = entApi
    if (global.isPlatform()) {
        api = platformApi
    }
    return api
}
global.getApi = getApi

// 菜单trigger 统一为 click
//global.trigger = ['click', 'hover']
global.trigger = ['click']

/**
 * 计算网页窗口尺寸
 */
global.windowSize = () => {
    /**
     * 网页宽度
     */
    global.clientWidth = document.body.clientWidth

    /**
     * 网页高度
     */
    global.clientHeight = document.body.clientHeight
    //console.log('网页宽度 clientWidth', global.clientWidth)
    //console.log('网页高度 clientHeight', global.clientHeight)
    // 高度或宽度最小值小于600认为是手机
    global.isPhone = Math.min(global.clientWidth, global.clientHeight) < 500
    global.isPhoneLandscape = false
    global.isPhoneVertical = false
    global.buttonClass = ''
    
    // 宽度大于高度认为是手机横屏
    if (global.clientWidth > global.clientHeight && global.isPhone) {
        global.isPhoneLandscape = true
    }
    // 宽度小于高度认为是手机竖屏
    if (global.clientWidth < global.clientHeight && global.isPhone) {
        global.isPhoneVertical = true
        global.buttonClass = 'icon-button'
    }

    /**
     * maskWidth 弹窗左右空白宽度
     */
    global.maskWidth = 80

    /**
     * maskHeight 弹窗上下空白宽度
     */
    global.maskHeight = 60

    /**
     * 弹窗宽度
     */
    let width = global.clientWidth
    //console.log('width', width)
    //if (width < global.clientHeight) {
    //    width = global.clientHeight
    //}
    // 主框架最小宽度300px
    if (width < 300) {
        width = 300
    }

    // 弹窗宽度
    global.modalWidth = width - global.maskWidth
    if (global.isPhoneVertical) {
        global.modalWidth = width
    }
    //console.log('global.modalWidth', global.modalWidth)
    global.cols = Math.round(global.modalWidth / 500)
    //console.log('global.cols', global.cols)

    // 弹窗高度
    global.modalHeight = global.clientHeight - global.maskHeight

    // 框架头高度
    global.headerHeight = 50

    // 正文留白 paddingTop: 4, paddingBottom: 4
    global.contentPaddingHeight = 4 + 4

    // 按钮区域高度
    global.toolHeight = 32/*按钮高度*/ + 8/*按钮底部空白*/ + 50/*大中小区域*/

    // 展开收起图标高度
    global.hideButtonHeight = 20

    // 框架正文高度
    global.contentHeight = global.clientHeight - global.headerHeight
    //console.log(global.contentHeight, '框架正文高度 ')

    // 树组件高度 带分类 
    global.treeHasClassHeight = global.contentHeight 
        // 正文留白
        - global.contentPaddingHeight 
        // 按钮区域高度
        - global.toolHeight
        // 展开收起图标高度
        - global.hideButtonHeight

    // 表格组件高度 带分类 
    global.tableHasClassHeight = global.treeHasClassHeight; 
    //console.log(global.tableHasClassHeight, '表格组件高度 带分类 ')

    // 树组件高度 独立模块 无分类
    global.treeHeight = global.contentHeight
    //console.log(global.treeHeight, '树组件高度 独立模块 无分类')

    // 表格组件高度 独立模块 无分类
    global.tableHeight = global.contentHeight 
        // 正文留白
        - global.contentPaddingHeight 
        // 按钮区域高度
        - global.toolHeight
    //console.log(global.tableHeight, '表格组件高度 独立模块 无分类')

    // 表格分页高度
    global.paginationHeight = 64 // 32/*高度*/ + 16/*marginTop*/ + 16/*marginBottom*/
}
// 计算网页窗口尺寸
global.windowSize()

const aStyle = {
    color: '#1890ff', //  hover #40a9ff
}
global.aStyle = aStyle

/**
 * 操作图标样式
 */
const iconStyle = {
    color: '#1890ff',
    marginRight: 8
}
global.iconStyle = iconStyle

/**
 * 自定义h样式
 */
const h3 = {
    fontSize: 16, 
    color: '#1890ff'
}
global.h3 = h3
const h2 = {
    fontSize: 18, 
}
global.h2 = h2

/**
 * 保留几位小数，默认6位，小数位数不够不补0
 */
function toFixedNumber(source, decimalplaces) {
    let precision = o.setting.numberdecimalplaces
    if (isNotBlank(decimalplaces) && !isNaN(decimalplaces)) {
        precision = parseInt(decimalplaces, 10)
    }
    if (precision === null || isNaN(precision)) {
        precision = 6
    }
    let num = parseFloat(source)
    if (isNaN(num) || isBlank(num)) {
        return 0
    }
    const factor = 10 ** precision
    return Math.round(num * factor) / factor
}
global.toFixedNumber = toFixedNumber

/**
 * 保留几位小数，默认2位，小数位数不够补0
 */
function toFixedMoney(source, decimalplaces) {
    let precision = o.setting.moneydecimalplaces
    if (isNotBlank(decimalplaces) && !isNaN(decimalplaces)) {
        precision = parseInt(decimalplaces, 10)
    }
    let num = parseFloat(source)
    if (isNaN(num) || isBlank(num)) {
        return ''
    }
    return num.toFixed(precision)
}
global.toFixedMoney = toFixedMoney

/**
 * 浏览器地址栏携带参数解析
 */
function parseSearchStr() {
    let search = window.location.search || ''
    search = decodeURI(search, 'UTF-8')
    // 解析url携带参数
    let paramObj = search.substring(1)
        .split('&')
        .reduce((obj, param) => {
            let index = param.indexOf('=')
            if (index !== -1) {
                let key = param.substring(0, index).trim()
                let value = param.substring(index + 1).trim()
                obj[key] = value
            }
            return obj
        }, {})
    return paramObj
}
global.parseSearchStr = parseSearchStr

/**
 * 将null或undefined转化为空字符串
 * @param {*} obj 
 */
function n2s(obj) {
    if (obj === null || obj === undefined || obj === 'null') {
        return ''
    }
    return obj
}
global.n2s = n2s
/**
 * 将文本或数字统一转换为数字
 * @param {*} obj 
 */
function o2n(obj) {
    let num = Number(obj)
    if (isNaN(num) || isBlank(obj)) {
        num = 0
    }
    return num
}
global.o2n = o2n

/**
 * 将文本或数字统一转换为整数
 * @param {*} obj 
 */
function o2i(obj) {
    let num = parseInt(obj, 10)
    if (isNaN(num) || isBlank(obj)) {
        num = 0
    }
    return num
}
global.o2i = o2i
/**
 * 将NaN、null、undefined转换为0
 * @param {*} obj 
 */
function n2zero(obj) {
    if (isBlank(obj) || isNaN(obj) || obj === 'null') {
        return 0
    }
    return Number(obj)
}
global.n2zero = n2zero

/**
 * js 数据判断严格相等
 */
function equals(a, b, depth = 0) {
    if (depth > 3) return true
    if ((a instanceof Object) && (b instanceof Object)) {
        depth++
        if (Object.keys(a).length !== Object.keys(b).length) {
            return false
        }
        return Object.keys(a).reduce((ret, key) => {
            if (ret === false) {
                return false
            }
            return equals(a[key], b[key], depth)
        }, true)
    } else {
        return a === b
    }
}
global.equals = equals

/**
 * js 深拷贝
 */
function copy(a, depth = 0) {
    if (depth > 6) return a
    if (a instanceof Date || a instanceof Number || a instanceof Boolean ||
        a instanceof Function || a instanceof String
    ) {
        return a
    }
    if (a instanceof Array) {
        depth++
        return a.map(item => copy(item, depth))
    } else if (a instanceof Object) {
        depth++
        return Object.keys(a).reduce((obj, key) => {
            obj[key] = copy(a[key], depth)
            return obj
        }, {})
    } else {
        return a
    }
}
global.copy = copy

/**
 * 搜索树， 返回匹配的所有节点
 * @param {String} text 搜索值
 * @param {Array<TreeNode>} treeData 
 * @param {String} key 搜索字段 默认 value
 * @returns 
 */
function searchTree(text, treeData, key) {
    let res = []
    for (let item of treeData) {
        let children = item.children
        if (children && children.length > 0) {
            children = global.searchTree(text, children, key)
            if (children.length > 0) {
                item.children = children
            }
        }
        if (item[key].indexOf(text) !== -1 || (children && children.length > 0)) {
            //console.log(text, item[key], item)
            res.push(item)
        }
    }
    return res
}

/**
 * 精确搜索搜索树节点， 返回匹配的第一个节点
 * @param {String} text 搜索值
 * @param {Array<TreeNode>} treeData 
 * @param {String} key 搜索字段 默认 value
 * @returns 
 */
function searchTreeNode(text, treeData, key = 'value') {
    for (let item of treeData) {
        let { children } = item
        if (children && children.length > 0) {
            let node = global.searchTreeNode(text, children, key)
            if (node) {
                return node
            }
        }

        if (item[key] === text) {
            return item
        }
    }
    return null
}
global.searchTreeNode = searchTreeNode

/**
 * 遍历树
 * @param {Array<TreeNode>} treeData 
 * @param {Function} operation 
 * @param {String} childrenKey 默认 children
 * @param {TreeNode} parent 
 * @returns 
 */
function traverseTree(treeData, operation, childrenKey, parent) {
    childrenKey = childrenKey || 'children'
    if (null == operation) return false
    for (let item of treeData) {
        if (operation instanceof Function) {
            if (operation(item, parent) === false) {
                return false
            }
        }
        let children = item[childrenKey]
        if (children && children.length > 0) {
            if (global.traverseTree(children, operation, childrenKey, item) === false) {
                return false
            }
        }
    }
    return true
}
global.traverseTree = traverseTree

/**
 * 判断变量命名是否全为ASCII码
 */
function isASCII(str) {
    let regexpObj = new RegExp(/[a-zA-Z0-9_$].+/g)
    return regexpObj.test(str)
}
global.isASCII = isASCII

function getNameChar() {
    return '[a-zA-Z0-9_$\u4e00-\u9fa5]'
}
function getNonNameChar() {
    return '[^a-zA-Z0-9_$\u4e00-\u9fa5]'
}


/**
 * JSON字符串转对象
 */
function parseErrorNull(str) {
    try {
        return JSON.parse(str)
    } catch (error) {
        //console.error(error)
        return null
    }
}
global.parseErrorNull = parseErrorNull

/**
 * JSON字符串转对象
 */
function parse(str) {
    if (str == null) return {}
    if (typeof str !== 'string') return str
    try {
        return JSON.parse(str) || {}
    } catch (error) {
        //console.error(error)
        return {}
    }
}
global.parse = parse
/**
 * JSON字符串转数组
 */
function parseArray(str) {
    if (str instanceof Array) return str
    try {
      return JSON.parse(str) || []
    } catch (error) {
      //console.error(error)
      return []
    }
}
global.parseArray = parseArray
  
/**
* sortByKeyAndType
* 根据数据类型生成排序函数
* key 指定排序字段
* type 字段类型
* order 排序类型，默认升序
*/
function sortByKeyAndType(key, type, order) {
    const stringSorter = (a, b, key, order) => {
        let aa = a[key] || ''
        let bb = b[key] || ''
        if (order === 'descend') {
            return bb.localeCompare(aa)
        } else {
            return aa.localeCompare(bb)
        }
    }
    const dateSorter = (a, b, key, order) => {
        if (order === 'descend') {
            return moment(b[key]).valueOf() - moment(a[key]).valueOf()
        } else {
            return moment(a[key]).valueOf() - moment(b[key]).valueOf()
        }
    }
    const numberSorter = (a, b, key, order) => {
        let aa = a[key] || 0
        let bb = b[key] || 0
        if (order === 'descend') {
            return bb - aa
        } else {
            return aa - bb
        }
    }
    return (a, b) => {
        if (type === 'STRING') {
            return stringSorter(a, b, key, order)
        }
        if (type === 'DATE') {
            return dateSorter(a, b, key, order)
        }
        if (type === 'NUMBER') {
            return numberSorter(a, b, key, order)
        }
        
        if (a[key] instanceof Number || b[key] instanceof Number) {
            return numberSorter(a, b, key, order)
        }
        if (typeof a[key] === 'string' || typeof b[key] === 'string') {
            return stringSorter(a, b, key, order)
        }
        return 0
    }
} 


/**
 * 将数据保存为全局变量
 * obj 页面this对象
 * key 模块名称
 * data 要保存的数据
 */
function storeData(_this, key, data) {
    if (key != null) {
        global[key] = global[key] || {}
        global[key] = Object.assign(global[key], data)
    }
    if (!_this.unmount) {
        _this.setState(data)
    }
}
global.storeData = storeData

function getCols() {
    return (global.Dashboard && global.Dashboard.cols) || global.cols
}

/**
 * 判断字符串类型是否为空
 */
function isBlank(str) {
    try {
        if (undefined === str || null === str || '' === ('' + str).trim()) {
            return true
        }
    } catch (error) {
        //console.error(error)
        return true
    }
    return false
}

/**
 * 判断字符串类型是否非空
 */
function isNotBlank(str) {
    return !isBlank(str)
}

function get(obj, keyStr) {
    if (obj == null) {
        return null
    }
    if (!(typeof keyStr === 'string')) {
        console.error('isNull(obj, keyStr): ', obj, keyStr)
        return null
    }
    const keys = keyStr.split('.')
    let value = obj
    for (let key of keys) {
        value = value[key]
        if (value == null) {
            return null
        }
    }
    return value
}


/**
 * 返回数组新增元素数组和数组删除元素数组
 * @param {Array} sourceArr 数组对比源
 * @param {Array} targetArr 数组对比目标
 * @param {Function} callback 元素是否相等回调
 */
function diffArray(sourceArr, targetArr, callback) {
    sourceArr = sourceArr || []
    targetArr = targetArr || []
    //console.log('sourceArr, targetArr: ', sourceArr, targetArr)
    let addArr = []
    let delArr = []
    let sameArr = []
    if (!(sourceArr instanceof Array)) {
        console.error('sourceArr must be Array'); 
        return { addArr, delArr, sameArr }
    }
    if (!(targetArr instanceof Array)) {
        console.error('targetArr must be Array'); 
        return { addArr, delArr, sameArr }
    }

    if (callback && !(callback instanceof Function)) {
        console.error('callback must be Function'); 
        return { addArr, delArr, sameArr }
    }

    // 查找新增元素
    for (let item of targetArr) {
        if (-1 === sourceArr.findIndex(it => {
            if (callback) {
                return callback(it, item)
            } else {
                return item === it
            }
        })) {
            addArr.push(item)
        } else {
            sameArr.push(item)
        }
    }
    // 查找删除元素
    for (let item of sourceArr) {
        if (-1 === targetArr.findIndex(it => {
            if (callback) {
                return callback(item, it)
            } else {
                return item === it
            }
        })) {
            delArr.push(item)
        }
    }
    //console.log('addArr: ', addArr)
    //console.log('delArr: ', delArr)
    return { addArr, delArr, sameArr }
}

function swap(list, i, j) {
    [list[i], list[j]] = [list[j], list[i]]
}

/**
 * 位数不足时在字符串前填充指定字符
 */
function fillChar(num, length, char) {
    char = char || '0'
    let str = '' + num
    let curlen = str.length
    while (curlen++ < length) {
        str = char + str
    }
    return str
}
global.fillChar = fillChar

/**
 * 打开浏览器新窗口
 *     
 * name：
    可选。指定 target 属性或窗口的名称。支持以下值：
    _blank - URL 加载到一个新的窗口。这是默认
    _parent - URL 加载到父框架
    _self - URL 替换当前页面
    _top - URL 替换任何可加载的框架集
    name - 窗口名称
 * specs：
 * channelmode=yes|no|1|0	是否使用剧院模式显示窗口。默认为 no。
 * directories=yes|no|1|0	是否添加目录按钮。默认为 yes。
 * fullscreen=yes|no|1|0	是否使用全屏模式显示浏览器。默认是 no。处于全屏模式的窗口必须同时处于剧院模式。
 * height=pixels	窗口文档显示区的高度。以像素计。
 * left=pixels	窗口的 x 坐标。以像素计。
 * location=yes|no|1|0	是否显示地址字段。默认是 yes。
 * menubar=yes|no|1|0	是否显示菜单栏。默认是 yes。
 * resizable=yes|no|1|0	窗口是否可调节尺寸。默认是 yes。
 * scrollbars=yes|no|1|0	是否显示滚动条。默认是 yes。
 * status=yes|no|1|0	是否添加状态栏。默认是 yes。
 * titlebar=yes|no|1|0	是否显示标题栏。默认是 yes。
 * toolbar=yes|no|1|0	是否显示浏览器的工具栏。默认是 yes。
 * top=pixels	窗口的 y 坐标。
 * width=pixels	窗口的文档显示区的宽度。以像素计。
 */
function openBrowser(path, newWindow) {
    if (!newWindow) {
        window.open(path, '_blank')
    } else {
        window.open(path, '_blank',
            'channelmode=yes,directories=no,location=1,menubar=0,status=0,titlebar=0,toolbar=0'
        )
    }
}
global.openBrowser = openBrowser

/**
 * 获取全量系统配置
 * @returns Array
 */
function getModuleConfigs() {
    return global.moduleConfigs || []
}

/**
 * 根据模块名称获取模块配置
 */
const getConfigByModuleName = (moduleName) => {
    if (isBlank(moduleName)) {
        return {}
    }
    let moduleConfigs = getModuleConfigs()
    //console.log('moduleConfigs',moduleConfigs)
    let config = moduleConfigs.find(item => {
        //console.log(item)
        return item.modulename === moduleName
    })
    //console.log(config)
    return config || {fields_config: []}
}

const getModuleConfig = (moduleName) => {
    return getConfigByModuleName(moduleName)
}
global.getModuleConfig = global.getConfigByModuleName = getConfigByModuleName

/**
 * 根据字段名称获取字段配置
 * @param {*} fields_config 
 * @param {*} name 
 * @returns FieldConfig
 */
const getFieldConfig = (fields_config, name) => {
    if (!(fields_config instanceof Array)) {
        return null
    }
    const config = fields_config.find(it => it.name === name)
    if (config) {
        return copy(config)
    }
    return null
}
global.getFieldConfig = getFieldConfig

/**
 * 根据模块名称、字段名称获取字段配置
 * @param {*} moduleName 
 * @param {*} name 
 * @returns 
 */
const getConfig = (moduleName, name) => {
    const fields_config = getModuleConfig(moduleName).fields_config
    return getFieldConfig(fields_config, name)
}
global.getConfig = getConfig

/**
 * 获取选项分隔符
 * 先查找空格，如果有按空白分割，如果没有按 / 分割
 * @param {*} str 
 * @returns 
 */
function getOptionSplit(str) {
    if (str && str.indexOf(' ') !== -1) {
        return /\s+/
    }
    if (str && str.indexOf('、') !== -1) {
        return '、'
    }
    return '/'
}

/**
 * 获取Select显示值
 * @param {*} value 
 * @param {Object | String} 
 * Object: config 字段配置
 * String: options valueLabel/valueLabel
 * @returns 
 */
function getSelectLabel(value, config) {
    let optionStr
    if (typeof config === 'string') {
        optionStr = config
    } else {
        optionStr = (config && config.params) || ''
    }
    
    let options = optionStr.split(getOptionSplit(optionStr))
    let option = options.find(it => it.indexOf(value) !== -1)
    if (option) {
        // 搜索英文输入法逗号
        let index = option.indexOf(',') 
        if (index !== -1) {
            return option.substring(index + 1).trim()
        }
        // 搜索中文输入法逗号
        index = option.indexOf('，') 
        if (index !== -1) {
            return option.substring(index + 1).trim()
        }
    }
    return value
}

/**
 * 根据字段配置获取选择字段label
 * @param {*} moduleName 
 * @param {*} name 
 * @param {*} value 
 * @returns 
 */
const getLabelByConfig = (moduleName, name, value) => {
    const options = (getConfig(moduleName, name) || {}).params
    return getSelectLabel(value, options)
}

/**
 * 根据value查找label
 * @param {String} value 
 * @param {Array} options option Array
[
    {value: 1, label: 'a'}
]
 * @returns 
 */
const getLabelByValue = (value, options) => {
    if (!(options instanceof Array)) {
        return value
    }
    let option = options.find(it => it.value === value)
    if (option) {
        return option.label || value
    }
    return value
}

/**
 * 获取数组格式选项Option
 * @param {String | Array} str
 * @returns 
 */
function getOptionArray(str) {
    if (str instanceof Array) {
        return str
    }
    if (isBlank(str)) {
        return []
    }
    return str.split(getOptionSplit(str)).map((item) => {
        item = item.trim()
        let value = item
        let label = item
        // 搜索英文输入法逗号
        let index = item.indexOf(',')
        if (index !== -1) {
            value = item.substring(0, index).trim()
            label = item.substring(index + 1).trim()
            return {value, label}
        }
        // 搜索中文输入法逗号
        index = item.indexOf('，')
        if (index !== -1) {
            value = item.substring(0, index).trim()
            label = item.substring(index + 1).trim()
            return {value, label}
        }
        return {value, label}
    })
}

/**
 * Json 选项 Option value 转换 label
 * @param {*} value 
 * @param {*} options 
 * @returns 
 */
function valueToLabel(value, options = [], valueKey = 'value', labelKey = 'label') {
    if (isBlank(value)) {
        return value
    }
    // 多选
    if (value instanceof Array) {
        return value.map(item => {
            let option = options.find(it => item === it[valueKey])
            if (option) {
                return option[labelKey]
            }
            return item
        }).join(" , ")
    }
    // 单选
    let option = options.find(it => value === it[valueKey])
    if (option) {
        return option[labelKey]
    }
    return value
}

/**
 * 获取显示名称，名称或编码
 * @param {*} data 
 * @returns 
 */
const getTitle = (data) => {
    data = data || {}
    return data.name || data.code || data.alias || data.modulename || ''
}

/**
 * 获取id
 * @returns uuid
 */
function uuid() {
    return v1().replace(/-/g, '')
} 
global.uuid = uuid

/**
 * 获取id
 * @returns uuid
 */
function uuid6() {
    return uuid().substring(0, 6)
} 

const toString = (obj) => {
    if (obj == null) return null
    if (typeof obj === 'string') {
        return obj
    }
    return JSON.stringify(obj)
}
global.toString = toString

/**
 * 将对象中指定的字符串类型key转成数字
 * @param {*} props 
 * @param  {...any} keys 
 * @returns 
 */
const stringToInt = (props, ...keys) => {
    for (let key of keys) {
        let str = props[key]
        if (isBlank(str)) {
            delete props[key]
        }
        if (typeof str === 'string') {
            if (isNaN(str) || isBlank(str)) {
                return delete props[key]
            }
            props[key] = parseInt(str, 10)
        }
    }
}
global.stringToInt = stringToInt

function parseObjectOrArray(record) {
    for (let key in record) {
        let value = record[key]
        if (isBlank(value)) {
            continue
        }
        if (typeof value === 'string') {
            if (value.startsWith('[')) {
                record[key] = parseArray(value)
            } else if (value.startsWith('{')) {
                record[key] = parse(value)
            }
        }
    }
}

function success(message, content, onOk) {
    Modal.success({ title: message, content, okText: '知道了', onOk})
}
function info(message, content, onOk) {
    Modal.info({ title: message, content, okText: '知道了', onOk})
}
function warning(message, content, onOk) {
    Modal.warning({ title: message, content, okText: '知道了', onOk})
}
function error(message, content, onOk) {
    Modal.error({ title: message, content, okText: '知道了', onOk})
}
function confirm(message, onOk, onCancel, content) {
    Modal.confirm({ title: message, content, onOk, onCancel })
}
function notify(title, conent = '', autoClose) {
    const args = {
        message: title,
        description: conent, 
        duration: 0,
    }
    if (autoClose) delete args.duration
    notification.open(args)
}
async function sleep(ms) {
    return await new Promise(resolve => setTimeout(resolve, ms))
}
/**
 * 初始化或清空全局数据
 */
function clearGlobalData() {
    /** 参数设置 */
    Object.assign(o.setting, {
        numberdecimalplaces: 6,
        moneydecimalplaces: 6,
    })
    /** 数据传递表全量数据 */
    datatransferList.splice(0, datatransferList.length)
    /** 业务编排表全量数据 */
    orchestrateList.splice(0, orchestrateList.length)
    /** 报表引擎表全量数据 */
    reportengineList.splice(0, reportengineList.length)
    /** 图表引擎表全量数据 */
    chartdesignList.splice(0, chartdesignList.length)
    /** 消息 */
    Object.keys(ooMessageObj).forEach((prop) => delete ooMessageObj[prop])

    /** 
     * 全局表单 moduleName 和 id 对应的 inst 相同
     *  {
     *      [moduleName]: inst 表单所在页面实例
     *      [id]: inst 表单所在页面实例
     *  }
    */
    Object.keys(forms).forEach((prop) => delete forms[prop])

    /** 
     * 系统树缓存 
     * moduleName 树数据
     * moduleName + 'update'  更新标记
     *  {
     *      [moduleName]: treeData,       
     *      [moduleName + 'update']: bool 
     *  }
     */
    Object.keys(treeDataObj).forEach((prop) => delete treeDataObj[prop])

    /**
     * 打开页签的所有页面实例
     * path pathname + searchStr
     * inst 页面实例
     *  {
     *      [path]: inst       
     *  }
     */
    Object.keys(pageInstObj).forEach((prop) => delete pageInstObj[prop])
    // 初始化全局方法，开始加载/停止加载
    global.startLoad = null
    global.stopLoad = null
}

function hasfid(fid) {
    return isNotBlank(fid) && fid !== nofid
}

function getColor() {
    // 生成 0-255 的 RGB 通道值
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);
  
    // 转换为两位十六进制并补零
    const toHex = (n) => n.toString(16).padStart(2, '0').toUpperCase();
    
    // 拼接为十六进制颜色代码
    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}

export {
    platformMenu,
	noAuthOperMenu,
	isNoAuthOperMenu,
	fixedColMenu,
	isFixedColMenu,
	sysMenu,
	sysModules,
	isSysModule,
	isSysMenu,
	sysFields,
	isSysFields,
	isNameCode,
	firstPage,
	personalPage,
	isPlatform,
	getApi,
    aStyle,
	iconStyle,
	h3,
	h2,
	toFixedNumber,
	toFixedMoney,
	parseSearchStr,
	n2s,
	o2n,
	o2i,
	n2zero,
	equals,
	copy,
	searchTree,
	searchTreeNode,
	traverseTree,
	isASCII,
	getNameChar,
	getNonNameChar,
	parseErrorNull,
	parse,
	parseArray,
	sortByKeyAndType,
	storeData,
	getCols,
	isBlank,
	isNotBlank,
    get,
    diffArray, swap,
	fillChar,
	openBrowser,
	getModuleConfigs,
	getConfigByModuleName,
	getModuleConfig,
	getFieldConfig,
	getConfig,
	getOptionSplit,
	getSelectLabel,
	getLabelByConfig,
	getLabelByValue, getOptionArray, valueToLabel,
	getTitle,
	uuid, uuid6,
	toString,
	stringToInt,
	parseObjectOrArray,
	success,
	info,
	warning,
	error,
	confirm,
	notify,
	sleep,
	clearGlobalData,
	hasfid,
    getColor
}
