import * as utils from '@utils/index'

function checkCodeItems(codeitems = []) {
    if (codeitems.length === 0) {
        utils.warning('码段信息为空')
        return false
    }
    try {
        const autoincreaseItems = codeitems.filter(it => it.codeitemtype === 'autoincrease')
        if (autoincreaseItems.length > 1) {
            throw new Error("自增类型码段只能有一个")
        }
        if (autoincreaseItems.length === 1) {
            const record = autoincreaseItems[0]
            if (utils.isBlank(record.codeitemlength)) {
                throw new Error("码段长度不能为空")
            }
            if (utils.isBlank(record.startvalue)) {
                throw new Error("起始值不能为空")
            }
            if (utils.isBlank(record.fillchar)) {
                throw new Error("填充字符不能为空")
            }
        }
        const dateItems = codeitems.filter(it => it.codeitemtype === 'date')
        if (dateItems.length > 1) {
            throw new Error("日期类型码段只能有一个")
        }
        if (dateItems.length === 1) {
            const record = dateItems[0]
            if (utils.isBlank(record.dateformat)) {
                throw new Error("日期格式不能为空")
            }
        }
        for (let record of codeitems) {
            if ("fixed" === record.codeitemtype && utils.isBlank(record.codeitemvalue)) {
                throw new Error("码段值不能为空")
            }
        }
    } catch (e) {
        utils.error(e.message)
        return false
    }
    return true
}

export async function beforeAdd(apiArray, record) {
    return checkCodeItems(record.codeitems)
}

export async function beforeSave(apiArray, record, stateRecord) {
    return checkCodeItems(record.codeitems)
}

export function specialItemProps(formField, props, record, disabled) {
    const {id} = formField
    if (id === 'schemename') {
        props.initialValue = record.alias || record.schemename
    }
}