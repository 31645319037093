/**
 * ModuleDataSelect 模块数据选择组件， 同时支持树模型模块和列表模型模块
 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Input from 'antd/lib/input'
import Icon from './Icon'
import Spin from 'antd/lib/spin'
import ModalEx from './ModalEx'
import ListModule from '@list/ListModule'
import CascaderPopover from './CascaderPopover'
import ListFieldsPopover from './ListFieldsPopover'
import {getTreePromise} from '@tree/Action'
import {getOneByCode} from '@list/Action'
import * as dataUtil from '@utils/DataUtil'
import * as utils from '@utils/index'
import * as p from '@utils/page'
import A from '@com/A'

export default class ModuleDataSelect extends Component {
    constructor(props) {
        super()
        // 字段配置中params为模块名， Form表单和表格都有
        this.moduleName = props.config.params
        this.state = {
            showModal: false,
            config: null,
            loading: false,
            name: null,
            data: null,
            treeData: null,
        }
        // 是否显示编码
        this.isShowCode = dataUtil.isShowCode(this.moduleName)
        this.searchConds = null
    }

    /**
     * 根据模块名获取模块配置信息
     */
    async componentDidMount() {
        this.unmount = false
        this.refresh(this.props)
    }
    componentWillUnmount() {
        this.unmount = true
    }

    async refresh(props) {
        const {moduletype, state, config, moduleName} = props
        //console.log('moduletype, state, config, moduleName')
        //console.log(moduletype, state, config, moduleName)
        // 可编辑表格中数据处理
        if (utils.isNotBlank(moduleName)) {
            if (moduletype === 'list') {
                // 关联模块配置, 目标模块名称
                let joinConfig = global.getConfigByModuleName(config.params)
                this.setState({config: joinConfig})  
            } else if (moduletype === 'tree') {
                let joinConfig = global.getConfigByModuleName(config.params)
                let treeData = (state && state[config.params] && state[config.params].treeData) || []
                this.setState({treeData, config: joinConfig})
            }
        } else if (utils.isNotBlank(this.moduleName)) {
            // 表单中数据处理
            let config = global.getConfigByModuleName(this.moduleName)
            //console.log('config: ', config)
            if (moduletype === 'list') {
                this.setState({config})
            } else if (moduletype === 'tree') {
                // 树的全量数据只获取一次
                if (!(this.state.treeData && this.state.treeData.length > 0)) {
                    this.setState({loading: true})
                    const treeData = await getTreePromise(this.moduleName)
                    if (!this.unmount) {
                        this.setState({treeData, config, loading: false})
                    }
                }
            }
        }
    }

    /**
     * props发生变化时
     * @param {*} nextProps 
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        const {state, value, moduleName} = nextProps
        if (
            (moduleName && moduleName !== this.props.moduleName) ||
            (state && !utils.equals(state, this.props.state)) || 
            (!utils.equals(value, this.props.value))
        ) {
            this.refresh(nextProps)
        }
    }

    /**
     * 关闭弹窗回调
     */
    onCancel() {
        this.setState({showModal: false})
    }
    
    /**
     * 选中回调
     */
    onSelect(record) {
        let id = record.id
        let code = record.code
        let name = record.name
        // id 不变时更新其他字段
        //if (id !== (this.props.value && this.props.value.id)) {
            //console.log('value', value)
            this.props.onChange && this.props.onChange({id, code, name}, record)
        //}  
        this.setState({data: record, showModal: false})
    }

    toDetail() {
        const {value} = this.props
        if (value == null) {
            return
        }
        const {id, code} = value
        if (utils.isNotBlank(id)) {
            p.toDetail(this.moduleName, id, value)
            return
        }
        if (utils.isNotBlank(code)) {
            getOneByCode(this.moduleName, code, record => {
                p.toDetail(this.moduleName, record.id, record)
            })
        }
    }
    async onShowModal() {
        this.searchConds = await dataUtil.buildModuleDataSelectConds(this.props.thispage, this.moduleName)
        this.setState({showModal: true})
    }

    render() {
        const value = this.props.value || {}
        const {code, name} = value
        const showName = this.isShowCode ? code : (name || code)
        const { config } = this.state
        // 渲染阻断
        if (utils.isBlank(this.moduleName) || 
            config == null || utils.isBlank(config.modulename)
        ) {
            return <span>{showName}</span>
        }
        let ret
        if (this.props.moduletype === 'list') {
            const {moduletype, moduleName, onlySelect, disabled, ...restProps} = this.props
            if (disabled) {
                ret = <ListFieldsPopover
                   config={this.state.config} 
                   value={value}
                >
                    <A onClick={this.toDetail.bind(this)}>{showName}</A>
                </ListFieldsPopover>
            } else {
                ret = <Input {...restProps}
                    addonAfter={<Icon type="close" onClick={() => {
                        this.props.onChange && this.props.onChange(null)
                    }}/>}
                    onClick={this.onShowModal.bind(this)}
                    value={showName}
                />
            }
        } else if (this.props.moduletype === 'tree') {
            const {moduletype, moduleName, ...restProps} = this.props
            return <Spin spinning={this.state.loading}>
                <CascaderPopover
                    {...restProps}
                    options={this.state.treeData || []}
                />
            </Spin>
        }
        return <Spin spinning={this.state.loading}>
            {ret}
            {this.state.showModal && <ModalEx
                title={`从${dataUtil.showModuleName(this.moduleName)}中选`}
                visible={this.state.showModal}
                onCancel={this.onCancel.bind(this)}
                onClose={this.onCancel.bind(this)}
                maskClosable={true}
                width={global.modalWidth}
                footer={[]}
            >
                {this.state.config && <ListModule
                    searchPageKey={'_search_' + this.props.searchpagekey}
                    selectOne
                    hideSelect
                    disabled={this.props.onlySelect || false}
                    onSelect={this.onSelect.bind(this)}
                    config={this.state.config} 
                    fixedSearchConds={this.searchConds}
                />}
            </ModalEx>}
        </Spin>
    }
}
/**
 * value 组件value
 * moduleName 可编辑表格页面模块名称
 * state 可编辑表格页面this.state[config.params].treeData, 存放表格所有树类型的treeData
 */
ModuleDataSelect.propTypes = {
    config: PropTypes.object, // 字段配置
    moduletype: PropTypes.string, // moduletype（列表|树）
}