let dataBase = {
    name: '流程A',
    nodeList: [
        {
            id: 'nodeStart',
            name: '流程开始',
            type: 'process-start',
            left: 80,
            top: 70,
            ico:'play-circle',
            className: '',
            style: {
                background: '#7fecad'
            }
        },
        {
            id: 'nodeEnd',
            name: '流程结束',
            type: 'process-end',
            left: 380,
            top: 70,
            ico:'pause',
            className: '',
            style: {
                background: '#7fecad'
            }
        }
    ],
    lineList: []
}

export function getDataBase () {
    return dataBase
}
