import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Icon from './Icon'
import Button from 'antd/lib/button'
import Input from 'antd/lib/input'
import ModalEx from './ModalEx'
import {allIconNames} from './AllIconNames'
import ButtonIcon from './ButtonIcon'
import * as utils from '@utils/index'

export default class IconSelect extends Component {
    constructor() {
        super()
        this.state = {
            showModal: false,
            names: allIconNames,
            text: '',
        }
    }
    /**
     * 回车键响应函数
     * @param {*} e 
     */
    onKeyDown(e) {
        if (e.keyCode === 13) {
            this.onSearch()
        }
    }
    onSearch() {
        if (utils.isBlank(this.state.text)) {
            this.setState({names: allIconNames})
        } else {
            this.setState({names: allIconNames.filter(it => it.indexOf(this.state.text) !== -1)})
        }
    }

    /**
     * 关闭弹窗回调
     */
    onCancel() {
        this.setState({showModal: false})
    }

    render() {
        const {value, onChange, disabled} = this.props
        const {names} = this.state
        if (disabled && value == null) {
            return <span/>
        }
        return <div>
            <span style={this.props.style || {width: '100%'}}>
            {value ? 
                <span>
                    <Icon style={{fontSize: 20, marginRight: 8}} 
                        type={value}
                        onClick={() => this.setState({showModal: true, text: value})}
                    />
                    {!disabled && 
                    <Icon type='close' style={global.iconStyle}
                        onClick={() => onChange && onChange(null)}
                    />}
                </span>
                : 
                <Button onClick={() => this.setState({showModal: true})}>请选择</Button>
            }
            </span>
            {this.state.showModal && <ModalEx
                title={<span>
                    <Input style={{width: 200, marginLeft: 16, marginRight: 8}} 
                        placeholder='请输入图标名称' 
                        value={this.state.text} 
                        onChange={e => this.setState({text: e.target.value})} 
                        onKeyDown={this.onKeyDown.bind(this)}
                    />
                    <Button onClick={this.onSearch.bind(this)}><ButtonIcon type='search'/></Button>
                </span>}
                visible={this.state.showModal}
                onCancel={this.onCancel.bind(this)}
                onClose={this.onCancel.bind(this)}
                maskClosable={true}
                footer={[]}
            >
                <div style={{width: global.modalWidth}}>
                    {names.map((iconName, index) => 
                    <div key={index} style={{display: 'inline-block', width: 120, margin: 8}}>
                        <div className='centered'
                            onClick={disabled ? null : () => {
                                onChange && onChange(iconName)
                                this.setState({showModal: false})
                            }}
                        >
                            <div style={{height: 40}}><Icon style={{fontSize: 36}} type={iconName}/></div>
                            <div style={{height: 40, whiteSpace: 'text-wrap'}}><span>{iconName}</span></div>
                        </div>
                    </div>)}
                </div>
            </ModalEx>}
        </div>
    }
}

IconSelect.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
}