/**
 * <span/> 封装，可以接收form表单数据
 */
import React, { Component } from 'react'
import OOSelect from './OOSelect'
const options = [
    {value: 1, label: '是'},
    {value: 0, label: '否'},
]
export default class OOCheckBoxSearch extends Component {
    render () {
        return  <OOSelect {...this.props} options={options}/>
    }
}