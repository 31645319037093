/**
 * 通用独立接口
 */

import platformApi from '@utils/platform_api'
import message from 'antd/lib/message'
import * as p from '@utils/page'
import * as dataUtil from '@utils/DataUtil'
import c from '@utils/constants'
const { add ,update } = c

export function getObjByCode(code, cb) {
    global.getApi().get('/code', { 
        params: {
            code
        } 
    }).then(payload => {
        cb && cb(payload.body || {})
    }, () => {
        cb && cb({})
    })
}
export async function getObjByCodePromise(code) {
    try {
        const res = await global.getApi().get('/code', { 
            params: {
                code
            } 
        })
        return res && res.body
    } catch (e) {
        console.error(e)
    }
    return {}
}

export function login(data, success, err) {
    platformApi.post('/login', { 
        data: data
    }).then(payload => {
        success && success(payload.body || {})
    }, () => {
        err && err()
    })
}

/**
 * 使用事务批量串行调用接口
 * @param {Array<ApiItem>} apiArray 
 ApiItem: {
    name: update, 函数名称， processStart 流程开始moduleName
    moduleName: 模块名称， processStart 流程开始为空，函数内部固定使用flow
    args: [id, record]， 函数参数列表
 }
 */
export async function apiBatchPromise(apiArray) {
    const res = await global.getApi().post('/api/batch', { 
        params: {clientId: global.clientId},
        data: apiArray
    })
    return res && res.body
}

/**
 * 批量串行调用接口工具函数
 * @param {Function} getApiArray
 * @param {String} successMsg
 * @param {boolean} notip 操作完成后不提示
 * @returns 
 */
export async function callApiBatch(getApiArray, successMsg, notip) {
    if (!(getApiArray instanceof Function)) {
        console.error('getApiArray 必须为函数类型')
        return false
    }
    p.startLoad()
    const apiArray = []
    const res = await getApiArray(apiArray)
    if (!res || apiArray.length === 0) {
        p.stopLoad()
        return false
    }
    try {
        const resArray = await apiBatchPromise(apiArray)
        if (!(resArray instanceof Array)) {
            throw new Error('callApiBatch 结果不是数组')
        }
        if (apiArray.length !== resArray.length) {
            throw new Error('callApiBatch 结果数量和请求数量不相等')
        }
        for (let i = 0; i < apiArray.length; ++i) {
            let apiItem = apiArray[i]
            apiItem.success && apiItem.success(resArray[i])
        }
        !notip && message.success(successMsg || '操作成功', 1)
        p.stopLoad()
        return true
    } catch (e) {
        console.error(e)
        for (let apiItem of apiArray) {
            apiItem.error && apiItem.error()
        }
        p.stopLoad()
        return false
    }
}

/**
 * 组装修改操作 ApiItem
 * @param {*} moduleName 模块名称
 * @param {*} id id
 * @param {*} record 修改数据
 * @param {*} success 成功回调
 * @param {*} error
 * @returns ApiItem
 */
export function getUpdateApiItem(moduleName, id, record, success, error) {
    // 删除子模块key
    dataUtil.deleteSublistKey(record)
    return getApiItem(update, moduleName, [id, record], success, error)
}

/**
 * 组装修改操作 ApiItem
 * @param {*} moduleName 模块名称
 * @param {*} record 修改数据
 * @param {*} success 成功回调
 * @param {*} error
 * @returns ApiItem
 */
export function getAddApiItem(moduleName, record, success, error) {
    // 删除子模块key
    dataUtil.deleteSublistKey(record)
    return getApiItem(add, moduleName, [record], success, error)
}
/**
 * 组装操作 ApiItem
 * @param {String} name 操作名称
 * @param {String} moduleName 模块名称
 * @param {Array} args 修改数据
 * @param {Function} success 成功回调
 * @param {Function} error
 */
export function getApiItem(name, moduleName, args, success, error) {
    return {
        name, moduleName, args,
        success: async (newData) => {
            if (success instanceof Function) {
                await success(newData)
            }
        },
        error: async () => {
            if (error instanceof Function) {
                await error()
            }
        }
    }
}

/**
 * 组装修改操作 ApiItem, 然后调用 callApiBatch
 * @param {*} moduleName 
 * @param {*} id 
 * @param {*} record 
 * @param {*} success 
 * @param {*} message
 * @param {*} notip
 */
export async function callModifyByApiBatch(moduleName, id, record, success, message, notip) {
    const getApiArray = async apiArray => apiArray.push(
        getUpdateApiItem(moduleName, id, record, success)
    )
    await callApiBatch(getApiArray, message || '保存成功', notip)
}

/**
 * 组装新增操作 ApiItem, 然后调用 callApiBatch
 * @param {*} moduleName 
 * @param {*} record 
 */
export async function callAddByApiBatch(moduleName, record) {
    const getApiArray = async apiArray => apiArray.push(
        getAddApiItem(moduleName, record)
    )
    await callApiBatch(getApiArray, '保存成功')
}
