import React, { Component } from 'react'
import Popover from 'antd/lib/popover'
import Icon from './Icon'
import * as utils from '@utils/index'
export default class IconEx extends Component {
    render() {
        const {name, notip, ...restProps} = this.props
        if (notip || utils.isBlank(name)) {
            return <Icon {...restProps}/>
        }
        return <Popover content={<div> <span>{name}</span> </div>} >
            <Icon {...restProps}/>
        </Popover>
    }
}