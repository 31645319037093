import * as utils from '@utils/index'
import { initEnt, updateConfig } from '../../extension/PlatformAction'
import { callModifyByApiBatch } from '../layout/Action'
import { createIcon } from '@com/PageCreator'
import { wait } from '@utils/page'

export function iconOperation(text, record) {
    // 更新配置
    if (global.isPlatform()) {
        return createIcon('refresh', '更新配置', wait.bind(null, async () => {
            let sourceDbCode = record.template
            let targetDbCode = record.db
            console.log(sourceDbCode, targetDbCode)
            const res = await updateConfig(sourceDbCode, targetDbCode)
            if (res && res.message) {
                utils.success('更新配置完成')
            }
        }), { key: text + 'refresh' })
    }
    return null
}

export async function beforeAdd(apiArray, record) {
    if (global.isPlatform()) {
        try {
            // 初始化数据库
            await initEnt(record)
            // 绑定管理企业到用户
            const user = {}
            user.enterprise = record.code
            user.enterprise_id = record.id
            user.enterprise_name = record.name
            await callModifyByApiBatch('user', record.admin_id, user, null, null, true)
        } catch (e) {
            return false
        }
    }
    return true
}

export async function beforeSave(apiArray, record, stateRecord) {
    if (global.isPlatform()) {
        if (utils.isNotBlank(record.template) && record.template !== stateRecord.template) {
            // 修改模板数据库改变标记字段templateDbChange为false
            record.templateDbChange = true
        }
        // 数据库发生变化
        if (record.db !== stateRecord.db) {
            try {
                await initEnt(record)
            } catch (e) {
                return false
            } 
        }
    }
    return true
}