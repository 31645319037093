/**
 * OOTable
 * TableEx封装，根据oo系统规范配置创建Table
 * 不分页，展示全部数据
 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {configToColumn} from './PageCreator'
import TableEx from './TableEx'
import Button from 'antd/lib/button'
import ButtonIcon from './ButtonIcon'
import * as dataUtil from '@utils/DataUtil'
import * as utils from '@utils/index'
import c from '@utils/constants'
import { getTree } from '@tree/Action'
import {createIcon, addMenuItem} from './PageCreator'
import Menu from 'antd/lib/menu'
import Dropdown from 'antd/lib/dropdown'
import Search from './Search'
import { queryData, autoIncrease } from '@utils/tableUtil'

const {STRING} = c
export default class OOTable extends Component {
    constructor() {
        super()
        this.state = {
            list: [],
            listAll: [],
            fieldsConfig: [],
        }
    }
    /**
     * componentDidMount 页面加载完成后获取数据
     */
    async componentDidMount() {
        this.init()
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const {list, fieldsConfig, moduleName} = this.props
        if (nextProps.list !== list || 
            nextProps.fieldsConfig !== fieldsConfig || 
            nextProps.moduleName !== moduleName
        ) {
            this.init(nextProps)
        }
    }

    init(props = this.props) {
        const {moduleName} = props
        let fieldsConfig = props.fieldsConfig || []
        if (utils.isNotBlank(moduleName)) {
            console.log('moduleName', moduleName)
            let fields_config = utils.getModuleConfig(moduleName).fields_config
            if (fields_config instanceof Array && fields_config.length > 0) {
                fieldsConfig = fields_config.filter(it => !utils.isSysFields(it.name))
            }
        }
        this.setState({fieldsConfig})
        // 目前只为树模块获取数据，存入当前页面state中，以模块名称为key，treeData为值
        // TREEMODULE  分类等树形结构数据在表格中获取数据，每一列只需获取一次数据，
        // 如果进入 ModuleDataSelect 模块选择器组件后，再获取数据的话，每个单元格就会
        // 创建一个 ModuleDataSelect 实例，表格有多少行数据就会发多少次请求，所以表格中
        // ModuleDataSelect 所需的数据尽量在表格模型组件中获取，尤其是树形结构数据，不能分页，数据量会很大
        fieldsConfig.forEach(config => {
            if (config.isShowT !== '0') {
                if (utils.isNotBlank(config.params) && dataUtil.isTreeType(config.dataType)) {
                    getTree(config.params, treeData => {
                        this.setState({ [config.params]: { treeData } })
                    })
                }
            }
        })
        const {list} = props
        // 深拷贝数据源，在取消操作时可以复原到修改前的值
        const listCopy = utils.copy(list)
        if (list instanceof Array) {
            this.setState({list: listCopy, listAll: listCopy})
        }
    }

    // 获取表格数据
    getList() {
        const form = this.table
        const {moduleName, rowKey} = this.props
        const {listAll} = this.state
        return dataUtil.getTableList(this.state.fieldsConfig, form, listAll, rowKey, moduleName)
    }
    formError() {
        if (this.state.list.length === 0) {
            return false
        }
        return dataUtil.formError(this.table)
    }

    /**
     * 替换一条记录
     * @param {*} newRecord 
     */
    onOOTableChange(newRecord, key) {
        const listAll = this.state.listAll
        //console.log('OOTable onOOTableChange, newRecord', newRecord)
        let index = listAll.findIndex(item => item.id === newRecord.id)
        //console.log('oldRecord', listAll[index])
        if (index !== -1) {
            listAll.splice(index, 1, newRecord)
        }
        this.props.onOOTableChange && this.props.onOOTableChange(listAll, newRecord, key)
    }

    async onNew(insertIndex) {
        const {listAll, list} = this.state
        if (insertIndex === undefined) {
            let createuserIndex = listAll.findIndex(it => it.name === 'createuser')
            if (createuserIndex !== -1) {
                insertIndex = createuserIndex
            }
        }
        let newTotal = listAll.length + 1
        const initNewRecord = (total) => {
            const fieldsConfig = this.state.fieldsConfig
            const newRecord = {isNew: true}
            for (const config of fieldsConfig) {
                if (insertIndex !== undefined) {
                    total = newTotal
                }
                const initialValue = dataUtil.getInitialValue(config, {}, total, insertIndex)
                if (initialValue != null) {
                    newRecord[config.name] = initialValue
                }
            }
            return newRecord
        }

        const newRecords = []

        const addNewRecord = (total, initData) => {
            const newRecord = initNewRecord(total)
            Object.assign(newRecord, initData)
            newRecord.id = newRecord.id || utils.uuid()
            newRecords.push(newRecord)
        }
        // 合并父组件新记录初始化值
        if (this.props.getNewRecord) {
            const list = await this.props.getNewRecord()
            if (!list) {
                return
            }
            if (list instanceof Array) {
                for (let record of list) {
                    addNewRecord(newTotal++, record)
                }
            } else {
                addNewRecord(newTotal, list)
            }
        } else {
            addNewRecord(newTotal)
        }
        if (insertIndex !== undefined) {
            let insertIndexInAll = listAll.findIndex(it => list[insertIndex] && it.id === list[insertIndex].id)
            // list 和 listAll相同时不操作 list
            if (list.length < listAll.length) {
                list.splice(insertIndex, 0, ...newRecords)
            }
            if (insertIndexInAll !== -1) {
                listAll.splice(insertIndexInAll, 0, ...newRecords)
            }
        } else {
            // list 和 listAll相同时不操作 list
            if (list.length < listAll.length) {
                list.push(...newRecords)
            }
            listAll.push(...newRecords)
        }
        
        this.setState({ list, listAll })
        this.props.onOOTableChange && this.props.onOOTableChange(listAll)
    }

    async insertField(index) {
        await this.onNew(index)
    }

    onDel(id) {
        const list = this.state.list.filter(it => it.id !== id)
        const listAll = this.state.listAll.filter(it => it.id !== id)
        this.setState({ list, listAll })
        this.props.onOOTableChange && this.props.onOOTableChange(listAll)
    }
    
    up(index) {
        const {list, listAll} = this.state
        if (index > 0) {
            let indexInAll = listAll.findIndex(it => list[index] && it.id === list[index].id)
            let indexUpInAll = listAll.findIndex(it => list[index - 1] && it.id === list[index - 1].id)
            // list 和 listAll相同时不操作 list
            if (list.length < listAll.length) {
                utils.swap(list, index, index - 1)
            }
            if (indexInAll !== -1 && indexUpInAll !== -1) {
                utils.swap(listAll, indexInAll, indexUpInAll)
            }
            this.setState({ list, listAll })
            this.props.onOOTableChange && this.props.onOOTableChange(listAll)
        }
    }

    upToTop(index) {
        let {list, listAll} = this.state
        const toTop = (list, index) => {
            if (index > 0) {
                const record = list[index]
                list.splice(index, 1)
                list.unshift(record)
            }
        }
        // list 和 listAll相同时不操作 list
        if (list.length < listAll.length) {
            toTop(list, index)
        }
        let indexInAll = listAll.findIndex(it => list[0] && it.id === list[0].id)
        toTop(listAll, indexInAll)
        this.setState({ list, listAll })
        this.props.onOOTableChange && this.props.onOOTableChange(listAll)
    }

    down(index) {
        let {list, listAll} = this.state
        this.setState({ list })
        if (index < this.state.list.length - 1) {
            let indexInAll = listAll.findIndex(it => list[index] && it.id === list[index].id)
            let indexDownInAll = listAll.findIndex(it => list[index + 1] && it.id === list[index + 1].id)
            // list 和 listAll相同时不操作 list
            if (list.length < listAll.length) {
                utils.swap(list, index, index + 1)
            }
            if (indexInAll !== -1 && indexDownInAll !== -1) {
                utils.swap(listAll, indexInAll, indexDownInAll)
            }
            this.setState({ list, listAll })
            this.props.onOOTableChange && this.props.onOOTableChange(listAll)
        }
    }

    downToBottom(index) {
        let {list, listAll} = this.state
        const toBottom = (list, index) => {
            if (index < list.length - 1) {
                const record = list[index]
                list.splice(index, 1)
                list.push(record)
            }
        }
        // list 和 listAll相同时不操作 list
        if (list.length < listAll.length) {
            toBottom(list, index)
        }
        let indexInAll = listAll.findIndex(it => list[list.length - 1] && it.id === list[list.length - 1].id)
        toBottom(listAll, indexInAll)
        this.setState({ list, listAll })
        this.props.onOOTableChange && this.props.onOOTableChange(listAll)
    }

    specialColumn(column) {
        const {alwaysEdit, disabled, iconOperation} = this.props
		if (column.key === 'operation') {
			let {isShowAdd} = this.props
            if (isShowAdd == null) {
                isShowAdd = true
            }
            const {list} = this.state
            column.render = (text, record, index) =>{
                let moreOptions = []
                let canMoveDown = index < list.length - 1
                let canMoveUp = index !== 0
                if (canMoveUp) {
                    addMenuItem(moreOptions, '上移', this.up.bind(this, index))
                    addMenuItem(moreOptions, '上移至顶部', this.upToTop.bind(this, index))
                }
                if (canMoveDown) {
                    addMenuItem(moreOptions, '下移', this.down.bind(this, index))
                    addMenuItem(moreOptions, '下移至底部', this.downToBottom.bind(this, index))
                }
                if (isShowAdd) {
                    addMenuItem(moreOptions, '插入', this.insertField.bind(this, index))
                }
                return <span>
                    {iconOperation && iconOperation(text, record, index)}
                    {moreOptions.length > 0 && <Dropdown trigger={global.trigger} overlay={<Menu>{moreOptions}</Menu>}>
                        {createIcon('ellipsis-h')}
                    </Dropdown>}
                </span>
            }
		} else if (dataUtil.isNumberType(column.dataType) && alwaysEdit && !disabled &&
            column.otherParams && column.otherParams.autoincrease === '1'
        ) {
            column.sorter = false
            column.title = <div className='flex-row'>
                <div style={{marginRight: 8}}><span>{column.title}</span></div>
                <div className='circle' onClick={e => {
                    autoIncrease(column, this)
                }}><span>顺</span></div>
            </div>
        } else {
            this.props.specialColumn && this.props.specialColumn(column)
        }
	}
    onSearch(query = {}) {
        const list = this.state.listAll.filter(data => queryData(query, data))
        this.setState({list})
    }

    render () {
        const {
            // OOTable 参数
            // 显示新增按钮
            isShowAdd,
            addBtnName,
            // 新增回调初始化新增数据
            getNewRecord,
            // 显示保存按钮
            isShowSave,
            // 保存回调函数
            onSave,
            // 删除回调函数
            onDel,
            // 隐藏搜索组件
            hideSearch,
            // 隐藏操作列
            hideOperCol,
            // 隐藏序号列
            hideIndexCol,
            // 数据
            list,
            // 数据变化回调
            onOOTableChange,
            // configToColumn 其它参数
            specialColumn, disabled, props, renderWrapper, specialItemProps,
            // 注入图标操作
            iconOperation,
            // 其余参数，直接传入 TableEx
            ...restProps
        } = this.props
        const specialColumnWrapper = (item) => {
            let newItem = item
            let col = this.specialColumn(item)
            if (col) {
                newItem = col
            }
            if (disabled) {
                newItem.sorter = false
            }
            return newItem 
        }
        let configs = [].concat(this.state.fieldsConfig)
        if (!hideOperCol && !disabled && configs.findIndex(it => it.name === 'id') === -1) {
            configs.unshift({alias: '操作', name: 'id', dataType: STRING, width: 60, fixed: 'left' },)
        }
        const columns = configs.filter(config => !dataUtil.isGroupType(config.dataType))
        .map(config => {
            config.width = dataUtil.getNumber(config.width) || 160
            return configToColumn(this, config, specialColumnWrapper, !disabled, 
                {
                    moduleName: this.props.moduleName,    // 当前模块名称， 表格页面模块名称
                    state: this.state,              // 当前页面 state
                    /** 组件用到 state 的数据
                    {   key 为 bindModuleName 目标模块名称， value为树数据
                        [config.params]: {treeData}   // 存放表格所有树类型列的treeData
                    }
                    */
                    ...props
                }, 
                renderWrapper, specialItemProps
            )
        })
        const dataSource = this.state.list
        let rowKey = this.props.rowKey
        if (utils.isBlank(rowKey)) {
            rowKey = 'id'
            dataSource.map((it, index) => {
                it.id = it.id || global.uuid()
                it.index = it.index || index + 1
                return it
            })
        }
        
        let indexCol = {
            title: '序号',
            key: 'index',
            dataIndex: 'index',
            width: 60,
            fixed: 'left',
            render: (text, record, index) => {
                return index + 1
            }
        }
        const indexColIndex = columns.findIndex(col => col.key === 'index')
        if (!hideIndexCol && indexColIndex === -1) {
            const operColIndex = columns.findIndex(col => col.key === 'operation')
            if (operColIndex === -1) {
                columns.unshift(indexCol)
            } else {
                columns.splice(operColIndex + 1, 0, indexCol)
            }
        }

        let scrollx = 0
        // 计算表格总宽度
        columns.forEach(col => {
            scrollx += utils.o2i(col.width, 10)
        })
        if (scrollx < global.modalWidth) {
            columns.forEach(col => {
                delete col.fixed
            })
        }
        restProps.scroll = {x: scrollx, y: global.tableHeight - 76}
        //console.log('this.props.isShowDel', this.props.isShowDel)
        //console.log('restProps',restProps)
        const ootable = <TableEx 
            {...restProps} 
            disabled={disabled}
            ref={inst => this.table = inst}
            columns={columns} 
            dataSource={dataSource} 
            rowKey={rowKey}
            pagination={false}
            size={this.props.size || 'middle'}
            onOOTableChange={this.onOOTableChange.bind(this)}
            onDel={onDel || this.onDel.bind(this)}
            specialColumn={this.specialColumn.bind(this)}
        />
        const isEmpty = dataSource.length === 0
        // 封装新增按钮
        if (!disabled) {
            return <div>
                <div style={isEmpty ? {} : {marginBottom: 16}} >
                    {isShowAdd && <Button type='primary' className={global.buttonClass}
                        style={{marginRight: 16}}
                        onClick={() => this.onNew()}
                    >
                        <ButtonIcon type='plus' name={addBtnName || '新增'}/>
                    </Button>}
                    {isShowSave && <Button type='primary' className={global.buttonClass}
                        onClick={() => onSave && onSave()}
                    >
                        <ButtonIcon type='save' name='保存'/>
                    </Button>}
                    {this.props.buttons}
                    {!hideSearch && <Search
                        fieldsConfig={this.state.fieldsConfig}
                        onSearch={this.onSearch.bind(this)}
                    />}
                </div>
                {!isEmpty && ootable}
            </div>
        }
        if (isEmpty) {
            return <div/>
        }
        return ootable
    }
}

/**
 * configToColumn
 * 模块字段配置转换成表格组件Table的表头
 * @param  {any} thisPage 创建页面的this引用
 * @param  {Object} config 字段配置
 * @param  {CallableFunction} specialColumn(item/转换好的column/) 特殊字段处理回调函数
 * @param  {Boolean} isEdit 该列是否处于编辑状态
 * @param  {Object} props 其他额外的参数
 * @param  {CallableFunction} renderWrapper 拦截并包装 column render(text, row, index) 目前在报表合并单元格中使用
 * @param  {CallableFunction} specialItemProps (formField/转换好的参数/, index) 特殊处理回调函数
 * @returns {Object} column Table组件的标准表头
 */

/**
 * TableEx 其它参数
 * 
showHeader={false}
ref={(inst) => this.table = inst}
disabled={!this.tableCanEdit}
onSave={this.onSave.bind(this)}
onAdd={this.onAdd.bind(this)}
onCancle={this.onCancle.bind(this)}
scroll={{ x: scrollx, y: tableContentHeight}}
columns={columns}
dataSource={list}
onChange={this.handleTableChange.bind(this)}
rowKey={record => record.id}
pagination={pagination}
rowSelection={rowSelection}
onRow={this.onRow.bind(this)}
expandedRowRender={undefined}
size={this.state.size}
moduleName={this.moduleName}
 */
OOTable.propTypes = {
    // 模块名称
    moduleName: PropTypes.string,
    // 配置
    fieldsConfig: PropTypes.array,
    // 数据
    list: PropTypes.array,
    // 数据变化回调
    onOOTableChange: PropTypes.func,
    // configToColumn 其它参数
    specialColumn: PropTypes.func,
    props: PropTypes.any,
    renderWrapper: PropTypes.func,
    specialItemProps: PropTypes.func,
    isShowAdd: PropTypes.bool,
    addBtnName: PropTypes.string,
    // 新增回调，初始化新增记录数据
    getNewRecord: PropTypes.func,
    hideSearch: PropTypes.bool
}
