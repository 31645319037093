/**
 * 流程引擎接口
 */
import * as utils from '@utils/index'

/**
 * 查询待办流程
 * @param {*} moduleName 模块名称 flow
 * @param {*} page 
 * @param {*} pageSize 
 * @param {*} query 
 * @param {*} sorter 
 * @param {*} success 
 */
export async function processTodo(moduleName, page, pageSize, query, sorter, success) {
    let data = { page, pageSize, query, sorter }
    try {
        const payload = await utils.getApi().put('/process/todo', { data })
        return payload.body || {}
    } catch (e) {}
    return {}
}

/**
 * 查询已办流程
 * @param {*} moduleName 模块名称 flow
 * @param {*} page 
 * @param {*} pageSize 
 * @param {*} query 
 * @param {*} sorter 
 * @param {*} success 
 */
export async function processDone(moduleName, page, pageSize, query, sorter, success) {
    let data = { page, pageSize, query, sorter }
    try {
        const payload = await utils.getApi().put('/process/done', { data })
        return payload.body || {}
    } catch (e) {}
    return {}
}

// 根据业务对象id查询流程对象
export function processOne(data, success) {
    utils.getApi().post('/process/one', {data}).then(res => {
        success && success(res.body)
    })
}
export async function processOnePromise(data, success) {
    try {
        const res = await utils.getApi().post('/process/one', {data})
        return res.body
    } catch(e) {}
    return {}
}