import {getCookie} from '../cookie'
import * as utils from '../index'

/**
 * getItem
 * @param {String} key 
 * @param {String} moduleName 
 */
export function getItem(key, moduleName) {
    if (null == key) {
        console.error('getItem 参数 key不能为空')
        return null
    }
    if (null == moduleName) {
        console.error('getItem 参数 moduleName不能为空')
        return null
    }
    global[moduleName] = global[moduleName] || {}
    //console.log(global[moduleName], key)
    return global[moduleName][key]
}

export function getItemSync(key, moduleName, afterOPer) {
    global.getApi().get('/system/store/item', {
        params: {
            userid: getCookie('userid'),
            entid: getCookie('entid'),
            storeKey: key,
            storeModule: moduleName,
        }
    }).then((payload) => {
        let doc = payload.body || {}
        afterOPer && afterOPer(doc.storeValue)
    }, error => {
        console.error(error.body)
    })
}


/**
 * setItem
 * @param {String} key 
 * @param {String} value 
 * @param {String} moduleName 
 * @param {Fuction} afterOPer 等待异步操作完成后的操作
 */
export async function setItem(key, value, moduleName, afterOPer) {
    if (null == key) {
        console.error('setItem 参数 key不能为空')
        return
    }
    if (null == moduleName) {
        console.error('setItem 参数 moduleName不能为空')
        return null
    }
    await setItemPromise(key, value, moduleName)
    if (afterOPer) {
        if ((typeof afterOPer) === 'function') {
            afterOPer()
        } else {
            console.error('setItem 参数 afterOPer' + afterOPer + ' 不是函数类型')
        }
    }
}
/**
 * setItems
 * @param {Array<{key, value, moduleName}>} items 
 * @param {Fuction} afterOPer 等待异步操作完成后的操作
 */
export async function setItems(items, afterOPer) {
    if (!(items instanceof Array)) {
        console.error('setItems 参数 items' + items + ' 不是数组类型')
        return
    }
    await setItemPromise(items)
    if (afterOPer) {
        if ((typeof afterOPer) === 'function') {
            afterOPer()
        } else {
            console.error('setItems 参数 afterOPer' + afterOPer + ' 不是函数类型')
        }
    }
}

/**
 * removeItem
 * @param {String} key 
 * @param {String} moduleName 
 * @param {Fuction} afterOPer 等待异步操作完成后的操作
 */
export async function removeItem(key, moduleName, afterOPer) {
    if (null == key) {
        console.error('removeItem 参数 key不能为空')
        return
    }
    if (null == moduleName) {
        console.error('removeItem 参数 moduleName不能为空')
        return
    }
    await removeItemPromise(key, moduleName)
    if (afterOPer) {
        if ((typeof afterOPer) === 'function') {
            afterOPer()
        } else {
            console.error('removeItem 参数 afterOPer' + afterOPer + ' 不是函数类型')
        }
    }
}

/**
 * removeItems
 * @param {Array<{key, moduleName}>} items 
 * @param {Fuction} afterOPer 等待异步操作完成后的操作
 */
export async function removeItems(items, afterOPer) {
    if (!(items instanceof Array)) {
        console.error('removeItems 参数 items' + items + ' 不是数组类型')
        return
    }
    await removeItemPromise(items)
    if (afterOPer) {
        if ((typeof afterOPer) === 'function') {
            afterOPer()
        } else {
            console.error('removeItem 参数 afterOPer' + afterOPer + ' 不是函数类型')
        }
    }
}

/**
 * initGlobal 远程数据同步到本地全局变量
 */
export function initGlobal(afterOPer) {
    global.getApi().get('/system/store', {
        params: {
            userid: getCookie('userid'),
            entid: getCookie('entid')
        }
    }).then((payload) => {
        let list = payload.body || []
        for (let item of list) {
            const key = item.storeKey
            let moduleName = item.storeModule
            let value = item.storeValue
            global[moduleName] = global[moduleName] || {}
            global[moduleName][key] = value
        }
        afterOPer && afterOPer()
    }, error => {
        console.error(error && error.body)
    })
}

/**
 * setItemOne 设置单个
 * @param {String} key 
 * @param {String} value 
 * @param {String} moduleName 
 */
function setItemOne(key, value, moduleName) {
    if (utils.isBlank(getCookie('userid'))) {
        return null
    }
    if (null == key) {
        console.error("key不能为空")
        return null
    }
    if (null == moduleName) {
        console.error("storeModule不能为空")
        return null
    }
    global[moduleName] = global[moduleName] || {}
    global[moduleName][key] = value
    return global.getApi().post('/system/store', {
        data: [{
            id: global.uuid(),
            userid: getCookie('userid'),
            entid: getCookie('entid'),
            storeKey: key,
            storeModule: moduleName,
            storeValue: value
        }]
    })
}

/**
 * setItemList 设置一组
 * @param {Array<{key, value, moduleName}>} list 
 */
function setItemList(list) {
    if (utils.isBlank(getCookie('userid'))) {
        return null
    }
    if (!(list instanceof Array)) {
        console.error("必须为数组类型！")
        return null
    }
    let setList = []
    for (let item of list) {
        let key = item.storeKey
        let moduleName = item.storeModule
        let value = item.storeValue
        if (null == key) {
            console.error("存在key为空的项")
            return null
        }
        if (null == moduleName) {
            console.error("存在storeModule为空的项")
            return null
        }
        setList.push({
            id: global.uuid(),
            userid: getCookie('userid'),
            entid: getCookie('entid'),
            storeKey: key,
            storeModule: moduleName,
            storeValue: value
        })
        global[moduleName] = global[moduleName] || {}
        global[moduleName][key] = value
    }
    return global.getApi().post('/system/store', {
        data: setList
    })
}

/**
 * setItemPromise 
 * 1 第一个参数传数组时设置多个，后面参数不传
 * 2 第一个参数传String时设置单个
 * @param {*} key 
 * @param {*} value 
 * @param {*} moduleName 
 */
function setItemPromise(key, value, moduleName) {
    if (null == key) {
        console.error("key不能为空")
        return null
    }
    if (key instanceof Array) {
        return setItemList(key)
    } else if ((typeof key) === 'string') {
        return setItemOne(key, value, moduleName)
    } else {
        console.error("第一个参数只能是String或Array类型")
    }
    return null
}

/**
 * removeItemOne 删除一个
 * @param {*} key 
 * @param {*} moduleName 
 */
function removeItemOne(key, moduleName) {
    if (utils.isBlank(getCookie('userid'))) {
        return null
    }
    if (null == key) {
        console.error("key不能为空")
        return null
    }
    if (null == moduleName) {
        console.error("storeModule不能为空")
        return null
    }

    global[moduleName] = global[moduleName] || {}
    global[moduleName][key] = undefined
    return global.getApi().put('/system/store', {
        data: [{
            userid: getCookie('userid'),
            entid: getCookie('entid'),
            storeKey: key,
            storeModule: moduleName
        }]
    })
}

/**
 * removeItemList 批量删除
 * @param {Array<{key, moduleName}>} list 
 */
function removeItemList(list) {
    if (utils.isBlank(getCookie('userid'))) {
        return null
    }
    if (!(list instanceof Array)) {
        console.error("必须为数组类型！")
        return null
    }
    let delList = []
    for (let item of list) {
        let key = item.storeKey
        let moduleName = item.storeModule
        if (null == key) {
            console.error("存在key为空的项")
            return null
        }
        if (null == moduleName) {
            console.error("存在storeModule为空的项")
            return null
        }
        delList.push({
            userid: getCookie('userid'),
            entid: getCookie('entid'),
            storeKey: key,
            storeModule: moduleName
        })
        global[moduleName] = global[moduleName] || {}
        global[moduleName][key] = undefined
    }
    return global.getApi().put('/system/store', {
        data: delList
    })
}

/**
 * removeItemPromise
 * 1 第一个参数传数组时删除多个，后面参数不传
 * 2 第一个参数传String时删除单个
 */
function removeItemPromise(key, moduleName) {
    if (null == key) {
        console.error("key不能为空")
        return null
    }
    if (key instanceof Array) {
        return removeItemList(key)
    } else if ((typeof key) === "string") {
        return removeItemOne(key, moduleName)
    } else {
        console.error("第一个参数只能是String或Array类型")
    }
    return null
}