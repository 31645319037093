import React, { Component } from 'react';
import { ColorSelector } from 'color-selector-react';
import 'color-selector-react/dist/es/index.css'; // 样式
import { getColor, isBlank } from '@utils/index';

export default class OOColor extends Component {
    constructor() {
        super()
        this.state = {
            visible: false
        }
    }
    componentDidMount() {
        this.init()
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        const {value} = this.props
        if (value !== nextProps.value) {
            this.init(nextProps)
        }
    }
    init(props = this.props) {
        const randomColor = getColor()
        const {value, onChange} = props
        this.setState({value: value || randomColor})
        if (isBlank(value)) {
            onChange && onChange(randomColor)
        }
    }
    
    render() {
        const {style, onChange} = this.props
        const {value, visible} = this.state
        return <div style={style}>
            <div
                onClick={() => this.setState({visible: !visible})}
                style={{
                    width: 24,
                    height: 24,
                    border: '1px solid #ccc',
                    backgroundColor: value,
                }}
            />
            {visible && <ColorSelector
                style={{ position: 'absolute' }}
                color={value}
                visible={visible}
                onChange={({ color }) => {
                    this.setState({value: color})
                    onChange && onChange(color)
                }}
                onVisibleChange={(visible) => this.setState({visible})}
            />}
        </div>
    }
}