import {getListAll} from '@list/Action'
import * as o from './object'
import * as c from './constants'
import * as p from './page'
import * as msgType from './msgType'

const { datatransfer, orchestrate, reportengine, chartdesign, setting} = c
const { reportengineList, chartdesignList, orchestrateList, datatransferList, treeDataObj } = o
const { connected, systemConfigUpdate,
    treeUpdate, listUpdate, 
    add, update, del,
    notify, workflow, 
} = msgType
const { updateSystem, reRenderAllPage, 
    refreshPage, refreshListPage, deleteAllPage,
    refreshFModulePage, refreshModalPage
} = p

export function onmessage(msg) {
    try {
        let obj = JSON.parse(msg)
        const {id, clientId, type, body} = obj
        handleMsg(id, clientId, type, body)
    } catch(e) {
        console.log('parse message error, msg: ', msg)
    }
}
function handleMsg(id, clientId, type, body = {}) {
    //console.log('oomsg: id', id)
    //console.log('oomsg: clientId', clientId)
    console.log('oomsg:', type, body)
    const {moduleName, ids} = body
    switch (type) {
        case connected: {
            global.clientId = id
            console.log('global.clientId', global.clientId)
            break
        }
        case add: {
            refreshListPage(clientId, moduleName, 1)
            refreshFModulePage(clientId, moduleName)
            break
        }
        case update: {
            refreshPage(clientId, moduleName, ids)
            refreshFModulePage(clientId, moduleName)
            break
        }
        case del: {
            deleteAllPage(clientId, moduleName, ids)
            refreshFModulePage(clientId, moduleName)
            refreshModalPage(clientId)
            break
        }
        case systemConfigUpdate: {
            updateSystem()
            break
        }
        case treeUpdate: {
            treeDataObj[moduleName + 'update'] = true
            break
        }
        case listUpdate: {
            if (moduleName === setting) {
                getListAll(setting, {}, null, list => {
                    if (list instanceof Array && list.length === 1) {
                        Object.assign(o.setting, list[0])
                        reRenderAllPage()
                    }
                })
            } else if (moduleName === datatransfer) {
                getListAll(datatransfer, {}, null, list => {
                    if (list instanceof Array) {
                        datatransferList.splice(0, datatransferList.length, ...list)
                    }
                })
            } else if (moduleName === orchestrate) {
                getListAll(orchestrate, {}, null, list => {
                    if (list instanceof Array) {
                        orchestrateList.splice(0, orchestrateList.length, ...list)
                    }
                })
            } else if (moduleName === reportengine) {
                getListAll(reportengine, {}, null, list => {
                    if (list instanceof Array) {
                        reportengineList.splice(0, reportengineList.length, ...list)
                    }
                })
            } else if (moduleName === chartdesign) {
                getListAll(chartdesign, {}, null, list => {
                    if (list instanceof Array) {
                        chartdesignList.splice(0, chartdesignList.length, ...list)
                    }
                })
            }
            break
        }
        case notify: {
            break
        }
        case workflow: {
            break
        }
        default: {
            console.log('type: ', type, 'not support')
        }
    }
}