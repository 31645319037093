/**
 * 系统模块特殊处理
 */
import c, { auth, orchestrate, reportengine, chartdesign, staff } from '@utils/constants'
import * as utils from '@utils/index'
import * as datatransferProcessor from '../datatransfer/datatransferProcessor'
import * as enterpriseProcessor from '../entprise/enterpriseProcessor'
import * as codeSchemeProcessor from '../code_scheme/codeSchemeProcessor'
import * as roleProcessor from '../role/roleProcessor'
import * as staffProcessor from '../staff/staffProcessor'
import * as reportProcessor from '../report/reportProcessor'
import * as chartProcessor from '@/charts/chartProcessor'
import * as orchestrateProcessor from '../orchestrate/orchestrateProcessor'

const {tables_config, code_scheme, enterprise, datatransfer} = c

/**
 * 系统模块表单页字段配置处理
 * @param {*} moduleName 
 * @param {*} fieldsConfig 
 * @param {*} data 
 * @returns 
 */
export function filterConfigByModule(moduleName, fieldsConfig, data) {
    if (tables_config === moduleName) {
        // 系统模块只能修改别名，菜单，图标和排序信息
        if (!global.localFrontEnd && utils.isSysMenu(data.modulename)) {
            fieldsConfig = fieldsConfig.filter(config => 
                ['modulename', 'alias', 'menu', 'no', 'icon', 'sortfield', 'sortorder'].indexOf(config.name) !== -1)
        }
    }
    return fieldsConfig
}

export function moduleIconOperation(moduleName, text, record, index) {
    if (enterprise === moduleName) {
        return enterpriseProcessor.iconOperation(text, record, index)
    } else if (auth === moduleName) {
        return roleProcessor.iconOperation(text, record)
    } else if (staff === moduleName) {
        return staffProcessor.iconOperation(text, record)
    } else if (reportengine === moduleName) {
        return reportProcessor.iconOperation(text, record)
    } else if (chartdesign === moduleName) {
        return chartProcessor.iconOperation(text, record)
    } else if (orchestrate === moduleName) {
        return orchestrateProcessor.iconOperation(text, record)
    }
    return null
}

/**
 * 系统模块特殊字段处理
 * @param {*} moduleName 
 * @param {*} formField 
 * @param {*} props 
 * @param {*} data 
 */
export function moduleSpecialItemProps(moduleName, formField, props, data) {
    if (tables_config === moduleName) {
        // 系统模块禁止修改模块名称
        if (formField.id === 'modulename' && utils.isSysMenu(data.modulename)) {
            props.disabled = true
        }
    } else if (datatransfer === moduleName) {
        datatransferProcessor.specialItemProps(formField, props, data)
    } else if (code_scheme === moduleName) {
        codeSchemeProcessor.specialItemProps(formField, props, data)
    }
}

/**
 * 特殊模块列
 * @param {*} moduleName 
 * @param {*} column 
 */
export function moudleSpecialColumn(moduleName, column) {
    // 权限管理，权限编码为 admin 不可操作
    if (moduleName === auth && column.key === 'operation') {
        column.disabled = (text, record) => {
            if (record.code === 'admin') {
                return true
            }
            return false
        }
    }
}

/**
 * 特殊模块操作列渲染
 * @param {*} moduleName 
 * @param {*} text 
 * @param {*} record 
 * @param {*} index 
 * @returns 
 */
export function moduleSpecialOperationRender(moduleName, text, record, index) {
    // 权限管理，权限编码为 admin 不可操作
    if (moduleName === auth) {
        if (record.code === 'admin') {
            return null
        }
    }
    return undefined
}

export async function beforeAdd(moduleName, apiArray, record) {
    if (moduleName === enterprise) {
        return await enterpriseProcessor.beforeAdd(apiArray, record)
    } else if (moduleName === code_scheme) {
        return await codeSchemeProcessor.beforeAdd(apiArray, record)
    }
    return true
}

export async function beforeSave(moduleName, apiArray, record, stateRecord) {
    if (moduleName === enterprise) {
        return await enterpriseProcessor.beforeSave(apiArray, record, stateRecord)
    } else if (moduleName === code_scheme) {
        return await codeSchemeProcessor.beforeSave(apiArray, record)
    }
    return true
}

export async function canDelete(moduleName, apiArray, record) {
    if (staff === moduleName) {
        return await staffProcessor.canDelete(apiArray, record)
    }
    return true
}

export function moduleOnNew(moduleName, record) {
    if (record == null) {
        return
    }
    if (datatransfer === moduleName || orchestrate === moduleName) {
        record.modulename = record.modulename || utils.parseSearchStr().modulename
    }
}