let flowMenuData = [{
	id: '1',
	type: 'conctrol',
	name: '控制组件',
	ico: 'setting',
	open: true,
	children: [
		{
			id: '11',
			type: 'process-start',
			name: '流程开始',
			ico: 'play-circle',
			// 自定义覆盖样式
			style: {
				background: '#7fecad'
			}
		},
		{
			id: '12',
			type: 'process-end',
			name: '流程结束',
			ico: 'pause',
			// 自定义覆盖样式
			style: {
				background: '#7fecad'
			}
		},
		{
			id: '13',
			type: 'workflow-node',
			name: '流程节点',
			ico: 'random',
			// 自定义覆盖样式
			style: {
				background: '#d6ecf0'
			}
		},
		// {
		//	 id: '14',
		//	 type: 'branch',
		//	 name: '决策',
		//	 ico: 'share-alt',
		//	 // 自定义覆盖样式
		//	 style: {
		//		 background: '#fff2df'
		//	 }
		// },
	]
}]
export default flowMenuData
