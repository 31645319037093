/**
 * 数字范围组件
 */
import React, { Component } from 'react'
import Input from 'antd/lib/input'
import Percent from './Percent'
const InputGroup = Input.Group
export default class PercentArea extends Component {
    /**
     * 构造函数 初始化
     */
    constructor() {
        super()
        this.state = {
            leftValue: null,
            rightValue: null,
        }
    }

    /**
     * 左边输入框发生变化
     * @param {*} e 
     */
    LonChange(value) {
        this.setState({leftValue: value})
        const rightValue = this.state.rightValue
        this.props.onChange && this.props.onChange([
            !value ? undefined : value / 100,
            !rightValue ? undefined : rightValue != null && rightValue / 100
        ])
    }

    /**
     * 右边输入框发生变化
     * @param {*} e 
     */
    RonChange(value) {
        this.setState({rightValue: value})
        const leftValue = this.state.leftValue
        this.props.onChange && this.props.onChange([
            !leftValue ? undefined : leftValue != null && leftValue / 100,
            !value ? undefined : value / 100
        ])
    }

    /**
     * 值转换成state
     * @param {*} value 
     */
    propsValue2State(value) {
        let leftValue
        let rightValue
        if (value instanceof Array && value && value.length === 2) {
            leftValue = value[0] && value[0] * 100
            rightValue = value[1]
            rightValue = (!rightValue ? null : rightValue * 100)
        } else {
            leftValue = null
            rightValue = null
        }
        this.setState({leftValue: leftValue, rightValue: rightValue})
    }

    /**
     * 组件加载时
     */
    UNSAFE_componentWillMount() {
        const {value} = this.props
        this.propsValue2State(value)
    }

    /**
     * props发生变化时
     * @param {*} nextProps 
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        const {value} = nextProps
        this.propsValue2State(value)
    }

    /**
     * 渲染函数
     */
    render () {  
        return <InputGroup compact={true}>
            <Percent
                key='input1'
                style={{ width: '43%', textAlign: 'center' }}
                placeholder='最小值'
                type='number'
                onChange={this.LonChange.bind(this)}
                value={this.state.leftValue}
            />
            <Input
                key='input2'
                style={{ width: '14%', borderLeft: 0, pointerEvents: 'none', backgroundColor: '#fff' }}
                placeholder='-'
                disabled={true}
            />
            <Percent
                key='input3'
                style={{ width: '43%', textAlign: 'center', borderLeft: 0 }}
                placeholder='最大值'
                type='number'
                onChange={this.RonChange.bind(this)}
                value={this.state.rightValue}
            />
        </InputGroup>
    }
}