import React, { Component } from 'react'
import './flow.css'
import Menu from 'antd/lib/menu'
import Button from 'antd/lib/button'
import ButtonIcon from '@com/ButtonIcon'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Checkbox from 'antd/lib/checkbox'
import Modal from 'antd/lib/modal'
import ModalEx from '@com/ModalEx'
import jsplumb from 'jsplumb'
import { easyFlowMixin } from './mixins'
import flowMenuData from './flowMenuData'
import businessProcessMenuData from './businessProcessMenuData'
import { getDataBase } from "./dataBase"
import Icon from '@com/Icon'
import Popover from 'antd/lib/popover'
import Draggable from 'react-draggable'
import $ from 'jquery'
import * as TableAction from '../module_config/Action'
import * as ListAction from '@list/Action'
import * as TreeAction from '@tree/Action'
import {callModifyByApiBatch} from '../layout/Action'
import ModuleDataSelect from '@com/ModuleDataSelect'
import * as utils from '@utils/index'
import * as p from '@utils/page'
import { orchestrate, tables_config } from '@utils/constants'

const copy = utils.copy

const {SubMenu} = Menu
const offsetY = 100
const offsetX = 300
let jsPlumbIn = null
let easyFlowMixinData = easyFlowMixin.data()
let {jsplumbSetting, jsplumbConnectOptions, jsplumbSourceOptions, jsplumbTargetOptions} = easyFlowMixinData
const listOperation = ['新增', '修改', '删除', '查询', '查询单个', 'ID查询', '编码查询', '统计', '生成编码']
const treeOperation = ['修改', '删除', 'ID查询']
const controlNodeType = ['process-start', 'process-end', 'process-branch', 'process-async-start', 
	'process-async-end', 'loop-start', 'loop-end']
/**
* 错误连线的样式
*/
const errorPaintStyle = 
{
  // 线的颜色
  stroke: 'red',
  // 线的粗细，值越大线越粗
  strokeWidth: 1,
  // 设置外边线的颜色，默认设置透明，这样别人就看不见了，点击线的时候可以不用精确点击，参考 https://blog.csdn.net/roymno2/article/details/72717101
  outlineStroke: 'transparent',
  // 线外边的宽，值越大，线的点击范围越大
  outlineWidth: 10
}

class Flow extends Component {
	constructor(props) {
		super(props)
		this.id = utils.parseSearchStr().id
		this.nodeList = []
		this.lineList = []
		this.undoList = []
		this.redoList = []
		this.state = {
			update: false,
			menuList: [],
			bizNodeList: [],
			node: {},
			selectedNodes: [],
			activeElement: {
				// 可选值 node 、line
				type: undefined,
				// 节点ID
				id: undefined,
				// 连线ID
				sourceId: undefined,
				targetId: undefined,
			},
			line: {},
			activeMenuId: null,
			show: false,
			text: null,
			selectedKeys: [],
			menuTreeData: [],
			allMenuTreeData: [],
			flowJson: '',
		}
		this.disabled = global.parseSearchStr().disabled === 'true'
	}
	componentDidMount() {
		this.nodeList = []
		this.lineList = []
		this.undoList = []
		this.redoList = []
		//console.log('Flow componentDidMount---------------')
		p.startLoad()
		jsPlumbIn = jsplumb.jsPlumb.getInstance()
		jsPlumbIn.setContainer (this.refs.efContainer)//设置面板
		this.jsPlumbInit()
		let menuList = []
		// 工作流
		if (this.props.type === 'workflow') {
			menuList = flowMenuData
		} else if (this.props.type === 'bizflow') {
			// 流程编排
			menuList = businessProcessMenuData
		}
		let activeElement = {
			// 可选值 node 、line
			type: undefined,
			// 节点ID
			id: undefined,
		}
		// url中携带选中节点
		if (utils.isNotBlank(global.parseSearchStr().nodeId)) {
			activeElement.type = "node"
			activeElement.nodeId = global.parseSearchStr().nodeId
		}
		this.setState({menuList, activeElement})

		this.getInfo()
		this.getMenuTree()
		document.addEventListener('keydown', this.onKeyDown.bind(this))
		document.addEventListener('keyup', this.onKeyUp.bind(this))
	}

	componentWillUnmount() {
		if (jsPlumbIn) {
			jsPlumbIn.clear()
			jsPlumbIn = null
		}
		document.removeEventListener('keydown', this.onKeyDown.bind(this))
		document.removeEventListener('keyup', this.onKeyUp.bind(this))
	}

	componentDidUpdate() {
		// 初始化节点
		this.loadEasyFlow()
	}

	/**
	 * 获取系统菜单和系统配置
	 */
	getMenuTree() {
		TreeAction.getTree('menu', treeData => {
			treeData = global.copy(treeData)
			this.getModuleConfigs(treeData)
		})
	}

	/**
	 * 获取系统配置
	 */
	getModuleConfigs(treeData) {
		global.traverseTree(treeData, node => {
			node.title = node.name
		})
		if (!(treeData instanceof Array) || treeData.length !== 1) {
			Modal.error({
				title: `菜单数据损坏，请联系管理员`,
				okText: '知道了',
				onOk: () => {this.props.history.replace('/login');}
			})
			return
		}
		let bizNodeList = []
		//console.log(treeData)
		treeData[0].children = treeData[0].children || []
		let moduleConfigs = global.copy(utils.getModuleConfigs())
		for(let module of moduleConfigs) {
			if (utils.isSysModule(module.modulename)) {
				continue
			}
			let moduleName = global.showModuleName(module)
			module.key = module.title = moduleName
			let operation = listOperation
			if (module.moduletype === 'tree') {
				operation = treeOperation
			}
			module.children = operation.map(name => {
				let key = name + '_' + moduleName
				let title = name + ' - ' + moduleName
				let bizNode = {
					key,
					id: key,
					type: 'biz-node',
					title,
					name: title,
					ico: 'microchip',
					// 自定义覆盖样式
					style: {
						background: '#d6ecf0'
					}
				}
				bizNodeList.push(bizNode)
				return bizNode
			})
			if (utils.isBlank(module.menu)) {
				treeData[0].children.push(module)
			} else {
				let text = module.menu_id
				let node = utils.searchTreeNode(text, treeData, 'value')
				if (null == node) {
					treeData[0].children.push(module)
				} else {
					node.children = node.children || []
					node.children.push(module)
				}
			}
		}
		let menuTreeData = treeData[0].children
		let sortTree = (tree) => {
			if (tree instanceof Array) {
				tree.sort(utils.sortByKeyAndType('no', 'NUMBER'))
				for (let node of tree) {
					sortTree(node.children)
				}
			}
		}
		sortTree(menuTreeData)
		this.setState({allMenuTreeData:menuTreeData, menuTreeData:menuTreeData, bizNodeList})
		p.stopLoad()
	}

	onMenuItemClick(info) {
		const { key } = info
		//const { item, keyPath } = info
		//console.log(item, key, keyPath, 'item, key, keyPath')
		this.setState({selectedKeys: [key]})
	}

	saveNode(key, value) {
		//console.log('value', value)
		let node = this.state.node
		if (key === 'name' || key === 'jointlysign') {
			node[key] = this.props.form.getFieldValue(key)
		} else {
			value = value || {}
			const {id, code, name} = value
			// 候选人 候选组
			if ('candidategroup' === key) {
				node[key] = id
			} else {
				node[key] = code
			}
			node[`${key}_name`] = name
			node[`${key}_id`] = id
		}
		for (let i = 0; i < (this.nodeList && this.nodeList.length); ++i) {
			let item = this.nodeList[i]
			if (node.id === item.id) {
				this.nodeList[i] = node
			}
		}
		this.setState({node})
	}

	saveLine() {
		let line = this.state.line
		line.label = this.props.form.getFieldValue('label')
		line.cond = this.props.form.getFieldValue('cond')
		line.exception = this.props.form.getFieldValue('exception')
		
		for (let item of this.lineList) {
			if (item.from === line.from && item.to === line.to) {
				item.cond = line.cond
				item.label = line.label
				item.exception = line.exception
				//console.log('saveLine', item)
				let connParam = {
					source: line.from,
					target: line.to,
					label: line.label ? line.label : "",
					connector: line.connector ? line.connector : "",
					anchors: line.anchors ? line.anchors : undefined,
					paintStyle: line.exception ? errorPaintStyle : undefined,
				}
				let conn = jsPlumbIn.getConnections({source: line.from, target: line.to})
				if (conn.length > 0) {
					jsPlumbIn.deleteConnection(conn[0])
					//this.redoList.push({lineList: copy(this.lineList), nodeList: copy(this.nodeList)})
					this.lineList.push(item)
					jsPlumbIn.connect(connParam, jsplumbConnectOptions)
				}
				break
			}
		}
		this.setState({line})
	}

	saveNodeLabel() {
		let node = this.state.node
		node.label = this.props.form.getFieldValue('label')
		for (let item of this.nodeList) {
			if (node.id === item.id) {
				item.label = node.label
			}
		}
		this.setState({node})
	}

	saveLoopStart() {
		let node = this.state.node
		node.loopId = this.props.form.getFieldValue('loopId')
		for (let item of this.nodeList) {
			if (node.id === item.id) {
				item.loopId = node.loopId
			}
		}
		this.setState({node})
	}

	refreshPage() {
		console.log('flow refreshPage')
		this.getInfo()
	}

	getInfo() {
		let data = {}
		data = getDataBase()
		data = data || {}
		let id = this.id

		if (this.props.type === 'workflow') {
			TableAction.getOne(id, (record) => {
				let workflow = record.workflow || {}
				this.nodeList = workflow.nodeList || data.nodeList || []
				this.lineList = workflow.lineList || data.lineList || []
				this.nodeId = workflow.nodeId || 1
				this.reRender()
			})
		} else if (this.props.type === 'bizflow') {
			ListAction.getOne(orchestrate, id, (record) => {
				let bizflow = record.bizflow || {}
				this.nodeList = bizflow.nodeList || data.nodeList || []
				this.lineList = bizflow.lineList || data.lineList || []
				this.nodeId = bizflow.nodeId || 1
				this.reRender()
			})
		}
	}

	jsPlumbInit() {
		jsPlumbIn.ready(() => {
			// 导入默认配置
			jsPlumbIn.importDefaults(jsplumbSetting)
			// 会使整个jsPlumbInstance立即重绘。
			jsPlumbIn.setSuspendDrawing(false, true)
			// 单点击了连接线, https://www.cnblogs.com/ysx215/p/7615677.html
			jsPlumbIn.bind("click", (conn) => {
				let activeElement = {}
				activeElement.type = "line"
				activeElement.sourceId = conn.sourceId
				activeElement.targetId = conn.targetId
				let line = this.lineList.find(line => line.from === conn.sourceId && line.to === conn.targetId)
				this.setState({
					line: {
						from: conn.sourceId,
						to: conn.targetId,
						cond: line && line.cond,
						label: line && line.label,
						exception: line && line.exception,
					},
					activeElement
				})
				this.props.form.setFieldsValue({
					label: line.label,
					cond: line.cond,
					exception: line.exception,
				})
			})

			// 连线
			jsPlumbIn.bind("connection", (evt) => {
				//setTimeout(() => {
					let from = evt.source.id
					let to = evt.target.id
					//console.log('from, to',from, to)
					if (undefined === this.lineList.find(line => line.from === from && line.to === to)) {
						//this.redoList.push({lineList: copy(this.lineList), nodeList: copy(this.nodeList)})
						this.lineList.push({ from: from, to: to })
					}
					//console.log('this.lineList',this.lineList)
				//}, 300)
			})

			// 删除连线回调
			jsPlumbIn.bind("connectionDetached", (evt) => {
				this.deleteLine(evt.sourceId, evt.targetId)
			})

			// 改变线的连接节点
			jsPlumbIn.bind("connectionMoved", (evt) => {
				this.changeLine(evt.originalSourceId, evt.originalTargetId)
			})

			// 连线右击
			jsPlumbIn.bind("contextmenu", (evt) => {
				//console.log("contextmenu", evt)
			})

			// 连线
			jsPlumbIn.bind("beforeDrop", (evt) => {
				let from = evt.sourceId
				let to = evt.targetId
				//console.log('beforeDrop',from, to)
				if (from === to) {
					//节点不支持连接自己
					return false
				}
				if (this.hasLine(from, to)) {
					//该关系已存在,不允许重复创建
					return false
				}
				if (this.hashOppositeLine(from, to)) {
					//不支持两个节点之间连线回环
					return false
				}
				//console.log('beforeDrop连接成功',from, to)
				//连接成功
				return true
			})

			// beforeDetach
			jsPlumbIn.bind("beforeDetach", (/**evt*/) => {
				//console.log("beforeDetach", evt)
			})
		})
	}

	// 是否具有该线
	hasLine(from, to) {
		for (let line of this.lineList) {
			if (line.from === from && line.to === to) {
				return true
			}
		}
		return false
	}
	// 是否含有相反的线
	hashOppositeLine(from, to) {
		return this.hasLine(to, from)
	}

	// 加载流程图
	loadEasyFlow() {
		// 初始化节点
		for (let node of this.nodeList) {
			//console.log(node.id, '----------------------------')
			// 设置源点，可以拖出线连接其他节点
			jsPlumbIn.makeSource(node.id, Object.assign({}, jsplumbSourceOptions))
			// 设置目标点，其他源点拖出的线可以连接该节点
			jsPlumbIn.makeTarget(node.id, jsplumbTargetOptions)
			if (!this.disabled) {
				jsPlumbIn.draggable(node.id, {
					stop: this.onStop.bind(this, node)
				})
			}
		}

		// 初始化连线
		for (let line of this.lineList) {
			let connParam = {
				source: line.from,
				target: line.to,
				label: line.label ? line.label : "",
				connector: line.connector ? line.connector : "",
				anchors: line.anchors ? line.anchors : undefined,
				paintStyle: line.exception ? errorPaintStyle : undefined,
			}
			let conn = jsPlumbIn.getConnections({source: line.from, target: line.to})
			if (conn.length === 0) {
				jsPlumbIn.connect(connParam, jsplumbConnectOptions)
			}
		}
		// 会使整个jsPlumbInstance立即重绘。
		jsPlumbIn.setSuspendDrawing(false, true)
	}

	onStop(node) {
		//this.redoList.push({lineList: copy(this.lineList), nodeList: copy(this.nodeList)})
		this.changeNodeSite(node)
	}

	menuDragStart(id) {
		this.setState({
			activeMenuId: id
		})
	}

	menuDragEnd(id, e) {
		let menuNode = this.getMenuById(id)
		let node = Object.assign({}, menuNode)
		node.id = 'n' + this.nodeId++
		node.top = e.clientY - offsetY
		node.left = e.clientX - offsetX
		node.top2 = node.top
		node.left2 = node.left
		node.className = ''
		//this.redoList.push({lineList: copy(this.lineList), nodeList: copy(this.nodeList)})
		this.nodeList.push(node)
		this.setState({
			activeMenuId: null,
			update: !this.state.update
		})
	}

	getMenuById(id) {
		let menu = global.searchTreeNode(id, this.state.menuList, 'id')
		if (menu) {
			return menu
		}
		return this.state.bizNodeList.find(node => node.id === id)
	}

	changeNodeSite(node) {
		let style = $("#"+node.id)[0].style
		node.left = style.left
		node.top = style.top
	}

	// 删除激活的元素
	deleteElement() {
		let activeElement = this.state.activeElement
		if (activeElement.type === "node") {
			if (['process-start'].indexOf(this.state.node.type) !== -1) {
				return
			}
			//this.redoList.push({lineList: copy(this.lineList), nodeList: copy(this.nodeList)})
			this.deleteNode(activeElement.nodeId)
		} else if (activeElement.type === "line") {
			//this.redoList.push({lineList: copy(this.lineList), nodeList: copy(this.nodeList)})
			let conn = jsPlumbIn.getConnections({
				source: activeElement.sourceId,
				target: activeElement.targetId,
			})[0]
			jsPlumbIn.deleteConnection(conn)
			this.setState({activeElement: {type: undefined}})
		}
	}
	/**
	 * 删除节点
	 * @param nodeId 被删除节点的ID
	 */
	deleteNode(nodeId) {
		/**
		 * 这里需要进行业务判断，是否可以删除
		 */
		this.nodeList = this.nodeList.filter(node => node.id !== nodeId)
		jsPlumbIn.removeAllEndpoints(nodeId)
		this.setState({node: {}, activeElement: {type: undefined}})
		return true
	}
	// 删除线
	deleteLine(from, to) {
		//console.log('deleteLine',from, to)
		this.lineList = this.lineList.filter(line => !(line.from === from && line.to === to))
		//console.log('this.lineList',this.lineList)
	}
	// 改变连线
	changeLine(oldFrom, oldTo) {
		this.deleteLine(oldFrom, oldTo)
	}

	// 保存流程图
	onSave(cb) {
		//console.log(cb)
		this.nodeList.forEach(node => this.changeNodeSite(node))
		let id = this.id
		if (this.props.type === 'workflow') {
			TableAction.getOne(id, async (record) => {
				record.workflow = {nodeList: this.nodeList, lineList: this.lineList, nodeId: this.nodeId}
				await callModifyByApiBatch(tables_config, id, record, null, null, true)
				cb instanceof Function && cb()
			})
		} else if (this.props.type === 'bizflow') {
			ListAction.getOne(orchestrate, id, async (record) => {
				record.bizflow = record.bizflow || {}
				record.bizflow.nodeList = this.nodeList
				record.bizflow.lineList = this.lineList
				record.bizflow.nodeId = this.nodeId
				await callModifyByApiBatch(orchestrate, id, record, null, null, true)
				cb instanceof Function && cb()
			})
		}
	}

	openMapping() {
		if (this.state.activeElement.type === undefined) {
			Modal.info({ title: '请先选中节点或线', okText: '知道了' })
			return
		}
		this.onSave(() => {
			let url
			let title
			if (this.state.activeElement.type === 'line') {
				let {from, to} = this.state.line
				let lineId = `${from}_${to}`
				url = `/mapping/line?id=${this.id}&lineId=${lineId}`
				title = '连线 ' + lineId
			} else {
				if (controlNodeType.indexOf(this.state.node.type) !== -1) {
					Modal.info({ title: '请先选择普通节点', okText: '知道了' })
					return
				}
				let nodeId = this.state.node.id
				url = `/mapping/node?id=${this.id}&nodeId=${nodeId}`
				title = `${this.state.node.name}_${nodeId}`
			}
			p.openpage(url, title)
		})
	}

	search(value) {
		if (utils.isNotBlank(value)) {
			let menuTreeData = utils.searchTree(value, this.state.allMenuTreeData, 'title')
			this.setState({text: value, menuTreeData})
		} else {
			this.setState({text: value, menuTreeData: this.state.allMenuTreeData})
		}
	}
	// 构造菜单树
	buildMenuTree(menuTreeData) {
		let menu = []
		for (let node of menuTreeData) {
			const config = node
			// 菜单树上的菜单分类, 有value
			if (node.value) {
				let menuItems = this.buildMenuTree(node.children || [])
				if (menuItems.length > 0) {
					menu.push(<SubMenu key={node.key} 
						title={
							<div className='centered-ch'>
								{config.icon && <Icon className='menu-icon' type={config.icon}/>}
								<span>{config.name}</span>
							</div>
						}
					>
						{menuItems}
					</SubMenu>)
				}
			} else {
				// 具体的模块，无value
				let menuItems = node => {
					return node.children.map(item => {
						let id = item.key
						let title = item.title
						return <Menu.Item key={id}>
							<div key={id} draggable
								onDragStart={this.menuDragStart.bind(this, id)} 
								onDragEnd={this.menuDragEnd.bind(this, id)}
							>
								<Icon type='microchip' style={{marginRight: 4}}/>
								<Popover content={<div>{title}</div>} >
									<span>{title}</span>
								</Popover>
							</div>
						</Menu.Item>
					})
				}
				const items = menuItems(node)
				menu.push(<SubMenu key={node.key} 
					title={
						<div className='centered-ch'>
							{config.icon && <Icon className='menu-icon' type={config.icon}/>}
							<span>{node.title}</span>
						</div>
					}
				>
					{items}
				</SubMenu>)
			}
		}
		return menu
	}
	clickNode(node) {
		//let isBizFlow = this.props.type === 'bizflow'
		let isWorkFlow = this.props.type === 'workflow'
		//console.log(node)
		let activeElement = {}
		activeElement.type = "node"
		activeElement.nodeId = node.id
		if (this.ctrlKeyDown) {
			let selectedNodes = this.state.selectedNodes
			if (selectedNodes.find(item => item.id === node.id) === undefined) {
				selectedNodes.push(node)
				this.setState({selectedNodes})
			}
		} else {
			this.setState({node, activeElement, selectedNodes: [node]})
		}
		
		if (isWorkFlow) {
			this.props.form.setFieldsValue({
				name: node.name,
				candidate: {id: node.candidate_id, code: node.candidate, name: node.candidate_name},
				candidategroup: {id: node.candidategroup_id, name: node.candidategroup_name},
				jointlysign: node.jointlysign
			})
		} else {
			this.props.form.setFieldsValue({
				label: node.label || node.name,
			})
			if (node.type === 'loop-start') {
				this.props.form.setFieldsValue({
					loopId: node.loopId,
				})
			}
		} 
	}
	onKeyDown(event) {
		if(event.ctrlKey) {
			this.ctrlKeyDown = true
		}
	}

	onKeyUp(event) {
		if(event.ctrlKey === false) {
			this.ctrlKeyDown = false
		}
	}

	horizontalAlign() {
		if (this.state.selectedNodes.length === 0) return
		let firstNode = this.state.selectedNodes[0]
		this.changeNodeSite(firstNode)
		for (let node of this.state.selectedNodes) {
			node.top = firstNode.top
		}
		this.reRender()
	}
	verticalAlign() {
		if (this.state.selectedNodes.length === 0) return
		let firstNode = this.state.selectedNodes[0]
		this.changeNodeSite(firstNode)
		for (let node of this.state.selectedNodes) {
			node.left = firstNode.left
		}
		this.reRender()
	}

	undo() {
		if (this.redoList.length === 0) {
			Modal.info({ title: '没有可撤销的操作', okText: '知道了' })
			return
		}
		this.undoList.push({lineList: copy(this.lineList), nodeList: copy(this.nodeList)})
		let obj = this.redoList.pop()
		this.lineList = obj.lineList
		this.nodeList = obj.nodeList
		this.reRender()
	}
	redo() {
		if (this.undoList.length === 0) {
			Modal.info({ title: '没有可重做的操作', okText: '知道了' })
			return
		}
		this.redoList.push({lineList: copy(this.lineList), nodeList: copy(this.nodeList)})
		let obj = this.undoList.pop()
		this.lineList = obj.lineList
		this.nodeList = obj.nodeList
		this.reRender()
	}

	/**
     * 重新渲染页面
     */
	reRender() {
		this.setState({update: !this.state.update})
	}

	render() {
		//console.log('render--------')
		//console.log(this.lineList, 'this.lineList-----------')
		/* 左侧控制组件列表 */
		let controlMenu = this.state.menuList.map(menu => {
			let subMenuDiv = menu.children.map(subMenu => {
				return <Menu.Item key={subMenu.id}>
					<div key={subMenu.id} draggable
						onDragStart={this.menuDragStart.bind(this, subMenu.id)} 
						onDragEnd={this.menuDragEnd.bind(this, subMenu.id)}
					>
						<Icon type={subMenu.ico} style={{marginRight: 4}}/>
						<Popover content={<div>{subMenu.name}</div>} >
							<span>{subMenu.name}</span>
						</Popover>
					</div>
				</Menu.Item>
			})
			return <SubMenu key={menu.id} 
			title={
				<div className='centered-ch'>
					<Icon className='menu-icon' type='gears'/>
					<span>{menu.name}</span>
				</div>
			}
			>
				{subMenuDiv}
			</SubMenu>
		})
		let moduleMenus = this.buildMenuTree(this.state.menuTreeData)
		let activeElement = this.state.activeElement
		//console.log(activeElement, 'activeElement')
		//console.log(this.state.selectedNodes)
		let nodeListDiv = this.nodeList.map(node => {
			//console.log(node.id, node.name, node.top, node.left)
			let style = Object.assign({width: 160}, node.style)
			style.top = node.top
			style.left = node.left
			style.position = 'absolute'
			let slectNode = this.state.selectedNodes.find(item => item.id === node.id)
			let isActive = (activeElement.type === 'node' ? activeElement.nodeId === node.id : false) || slectNode !== undefined
			let className = "ef-node-container"
			if (isActive) {
				className = "ef-node-container ef-node-active"
			}
			let nodeId = node.id
			if (['nodeStart', 'nodeEnd'].indexOf(nodeId) !== -1) {
				nodeId = ''
			}
			let name = node.label || `${nodeId} ${node.name}`
			if (this.props.type === 'workflow') {
				name = node.name
			}
			let nodeClassName = "ef-node-left-ico flow-node-drag"
			let iconClassName = "flow-node-drag"
			if (this.disabled) {
				nodeClassName = "ef-node-left-ico"
				iconClassName = ""
			}
			let nodeDiv = 
			<div key={node.id} id={node.id}
				style={style}
				onClick={this.disabled ? undefined : this.clickNode.bind(this, node)}
				className={className}
			>
				{/* 节点类型的图标 */}
				<div className={nodeClassName}>
					<Icon className={iconClassName} type={node.ico} style={{marginRight: 4, paddingTop: 2}}/>
				</div>
				{/* 节点名称 */}
				<Popover content={<div>{node.label || node.name}</div>} >
					<span className='ef-node-text'>{name}</span>
				</Popover>
			</div>
			if (this.disabled) {
				return nodeDiv
			}
			return <Draggable key={node.id}
				handle='.ef-node-text'
			>
				{nodeDiv}
			</Draggable>
		})
		/* 右侧表单 */
		// 审批流程节点
		const { getFieldDecorator } = this.props.form
		let nodeForm = <div/>
		if (this.state.node.type === 'workflow-node') {
			nodeForm = <div style={{marginTop: 16, marginRight: 8}}>
				<Form>
					<Form.Item label="名称" labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
						{getFieldDecorator('name', {
							rules: [{ required: true, message: '请输入流程节点名称!' }],
							initialValue: this.state.node.name,
						})(
							<Input onBlur={this.saveNode.bind(this, 'name')} placeholder="请输入流程节点名称" />
						)}
					</Form.Item>
					<Form.Item label="候选人" labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
						{getFieldDecorator('candidate', {
							initialValue: {code: this.state.node.candidate, id: this.state.node.candidate_id, name: this.state.node.candidate_name}
						})(
							<ModuleDataSelect onlySelect 
								config={{params: "staff"}} 
								moduletype='list' 
								onChange={this.saveNode.bind(this, 'candidate')}
							/>
						)}
					</Form.Item>
					<Form.Item label="候选组" labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
						{getFieldDecorator('candidategroup', {
							initialValue: {id: this.state.node.candidategroup, name: this.state.node.candidategroup_name}
						})(
							<ModuleDataSelect onlySelect 
								config={{params: "auth"}} 
								moduletype='list' 
								onChange={this.saveNode.bind(this, 'candidategroup')}
							/>,
						)}
					</Form.Item>
					<Form.Item>
						{getFieldDecorator('jointlysign', {
								valuePropName: 'checked',
								initialValue: this.state.node.jointlysign || false,
						})(<Checkbox style={{marginLeft: 20}} onBlur={this.saveNode.bind(this, 'jointlysign')}>会签</Checkbox>)}
					</Form.Item>
				</Form>
			</div>
		}
		// 业务编排
		// 线
		let lineForm = <div style={{marginTop: 16, marginRight: 8}}>
			<Form>
				<Form.Item label="名称" labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
					{getFieldDecorator('label', {
						initialValue: this.state.line.label,
					})(
						<Input onBlur={this.saveLine.bind(this)} placeholder="请输入连线名称" />
					)}
				</Form.Item>
				<Form.Item label="条件" labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
					{getFieldDecorator('cond', {
						initialValue: this.state.line.cond,
					})(
						<Input onBlur={this.saveLine.bind(this)} placeholder="请输入连线条件" />
					)}
				</Form.Item>
				<Form.Item label="失败信息" labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
					{getFieldDecorator('exception', {
						initialValue: this.state.line.exception,
					})(
						<Input onBlur={this.saveLine.bind(this)} placeholder="请输入失败信息" />
					)}
				</Form.Item>
			</Form>
		</div>
		// 节点
		let bizNodeForm = <div style={{marginTop: 16, marginRight: 8}}>
			<Form>
				<Form.Item label="ID" labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
					<span>{this.state.node.id}</span>
				</Form.Item>
				<Form.Item label="名称" labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
					<span>{this.state.node.name}</span>
				</Form.Item>
				<Form.Item label="别名" labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
					{getFieldDecorator('label', {
						initialValue: this.state.node.label || this.state.node.name,
					})(
						<Input onBlur={this.saveNodeLabel.bind(this)} placeholder="请输入节点别名" />
					)}
				</Form.Item>
				{this.state.node.type === 'loop-start' && <Form.Item label="循环节点ID" labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
					{getFieldDecorator('loopId', {
						initialValue: this.state.node.loopId,
					})(
						<Input onBlur={this.saveLoopStart.bind(this)} placeholder="请输入循环节点ID" />
					)}
				</Form.Item>}
			</Form>
		</div>
		let isBizFlow = this.props.type === 'bizflow'
		let isNodeType = this.state.activeElement.type === 'node'
		let isLineType = this.state.activeElement.type === 'line'
		return <div className="flow-main">
			{/* 顶部工具菜单 */}
			{!this.disabled && <div className="flow-tooltar">
				<Button className={global.buttonClass} style={{marginRight: 16}} 
					type='danger'
					onClick={this.deleteElement.bind(this)}
				><ButtonIcon antd type='delete' name='删除'/></Button>
				{/*<Button className={global.buttonClass} style={{marginRight: 16}} 
					onClick={this.undo.bind(this)}
				><ButtonIcon antd type='undo' name='撤销'/></Button>
				<Button className={global.buttonClass} style={{marginRight: 16}} 
					onClick={this.redo.bind(this)}
				><ButtonIcon antd type='redo' name='重做'/></Button>*/}
				<Button className={global.buttonClass} style={{marginRight: 16}} 
					onClick={this.horizontalAlign.bind(this)}
				><ButtonIcon antd type='vertical-align-middle' name='水平对齐'/></Button>
				<Button className={global.buttonClass} style={{marginRight: 16}} 
					onClick={this.verticalAlign.bind(this)}
				><ButtonIcon antd type='align-center' name='垂直对齐'/></Button>
				<Button className={global.buttonClass} style={{marginRight: 16}} 
					onClick={() => this.setState({
						show: true,
						flowJson: JSON.stringify({
							node: this.nodeList,
							line: this.lineList
						})
					})}
				><ButtonIcon antd type='code' name='查看配置'/></Button>
				<Button className={global.buttonClass} type="primary" style={{marginRight: 16}} 
					onClick={this.onSave.bind(this)}
				><ButtonIcon antd type='save' name='保存'/></Button>
				{isBizFlow && 
				<Button className={global.buttonClass} type="primary" style={{marginRight: 16}} 
					onClick={this.openMapping.bind(this)}
				><ButtonIcon antd type='swap' name='绑定参数'/></Button>}
			</div>}
			<div className="flow-body">
				{!this.disabled && 
				<div className="flow-menu-main">
					{isBizFlow &&
						<Input.Search
							onSearch={this.search.bind(this)}
							style={{ width: 240 }}
							enterButton 
						/>
					}
					{/* 左侧组件列表 */}
					<div className="flow-menu">
						<Menu theme="light" mode="inline" onClick={this.onMenuItemClick.bind(this)} selectedKeys={this.state.selectedKeys}>
							{controlMenu}
							{isBizFlow && moduleMenus}
						</Menu>
					</div>
				</div>}
				{/* 画布 */}
				<div id="efContainer" ref="efContainer" tabIndex="1" >
					<div>{nodeListDiv}</div>
					{/* 给画布一个默认的宽度和高度*/}
					<div className="flow-board">&nbsp;</div>
				</div>
				{/* 右侧表单 */}
				{this.state.node.type === 'workflow-node' && isNodeType && <div className="flow-form">
					{nodeForm}
				</div>}
				{isBizFlow && isNodeType && <div className="flow-form">
					{bizNodeForm}
				</div>}
				{isBizFlow && isLineType && <div className="flow-form">
					{lineForm}
				</div>}
			</div>
			<ModalEx
				title="查看json配置"
				visible={this.state.show}
				onClose={() => this.setState({show: false})}
				maskClosable={true}
				width={global.modalWidth}
				footer={[]}
				onOk={() => {
					let obj = utils.parse(this.state.flowJson)
					this.nodeList = obj.node
					this.lineList = obj.line
					this.onSave()
					this.setState({show: false})
				}}
			>
				<div>
					<Input.TextArea autoSize
						value={ this.state.flowJson } 
						onChange={(e) => this.setState({flowJson: e.target.value})}
					/>
				</div>
			</ModalEx>
		</div>
	} 
}

Flow = Form.create()(Flow)
export default Flow