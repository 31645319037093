import React from 'react'
import * as utils from '@utils/index'
import { getCookie } from '@utils/cookie'
import {callModifyByApiBatch} from '../layout/Action'
import AuthManage from '../../extension/AuthManage'
import { becameUser, deleteUser } from '../../extension/PlatformAction'
import { staff } from '@utils/constants'
import { wait } from '@utils/page'
import { createIcon } from '@com/PageCreator'

const moduleName = staff

export function iconOperation(text, record) {
    // 权限控制
    let moduleOpers = (global.role && global.role.moduleOpers) || []
    const operBecameUser = global.isAdmin || moduleOpers.indexOf(`${moduleName}_osbu`) !== -1
    const operUnregisterUser = global.isAdmin || moduleOpers.indexOf(`${moduleName}_osuu`) !== -1
    const operAuthManage = global.isAdmin || moduleOpers.indexOf(`${moduleName}_osam`) !== -1
    // 用户id为空，显示成为用户操作
    if (utils.isBlank(record.user_id)) {
        if (operBecameUser) {
            return createIcon('user-plus', '成为用户', wait.bind(null, async () => {
                const user = await becameUser(getCookie('entid'), record)
                if (user) {
                    await callModifyByApiBatch(moduleName, text, { 'user_id': user.id }, null,
                        `已成为用户, 用户名【${user.username}】,密码【88888888】`)
                }
            }), {key: text + 'user-plus'})
        }
    } else {
        const opers = []
        // 用户id存在，显示注销用户操作
        if (operUnregisterUser) {
            opers.push(createIcon('user-slash', '注销用户', wait.bind(null, async () => {
                if (await deleteUser(record.user_id)) {
                    await callModifyByApiBatch(moduleName, text, { 'user_id': null }, null, '该用户已注销')
                }
            }), {key: text + 'user-slash'}))
        }
        if (operAuthManage) {
            opers.push(<AuthManage key={text + 'AuthManage'} data={record} />)
        }
        return opers
    }
}
export async function canDelete(apiArray, record) {
    // 已成为用户的员工不能删除
    if (record.user_id) {
        utils.error(`${record.name}已成为用户`, '要删除该员工，请先注销用户')
        return false
    }
    return true
}