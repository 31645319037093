/**
 * 可编辑表格
 */
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Table from 'antd/lib/table'
import Popconfirm from 'antd/lib/popconfirm'
import Icon from './Icon'
import {getDecoratorAndComponetProps} from './PageCreator'
import * as dataUtil from '@utils/DataUtil'
import * as utils from '@utils/index'
import * as p from '@utils/page'
import c from '@utils/constants'
import FormWrapper from './FormWrapper'

const {EXPR} = c
const { nestedsublist } = p

export default class TableEx extends Component {
    /**
     * 初始化
     * @param {*} props 
     */
    constructor(props) {
        super(props)
        this.formInstObj = {}
        this.state = {
            disabled: {}
        }
        this.hasExpr = false
    }

    componentDidMount() {
        this.init()
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.moduleName !== this.props.moduleName) {
            this.init(nextProps)
        }
    }
    init(props = this.props) {
        const {moduleName} = props
        if (utils.isBlank(moduleName)) {
            return
        }
        this.fieldsConfig = dataUtil.getTableConfig(moduleName)
        let exprConfig = this.fieldsConfig.filter(it => it.dataType === EXPR)
        this.hasExpr = exprConfig.length > 0
    }

    /**
     * 获取rowKey
     * @param {*} record 
     */
    getRowKey(record) {
        let rowKey = this.props.rowKey
        if (rowKey) {
            if (typeof rowKey === 'function') {
                return rowKey(record)
            } else if (typeof rowKey === 'string') {
                return record[rowKey]
            } else {
                //console.error('the type of rowKey must be string or function')
                return record.id
            }
        } else {
            return record.id
        }
    }

    /**
     * 批录
     */
    batchSet(selectedRowKeys = []) {
        //let startTime = new Date().getTime()
        //console.log('batchSet start')
        // 剔除批录选择的行
        selectedRowKeys = selectedRowKeys.filter(key => key !== this.id)
        //console.log('selectedRowKeys', selectedRowKeys)
        if (this.name == null) {
            utils.info("请先选择要批录的数据")
            return
        }
        const setValues = () => {
            const values = {}
            for (let row of this.props.dataSource) {
                const rowKey = this.getRowKey(row)
                // 跳过非编辑状态下的数据
                if (row.disabled || (!this.props.alwaysEdit && this.state.disabled[rowKey])) {
                    continue
                }
                if (selectedRowKeys instanceof Array && selectedRowKeys.length > 0) {
                    // 只修改选中的数据
                    if (selectedRowKeys.indexOf(rowKey) === -1) {
                        continue
                    }
                }
                row.change = true
                values[this.name + rowKey] = this.value
            }
            // 先批录当前字段的值
            this.setFieldsValue(values)
            // 获取form中的数据，记录要合计的字段变化前的值
            const oldValues = this.getFieldsValue()
            //console.log('batchset oldValues', oldValues)
            // 批录完成后计算表达式
            this.hasExpr && this.props.dataSource.forEach(row => {
                const rowKey = this.getRowKey(row)
                const exprValues = dataUtil.callExpr(oldValues, rowKey, this.fieldsConfig, this.props.fRecord)
                this.setFieldsValue(exprValues)
            })
            //console.log('batchset exprValues', values)
            const fid = this.props.dataSource[0].fid
            if (fid) {
                // 子模块模块名， 当前
                const {moduleName} = this.props
                const rowKeys = this.props.dataSource.map(it => it.id)
                // 修改合计类型字段
                dataUtil.setSumFieldsValue(fid, oldValues, this, moduleName, rowKeys)
            }
           
            this.id = null
            this.name = null
            this.value = null
        }
        
        if (utils.isBlank(this.value)) {
            const message = `要批录的 ${this.name} 为空，确定要清空 ${this.name} 的所有数据吗？`
            utils.confirm(message, setValues)
        } else {
            setValues()
        } 
        //console.log('batchSet end. cost time', new Date().getTime() - startTime)
    }

    onBlur(row, name) {
        // 获取form中的数据，记录要合计的字段变化前的值
        const oldValues = this.getFieldsValue()
        // 批录，记录变化的字段名，字段值
        this.name = name
        this.id = row.id
        let nameFormKey = name + this.getRowKey(row)
        this.value = oldValues[nameFormKey]
        // 离开时计算表达式
        this.callExpr(row, name)
        this.props.onOOTableChange && this.props.onOOTableChange(row)
    }

    /**
     * 计算表达式
     */
    callExpr(row, name) {
        if (!this.hasExpr) {
            return
        }
        // 子模块模块名， 当前
        const {moduleName, mode, hasOneNestedSublist} = this.props
        //console.log('callExpr moduleName', moduleName)
        if (utils.isBlank(moduleName)) {
            return
        }
        const rowKey = this.getRowKey(row)
        // 获取form中的数据，记录要合计的字段变化前的值
        const oldValues = this.getFieldsValue()
        const values = dataUtil.callExpr(oldValues, rowKey, this.fieldsConfig, this.props.fRecord)
        //console.log(moduleName, 'values', values)
        this.setFieldsValue(values)
        // 如果是嵌入子模块需要同步修改嵌入子模块所在内嵌表单数据
        if (hasOneNestedSublist) {
            // 组装内嵌表单数据，去掉rowKey，内嵌表单key为字段名称
            const innerFormValues = {}
            innerFormValues[name] = oldValues[name + rowKey]
            for (let key in values) {
                const innerFormKey = key.substring(0, key.indexOf(rowKey))
                innerFormValues[innerFormKey] = values[key]
            }
            dataUtil.setValues(rowKey, innerFormValues)
        }
        /**
         * 此处onBlur修改的是父模块表单数据，嵌入子模块应跳过此处逻辑
         * 嵌入子模块在onChange时修改合计值，直接替换父模块表格数据，
         * 在此计算时，onChange已经将值修改，onBlur触发时值已经改变，新值和旧值相同，
         * 会导致计算结果 = 新值 - 旧值 = 0
         */
        if (row.fid && mode !== nestedsublist) {
            // 子模块模块名， 当前
            const {moduleName} = this.props
            // 修改合计类型字段
            dataUtil.setSumFieldsValue(row.fid, oldValues, this, moduleName, rowKey)
        }
    }

    /**
     * 拦截组件 onChange
     */
    componentChange(key, record, value, ...otherArgs) {
        record.change = true
        /**
         * Warning: This synthetic event is reused for performance reasons. 
         * If you're seeing this, you're accessing the property `nativeEvent` 
         * on a released/nullified synthetic event. This is set to null. 
         * If you must keep the original synthetic event around, use event.persist(). 
         * See https://fb.me/react-event-pooling for more information.
         * 出于性能原因，将重用此合成事件。如果您看到这一点，那么您正在访问已释放/取消的合成事件的属性
         * “target”。设置为空。如果必须保留原始合成事件，请使用event.persist（）
         */
        const e = value
        e && e.persist && e.persist()
        // 可编辑表格中的formitem id
        const id = key + this.getRowKey(record)
        this.onChangeMap[id] && this.onChangeMap[id](value, ...otherArgs)
        //console.log('componentChange value',value)
        if (e && e.target) {
            value = e.target.checked || e.target.value
        }
        if (this.props.onOOTableChange) {
            // e.target.checked 复选框
            // e.target.value 输入框
            record[key] = dataUtil.componentChangeValueFormat(value)
            //console.log('componentChange record[key]', record[key])
            this.props.onOOTableChange(record, key)
        }
        
        // 值变化时修改合计类型
        if (record.fid) {
            // 子模块模块名， 当前
            const {moduleName} = this.props
            // 获取form中的数据，记录要合计的字段变化前的值
            const oldValues = this.getFieldsValue()
            // 修改合计类型字段
            dataUtil.setSumValue(record.fid, key, oldValues[id], value, moduleName)
        }
    }

    /**
     * 批量保存，由父组件调用
     * @param {*} list 
     */
    batchSave(list) {
        if (list instanceof Array) {
            // 保存成功后禁用编辑
            let disabled = this.state.disabled
            for (let record of list) {
                disabled[record.id] = true
                delete record.change
                delete record.disabled
            }
            this.setState({ disabled })
        }
    }

    /**
     * 保存
     * @param {*} id 
     * @param {*} record 
     * @param {*} index 
     */
    async onSave(id, record, index) {
        // 第一次校验
        if (dataUtil.formError(this)) return false
        // revalidate 校验出发时机为onBlur离开时，所以需要重新校验
        if (dataUtil.formError(this)) return false
        const values = this.getFieldsValue()
        //console.log('values', values)
		let data = Object.assign({}, record)
        // 拷贝数据， 删除状态标记, 避免将标记状态保存到数据库
        // 编辑状态
        delete data.disabled
        // 新增状态
        delete data.isNew
        // 数据变化标记
        delete data.change
		this.props.columns.map((col) => {
			if (col.component) {
				if (typeof (col.component) === 'function') {
					if (col.component(undefined, record, index)) {
						data[col.dataIndex] = values[col.dataIndex + this.getRowKey(record)]
					}
				} else {
					data[col.dataIndex] = values[col.dataIndex + this.getRowKey(record)]
				}
            }
            return col
		})
        let ret
        if (record.isNew) {
            ret = !this.props.onAdd || await this.props.onAdd(data, index)
        } else {
            ret = !this.props.onSave || await this.props.onSave(id, data, index)
        }
		if (ret) {
            // 业务执行成功后删除原始数据中的状态标记
            delete record.disabled
            delete record.isNew
            delete record.change
            // 保存成功后禁用编辑
            let disabled = this.state.disabled
            disabled[id] = true
			this.setState({ disabled })
        }
        this.props.onOOTableChange && this.props.onOOTableChange(record)
        return ret
	}

    /**
     * 点击编辑时
     * @param {*} id 
     * @param {*} record 
     * @param {*} index 
     */
	onEdit(id, record, index) {
		if (this.props.onEdit) {
			if(this.props.onEdit(id, record, index)) {
                record.disabled = false
                let disabled = this.state.disabled
                disabled[id] = false
                this.setState({ disabled })
            }
		} else {
            record.disabled = false
            let disabled = this.state.disabled
            disabled[id] = false
            this.setState({ disabled })
        }
    }
    /**
     * 表格新增或编辑取消
     * @param {*} id 
     * @param {*} record 
     * @param {*} index 
     */
	onCancle(id, record, index) {
        let disabled = this.state.disabled
        disabled[id] = true
		this.setState({ disabled })
        this.props.onCancle && this.props.onCancle(id, record, index)
        delete record.disabled
        delete record.isNew
    }
    /**
     * 删除操作
     * @param {*} id 
     * @param {*} record 
     * @param {*} index 
     */
	onDel(id, record, index) {// 删除操作
		let disabled = this.state.disabled
        disabled[id] = true
		this.setState({ disabled })
		this.props.onDel && this.props.onDel(id, record, index)
    }

    setFieldsValue(values) {
        for (const key in values) {
            const form = this.formInstObj[key]
            form && form.setFieldsValue({[key]: values[key]})
        }
    }
    getFieldsValue() {
        const values = {}
        for (const key in this.formInstObj) {
            const form = this.formInstObj[key]
            if (form) {
                const value = form.getFieldValue(key)
                values[key] = value
            }
        }
        return values
    }
    validateFields(cb) {
        for (const key in this.formInstObj) {
            const form = this.formInstObj[key]
            if (form) {
                // 校验数据
                form.validateFields(cb)
            }
        }
    }

    componentDidUpdate() {
        //console.log('TableEx render end. cost time: ', new Date().getTime() - this.startTime)
    }
    
    /**
     * 页面渲染
     */
    render() {
        this.startTime = new Date().getTime()
        //console.log('TableEx render start')
        // 数据修改标记
        this.onChangeMap = {}
        const {
            // 剔除不属于Table的属性
            mode, hasOneNestedSublist,
            alwaysEdit, fRecord, disabled, form, moduleName, 
            onAdd, onCancle, onDel, onSave, onEdit, onOOTableChange,
            size,
            ...restProps} = this.props
        //console.log('alwaysEdit', alwaysEdit)
        let columns = this.props.columns || []
        let hasComponent = false
        // 判断是否定义编辑组件
        for (let col of columns) {
            if (col.component) {
                hasComponent = true
                break
            }
        }
        //console.log('hasComponent', hasComponent)
        // 组件占满表格单元格
        let componentStyle = { color: '#666', width: '100%' }
        // 编辑状态下使用定义组件进行渲染
        columns = columns.map(oldCol => {
            let col = Object.assign({}, oldCol)
            col.render = (text, record, index) => {
                let component = col.component
                if (typeof component === 'function') {
                    component = component(text, record, index)
                }
                let rowKey = this.getRowKey(record)
                let disabled = this.state.disabled[rowKey]
                // disabled 未定义时不编辑
                if (disabled == null ) {
                    disabled = true
                }
                // 记录控制编辑状态
                if (record.disabled === false) {
                    disabled = false
                } else if (record.disabled === true) {
                    disabled = true
                }

                // 先处理操作列
                if (col.key === 'operation') {
                    if (global.isPhoneVertical) {
                        if (oldCol.render) return oldCol.render(text, record, index)
                        return <span/>
                    }
                    /**
                     * 按钮组，其它参数控制按钮是否显示
                     * 始终编辑 保存 删除
                     * 直接编辑 编辑？保存：取消 删除
                     * 非编辑状态 删除
                     */
                    // 保存 id为new标识新增数据
                    let onSave = this.onSave.bind(this, rowKey, record, index)
                    // 编辑状态 保存
                    let editClick = onSave
                    if (disabled) {
                        // 非编辑状态 编辑
                        editClick = this.onEdit.bind(this, rowKey, record, index) 
                    }
                    // 编辑 或 保存
                    let editIcon = <Icon style={global.iconStyle} 
                        onClick={editClick} type={disabled ? 'edit' : 'save'}
                    />
                    // 取消
                    let cancleIcon = <Icon style={global.iconStyle}
                        onClick={this.onCancle.bind(this, rowKey, record, index)}
                        type='close'
                    />
                    // 删除
                    let delIcon = <Popconfirm
                        title="确定要删除这条数据吗？"
                        onConfirm={this.onDel.bind(this, rowKey, record, index)}
                    >
                        <Icon style={global.iconStyle} type='trash'/>
                    </Popconfirm>

                    /**
                     * col.disabled(text, record, index) 返回bool
                     * 父组件 column 参数, 回调函数，禁用所有操作
                     * 返回true不显示所有操作，默认false
                     * 默认显示所有操作
                     */
                    let operDisabled = false
                    if ((typeof col.disabled) === 'function') {
                        operDisabled = col.disabled(text, record, index) || false
                    }

                    /**
                     * isShowDel bool类型
                     * 父组件参数， 控制是否显示删除按钮
                     * 值为true显示编辑操作
                     * 默认不显示
                     */
                    let isShowDel = false
                    if (this.props.isShowDel) {
                        // TableEx中是否直接渲染删除操作
                        isShowDel = true
                    }

                    /**
                     * hasComponent = true
                     * 是否有可编辑组件, 遍历表格列配置中至少有一个可编辑组件
                     * 如果没有可编辑组件，可编辑表格的编辑功能将失去意义
                     * 如果有再显示内置操作按钮
                     */
                    if (hasComponent) {
                        return <span style={{marginLeft: 8}}>
                        {
                            // 显示编辑按钮组条件 保存 编辑？保存：取消 删除
                            // 操作列没有被禁用
                            !operDisabled && 
                            // 表格不是禁用状态
                            !this.props.disabled && 
                            <span>
                            {
                                /**
                                 * 如果是子模块，通过父模块的保存按钮保存数据
                                 * 不显示 保存 编辑
                                 */
                                !this.props.isSub && 
                                // 始终编辑状态不需要保存按钮
                                !alwaysEdit && 
                                // 直接编辑表格有编辑和保存连个操作公用一个按钮
                                editIcon
                            }
                            {
                                /**
                                 * 如果是子模块，保留取消按钮用来删除新增的数据
                                 * 条件不变
                                 */
                                // 编辑中状态，显示取消按钮
                                !disabled && cancleIcon
                            }
                            {
                                /**
                                 * 如果是子模块
                                 * 删除按钮显示条件不变
                                 */
                                // 删除按钮显示条件
                                // 父组件传入参数，是否渲染删除按钮
                                isShowDel && 
                                // 行级，数据是否允许被删除
                                record.isShowDel !== false && 
                                delIcon
                            }
                            </span>
                        }  
                        {
                            // 非编辑状态，父组件的操作列渲染
                            disabled && 
                            oldCol.render && 
                            oldCol.render(text, record, index)
                        }
                        </span>
                    }
                    if (oldCol.render) {
                        return <span style={{marginLeft: 8}}>
                            {oldCol.render(text, record, index)}
                        </span>
                    }
                    return <span/>
                }
                // 编辑状态下安装定义组件渲染单元格
                if (component && record.disabled !== true && (alwaysEdit || !disabled)) {
                    let id = col.dataIndex + this.getRowKey(record)
                    let props = component.props || {}
                    // 处理传入参数
                    let {componentProps, decoratorProps} = getDecoratorAndComponetProps(props)
                    if (decoratorProps.initialValue === undefined) {
                        decoratorProps.initialValue = text
                    }
                    // 绑定onBlur，计算表达式的值
                    componentProps.onBlur = this.onBlur.bind(this, record, col.dataIndex)
                    // 拦截 onChange
                    this.onChangeMap[id] = decoratorProps.onChange
                    decoratorProps.onChange = this.componentChange.bind(this, col.dataIndex, record)
                    const componentInst = React.createElement(component.name, {
                        ...componentProps, 
                        style: componentStyle,
                    })
                    const config = componentProps.config || {}
                    const dataType = config.dataType
                    let className = ''
                    if (config.centered || dataUtil.isCenteredType(dataType)) {
                        className = 'centered'
                    }
                    const formWrapperProps = {id, decoratorProps, componentInst}
                    return <div className={className}>
                        <FormWrapper {...formWrapperProps}
                            wrappedComponentRef={inst => 
                                this.formInstObj[id] = inst && inst.props && inst.props.form
                            }
                        />
                    </div>
                }
                if (oldCol.render) {
                    return oldCol.render(text, record, index)
                }
                return text
            }
            return col
        })

        let showOOTableClassName = (!global.isPhoneVertical && alwaysEdit && (disabled !== true)) || 
            size === 'small'
        return <Table
            bordered
            rowKey='id'
            rowClassName={(record, index) => `tableRow-${index % 2}`}
            {...restProps} 
            columns={columns}
            size={size}
            className={showOOTableClassName ? 'ootable' : ''}
        />
    }
}

TableEx.propTypes = {
    columns: PropTypes.array,
    dataSource: PropTypes.array,
    onOOTableChange: PropTypes.func,
}