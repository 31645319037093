/**
 * 文件接口
 */

/**
 * 根据文件id删除
 * @param {} id 
 */
export function delById(id, cb) {
    global.getApi().del(`/file/id`, {
        params: { id }
    }).then(payload => {
        cb && cb()
    }, () => {
        cb && cb()
    })
}

/**
 * 根据业务id删除
 * @param {} id 
 */
export function deleteFilesByBusinessId(businessId, cb) {
    global.getApi().del(`/file/businessId`, {
        params: { businessId }
    }).then(payload => {
        cb && cb()
    }, () => {
        cb && cb()
    })
}

/**
 * 根据业务id获取附件列表
 * @param {*} businessId 
 * @param {*} cb 
 */
export function getByBusinessId(businessId, cb) {
    global.getApi().get(`/file/businessId`, {
        params: { businessId }
    }).then(payload => {
        cb && cb(payload.body || [])
    }, () => {
        cb && cb([])
    })
}

/**
 * 根据业务id获取附件列表(分页)
 * @param {*} businessId 
 * @param {*} cb 
 */
export function getByBusinessIdAndPage(data, cb) {
    global.getApi().put(`/file/businessId/page`, {
        data
    }).then(payload => {
        cb && cb(payload.body || [])
    }, () => {
        cb && cb([])
    })
}
export async function getByBusinessIdAndPagePromise(data, cb) {
    try {
        const payload = await global.getApi().put(`/file/businessId/page`, {
            data
        })
        return payload.body || []
    } catch(e) {}
    return []
}

/**
 * 根据文件id获取单个文件
 * @param {*} businessId 
 * @param {*} cb 
 */
export function getFileById(id, cb) {
    global.getApi().get(`/file/id`, {
        params: { id }
    }).then(payload => {
        cb && cb(payload.body || {})
    }, () => {
        cb && cb({})
    })
}
export async function getFileByIdPromise(id) {
    try {
        const payload = await global.getApi().get(`/file/id`, {
            params: { id }
        })
        return payload.body || {}
    } catch(e) {}
    return {}
}