import React, { Component } from 'react'
import message from 'antd/lib/message'
import { getObjByCodePromise } from '../modules/layout/Action'
import jsQR from 'jsqr'
import * as utils from '@utils/index'
import * as p from '@utils/page'
import A from '@com/A'

export default class QRScanner extends Component {
    constructor() {
        super()
        this.width = global.clientWidth - 24
        this.height = global.clientHeight - 130
    }
    componentDidMount() {
        setTimeout(() => {
            const video = this.videoRef
            const mediaStream = navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } })
            mediaStream.then((stream) => {
                video.srcObject = stream
                video.setAttribute('playsinline', true) // iOS
                video.play()
            })
        }, 300)
    }
    componentWillUnmount() {
        clearInterval(this.scanInterval)
        // 组件销毁前停止视频流
        if (this.videoRef && this.videoRef.srcObject) {
            const videoStream = this.videoRef.srcObject
            const tracks = videoStream.getTracks()
            tracks.forEach(track => track.stop())
        }
    }

    scan(image) {
        console.log('image', image)
        const video = image || this.videoRef
        image = image || {}
        if (video) {
            const canvas = document.createElement('canvas')
            const context = canvas.getContext('2d')
            canvas.width = image.width || video.videoWidth
            canvas.height = image.height || video.videoHeight
            context.drawImage(video, 0, 0, canvas.width, canvas.height)
            const imageData = context.getImageData(0, 0, canvas.width, canvas.height)
            const code = jsQR(imageData.data, imageData.width, imageData.height)
            if (code) {
                console.log('result: ', code.data)
                message.success('扫描成功, 正在获取数据，请稍等...', 3)
                clearInterval(this.scanInterval)
                p.wait(async () => {
                    const obj = await getObjByCodePromise(code.data)
                    //console.log('obj: ', obj)
                    let moduleConfig = utils.getConfigByModuleName(obj.moduleName)
                    if (obj && obj.moduleName && !utils.isFixedColMenu(moduleConfig.modulename)) {
                        let url = `/${obj.moduleName}/detail?id=${obj.id}`
                        let title = `${obj.moduleName}-${code.data}`
                        console.log(url, title)
                        p.closepage()
                        p.openpage(url, title)
                    } else {
                        utils.warning('请扫码本系统生成的二维码', '', () => {
                            this.scanInterval = setInterval(this.scan.bind(this), 1000)
                        })
                    }
                })
            }
        }
    }
    videoLoaded() {
        this.videoWidth = this.videoRef.videoWidth
        this.videoHeight = this.videoRef.videoHeight
        this.scanInterval = setInterval(this.scan.bind(this), 1000)
    }
    imageChange(e) {
        if (e.target.files && e.target.files.length > 0) {
            console.log(e.target.files[0])
            let image = new Image()
            image.src = URL.createObjectURL(e.target.files[0])
            image.onload = this.scan.bind(this, image)
        }
    }
    render() {
        return <div>
            <div className='space-between' style={{height: 50}}>
                <label style={{padding: 16}}><A onClick={() => global.closepage()}>关闭</A></label>
                <div className="file-upload-wrapper">
                    <input id="file-upload" 
                        type="file" accept="image/*" 
                        className="file-upload" 
                        onChange={this.imageChange.bind(this)}
                    />
                    <label type='primary' 
                        htmlFor="file-upload" 
                        className="file-upload-button"
                        style={{padding: 16}}
                    ><A>相册</A></label>
                </div>
            </div>
            <video ref={inst => {
                inst && inst.addEventListener('loadedmetadata', this.videoLoaded.bind(this))
                this.videoRef = inst
            }} width={this.width} height={this.height} autoPlay />
        </div>
    }
}
 