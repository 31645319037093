import React, { Component } from 'react'
import Upload from 'antd/lib/upload'
import {getCookie} from '@utils/cookie'
export default class UploadEx extends Component {
    render() {
        return <Upload
            {...this.props}
            headers={{
                token: getCookie('token'),
            }}
        />
    }
}