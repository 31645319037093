import React, { Component } from 'react'
import Button from 'antd/lib/button'
import Upload from 'antd/lib/upload'
import message from 'antd/lib/message'
import Row from 'antd/lib/row'
import ButtonIcon from './ButtonIcon'
import {getCookie} from '@utils/cookie'
import {delById, getByBusinessId} from '../modules/file/Action'


export default class Assy extends Component {
    constructor(props) {
		super()
		this.state = {
			fileList: [],
		}
        this.token = getCookie('token')
    }
    
	componentDidMount() {
		this.refresh()
    }

    refresh() {
        const businessId = this.props.businessId
        if (businessId == null) return
        getByBusinessId(businessId, list => {
            let uid = 0
            let fileList = list.map((file) => {
                let assy = {
                    uid: --uid,
                    name: file.originalFilename,
                    status: 'done',
                    url: global.getApi().opts.baseURI + file.filePath,
                    thumbUrl: global.getApi().opts.baseURI + file.filePath,
                    id: file.id,
                    createtime: file.createtime,
                }
                return assy
            })
            //console.log(fileList, 'fileList')
            this.setState({fileList})
        })
    }
    
	//文件上传
	handleChange(info) {
		let fileList = info.fileList
		if (info.file.status === 'done') {
			this.refresh()
		} else if (info.file.status === 'error') {
			message.error('附件上传失败！')
        }
        //console.log('fileList: ', fileList)
        this.setState({
            fileList: fileList
        })
    }
    
	//删除附件
	removeFile(file) {
        //console.log(file, this.props.disable, this.props.disable === true)
		if (file.id && this.props.disabled !== true) {
			delById(file.id, () => {
                this.refresh()
            })
		}
	}

	beforeUpload(file) {
        const isLt20M = file.size / 1024 / 1024 < 20
        if (!isLt20M) {
            message.error(`图片必须小于20MB!`)
        }
        return isLt20M
	}
    render() {
        //文件上传
		const props = {
            className: 'upload-list-inline',
			action: `${global.getApi().opts.baseURI}/file/upload`,
			listType: this.props.fileType ? this.props.fileType : 'picture',
			onChange: this.handleChange.bind(this),
			onRemove: this.removeFile.bind(this),
			multiple: true,
			fileList: this.state.fileList,
			beforeUpload: this.beforeUpload.bind(this),
			data: {
                multiple: true,
                businessId: this.props.businessId,
            },
            headers: {token: this.token},
			disabled: this.props.disabled ? this.props.disabled : false
		}
		return <Row style={{marginBottom: 16}}>
            <h2>{this.props.name || '附件信息'}</h2>
            <Upload {...props}>
                {!this.props.disabled && <Button type="ghost" style={{ marginLeft: 8 }}>
                    <ButtonIcon type="cloud-upload" />点击上传
                </Button>}
            </Upload>
        </Row>
    }
}