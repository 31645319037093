import React, { Component } from 'react'
import Input from 'antd/lib/input'
import Button from 'antd/lib/button'
import Form from 'antd/lib/form'
import {getObjByCode} from './Action'
import ButtonIcon from '@com/ButtonIcon'
import * as utils from '@utils/index'
import * as p from '@utils/page'

class Home extends Component {

    code2Page() {
        let strCode = this.props.form.getFieldValue('qrCode') || ''
        strCode = strCode.trim()
        if (strCode === '') {
            return
        }
        p.startLoad()
        getObjByCode(strCode, (obj => {
            p.stopLoad()
            console.log('obj: ', obj)
            let moduleConfig = utils.getConfigByModuleName(obj.moduleName)
            if (obj && obj.moduleName && !utils.isFixedColMenu(moduleConfig.modulename)) {
                let url = `/${obj.moduleName}/detail?id=${obj.id}`
                let title = `${obj.moduleName}-${strCode}`
                p.openpage(url, title)
            } else {
                utils.warning('请扫码本系统生成的二维码')
            }
        }))
    }

    onKeyDown(e) {
        if (e.keyCode === 13) {
            this.code2Page()
        }
    }
      
    render() {
        const {getFieldDecorator} = this.props.form
        return <div>
            {/*<i className="fa fa-camera-retro"></i> fa-camera-retro*/}
            <h3 style={{ marginTop: 16 }}>查看详情</h3>
            <nobr style={{ marginTop: 16 }}>
                {getFieldDecorator('qrCode')(<Input
                    placeholder={'请输入编码'}
                    style={{ width: 230, height: 29 }}
                    onKeyDown={this.onKeyDown.bind(this)}
                />)}
                <Button type='primary' style={{ marginLeft: 8 }} 
                    onClick={this.code2Page.bind(this)}
                >Go</Button>
                <Button type='primary' className='icon-button' style={{ marginLeft: 16}}
                    onClick={() => p.openpage('/qrcode-scan', '扫码')}
                ><ButtonIcon type='expand'/></Button>
            </nobr>
        </div>
    }
}

export default Form.create()(Home)