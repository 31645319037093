/**
 * 生成页面相关函数
 * PageCreator
 */
import React from 'react'
import moment from 'moment'
import Popconfirm from 'antd/lib/popconfirm'
import Button from 'antd/lib/button'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Select from 'antd/lib/select'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import InputNumber from 'antd/lib/input-number'
import Cascader from 'antd/lib/cascader'
import DatePicker from 'antd/lib/date-picker'
import Checkbox from 'antd/lib/checkbox'
import Radio from 'antd/lib/radio'
import Slider from 'antd/lib/slider'
import Switch from 'antd/lib/switch'
import TimePicker from 'antd/lib/time-picker'
import Transfer from 'antd/lib/transfer'
import TreeSelect from 'antd/lib/tree-select'
import Upload from 'antd/lib/upload'
import Menu from 'antd/lib/menu'
import NumberArea from './NumberArea'
import ModuleDataSelect from './ModuleDataSelect'
import Avatar from './Avatar'
import Span from './Span'
import ExprSpan from './ExprSpan'
import InputSelect from './InputSelect'
import Percent from './Percent'
import PercentArea from './PercentArea'
import QRCodeEx from './QRCodeEx'
import * as dataUtil from '@utils/DataUtil'
import * as utils from '@utils/index'
import OOTableEx from './OOTableEx'
import OptionOOTable from './OptionOOTable'
import SubListConfig from './SubListConfig'
import CondOOTable from './CondOOTable'
import OOCheckBox from './OOCheckBox'
import IconEx from './IconEx'
import ButtonIcon from './ButtonIcon'
import OOCheckBoxSearch from './OOCheckBoxSearch'
import OOSelect from './OOSelect'
import OOTag from './OOTag'
import A from './A'
import OOColor from './OOColor'

const RangePicker = DatePicker.RangePicker
const antdNameObj = {
    Input,
    InputNumber,
    DatePicker,
    Checkbox,
    Cascader,
    Radio,
    Select,
    Slider,
    Switch,
    TimePicker,
    Transfer,
    TreeSelect,
    Upload
}

const {valueToMoment} = dataUtil

/**
 * 根据数据类型获取系统组件实例
 * @param {*} dataType 系统定义的数据类型
 * @param {*} isForSearch 搜索下的组件，数字、日期为范围组件
 */
export function getSysComponent(dataType, isForSearch) {
    let name
    if (dataUtil.isListTreeType(dataType)) {
        name = ModuleDataSelect
    } else if (dataUtil.isAntdSelectType(dataType)) {
        name = OOSelect
    } else if (dataUtil.isInputSelectType(dataType)) {
        name = InputSelect
    } else if (dataUtil.isInputType(dataType)) {
        name = Input
    } else if (dataUtil.isExprType(dataType)) {
        name = ExprSpan
        if (isForSearch) name = NumberArea
    } else if (dataUtil.isTextType(dataType)) {
        name = Input.TextArea
        if (isForSearch) name = Input
    } else if (dataUtil.isNumberMoneyType(dataType)) {
        name = InputNumber
        if (isForSearch) name = NumberArea
    } else if (dataUtil.isDateTimeType(dataType)) {
        name = DatePicker
        if (isForSearch) name = RangePicker
    } else if (dataUtil.isPercentType(dataType)) {
        name = Percent
        if (isForSearch) name = PercentArea
    } else if (dataUtil.isCheckboxType(dataType)) {
        name = OOCheckBox
        if (isForSearch) name = OOCheckBoxSearch
    } else if (dataUtil.isFileType(dataType)) {
        name = Avatar
    } else if (dataUtil.isSumType(dataType)) {
        name = ExprSpan
        if (isForSearch) name = NumberArea
    } else if (dataUtil.isTableType(dataType)) {
        name = OOTableEx
    } else if (dataUtil.isOptionType(dataType)) {
        name = OptionOOTable
    } else if (dataUtil.isSubListConfigType(dataType)) {
        name = SubListConfig
    } else if (dataUtil.isCondOOTableType(dataType)) {
        name = CondOOTable
    } else if (dataUtil.isGroupType(dataType)) {
        name = Span
    } else if (dataUtil.isTagType(dataType)) {
        name = OOTag
        if (isForSearch) name = Input
    } else if (dataUtil.isColorType(dataType)) {
        name = OOColor
    }
    return name
}

/**
 * 
 * @param {*} id 字段名称
 * @param {*} dataType 系统定义的数据类型
 * @param {*} isForSearch 搜索下的组件，数字、日期为范围组件
 * @param {*} props 组件参数
 * @returns 
 */
export function getComponentClass(id, dataType, isForSearch, props) {
    if (utils.isBlank(dataType)) {
        console.log('getComponentClass dataType is null, id: ', id)
        return null
    }
    if (dataUtil.isFFieldType(dataType)) {
        // 默认父列 使用父模块字段类型
        dataType = dataUtil.getFDataType(props)
    }
    // 先在系统类型中查找组件
    let name = getSysComponent(dataType, isForSearch)
    if (name === undefined) {
        // 如果非系统组件
        if (antdNameObj[dataType]) {
            // 先在antd组件库中查找
            name = antdNameObj[dataType]
        } else {
            // 剩下的在 src/components 目录下根据文件名称查找
            name = require(`./${dataType}`).default
        }
    }
    // 如果未找到组件，抛异常
    if (name === undefined) {
        throw new Error(`${id} ${dataType} 组件类型未定义`)
    }
    return name
}

/**
 * 分离 getFieldDecorator 包装参数和组件参数
 * @param {*} props 
 * @param {*} dataType 
 */
export function getDecoratorAndComponetProps(props) {
    let componentProps = {}
    let decoratorProps = {}
    for (let key in props) {
        let prop = props[key]
        if (dataUtil.isFieldDecoratorProps(key)) {
            decoratorProps[key] = prop
        } else {
            componentProps[key] = prop
        }
    }
    return {componentProps, decoratorProps}
}

/**
 * 创建禁用编辑状态下的组件
 * @param {*} dataType 
 * @param {*} name 
 * @param {*} value 
 * @param {*} componentProps 
 * @returns 
 */
export function createDisabledComponent(dataType, name, value, componentProps) {
    let component = <span>{value || ''}</span>
    if (value == null) {
        return component
    }
    if (componentProps.key === 'code' && typeof value === 'string') {
        return <span className='autobr'>
            <QRCodeEx value={value}/>
        </span>
    }
    if (dataUtil.isInputType(dataType) || 
        dataUtil.isInputSelectType(dataType) ||
        dataUtil.isTextType(dataType)
    ) {
        component = <span className='autobr'>{value}</span>
    } else if (dataUtil.isAntdSelectType(dataType)) {
        const options = utils.getOptionArray(componentProps.options)
        component = <span className='autobr'>{utils.valueToLabel(value, options)}</span>
    } else if (dataUtil.isNumberType(dataType)) {
        component = <span>{utils.toFixedNumber(value, componentProps.decimalplaces)}</span>
    } else if (dataUtil.isMoneyType(dataType)) {
        component = <span>{utils.toFixedMoney(value, componentProps.decimalplaces)}</span>
    } else if (dataUtil.isPercentType(dataType)) {
        component = <span>{value && value * 100}</span>
    } else if (dataUtil.isDateType(dataType)) {
        component = <span>{moment(value).format('YYYY-MM-DD')}</span>
    } else if (dataUtil.isTimeType(dataType)) {
        component = <span>{moment(value).format('YYYY-MM-DD HH:mm:ss')}</span>
    } else {
        // 禁用编辑
        componentProps.disabled = true
        // 这里没有使用 getFieldDecorator， 需要给 value 赋值
        componentProps.value = value
        component = React.createElement(name, componentProps)
    }
    return component
}

/**
 * 根据数据类型生成表单FormItem
 * @param  {Function} getFieldDecorator form
 * @param  {String} dataType 数据类型 根据数据类型创建不同组件
    1. 如果是antd的组件，antdNameObj 为目前支持的组件map，用 antdNameObj[dataType] 获取组件
    2. 如果是自定义组件，自定义组件必须放在当前文件目录下，用 require(`./${dataType}`).default 获取组件
 * 然后匹配antd组件, 其他类型根据名字在当前文件所在目录寻找同名组件
 * @param  {String} id 组件id
 * @param  {Object} param FormItem参数，由于此参数构造比较复杂，建议使用下面的配置转组件方法来构造
    function configToItemProps(thisPage, config, index, initialValue, specialItemProps, isForSearch)
    根据系统设置中的字段配置已经完成了antd的常用组件的参数处理，通过specialItemProps
    来构造antd复杂组件和自定义组件的更多参数。 
    参数内部结构如下：
    {
        props: props,               // FormItem中组件的参数，此方法会将其注入到组件中
        formProps: {                // FormItem自身参数
            label: label,           // FormItem表单字段标签
            labelCol: {span: 6},    // FormItem表单字段标签宽度占比，参阅antd 24栅格
            wrapperCol: {span: 18}, // FormItem表单字段数据宽度占比，参阅antd 24栅格
            style: {},              // FormItem表单样式
            children: component,    // FormItem中的组件  
        },
    }
 * @param  {Bool} disabled 值为true时直接显示文本， 值为false时使用组件
 * @param  {Bool} isForSearch 是否用于搜索
 */
export function createItem(getFieldDecorator, dataType, id, param, disabled, gutter, isForSearch) {
    if (utils.isBlank(dataType)) throw new Error('dataType 为空！')
    const params = param || {}
    const props = params.props || {}
    const formProps = params.formProps || {}
    const {componentProps, decoratorProps} = getDecoratorAndComponetProps(props)
    componentProps.key = id
    let component = null
    const name = getComponentClass(id, dataType, isForSearch, props)
    // 禁用编辑状态删除placeholder
    if (disabled || props.disabled) {
        delete componentProps.placeholder
    }
    if (name) {
        // 表达式直接显示格式化文本
        if (disabled) {
            const value = decoratorProps.initialValue
            component = createDisabledComponent(dataType, name, value, componentProps)
        } else {
            // 创建组件    
            const thisComponent = React.createElement(name, 
                {
                    ...componentProps,
                    ref: (inst) => {
                        // 组件所在页面实例this指针
                        if (componentProps.thispage) {
                            // 将组件实例this指针挂载到页面实例this指针
                            componentProps.thispage[id] = inst
                        }
                    }
                }
            )
            component = getFieldDecorator(id, decoratorProps)(thisComponent)
        }
    }

    formProps.children = component
    formProps.key = id
    formProps.style = { marginBottom: gutter }
    if (dataUtil.isRowOneType(dataType)) {
        formProps.labelCol = { span: 24 }
        formProps.wrapperCol = { span: 24 }
    }
    return wrapExtraFields(<Form.Item {...formProps}/>, 
        dataType, getFieldDecorator, id, componentProps.options)
}

/**
 * 给组件添加额外字段
 * @param {*} component 
 * @param {*} dataType 
 * @param {*} getFieldDecorator 
 * @param {*} id 
 * @returns 
 */
function wrapExtraFields(component, dataType, getFieldDecorator, id, options) {
    if (dataUtil.isSelectType(dataType)) {
        component = <span>{component}
            {getFieldDecorator(id + '_options', {initialValue: options})(<Span hide/>)}
        </span>
    }
    return component
}
/**
 * 使用元素参数数组创建n列等宽表格排版的表单
 * @param  {Function} getFieldDecorator
 * @param  {Number} cols 表单列数
 * @param  {Number} gutter 表单行间隔
 * @param  {Array<param>} formItems 创建表单的元素参数数组 param {props: props(FormItem中组件的参数), formProps: formProps(FormItem参数)}
 * @param  {Bool} disabled 值为true时直接显示文本， 值为false时使用组件
 * @param  {Bool} isForSearch 是否用于搜索
 * @return {Array} children antd component Form.Item Array
 */
export function createItems(getFieldDecorator, cols, gutter, allformItems, disabled, isForSearch) {
    gutter = gutter || 16
    cols = cols || utils.getCols()
    // 其他类型
    let children = []
    // 文本域类型
    let textAreaItems = []
    if (isForSearch) {
        allformItems = allformItems.filter(it => !dataUtil.isHideOnSearchType(it.dataType))
    }
    
    // 创建一般类型
    let colSpan = parseInt(24 / cols, 10)
    let len = allformItems.length
    // 按照列数创建网格表单界面
    for (let i = 0, j = 1, childs = []; i < len; ++i) {
        let params = allformItems[i] || {}
        let colId = params.id
        let dataType = params.dataType
        let component = null
        if (!dataUtil.isGroupType(dataType)) {
            component = createItem(getFieldDecorator, params.dataType, params.id, params.param, disabled, gutter, isForSearch)
        }
        
        // 文件、选项、子模块、分组类型占一整行
        if (dataUtil.isRowOneType(dataType)) {
            // 添加网格表单组件
            if (childs.length > 0) {
                children.push(<Row gutter={gutter} key={'row' + i}>
                    {childs}
                </Row>)
            }
            // 独占一行表单组件或分组类型
            // 添加h2标签
            if (dataUtil.isH2LabelType(dataType)) {
                const {title, alias} = (params.param.props.config || {})
                children.push(<Row gutter={gutter} key={'h2' + params.id}>
                    <Col span={24}><h2>{title || alias || params.id}</h2></Col>
                </Row>)
            }
            // 分组类型不渲染组件
            if (!dataUtil.isGroupType(dataType)) {
                const row = <Row gutter={gutter} key={'row' + params.id}>
                    <Col span={24}>{component}</Col>
                </Row>
                if (dataUtil.isTextType(dataType)) {
                    textAreaItems.push(row)
                } else {
                    children.push(row)
                }
            }
            childs = []
            j = 1
            continue
        }
        childs.push(<Col span={colSpan} key={colId}>{component}</Col>)
        
        if (j === cols || i === len - 1) {
            children.push(<Row gutter={gutter} key={'row' + i}>{childs}</Row>)
            childs = []
            j = 1
        } else {
            ++j
        }
    }
    // 文本域类型放在最后
    return children.concat(textAreaItems)
}

/**
 * configToItemProps
 * 模块字段配置转换成Form表单FormItem的参数和组件参数
 * 模块字段配置转换成表格组件Table的表头, 可以使用 configToColumn 方法，原理和此方法相同
 * ******************************************************************************
 * config.dataType 为 TREEMODULE 或 LISTMODULE时，Form表单中的字段关联其他模块，
 * 关联模块名称 config.params
 * 使用 ModuleDataSelect 模型选择组件， TREEMODULE <-> 树 LISTMODULE <-> 列表
 * 会以弹窗的形式展现，在模型选择组件内部获取数据
 * *******************************************************************************
 * @param  {Function} thisPage 创建页面的this引用
 * @param  {Object} config 字段配置
 * @param  {Object} data 表单中的全部数据, 用来初始化其它字段的初始值
 * @param  {Object} initialValue 初始值
 * @param  {CallableFunction} specialItemProps (formField 全部参数, props 组件参数) 特殊处理回调函数
 * @param  {Bool} isForSearch 是否用于搜索
 * @returns {Object}
{
    id: id,
    dataType: dataType,
    param: {
        props: props,
        formProps formProps
    }
}
 */
export function configToItemProps(thisPage, fieldConifg, data, initialValue, specialItemProps, isForSearch) {
    if (fieldConifg == null) throw new Error("字段配置为空")
    if (fieldConifg.name == null) throw new Error("字段配置中的字段名称为空")
    const props = {} 
    // 将字段配置信息传递到组件
    props.config = fieldConifg
    let config = utils.copy(fieldConifg)
    // 解析参数中的变量
    for (let key in config) {
        let value = config[key]
        if (typeof value === 'string' && value.charAt(0) === '$') {
            let fieldName = value.substring(1)
            config[key] = data[fieldName]
        }
    }
    // 解析其他参数中的变量
    for (let key in (config.otherParams || {})) {
        let value = config.otherParams[key]
        if (typeof value === 'string' && value.charAt(0) === '$') {
            let fieldName = value.substring(1)
            config.otherParams[key] = data[fieldName]
        }
    }
    const id = config.name
    let dataType = config.dataType
    let key = id
    let label = config.alias || config.name
    if (dataUtil.isH2LabelType(dataType)) {
        label = null
    }
    let formField = {}
    formField.id = id
    formField.param = {}
    formField.param.formProps = {
        label: config.title || label,
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    }

    config.otherParams = config.otherParams || {}
    if (dataUtil.isFFieldType(dataType)) {
        // 默认父列 使用父模块字段配置
        dataUtil.mergeFConfig(config)
        dataType = config.dataType
    } else if (dataUtil.isSumType(dataType)) {
        // 合并合计子模块字段参数
        dataUtil.mergeSumConfig(config)
    }
    
    // 参数注入， 注入页面组件this
    props.thispage = thisPage
    // 参数注入， 将整体数据data作为参数注入到oodata
    props.oodata = data
    
    Object.assign(props, config.otherParams)
    formField.param.props = props
    props.rules = []
    props.placeholder = '请输入' + label
    // 选择类型选项
    const options = data && data[`${key}_options`]
    if (dataUtil.isTimeType(dataType)) {
        props.showTime = true
        props.format = 'YYYY-MM-DD HH:mm:ss'
    }
    // 选择，数字，金额 类型离开后计算表达式
    // 查询组件不绑定onBlur， 默认所有组件绑定onBlur
    if (!isForSearch) {
        // 计算表达式
        props.onBlur = thisPage && thisPage.callExpr && thisPage.callExpr.bind(thisPage)
    }
    if (dataUtil.isDateTimeType(dataType)) {
        if (isForSearch) {
            if (initialValue instanceof Array && initialValue.length === 2) {
                initialValue[0] = valueToMoment(initialValue[0])
                initialValue[1] = valueToMoment(initialValue[1])
            }
            delete props.placeholder
            props.ranges = { 
                '今天': [moment().startOf('day'), moment().endOf('day')], 
                '本周': [moment().startOf('week'), moment().endOf('week')],
                '本月': [moment().startOf('month'), moment().endOf('month')],
                '本季': [moment().startOf('quarter'), moment().endOf('quarter')],
                '本年': [moment().startOf('year'), moment().endOf('year')],
                '昨天': [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')], 
                '上周': [moment().subtract(1, 'weeks').startOf('week'), moment().subtract(1, 'weeks').endOf('week')],
                '上月': [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')],
                '上季': [moment().subtract(1, 'quarters').startOf('quarter'), moment().subtract(1, 'quarters').endOf('quarter')],
                '上年': [moment().subtract(1, 'years').startOf('year'), moment().subtract(1, 'years').endOf('year')],
                '至今': [moment('2000-01-01'), moment()],
            }
        } else {
            initialValue = valueToMoment(initialValue)
            props.placeholder = '请选择' + label
        }
    } else if (dataUtil.isNumberMoneyType(dataType) || dataUtil.isPercentType(dataType)) {
        if (initialValue != null) {
            initialValue = dataUtil.getNumber(initialValue)
        }
        utils.stringToInt(props, 'min', 'max', 'step')
        if (!props.min) {
            props.min = 0
        }
    } else if (dataUtil.isAntdSelectType(dataType)) {
        if (dataUtil.isSelectMultiType(dataType)) props.mode = 'multiple'
        props.showSearch = true
        props.optionFilterProp = "label"
        props.allowClear = true
        props.placeholder = '请选择' + label
        props.options = options || config.params
    } else if (dataUtil.isInputSelectType(dataType)) {
        props.placeholder = '请输入或选择' + label
        props.options = options || config.params
    } else if (dataUtil.isCheckboxType(dataType)) {
        if (!isForSearch) {
            initialValue = initialValue || config.defaultValue || false
        }
    } else if (dataUtil.isFileType(dataType)) {
        props.businessId = data && data.id
    } else if (dataUtil.isListTreeType(dataType)) {
        props.placeholder = '请选择' + label
        props.moduletype = 'list'
        props.config.params = config.params
        if (dataUtil.isTreeType(dataType)) props.moduletype = 'tree'
        // ModuleDataSelect 注入 searchpagekey 参数, ListModule 内存分区
        props.searchpagekey = thisPage && thisPage.pageKey
        // 绑定父组件的 moduleDataChange 方法到 ModuleDataSelect 组件的 onChange 属性
        props.onChange = thisPage && thisPage.moduleDataChange && 
            thisPage.moduleDataChange.bind(thisPage, config.params, config.name, data)
        if (dataUtil.isTreeType(dataType)) {
            // 树模块特殊处理
            props.showSearch = true
            props.changeOnSelect = true
        }
        let idKey = `${key}_id`
        let nameKey = `${key}_name`
        let classesKey = `${key}_classes`
        let classnamesKey = `${key}_classnames`
        let record = data || {}
        if (typeof initialValue === 'string') {
            initialValue = { 
                code: initialValue, 
                id: record[idKey], 
                name: record[nameKey], 
                classes: record[classesKey], 
                classnames: record[classnamesKey]
            }
        }
    } else if (dataUtil.isSubListConfigType(dataType)) {
        initialValue = utils.parseArray(initialValue)
    } else {
        if (utils.isNotBlank(config.params)) {
            props.params = config.params
        }  
    }

    props.initialValue = initialValue
    if (!dataUtil.isTagType(dataType)) {
        if (dataUtil.isColorType(dataType)) {
            props.style = props.style || { marginTop: 4 }
        } else {
            props.style = props.style || { width: '100%' }
        }
    }
    
    formField.dataType = config.dataType
    let disabled = false
    // 禁用编辑
    if (config.isDisabled === '1') {
        disabled = true
    }
    if (isForSearch) {
        disabled = false
    }
    props.disabled = disabled
    if (dataUtil.isPhoneType(dataType)) {
        props.rules.push({
            validator: (rule, value, callback) => {
                if (utils.isBlank(value)) {
                    callback()
                    return
                }
                let re = /^((1[3-9]))\d{9}$/
                if (!re.test(value)) {
                    callback(`手机号码${value}格式不正确，请重新输入`)
                } else {
                    callback()
                }
            }
        })
    }

    // 必填项校验
    if (config.isRequire === '1' && !isForSearch) {
        if (dataUtil.isStringTextType(dataType) || 
            dataUtil.isInputType(dataType)
        ) {
            props.rules.push({
                required: true,
                whitespace: true,
                message: '请输入' + label
            })
        } else if (dataUtil.isNumberMoneyType(dataType)) {
            props.rules.push({
                required: true,
                message: '请输入' + label
            })
        } else if (dataUtil.isInputSelectType(dataType)) {
            props.rules.push({
                required: true,
                whitespace: true,
                message: '请输入或选择' + label
            })
        } else {
            props.rules.push({
                required: true,
                message: '请选择' + label
            })
        }
    }
    // 特殊处理
    const specialFormField = specialItemProps && specialItemProps(formField, props, data)
    if (specialFormField) {
        return specialFormField
    }
    return formField
}

/**
 * createComponent
 * 根据配置创建单个组件
 * @param {*} thisPage 
 * @param {*} config 
 * @param {*} data 
 * @param {*} initialValue 
 * @param {*} specialItemProps 
 * @param {*} isForSearch 
 * @returns 
 */
export function createComponent(thisPage, config, data, initialValue, specialItemProps, isForSearch) {
    if (config == null || utils.equals(config, {})) {
        console.error('createComponent config is null or empty')
        return null
    }
    dataUtil.mergeFConfig(config)
    const formItemProps = configToItemProps(thisPage, config, data, initialValue, specialItemProps, isForSearch)
    const props = formItemProps.param.props || {}
    if (props.initialValue !== undefined) {
        props.value = props.initialValue
        //delete props.initialValue
    }
    const name = getComponentClass(config.name, config.dataType, false, props)
    if (name == null) {
        return <span/>
    }
    return React.createElement(name, {
        ...props, 
        ref: (inst) => {
            // 组件所在页面实例this指针
            if (thisPage) {
                // 将组件实例this指针挂载到页面实例this指针
                thisPage[config.name] = inst
            }
        }
    })
}

/**
 * configToColumn
 * 模块字段配置转换成表格组件Table的表头
 * 模块字段配置转换成Form表单FormItem的参数, 可以使用 configToItemProps 方法，原理和此方法相同
 * @param  {any} thisPage 创建页面的this引用
 * @param  {Object} config 字段配置
 * @param  {CallableFunction} specialColumn(column/转换好的列/) 特殊字段处理回调函数
 * @param  {Boolean} isEdit 该列是否处于编辑状态
 * @param  {Object} props 其他额外的参数
 * @param  {CallableFunction} renderWrapper 拦截并包装 column render(text, row, index) 目前在报表合并单元格中使用
 * @param  {CallableFunction} specialItemProps (formField/转换好的参数/, index) 特殊处理回调函数
 * @returns {Object} column Table组件的标准表头
 */
export function configToColumn(thisPage, config, specialColumn, isEdit, props, renderWrapper, specialItemProps) {
    const id = config.name
    let dataType = config.dataType

    /**
     * 手机端表格不渲染编辑组件
     */
    if (global.isPhoneVertical) {
        isEdit = false
    }
    const column = Object.assign({}, config)
    column.key = column.dataIndex = config.dataIndex || id
    if (column.dataIndex === 'id') {
        column.key = 'operation'
    }
    column.width = parseInt(config.width, 10)
    column.title = config.alias || config.name
    if (config.isSort === '1') {
        column.sorter = true
    } else {
        column.sorter = false
    }
    const getProps = (text, row) => {
        const itemProps = configToItemProps(thisPage, config, row, text, specialItemProps)
        // configToItemProps 可以修改dataType， 取修改后的 dataType
        dataType = config.dataType
        //console.log(config.name, dataType, text)
        if (!itemProps) 
            throw new Error('config To Column config To ItemProps 返回结果为空')
        if (!itemProps.param) 
            throw new Error('config To Column config ToI temProps 返回结果itemProps.param为空')
        if (dataUtil.isSelectType(dataType)) {
            // 表格中以下拉形式展示， RadioGroup CheckboxGroup 单元格放不下
            delete itemProps.param.props.showType
        }
        itemProps.param.props.intable = '1'
        // 合并组件的配置参数和自定义参数
        return itemProps.param.props
    }
    
    column.render = (text, row, index) => {
        const allProps = getProps(text, row)
        dataType = config.dataType
        //console.log(config.name, dataType)
        const name = getComponentClass(config.name, dataType, false, allProps)
        const {componentProps, decoratorProps} = getDecoratorAndComponetProps(allProps)
        componentProps.key = id
        const value = decoratorProps.initialValue
        // 禁用编辑
        componentProps.disabled = true
        // 这里没有使用 getFieldDecorator， 需要给 value 赋值
        componentProps.value = value
        // 默认父列 使用父模块字段类型
        if (dataUtil.isFFieldType(dataType)) {
            dataType = dataUtil.getDataType(allProps.fmodule, allProps.name)
        }
        // 添加表格额外参数
        if (dataUtil.isListTreeType(dataType)) {
            // ModuleDataSelect 模块选择器组件使用以下数据
            // moduleName 可编辑表格页面模块名称
            // state 可编辑表格页面this.state[config.params].treeData, 存放表格所有树类型的treeData
            componentProps.moduleName = props.moduleName
            componentProps.state = props.state
        }
        let component = null 
        if (name) {
            component = createDisabledComponent(dataType, name, value, componentProps)
        }
        if (dataUtil.isFileType(dataType)) {
            // 表格中不显示 文件类型
            component = null
        }
        if (renderWrapper) return renderWrapper(component, row, index)
        let className = ''
        if (config.centered || dataUtil.isCenteredType(dataType)) {
            className = 'centered'
        }
        return <div className={className}>{component}</div>
    }
    if (isEdit && config.isDisabled !== '1') {
        column.component = (text, row, index) => {
            const allProps = getProps(text, row)
            dataType = config.dataType
            //console.log(config.name, dataType)
            const name = getComponentClass(config.name, dataType, false, allProps)
            // 系统字段禁用编辑
            if (utils.isSysFields(config.name)) {
                allProps.disabled = true
            }
            // 默认父列 使用父模块字段类型
            if (dataUtil.isFFieldType(dataType)) {
                dataType = dataUtil.getDataType(allProps.fmodule, allProps.name)
            }
            // 添加表格额外参数
            if (dataUtil.isListTreeType(dataType)) {
                // ModuleDataSelect 模块选择器组件使用以下数据
                // moduleName 可编辑表格页面模块名称
                // state 可编辑表格页面this.state[config.params].treeData, 存放表格所有树类型的treeData
                allProps.moduleName = props.moduleName
                allProps.state = props.state
            }
            return {
                name: name,
                // 合并组件的配置参数和自定义参数
                props: allProps
            }
        }
    }

    if (specialColumn) {
        const specialItem = specialColumn(column)
        if (specialItem) return specialItem
    } 

    return column
}

export function getPagination(page, pageSize, totalElements) {
    let pagination = {}
    pagination.showSizeChanger = true
    pagination.showQuickJumper = true
    pagination.showTotal = () => `共${totalElements}条`
    pagination.current = page
    pagination.pageSize = pageSize
    pagination.pageSizeOptions = ['5', '10', '20', '50', '100']
    if (global.isPhoneVertical) {
        pagination.pageSizeOptions = ['5', '10', '20']
    }
    pagination.total = totalElements
    return pagination
}

/**
 * createIcon
 * @param {String} type IconName
 * @param {String} name 显示名称
 * @param {Function} onClick 
 * @param {Object} otherProps 
 * @returns 
 */
export function createIcon(type, name, onClick, otherProps = {}) {
    const {showType, style, key, needConfirm, title, notip} = otherProps
    if (showType === 'button') {
        return createButton(type, name, onClick, otherProps)
    }
    const props = {
        type, name, onClick, notip,
        style: Object.assign({}, global.iconStyle, style)
    }
    props.key = key || type || name
    if (needConfirm) {
        delete props.onClick
        return <Popconfirm title={title}
            onConfirm={onClick}
        >
           <IconEx {...props}/>
        </Popconfirm>
    }
    return <IconEx {...props}/>
}

/**
 * createButton
 * @param {String} type IconName
 * @param {String} name 按钮名称
 * @param {Function} onClick 
 * @param {Object} otherProps 
 * {
 *  buttonType ["default", "primary", "dashed", "danger", "link"]
 *  showName 显示按钮名称
 * }
 * @returns 
 */
export function createButton(type, name, onClick, otherProps = {}) {
    const {showType, showName, antd, style, key, needConfirm, title, notip,
        ...restProps} = otherProps
    if (showType === 'icon') {
        return createIcon(type, name, onClick, otherProps)
    }
    const props = {
        ...restProps,
        className: showName ? '' : global.buttonClass,
        onClick: onClick, 
        style: style || {marginRight: 16}
    }
    props.key = key || type || name
    const iconProps = { type, name, antd, showName }
    if (needConfirm) {
        delete props.onClick
        return <Popconfirm title={title}
            onConfirm={onClick}
        >
            <Button {...props}><ButtonIcon {...iconProps}/></Button>
        </Popconfirm>
    }
    if (global.isPhoneVertical && props.type === 'primary') {
        props.ghost = true
    }

    return <Button {...props}><ButtonIcon {...iconProps}/></Button>
}

export function createA(name, onClick, otherProps = {}) {
    const {style, key, needConfirm, title} = otherProps
    const props = {
        onClick, 
        style: style || global.iconStyle
    }
    props.key = key || name
    if (needConfirm) {
        delete props.onClick
        return <Popconfirm title={title}
            onConfirm={onClick}
        >
            <A {...props}>{name}</A>
        </Popconfirm>
    }
    return <A {...props}>{name}</A>
}

export function addMenuItem(moreOptions, name, onClick) {
    if (moreOptions instanceof Array) {
        moreOptions.push(<Menu.Item key={moreOptions.length + 1} >
            <A onClick={onClick}>{name}</A>
        </Menu.Item>)
    }
}