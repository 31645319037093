/**
 * CondOOTable
 * 条件配置编辑表格
 */
import React, { Component } from 'react'
import OOTableEx from './OOTableEx'
import {configToItemProps} from './PageCreator'
import * as dataUtil from '@utils/DataUtil'
import * as utils from '@utils/index'

export default class CondOOTable extends Component {
    constructor(props) {
        super()
        this.state = {
            list: [],
        }
        const {moduleName} = props
        this.fields_config = dataUtil.getTableConfig(moduleName)
        this.fieldsConfig = [
            /**
             * 字段
             */
            dataUtil.getConfig('fieldName', '字段', 'FieldSelect', {
                isRequire: '1',
                otherParams: {
                    moduleName,
                }
            }),
            /**
             * 操作
             */
            dataUtil.getConfig('oper', '操作', 'SELECT', {
                width: 100,
                isRequire: '1',
                params: dataUtil.operOptions
            }),
            /**
             * 值
             */
            dataUtil.getStringConfig('value', '值', {isRequire: '1'}),
            /**
             * 值类型
             */
            dataUtil.getConfig('type', '值类型', 'SELECT', {
                width: 80,
                params: '0,值/1,字段'
            }),
        ]
    }
    /**
     * 字段特殊处理回调函数
     * @param {*} formField 全部参数
     * @param {*} props 组件参数
     * @param {*} row 数据
     */
    specialItemProps(formField, props, row) {
        const {id} = formField
        if (id === 'value') {
            if (row.type === '1') {
                props.config.dataType = 'FieldSelect'
                props.moduleName = this.props.moduleName
            } else {
                let config = this.fields_config.find(item => item.name === row.fieldName)
                if (!config) {
                    return
                }
                config = utils.copy(config)
                config.name = 'value'
                let dataType = config.dataType
                if (dataUtil.isFFieldType(dataType)) {
                    dataUtil.mergeFConfig(config)
                    dataType = config.dataType
                } 
                if (dataUtil.isExprType(dataType) || dataUtil.isSumType(dataType)) {
                    config.dataType = 'NUMBER'
                }
                if (config && config.dataType) {
                    const thisPage = this.condtable && this.condtable.table
                    const itemProps = configToItemProps(thisPage, config, row, row.value)
                    props.config.dataType = config.dataType
                    itemProps.param.props.disabled = this.props.disabled
                    delete itemProps.param.props.placeholder
                    return itemProps
                }
            }
        } else if (id === 'type' || id === 'fieldName') {
            props.onChange = () => {
                row.value = null
                const thisPage = this.condtable && this.condtable.table
                if (thisPage.table && thisPage.table.setFieldsValue) {
                    thisPage.table.setFieldsValue({['value' + row.id]: null})
                }
            }
        }
    }
    formError() {
        return this.condtable && this.condtable.formError && this.condtable.formError()
    }
    render() {
        const {moduleName, ...restProps} = this.props
        return <OOTableEx 
            {...restProps}
            addBtnName='新增条件'
            ref={(inst) => this.condtable = this.condtable || inst}
            specialItemProps={this.specialItemProps.bind(this)}
            fieldsConfig={this.fieldsConfig}
        />
    }
}