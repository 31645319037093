import React, { Component } from 'react'
import Checkbox from 'antd/lib/checkbox'
import ModalEx from '@com/ModalEx'
import {getOnePromise} from '@list/Action'
import {callModifyByApiBatch} from '../modules/layout/Action'
import * as utils from '@utils/index'
import * as dataUtil from '@utils/DataUtil'
import * as p from '@utils/page'
import { auth, code_scheme, staff, tables_config } from '@utils/constants'
import { createIcon } from '@com/PageCreator'
import OOTable from '@com/OOTable'
import A from '@com/A'
import {reportengineList, chartdesignList} from '@/utils/object'

const fieldsConfig = [
    dataUtil.getStringConfig('modulename', '模块', {width: 160, isQuery: '2'}),
    dataUtil.getStringConfig('fields', '字段', {width: 80, isQuery: '0'}),
    dataUtil.getStringConfig('opers', '操作', {width: 1200, isQuery: '0'}),
]
class Auth extends Component {
    /**
     * 构造函数
     */
    constructor() {
        super()
        this.state = {
            visible: false,
            moduleNames: [],
            moduleOpers: [],
            hideFields: {},
            fieldVisible: {},
            reportList: [],
            reportNames: [],
            orchestrateMoudleMap: {},
            dataSource: []
        }
    }
    /**
     * 组件加载时
     */
    async componentDidMount() {
        let id = this.props.data.id
        p.startLoad()
        const body = await getOnePromise(auth, id)
        let {moduleNames, moduleOpers, hideFields, reportNames} = body
        moduleNames = moduleNames || []
        moduleOpers = moduleOpers || []
        hideFields = hideFields || {}
        reportNames = reportNames || []
        this.setState({moduleNames, moduleOpers, hideFields, reportNames})
        const reportAndChartList = reportengineList.concat(chartdesignList).map(it => {
            return {modulename: it.name, noAuthOper: true, report: true, 
                menu_classes: it.menu_classes,
                no: it.no,
                id: it.id,
            }
        })
        let dataSource = utils.getModuleConfigs().concat(reportAndChartList)
        dataSource.sort(utils.sortByKeyAndType('no', 'NUMBER'))
        this.setState({dataSource})
        const orchestrateButtons = await dataUtil.getOrchestrateButtonAll()
        const orchestrateMoudleMap = {}
        for (const button of orchestrateButtons) {
            const buttonData = {
                value: button.name,
                label: button.name
            }
            orchestrateMoudleMap[button.modulename] = orchestrateMoudleMap[button.modulename] || []
            orchestrateMoudleMap[button.modulename].push(buttonData)
        }
        this.setState({orchestrateMoudleMap})
        p.stopLoad()
    }
    /**
     * 取消
     */
    onCancel() {
        this.setState({visible: false})
    }
    /**
     * 确定
     */
    onOk() {
        let id = this.props.data.id
        const {moduleNames, moduleOpers, hideFields, reportNames} = this.state
        callModifyByApiBatch(auth, id, {
            moduleNames, moduleOpers, hideFields, reportNames
        }, () => {
            this.setState({visible: false})
            // 修改权限后重新登录或强刷生效
        })
    }
    /**
     * 模块权限发生变化
     * @param {*} key 
     * @param {*} e 
     */
    onModuleChange(key, record, e) {
        let moduleNames = this.state.moduleNames
        let moduleOpers = this.state.moduleOpers
        let reportNames = this.state.reportNames
        
        // 勾选模块权限，全选所有操作权限
        if (e.target.checked) {
            moduleNames.push(key)
            if (this.moduleOperMap[key] instanceof Array) {
                moduleOpers = moduleOpers.concat(this.moduleOperMap[key])
            }
            if (record.report) {
                reportNames.push(key)
            }
            this.setState({moduleNames, moduleOpers, reportNames})
        } else {
            // 取消勾选模块权限，取消勾选所有操作权限
            moduleNames = moduleNames.filter(it => it !== key)
            moduleOpers = moduleOpers.filter(it => {
                if (this.moduleOperMap[key] instanceof Array) {
                    return this.moduleOperMap[key].indexOf(it) === -1
                }
                return true
            })
            if (record.report) {
                reportNames = reportNames.filter(it => it !== key)
            }
            this.setState({moduleNames, moduleOpers, reportNames})
        }
    }
    /**
     * 模块操作权限发生变化
     * @param {*} key 
     * @param {*} e 
     */
    onModuleOperChange(key, e) {
        let moduleNames = this.state.moduleNames
        let moduleOpers = this.state.moduleOpers
        let moduleName = ''
        let index = key.indexOf('_')
        if (index !== -1) {
            moduleName = key.substring(0, index)
        }
        //console.log(moduleName, 'moduleName')
        if (e.target.checked) {
            // 有一个操作权限勾选时，勾选模块权限
            moduleOpers.push(key)
            if (moduleNames.indexOf(moduleName) === -1) {
                moduleNames.push(moduleName)
            }
            this.setState({moduleOpers, moduleNames})
        } else {
            moduleOpers = moduleOpers.filter(it => it !== key)
            // 如果模块权限下操作权限为空，取消模块权限勾选
            let moduleHasOper = false
            for (let key of moduleOpers) {
                if (key.indexOf(moduleName) !== -1) {
                    moduleHasOper = true
                    break
                }
            }
            if (!moduleHasOper) {
                moduleNames = moduleNames.filter(it => it !== moduleName)
            }
            this.setState({moduleOpers, moduleNames})
        }
    }
    onFieldCancel(moduleName) {
        let fieldVisible = this.state.fieldVisible || {}
        fieldVisible[moduleName] = false
        this.setState({fieldVisible: fieldVisible})
    }
    onFeildChange(moduleName, key, info) {
        if (info.target.checked) {
            let keys = this.state.hideFields[moduleName] || []
            let hideKeys = keys.map(it => it)
            hideKeys = hideKeys.filter(it => it !== key)
            let hideFields = this.state.hideFields
            hideFields[moduleName] = hideKeys
            this.setState({hideFields})
        } else {
            let keys = this.state.hideFields[moduleName] || []
            let hideKeys = keys.map(it => it)
            hideKeys.push(key)
            let hideFields = this.state.hideFields
            hideFields[moduleName] = hideKeys
            this.setState({hideFields})
        }
    }
    specialColumn(col) {
		if (col.dataIndex === 'modulename') {
            col.render = (text, record) => {
                return <Checkbox 
                    value={text} 
                    checked={this.state.moduleNames.indexOf(text) !== -1}
                    onChange={this.onModuleChange.bind(this, text, record)}
                >{dataUtil.showModuleName(record)}</Checkbox>
            }
        } else if (col.dataIndex === 'fields') {
            col.render = (text, record) => {
                if (utils.isFixedColMenu(record.modulename) || record.noAuthOper) {
                    return null
                }
                let moduleName = dataUtil.showModuleName(record)
                let fields_config = record.fields_config || []
                let allKeyLabels = fields_config.map(field => {
                    return {
                        key: field.name,
                        label: dataUtil.showName(field),
                    }
                })
                let hideKeys = this.state.hideFields[record.modulename] || []
                return <div>
                    <A onClick={() => {
                        let fieldVisible = this.state.fieldVisible || {}
                        fieldVisible[record.modulename] = true
                        this.setState({fieldVisible: fieldVisible})
                    }}>字段</A>
                    <ModalEx
                        title={`${moduleName}字段`}
                        visible={this.state.fieldVisible[record.modulename]}
                        onCancel={this.onFieldCancel.bind(this, record.modulename)}
                        onClose={this.onFieldCancel.bind(this, record.modulename)}
                        maskClosable={false}
                        width={300}
                        footer={[]}
                    >
                        {allKeyLabels.map((item, index) => {
                            return <div key={index}>
                                <Checkbox key={item.key} value={item.key} onChange={this.onFeildChange.bind(this, record.modulename, item.key)}  checked={hideKeys.indexOf(item.key) === -1} >{item.label}</Checkbox>
                            </div>
                        })}
                    </ModalEx>
                </div>
            }
        } else if (col.dataIndex === 'opers') {
            col.render = (text, record) => {
                const moduletype = record.moduletype
                if (utils.isNoAuthOperMenu(record.modulename) || record.noAuthOper) {
                    return null
                }
                let style={display: 'inline-block', width: 160, margin: 4}
                let CheckboxItem = (opername, label) => {
                    let value = `${record.modulename}_${opername}`
                    if (this.moduleOperMap[record.modulename] instanceof Array) {
                        this.moduleOperMap[record.modulename].push(value)
                    }
                    return <div key={opername} style={style}>
                        <Checkbox
                            value={value}
                            checked={this.state.moduleOpers.indexOf(value) !== -1}
                            onChange={this.onModuleOperChange.bind(this, value)}
                        >{label}</Checkbox>
                    </div>
                }
                // 公共操作
                let operAdd = CheckboxItem('oa', '新增')
                let operUpdate = CheckboxItem('ou', '修改')
                let operDelete = CheckboxItem('od', '删除')
                let operView = CheckboxItem('ov', '详情')
                let operImport = CheckboxItem('oi', '导入')
                let operExport = CheckboxItem('oe', '导出')
                let adminOper = CheckboxItem('oau', '管理员')
                // 系统配置 
                let operFields = CheckboxItem('of', '配置字段')
                let operWorkFlow = CheckboxItem('ow', '工作流')
                let operOrchestrate = CheckboxItem('oo', '业务编排')
                let operDisableEdit = CheckboxItem('ode', '禁用编辑')
                // 系统配置 字段配置
                let operAddField = CheckboxItem('oaf', '新增字段')
                let operUpdateField = CheckboxItem('ouf', '修改字段')
                let operDeleteField = CheckboxItem('odf', '删除字段')
                // 权限管理
                let operAuth = CheckboxItem('oaa', '权限')
                // 人员
                let operBecameUser = CheckboxItem('osbu', '成为用户')
                let operUnregisterUser = CheckboxItem('osuu', '注销用户')
                let operAuthManage = CheckboxItem('osam', "权限管理")
                let orchestrateOpers = (this.state.orchestrateMoudleMap[record.modulename] || [])
                    .map(buttonData => CheckboxItem(buttonData.value, buttonData.label))
                let operDiv
                let operDivStyle = { whiteSpace: 'normal'}
                if (record.modulename === tables_config) {
                    operDiv = <div style={operDivStyle}>
                        {operAdd}
                        {operUpdate}
                        {operDelete}
                        {operFields}
                        {operWorkFlow}
                        {operOrchestrate}
                        {operDisableEdit}
                        {operAddField}
                        {operUpdateField}
                        {operDeleteField}
                    </div>
                } else if (record.modulename === code_scheme) {
                    operDiv = <div style={operDivStyle}>
                        {operView}
                        {operAdd}
                        {operUpdate}
                        {operDelete}
                    </div>
                } else {
                    operDiv = <div style={operDivStyle}>
                        {moduletype === 'list' && operView}
                        {operAdd}
                        {operUpdate}
                        {operDelete}
                        {operImport}
                        {operExport}
                        {record.modulename === auth && operAuth}
                        {record.modulename === staff && operBecameUser}
                        {record.modulename === staff && operUnregisterUser}
                        {record.modulename === staff && operAuthManage}
                        {orchestrateOpers}
                        {moduletype === 'list' && adminOper}
                    </div>
                }
                return operDiv
            }
        }
	}
    /**
     * 渲染函数
     */
    render() {
        const {data} = this.props
        const {dataSource} = this.state
        // 普通员工应该有权限管理的权限
        //dataSource = dataSource.filter(item => item.modulename !== auth)
        this.moduleOperMap = {}
        for (let item of dataSource) {
            this.moduleOperMap[item.modulename] = []
        }
        
        //console.log(dataSource)
        //console.log(this.state.moduleNames)
        //console.log(this.state.moduleOpers)
        //console.log(this.moduleOperMap, 'this.moduleOperMap')
        return <span>
            {createIcon('users-gear', '权限', () => this.setState({visible: true}))}
            <ModalEx
                title={`${data.name}权限`}
                visible={this.state.visible}
                onCancel={this.onCancel.bind(this)}
                onClose={this.onCancel.bind(this)}
                onOk={this.onOk.bind(this)}
                maskClosable={false}
                width={global.modalWidth}
            >
                <OOTable 
                    hideOperCol hideIndexCol
                    list={dataSource}
                    fieldsConfig={fieldsConfig}
                    specialColumn={this.specialColumn.bind(this)}
                />
            </ModalEx>
        </span>
    }
}
export default Auth