import React, { Component } from 'react'
import SimpleLineChart from './line/SimpleLineChart'
import LineBarAreaComposedChart from './composed/LineBarAreaComposedChart'
import ScatterAndLineOfBestFit from './scatter/ScatterAndLineOfBestFit'
import TwoLevelPieChart from './pie/TwoLevelPieChart'
import StraightAnglePieChart from './pie/StraightAnglePieChart'
import TwoSimplePieChart from './pie/TwoSimplePieChart'
import CustomActiveShapePieChart from './pie/CustomActiveShapePieChart'
import PieChartWithCustomizedLabel from './pie/PieChartWithCustomizedLabel'
import PieChartWithPaddingAngle from './pie/PieChartWithPaddingAngle'
import VerticalComposedChart from './composed/VerticalComposedChart'
import PositiveAndNegativeBarChart from './bar/PositiveAndNegativeBarChart'
import LineChartWithReferenceLines from './line/LineChartWithReferenceLines'
import TinyBarChart from './bar/TinyBarChart'
import SimpleBarChart from './bar/SimpleBarChart'
import StackedBarChart from './bar/StackedBarChart'
import MixBarChart from './bar/MixBarChart'
import CustomShapeBarChart from './bar/CustomShapeBarChart'
import SameDataComposedChart from './composed/SameDataComposedChart'
import ComposedChartWithAxisLabels from './composed/ComposedChartWithAxisLabels'
import BandedChart from './composed/BandedChart'
import SimpleScatterChart from './scatter/SimpleScatterChart'
import CustomizedDotLineChart from './line/CustomizedDotLineChart'
import SimpleTreemap from './tree_map/SimpleTreemap'
import CustomContentTreemap from './tree_map/CustomContentTreemap'
import SimpleRadarChart from './radar/SimpleRadarChart'
import SpecifiedDomainRadarChart from './radar/SpecifiedDomainRadarChart'
import ThreeDimScatterChart from './scatter/ThreeDimScatterChart'
import JointLineScatterChart from './scatter/JointLineScatterChart'
import BubbleChart from './scatter/BubbleChart'
import MultipleYAxesScatterChart from './scatter/MultipleYAxesScatterChart'
import SimpleRadialBarChart from './radial_bar/SimpleRadialBarChart'
import SimpleAreaChart from './area/SimpleAreaChart'
import StackedAreaChart from './area/StackedAreaChart'
import TinyAreaChart from './area/TinyAreaChart'
import AreaChartConnectNulls from './area/AreaChartConnectNulls'
import SynchronizedAreaChart from './area/SynchronizedAreaChart'
import AreaChartFillByValue from './area/AreaChartFillByValue'
import DashedLineChart from './line/DashedLineChart'
import BiaxialLineChart from './line/BiaxialLineChart'
import VerticalLineChartWithSpecifiedDomain from './line/VerticalLineChartWithSpecifiedDomain'
import LineChartConnectNulls from './line/LineChartConnectNulls'
import SynchronizedLineChart from './line/SynchronizedLineChart'
import CustomizedLabelLineChart from './line/CustomizedLabelLineChart'
import HighlightAndZoomLineChart from './line/HighlightAndZoomLineChart'

export default class Test extends Component {
    
    render() {
        let cols = 2
        let width = global.clientWidth / cols / 1.1
        let height = global.clientHeight / cols / 1.1
        const options = {width, height}
        let className = 'chart-grid' + cols

        return <div className={className}>
            {/** 折线图 */}
            <SimpleLineChart options={options}/>
            <LineChartWithReferenceLines options={options} />
            <CustomizedLabelLineChart options={options} />
            <CustomizedDotLineChart options={options}/>
            <DashedLineChart options={options}/>
            <BiaxialLineChart options={options}/>
            <VerticalLineChartWithSpecifiedDomain options={options}/>
            <HighlightAndZoomLineChart options={options}/>
            <LineChartConnectNulls options={options}/>
            <SynchronizedLineChart options={options}/>
            {/**柱状图 */}
            <TinyBarChart  options={options} />
            <SimpleBarChart  options={options} />
            <StackedBarChart options={options} />
            <MixBarChart options={options} />
            <CustomShapeBarChart options={options} />
            <PositiveAndNegativeBarChart options={options}/>
            {/** 区域图 */}
            <SimpleAreaChart  options={options}/>
            <StackedAreaChart  options={options}/>
            <TinyAreaChart  options={options}/>
            <AreaChartConnectNulls  options={options}/>
            <SynchronizedAreaChart options={options}/>
            <AreaChartFillByValue options={options}/>
            {/** 散点图 */}
            <SimpleScatterChart options={options}/>
            <ThreeDimScatterChart options={options}/>
            <JointLineScatterChart options={options}/>
            <BubbleChart options={options}/>
            <MultipleYAxesScatterChart options={options}/>
            {/** 组合图 */}
            <LineBarAreaComposedChart options={options}/>
            <SameDataComposedChart options={options}/>
            <VerticalComposedChart options={options}/>
            <ComposedChartWithAxisLabels options={options}/>
            <ScatterAndLineOfBestFit options={options}/>
            <BandedChart options={options}/>
            {/** 饼图 */}
            <TwoLevelPieChart options={options}/>
            <StraightAnglePieChart options={options}/>
            <TwoSimplePieChart options={options}/>
            <CustomActiveShapePieChart options={options}/>
            <PieChartWithCustomizedLabel options={options}/>
            <PieChartWithPaddingAngle options={options}/>
            {/** 雷达图 */}
            <SimpleRadarChart options={options}/>
            <SpecifiedDomainRadarChart options={options}/>
            <SimpleRadialBarChart options={options}/>
            {/** 树图 */}
            <SimpleTreemap options={options}/>
            <CustomContentTreemap options={options}/>
        </div>
    }
}