/**
 * 列表模型接口
 */
import * as utils from '@utils/index'

/**
 * 查询分页 异步
 * @param {*} moduleName 
 * @param {*} page 
 * @param {*} pageSize 
 * @param {Query} query
{
    searchFields 搜索值
    mainFields 主字段类型
    moreFields 更多字段类型
}
 * @param {Sorter} sorter
 * @param {*} cb 
 */
export function getList(moduleName, page, pageSize, query, sorter, cb) {
    let data = { page, pageSize, query, sorter }
    utils.getApi().put(`/${moduleName}/list`, { data }).then(payload => {
        cb && cb(payload.body || {})
    }, () => {
        cb && cb({})
    })
}
/**
 * 查询分页 同步
 * @param {*} moduleName 
 * @param {*} page 
 * @param {*} pageSize 
 * @param {Query} query
{
    searchFields 搜索值
    mainFields 主字段类型
    moreFields 更多字段类型
}
 * @param {Sorter} sorter
 */
export async function getListPromise(moduleName, page, pageSize, query, sorter) {
    let data = { page, pageSize, query, sorter }
    try {
        const payload = await utils.getApi().put(`/${moduleName}/list`, { data })
        return payload.body || {}
    } catch (e) {}
    return {}
}

/**
 * 查询不分页 异步
 * @param {*} moduleName 
 * @param {Query} query
{
    searchFields 搜索值
    mainFields 主字段类型
    moreFields 更多字段类型
}
 * @param {Sorter} sorter
 */
export function getListAll(moduleName, query, sorter, cb) {
    utils.getApi().put(`/${moduleName}/listAll`, {
        data: query,
        params: sorter
    }).then(payload => {
        cb && cb((payload && payload.body) || [])
    }, () => {
        cb && cb([])
    })
}

/**
 * 查询不分页 同步
 * @param {*} moduleName 
 * @param {Query} query
{
    searchFields 搜索值
    mainFields 主字段类型
    moreFields 更多字段类型
}
 * @param {Sorter} sorter
 */
export async function getListAllPromise(moduleName, query = {}, sorter) {
    try {
        const payload = await utils.getApi().put(`/${moduleName}/listAll`, {
            data: query,
            params: sorter
        })
        return payload.body || []
    } catch (e) {
        console.error(e)
        return []
    }
}

/**
 * 根据id获取一条数据
 * @param {*} id 
 * @param {*} success 
 */
export function getOne(moduleName, id, success) {
    utils.getApi().get(`/${moduleName}`, {
        params: { id }
    }).then(payload => {
        let body = payload.body || {}
        success && success(body)
    })
}

/**
 * 根据id获取一条数据
 * @param {*} id 
 */
export async function getOnePromise(moduleName, id) {
    try {
        const payload = await utils.getApi().get(`/${moduleName}`, { params: { id } })
        return  (payload && payload.body) || {}
    } catch (e) {
        console.error(e)
        return {}
    }
}

/**
 * 根据编码获取一条数据
 * @param {*} id 
 * @param {*} success 
 */
export function getOneByCode(moduleName, code, success) {
    utils.getApi().get(`/${moduleName}/bycode`, {
        params: { code }
    }).then(payload => {
        success && success(payload.body || {})
    })
}

/**
 * 根据编码获取一条数据
 * @param {*} id 
 * @param {*} success 
 */
export async function getOneByCodePromise(moduleName, code) {
    try {
        const payload = await utils.getApi().get(`/${moduleName}/bycode`, { params: { code } })
        return  (payload && payload.body) || {}
    } catch (e) {
        console.error(e)
        return {}
    }
}
