/**
 * Mapping
 * 字段映射组件
 * LeftTree 映射目标
 * RightTree 映射数据源
 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Mapping from './Mapping'
import {createButton, createIcon} from './PageCreator'
import ModalEx from './ModalEx'
import Tabs from 'antd/lib/tabs'
import * as utils from '@utils/index'

const TabPane = Tabs.TabPane
export default class MappingIncludeSublist extends Component {
    constructor() {
        super()
        this.state = {
			mappingPropsArray: []
        }
		this.mapInst = {}
    }
	
    openMapping() {
        let {initData, value} = this.props
        if (!(initData instanceof Function)) {
            utils.warning('请传入 initData 回调函数')
            return
        }
		value = value || {}
        const {leftModule, rightModule, sublistmapping} = this.props.initData() || {}
		if (utils.isBlank(rightModule) || utils.isBlank(leftModule) || utils.equals(sublistmapping, {})) {
			console.error('MappingIncludeSublist openMapping fail, rightModule, leftModule, sublistmapping: ', 
				rightModule, leftModule, sublistmapping)
			return
		}
        const leftModuleConfig = utils.getModuleConfig(leftModule)
		const rightModuleConfig = utils.getModuleConfig(rightModule)
		if (!(leftModuleConfig.sublist instanceof Array && leftModuleConfig.sublist.length > 0 &&
			rightModuleConfig.sublist instanceof Array && rightModuleConfig.sublist.length > 0)
		) {
			console.error('MappingIncludeSublist openMapping fail, leftModule.sublist, rightModule.sublist: ', 
				leftModuleConfig.sublist, leftModuleConfig.sublist)
			return
		}
		const mappingPropsArray = []
		mappingPropsArray.push({value: value[leftModule], modulename: leftModule, 
			leftConfig: leftModuleConfig, rightConfig: rightModuleConfig})
		for (let key in sublistmapping) {
			if (utils.isNotBlank(sublistmapping[key])) {
				let leftConfig = utils.getModuleConfig(key)
                let rightConfig = utils.getModuleConfig(sublistmapping[key])
				mappingPropsArray.push({value: value[key], modulename: key, leftConfig, rightConfig})
			}
		}
		this.setState({mappingPropsArray, visible: true})
	}
	
    render () {
		const {mappingPropsArray} = this.state
        const {mode, title, disabled, canEdit, isForSearch, showType} = this.props
		let { icon, buttonName } = this.props
		if (buttonName == null) {
			buttonName = '数据传递'
		}
		if (utils.isBlank(icon)) {
			icon = 'exchange'
		}
        let iconMode = createIcon(icon, buttonName, this.openMapping.bind(this), {disabled, showType})
        let buttonMode = createButton(icon, buttonName, this.openMapping.bind(this), 
			{ disabled, showType, type: "primary", ghost: true })
		
        return <span>
            {mode === 'icon' ? iconMode : buttonMode}
			<ModalEx
				title={title || '数据传递'}
				visible={this.state.visible}
				onCancel={() => this.setState({visible: false})}
				onClose={() => this.setState({visible: false})}
				maskClosable={false}
				width={global.modalWidth}
				onOk={() => {
					let value = {}
					for (let key in this.mapInst) {
						const inst = this.mapInst[key]
						if (inst && (inst.getValue instanceof Function)) {
							value[key] = this.mapInst[key].getValue()
						}
					}
					//console.log(value)
					this.props.onChange && this.props.onChange(value)
					this.setState({visible: false})
				}}
			>
				<Tabs>
					{mappingPropsArray.map((props, index) => <TabPane key={index} tab={props.modulename}>
						<Mapping ref={inst => {
							this.mapInst[props.modulename] = inst
						}} notModal fModal {...props} canEdit={canEdit} isForSearch={isForSearch}/>
					</TabPane>)}
				</Tabs>
			</ModalEx>
        </span>
    }
}

Mapping.propTypes = {
	/**
	 * 字段映射
	 */
	value: PropTypes.any,
	/**
	 * 弹窗打开时数据初始化回调函数
	 */
    initData: PropTypes.func,
}