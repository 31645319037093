/*******************************************************************************
 * 路由相关配置
 ******************************************************************************/

/**
 * 不需要登录可以直接访问的路由
 */
export const noTokenRoute = [
    '/login', 
    '/qrcode-scan',
    '/charts-test'
]

export const isNoTokenRoute = (path) => {
    return noTokenRoute.indexOf(path) !== -1
}
