/**
 * 表格工具
 */
import * as utils from './index'
import * as dataUtil from './DataUtil'
import {addMenuItem} from '@com/PageCreator'
import moment from 'moment'

/**
 * 顺序号
 * 字段配置
 * @param {*} config 
 */
function autoIncrease(config, thisPage, ootable = true) {
    if (thisPage == null || thisPage.table == null || thisPage.state == null
        || config == null || utils.isBlank(config.name)
    ) {
        console.log('autoIncrease param check fail. config, thisPage:', config, thisPage)
    }
    if (config && config.otherParams && config.otherParams.autoincrease === '1') {
        const {name} = config
        let {min, step} = config.otherParams
        min = min || 1
        step = step || 1
        const list = thisPage.state.list || []
        let listAll = list
        if (ootable) {
            listAll = thisPage.state.listAll || []
        }
        if (listAll.length > 0 && utils.isNotBlank(name)) {
            listAll.forEach((item, index) => {
                item[name] = min + index * step
                if (!ootable) {
                    item.change = true
                }
            })
            const numberArray = list.map(item => {
                return {id: item.id, [name]: item[name]}
            })
            const tableFormValues = dataUtil.buildTableFormData(numberArray)
            thisPage.table.setFieldsValue(tableFormValues)
            if (ootable) {
                utils.storeData(thisPage, thisPage.pageKey, {list, listAll})
                if (thisPage.props && thisPage.props.onOOTableChange) {
                    thisPage.props.onOOTableChange(listAll)
                }
            } else {
                utils.storeData(thisPage, thisPage.pageKey, {list: listAll})
            }
        }
    }
}
/**
 * replaceRecord 替换一条记录
 * @param {*} list 
 * @param {*} newRecord 
 */
function replaceRecord(list, newRecord) {
    if (!(list instanceof Array) || newRecord == null) {
        console.error('replaceRecord param check fail. list, newRecord: ', list, newRecord)
        return
    }
    let index = list.findIndex(item => item.id === newRecord.id)
    if (index !== -1) {
        list.splice(index, 1, newRecord)
    }
}

function getSearchFields(fieldsConfig = [], fixedSearchConds) {
    fixedSearchConds = fixedSearchConds || {}
    const fixedSearchKeys = Object.keys(fixedSearchConds) 
    const mainSearchFields = []
    const moreSearchFields = []
    // 0,不查询/2,主要条件/1,更多条件
    for (const config of fieldsConfig) {
        const {name, dataType, isQuery} = config
        // 跳过固定搜索条件的字段
        if (fixedSearchKeys.indexOf(name) !== -1) {
            continue
        }
        // 跳过不查询和父模块字段
        if (isQuery === '0' || name === 'fmodule' || 
            // 跳过分组和表格类型字段
            dataUtil.isGroupType(dataType) || dataUtil.isTableType(dataType)
        ) {
            continue
        }
        // 主查询
        if (utils.isNameCode(name) || isQuery === '2') {
            mainSearchFields.push(config)
        } else if (isQuery === '1' || utils.isBlank(isQuery)) {
            moreSearchFields.push(config)
        }
    }
    return {mainSearchFields, moreSearchFields}
}

function queryString(data = {}, name = '', value) {
    if (utils.isNotBlank(data[name]) && data[name].indexOf(value) !== -1) {
        return true
    }
    return false
}
function queryId(data = {}, name = '', value) {
    if (utils.isNotBlank(data[name]) && data[name] === value) {
        return true
    }
    return false
}
function isBefore(data = {}, name = '', value) {
    return moment(data[name]).isBefore(value)
}
function isAfter(data = {}, name = '', value) {
    return moment(data[name]).isAfter(value)
}
function queryData(query, data = {}) {
    if (query == null) {
        return true
    }
    let {searchFields, mainFields, moreFields} = query
    if (searchFields == null || utils.equals(searchFields, {})) {
        return true
    }
    mainFields = mainFields || []
    moreFields = moreFields || []
    if (mainFields.length === 0 && moreFields.length === 0) {
        return true
    }
    // 或查询，默认false
    let mainSearchResult = false
    if (mainFields.length > 0 && utils.isNotBlank(searchFields.mainKey)) {
        const mainKey = searchFields.mainKey
        for (let field of mainFields || []) {
            const {id, dataType} = field
            if (dataUtil.isStringTextType(dataType)) {
                if (queryString(data, id, mainKey)) {
                    mainSearchResult = true
                    break
                }
            } else {
                if (queryId(data, id, mainKey)) {
                    mainSearchResult = true
                    break
                }
            }
        }
    } else {
        // 主条件为空
        mainSearchResult = true
    }
    // 主条件不满足
    if (mainSearchResult === false) {
        return false
    }
    // 更多条件，与查询
    let moreSearchResult = true
    for (let field of moreFields) {
        const id = field.id
        let dataType = field.dataType
        if (dataUtil.isFFieldType(dataType)) {
            const config = utils.copy(field)
            dataUtil.mergeFConfig(config)
            dataType = config.dataType
        }
        let value = searchFields[id]
        // 空值跳过
        if (utils.isBlank(value)) {
            continue
        }
        if (dataUtil.isGroupType(dataType)) {
            continue
        }
        // 查询字符串
        if (dataUtil.isStringTextType(dataType)) {
            if (!queryString(data, id, value)) {
                moreSearchResult = false
                break
            }
        } 
        // 查询数字范围
        else if (dataUtil.isNumber(dataType)) {
            if (!(value instanceof Array) || value.length !== 2) {
                continue
            }
            let start = value[0]
            let end = value[1]
            if (utils.isNotBlank(start)) {
                if (data[id] < parseFloat(start)) {
                    moreSearchResult = false
                    break
                }
            }
            if (utils.isNotBlank(end)) {
                if (data[id] > parseFloat(end)) {
                    moreSearchResult = false
                    break
                }
            }
        }
        // 查询日期范围
        else if (dataUtil.isDateType(dataType)) {
            if (value.length !== 2) {
                continue
            }
            let start = value[0]
            let end = value[1]
            if (start) {
                const date = new Date(start)
                date.setHours(0)
                date.setMinutes(0)
                date.setSeconds(0)
                if (isAfter(data, id, date)) {
                    moreSearchResult = false
                    break
                }
            }
            if (end) {
                const date = new Date(end)
                date.setHours(23)
                date.setMinutes(59)
                date.setSeconds(59)
                if (isBefore(data, id, date)) {
                    moreSearchResult = false
                    break
                }
            }
        } else if (dataUtil.isTimeType(dataType)) {
            if (value.length !== 2) {
                continue
            }
            let start = value[0]
            let end = value[1]
            if (start) {
                if (isAfter(data, id, start)) {
                    moreSearchResult = false
                    break
                }
            }
            if (end) {
                if (isBefore(data, id, end)) {
                    moreSearchResult = false
                    break
                }
            }
        } else if (dataUtil.isListTreeType(dataType) || dataUtil.isIDType(dataType) || 
            dataUtil.isCheckboxType(dataType)
        ) {
            if (!queryId(data, id, value)) {
                moreSearchResult = false
                break
            }
        } else if (dataUtil.isSelectMultiType(dataType)) {
            const { addArr } = utils.diffArray(data[id], value)
            if (addArr.length > 0) {
                moreSearchResult = false
                break
            }
        } else {
            if (!queryId(data, id, value)) {
                moreSearchResult = false
                break
            }
        }
    }
    return moreSearchResult
}
function up(index, list, reRender) {
    if (index > 0) {
        utils.swap(list, index, index - 1)
        reRender()
    }
}
function upToTop(index, list, reRender) {
    if (index > 0) {
        const record = list[index]
        list.splice(index, 1)
        list.unshift(record)
        reRender()
    }
}
function down(index, list, reRender) {
    if (index < list.length - 1) {
        utils.swap(list, index, index + 1)
        reRender()
    }
}
function downToBottom(index, list, reRender) {
    if (index < list.length - 1) {
        const record = list[index]
        list.splice(index, 1)
        list.push(record)
        reRender()
    }
}

function addMoveOpers(moreOptions, index, list, reRender) {
    let canMoveDown = index < list.length - 1
    let canMoveUp = index !== 0
    if (canMoveUp) {
        addMenuItem(moreOptions, '上移', () => up(index, list, reRender))
        addMenuItem(moreOptions, '上移至顶部', () => upToTop(index, list, reRender))
    }
    if (canMoveDown) {
        addMenuItem(moreOptions, '下移', () => down(index, list, reRender))
        addMenuItem(moreOptions, '下移至底部', () => downToBottom(index, list, reRender))
    }
}

export {
    autoIncrease, replaceRecord, getSearchFields, queryData,
    up, upToTop, down, downToBottom, addMoveOpers,
}