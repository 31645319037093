/**
 * 模块配置主页面
 * 创建、修改、删除、查询模块配置，根据模块配置生成模块
 */
import React, { Component } from 'react'
import {createButton} from '@com/PageCreator'
import ListModule from '@list/ListModule'
import FieldsConfigOOTable from './FieldsConfigOOTable'
import { tables_config } from '@utils/constants'
import * as utils from '@utils/index'
import * as p from '@utils/page'

export default class ModuleList extends Component {
    constructor() {
        super()
        this.moduleName = tables_config
        this.state = {
            selectedRowKeys: [],
            selectedRows: [],
        }
    }

    iconOperation(text, record) {
        if (global.localFrontEnd ? !utils.isFixedColMenu(record.modulename) : 
            !utils.isSysModule(record.modulename)
        ) {
            return <FieldsConfigOOTable key={text + 'FieldsConfigOOTable'} data={record}/>
        }
        return null
    }

    openWorkflow() {
        let row = this.state.selectedRows[0]
        const showModuleName = global.showModuleName(row)
        if (!row.hasflow) {
            utils.warning('提示', `请先修改${showModuleName}模块配置，勾选其他配置中的流程复选框`)
            return
        }
        let url = `/workflow?id=${row.id}&name=${row.modulename}`
        let title = `${showModuleName}-流程编辑`
        p.openpage(url, title)
    }
    openBizfow() {
        let row = this.state.selectedRows[0]
        let url = `/orchestrate?&modulename=${row.modulename}`
        let title = `${global.showModuleName(row)}-业务编排`
        p.openpage(url, title)

    }
    openDataTransfer() {
        let row = this.state.selectedRows[0]
        let url = `/datatransfer?modulename=${row.modulename}`
        let title = `${global.showModuleName(row)}-数据传递`
        p.openpage(url, title)
    }
    beforeCopy(copyData = {}) {
        delete copyData.modulename
        delete copyData.sublist
        delete copyData.fmodule
    }

    async refreshPage(clientId, page) {
        if (this.list && this.list.refreshPage) {
            await this.list.refreshPage(clientId, page)
        }
    }

    onSelectChange(selectedRowKeys, selectedRows) {
        this.setState({
            selectedRowKeys: selectedRowKeys,
            selectedRows: selectedRows,
        })
    }

    specialItemProps(formField, props) {
        if (formField.id === 'sublist') {
            props.disabled = true
        }
    }

    render() {
        let config = utils.getConfigByModuleName(this.moduleName) || {}
        const {selectedRows} = this.state
        // 权限控制
        let moduleOpers = (global.role && global.role.moduleOpers) || []
        this.operWorkFlow = global.isAdmin || moduleOpers.indexOf(`${this.moduleName}_ow`) !== -1
        this.operOrchestrate = global.isAdmin || moduleOpers.indexOf(`${this.moduleName}_oo`) !== -1
        this.operDisableEdit = global.isAdmin || moduleOpers.indexOf(`${this.moduleName}_ode`) !== -1
        const buttons = []
        const isSelectOne = selectedRows.length === 1
        if (this.operWorkFlow && isSelectOne) {
            buttons.push(createButton('wave-square', '工作流', this.openWorkflow.bind(this), {
                key: 'workflow', type:'primary', ghost: true
            }))
        }
        if (this.operOrchestrate && isSelectOne) {
            buttons.push(createButton('code-merge', '业务编排', this.openBizfow.bind(this), {
                key: 'orchestrate', type:'primary', ghost: true
            }))
        }
        if (isSelectOne) {
            buttons.push(createButton('arrow-right-arrow-left', '数据传递', this.openDataTransfer.bind(this), {
                key: 'datatransfer', type:'primary', ghost: true
            }))
        }
        return <div>
            {config && <ListModule 
                ref={(inst) => this.list = inst} 
                {...this.props}
                onSelectChange={this.onSelectChange.bind(this)}
                buttons={buttons}
                moduleName={this.moduleName}
                config={config}
                beforeCopy={this.beforeCopy.bind(this)}
                iconOperation={this.iconOperation.bind(this)}
                specialItemProps={this.specialItemProps.bind(this)}
            />}
        </div>
    }
}