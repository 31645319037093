import React, { Component } from 'react'
import SelectOne from './SelectOne'
import InputSelect from './InputSelect'
import Button from 'antd/lib/button'
import Icon from './Icon'
import * as utils from '@utils/index'

export default class Expression extends Component {
    constructor() {
        super()
        this.state = {
            dataSource: [],
            visible: false,
        }
    }

    componentDidMount() {
        this.setState({dataSource: this.props.dataSource || []})
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.dataSource !== nextProps.dataSource) {
            this.setState({dataSource: nextProps.dataSource || []})
        }
    }

    onCancel() {
        this.setState({visible: false})
    }
    buildExpr(obj) {
        if (!(obj && obj.left && obj.oper && obj.right)) {
            return
        }
        obj.expr = {
            [obj.oper]: [this.foramt(obj.left), this.foramt(obj.right)]
        }
        if (!obj.parent) {
            return
        }
        if (obj.parent.leftShow) {
            obj.parent.left = obj.expr
        }
        if (obj.parent.rightShow) {
            obj.parent.right = obj.expr
        }
        this.buildExpr(obj.parent)
    }
    foramt(value) {
        if (utils.isBlank(value)) {
            return null
        }
        if (!isNaN(value)) {
            return Number(value)
        } 
        return value
    }

    render() {
        const opers = [
            {value: '$add', label: '加'},
            {value: '$subtract', label: '减'},
            {value: '$multiply', label: '乘'},
            {value: '$divide', label: '除'},
        ]
        let obj = this.props.value || {}
        let builEexprDiv = (obj, parent, root) => {
            obj.leftObj = obj.leftObj || {}
            obj.rightObj = obj.rightObj || {}
            obj.parent = parent
            return <div style={{marginTop: 16}}>
                <InputSelect style={{width: 200}} dataSource={this.state.dataSource} 
                value={obj.left && global.toString(obj.left)} 
                onChange={value => {
                    obj.left = value
                    this.buildExpr(obj)
                    this.props.onChange && this.props.onChange(root)
                }}/>
                <Button onClick={() => {
                    obj.leftShow = !obj.leftShow
                    obj.rightShow = false
                    this.props.onChange && this.props.onChange(root)
                }}>
                    <Icon type={obj.leftShow ? 'caret-up' : "caret-down"}/>
                </Button>
                <SelectOne placeholder='操作' style={{width: 80, marginLeft: 16, marginRight: 16}} dataSource={opers} value={obj.oper} onChange={value => {
                    obj.oper = value
                    this.buildExpr(obj)
                    this.props.onChange && this.props.onChange(root)
                }}/>
                <InputSelect style={{width: 200}} dataSource={this.state.dataSource} 
                value={obj.right && global.toString(obj.right)} 
                onChange={value => {
                    obj.right = value
                    this.buildExpr(obj)
                    this.props.onChange && this.props.onChange(root)
                }}/>
                <Button onClick={() => {
                    obj.rightShow = !obj.rightShow; 
                    obj.leftShow = false
                    this.props.onChange && this.props.onChange(root)
                }}>
                    <Icon type={obj.rightShow ? 'caret-up' : "caret-down"}/>
                </Button>
                <span style={{fontSize: 16, fontWeight: 800, marginLeft: 32}}>
                    {parent && parent.leftShow && <span style={{color: 'green'}}>左边</span>}
                    {parent && parent.rightShow && <span style={{color: 'blue', marginLeft: 64}}>右边</span>}
                </span>
                {obj.leftShow && builEexprDiv(obj.leftObj, obj, root)}
                {obj.rightShow && builEexprDiv(obj.rightObj, obj, root)}
            </div>
        }

        return <div>
            {builEexprDiv(obj, null, obj)}
        </div>
    }
}
