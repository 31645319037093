/*******************************************************************************
 * 常量定义
 ******************************************************************************/

/**
 * 无父模块path显示self
 */
global.nofid = 'self'

global.hasfid = (fid) => {
    return fid && fid !== global.nofid
}