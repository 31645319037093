import React, { Component } from 'react'
import Button from 'antd/lib/button'
import Upload from 'antd/lib/upload'
import message from 'antd/lib/message'
import ButtonIcon from './ButtonIcon'
import { getFileById } from '../modules/file/Action'
import {getCookie} from '@utils/cookie'
import { openpage } from '@utils/page'

export default class Avatar extends Component {
    constructor(props) {
        super()
        this.state = {
            loading: false,
        }
    }

    beforeUpload(file) {
        const isLt2M = file.size / 1024 / 1024 < 20
        if (!isLt2M) {
            message.error('Image must smaller than 20MB!')
        }
        return isLt2M
    }

    handleChange(info) {
        if (info.file.status === 'uploading') {
            this.setState({ loading: true })
            return
        }
        if (info.file.status === 'done') {
            //console.log(info.file.response, 'info.file.response')
            let file = info.file.response
            let imageUrl = global.getApi().opts.baseURI + file.filePath
            this.setState({imageUrl, loading: false})
            this.props.onChange && this.props.onChange(file.id)
        }
    }

    componentDidMount(props) {
        this.props.value && this.refresh(this.props.value)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.value && nextProps.value !== this.props.value) {
            this.refresh(nextProps.value)
        }
    }

    refresh(id) {
        getFileById(id, file => {
            if (file && file.filePath) {
                //console.log(file, 'file')
                let imageUrl = global.getApi().opts.baseURI + file.filePath
                this.setState({imageUrl})
            }
        })
    }

    render() {
        const { imageUrl } = this.state
        const width = this.props.width || 128
        return <div>
            {!this.props.disabled && <Upload
                headers={{token: getCookie('token')}}
                showUploadList={false}
                action={`${global.getApi().opts.baseURI}/file/upload`}
                beforeUpload={this.beforeUpload.bind(this)}
                onChange={this.handleChange.bind(this)}
                data={{ businessId: this.props.businessId }}
            >
                <Button type="ghost">
                    <ButtonIcon type={this.state.loading ? 'spinner' : "cloud-upload"} />{this.props.buttonName || '点击上传'}
                </Button>
            </Upload>}
            {imageUrl && <div onClick={() => openpage('/fullimage?url=' + imageUrl, '预览')}>
                <img src={imageUrl} alt="avatar" style={{ width, objectFit: 'contain' }} />
            </div>}
        </div>
    }
}