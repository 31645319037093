/**
 * Pipeline
 * 管道
 * 连接业务编排和数据传递的数据流
 */
import React, { Component } from 'react'
import OOTableEx from './OOTableEx'
import * as dataUtil from '@utils/DataUtil'
import {getOptionArray} from '@utils'

const pageOptions = getOptionArray("addPage,新增/detailPage,详情")
const typeOptions = getOptionArray('datatransfer,数据传递/orchestrate,业务编排/openpage,打开页面')
export default class Pipeline extends Component {
    constructor() {
        super()
        this.state = {
            list: [],
        }
        this.fieldsConfig = [
            dataUtil.getConfig('type', '类型', 'OOSelect', {
                width: 160,
                otherParams: {
                    options: typeOptions
                },
                isRequire: '1',
            }),
            dataUtil.getConfig('moduleName', '模块名称', 'ModuleSelect', {
                width: 160,
                isRequire: '1',
            }),
            dataUtil.getConfig('name', '操作名称', 'OOSelect', {
                width: 160,
                isRequire: '1',
                otherParams: {
                    options: '$names'
                }
            }),
        ]
    }

    componentDidMount() {
        this.init()
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.props.value) {
            this.init(nextProps)
        }
    }

    init(props = this.props) {
        const {value} = props
        let list = []
        if (value instanceof Array) {
            list = value
        }
        this.setState({list})
    }

    async onOOTableChange(list, record, key) {
		if (record == null || key == null) {
            this.setState({list})
            this.props.onChange && this.props.onChange(list)
			return
		}
        // 类型或模块名称变化，清空操作名称，重新获取操作名称选项
		if (key === 'type' || key === 'moduleName') {
            record.name = null
            record.names = await this.getNames(record)
        }
        this.setState({list})
        this.props.onChange && this.props.onChange(list)
	}
    /**
     * 类型或模块名称变化，清空操作名称，重新获取操作名称选项
     * @param {*} record 
     * @returns 
     */
    async getNames(record) {
        let names = []
        const {type, moduleName} = record
        if (type && moduleName) {
            if (type === 'datatransfer') {
                const rows = await dataUtil.getDatatransferList(moduleName)
                if (rows instanceof Array) {
                    names = rows.map(it => it.name)
                }
            } else if (type === 'orchestrate') {
                const rows = await dataUtil.getOrchestrateButton(moduleName)
                if (rows instanceof Array) {
                    names = rows.map(it => it.name)
                }
            } else if (type === 'openpage') {
                names = pageOptions
            }
        }
        return names
    }

    formError() {
        return this.ootable && this.ootable.formError && this.ootable.formError()
    }

    render() {
        return <OOTableEx 
            {...this.props} 
            ref={(inst) => this.ootable = this.ootable || inst}
            value={this.state.list}
            onChange={this.onOOTableChange.bind(this)}
            fieldsConfig={this.fieldsConfig}
        />
    }
}