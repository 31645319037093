import React, { Component } from 'react'
import message from 'antd/lib/message'
import ModalEx from '@com/ModalEx'
import Input from 'antd/lib/input'
import {callApiBatch, getApiItem} from '../layout/Action'
import { createButton, createIcon, } from '@com/PageCreator'
import FieldParamSet from './FieldParamSet'
import * as dataUtil from '@utils/DataUtil'
import * as utils from '@utils/index'
import c from '@utils/constants'
import {fieldsConfig} from './Config'
import OOTableEx from '@com/OOTableEx'
import * as tableUtil from '@utils/tableUtil'

const {// 动态模块配置修改
    tables_config, update,
} = c

export default class FieldsConfigOOTable extends Component {
	/**
     * 构造函数 初始化
     * @param {*} props 
     */
	constructor() {
		super()
		this.moduleName = tables_config
		this.state = {
			configJSON: '[]',
			config: [],
			query: {},
			showMore: false,
			nameStr: '',
			paramSetVisible: false,
		}
	}
	componentDidMount() {
        this.init(this.props)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!utils.equals(nextProps.data, this.props.data)) {
            this.init(nextProps)
        }
    }

	/**
     * 页面开始加载时
     */
	init(props) {
		const {data} = props
		let config = data.fields_config || []
		let fconfig = null
		if (data.fmodule) {
			fconfig = dataUtil.getMergeConfig(data.fmodule).fields_config
		}
		// 初始化表达式中的变量
		const record = {}
		const f = {}
		const initFackValue = (config, record) => {
			if (config == null) {
				return
			}
			// 按字段类型赋值默认值
			for (let feild of config) {
				if (dataUtil.isExprSupportConfig(feild)) {
					record[feild.name] = 524
				} else {
					record[feild.name] = 'string'
				}
			}
		}
		initFackValue(config, record)
		initFackValue(fconfig, f)
		this.setState({
			config, record, f,
			configJSON: JSON.stringify(config, null, 2)
		})
		// 权限控制
        let moduleOpers = (global.role && global.role.moduleOpers) || []
		this.operAddField = global.isAdmin || moduleOpers.indexOf(`${this.moduleName}_oaf`) !== -1
		this.operUpdateField = global.isAdmin || moduleOpers.indexOf(`${this.moduleName}_ouf`) !== -1
		this.operDeleteField = global.isAdmin || moduleOpers.indexOf(`${this.moduleName}_odf`) !== -1
	}

	/**
	 * 字段属性校验
	 * @param {*} row 
	 * @returns 
	 */
	checkRow(row) {
		const {name, dataType, params} = row
		if (utils.isBlank(name)) {
			if (utils.isBlank(params)) {
				message.error('列表或树的模块名称未选择，请检查')
				return false
			}
		}
		if (dataUtil.isListTreeType(dataType)) {
			if (utils.isBlank(params)) {
				message.error('列表或树的模块名称未选择，请检查')
				return false
			}
		}
		if (dataUtil.isSumType(dataType)) {
			if (utils.isBlank(row.otherParams && row.otherParams.modulename)) {
				message.error('合计的子模块未选择，请检查')
				this.record && this.setState({paramSetVisible: true})
				return false
			}
			if (utils.isBlank(row.otherParams && row.otherParams.name)) {
				message.error('合计的子模块字段未选择，请检查')
				this.record && this.setState({paramSetVisible: true})
				return false
			}
		}
		if (dataUtil.isFFieldType(dataType)) {
			if (utils.isBlank(row.otherParams && row.otherParams.name)) {
				message.error('默认父列的字段未选择，请检查')
				this.record && this.setState({paramSetVisible: true})
				return false
			}
		}
		// 校验表达式是否合法
		if (dataType === 'EXPR') {
			if (utils.isBlank(params)) {
				utils.warning('请配置表达式')
				return false
			}
			let expr = params.trim()
			expr = expr.replaceAll('（', '(')
			expr = expr.replaceAll('）', ')')
			row.params = expr
			// 表达式中的数据，recrod 本模块数据， f 父模块数据
			// eslint-disable-next-line
			const {record, f} = this.state
			try {
				let newExpr = dataUtil.getNewExpr(this.state.config, expr)
				// eslint-disable-next-line
				let value = eval(newExpr)
				if (!(typeof value === 'string') && isNaN(value)) {
					utils.warning('表达式测试结果不是数字，请检查')
				}
			} catch (e) {
				let msg = (e && e.message) || '表达式测试结果不是数字，请检查'
				msg = msg.replace('is not defined', '不是能解析成数字的类型')
				utils.warning('表达式不合法', msg)
				return false
			}
		}
		return true
	}
	
	async onSave(apiArray) {
		if (this.formError()) {
			return false
		}
		const config = this.getList()
		if (!(config instanceof Array) || config.length === 0) {
			utils.warning('请配置字段')
			return false
		}
		for (let row of config) {
			if (!this.checkRow(row)) {
				return false
			}
			// 将组件中获取的 undefined 值转为 null, 才能保存到数据库
			for (let key in row) {
				if (row[key] === undefined) {
					row[key] = null
				}
				// 去掉文本前后空格
				if (typeof row[key] === 'string') {
					row[key] = row[key].trim()
				}
			}
		}
		const record = this.props.data
		record.fields_config = config
		if (apiArray instanceof Array) {
            apiArray.push(getApiItem(update, tables_config, [record.id, record]))
        }
		this.setState({visible: false})
		return true
    }

	async onSaveWrapper() {
        const getApiArray = async apiArray => await this.onSave(apiArray)
        await callApiBatch(getApiArray, '保存成功')
    }

	/**
	 * 直接修改
	 */
    async modifyDirect() {
		let config = global.parseArray(this.state.configJSON)
		if (!(config instanceof Array) || config.length === 0) {
			utils.warning('json数据格式有误，请检查')
			return
		}
		this.setState({ config })
    }
	
	/**
     * 搜索按钮响应函数
     * @param {*} query 
     * @param {*} searchFields 
     */
    onSearch(query, searchFields) {
		let value = searchFields.mainKey || ''
		value = value.trim()
		if (value === '') {
			this.setState({config: this.state.config})
		} else {
			let config = this.state.config
				.filter(item => item.name.indexOf(value) !== -1 || item.alias.indexOf(value) !== -1)
			this.setState({config: config})
		}
	}

	getNames() {
		if (utils.isBlank(this.state.nameStr)) {
			return null
		}
		let nameStr = this.state.nameStr.replace(/\s+/g, ' ')
		return nameStr.split(' ')
	}

	/**
	 * 查找字段名称
	 * @returns boolean
	 */
	findFieldName() {
		let names = this.getNames()
		if (names == null) {
			return null
		}
		for (let name of names) {
			if (this.state.config.find(it => it.name === name)) {
				return name
			}
		}
		return null
	}

	/**
	 * 新增字段
	 */
	getNewRecord() {
		if (utils.isBlank(this.state.nameStr)) {
			utils.info('请输入字段名称')
			return null
		}
		const name = this.findFieldName()
		if (name) {
			utils.info(name + " 已存在，请重新命名")
			return null
		}
		const names = this.getNames()
		return names.map(name => dataUtil.getStringConfig(name))
	}

	iconOperation(text, record) {
		const {config} = this.state
		return <FieldParamSet
			key={text + 'FieldParamSet'}
			data={record} 
			config={this.props.data} 
			onChange={(value, isSelect) => {
				if (value !== null) {
					if (isSelect) {
						record.params = value
					} else {
						record.otherParams = value
					}
					tableUtil.replaceRecord(config, record)
				}
			}}
		/>
	}

	specialColumn(column) {
		const id = column.dataIndex
		if (id === 'id') {
			column.disabled = (text, record) => {
				let isSystemFields = record.isSystem === '1' || utils.isSysFields(record.name)
				// 系统字段禁用所有操作
				return isSystemFields
			}
		}
	}

	specialItemProps(formField, props, record) {
		props.placeholder = ' '
		const id = formField.id
		// 系统内置字段以下属性不可修改
		const sysFieldsOnlyReadProps = ['name', 'dataType', 'params', 
			'isRequire', 'isUnique', 'isDisabled', 'defaultValue']
		if ((sysFieldsOnlyReadProps.indexOf(id) !== -1 && record.isSystem === '1') 
			// 编码、名称字段可删不可改
			|| (id === 'name' && ['code', 'name'].indexOf(record.name) !== -1)
		) {
			props.disabled = true
		}
		if (id === 'dataType') {
			props.onChange = value => {
				record.dataType = value
				if (dataUtil.isFFieldType(value) || dataUtil.isSumType(value)) {
					this.record = record
					console.log('数据类型 onChange')
					this.setState({paramSetVisible: true})
				}
				this.reRender()
			}
		} else if (id === 'width') {
			Object.assign(props, {
				step: 1,
				min: 1,
				max: 3000
			})
		} else if (id === 'params') {
			const value = record.params
			const {dataType} = record
			const config = props.config
			if (dataUtil.isAntdSelectType(dataType) &&
				(value instanceof Array || value instanceof Object)
			) {
				props.initialValue = null
			}
			if (dataUtil.isListTreeType(dataType) || dataUtil.isTableType(dataType)) {
				const isList = dataUtil.isListType(dataType) || dataUtil.isTableType(dataType)
				config.dataType = c.ModuleSelect
				props.params = isList ? 'list' : 'tree'
			} else {
				config.dataType = c.STRING
			}
		}
	}

	formError() {
        return this.table && this.table.formError && this.table.formError()
    }

    getList() {
        return (this.table && this.table.getList && this.table.getList()) || []
    }

	reRender() {
		this.setState({update: !this.state.update})
	}
	
	/**
	 * 渲染函数
	 */
	render(){
		const {visible, config, configJSON, nameStr} = this.state
		// 权限控制
        const moduleOpers = (global.role && global.role.moduleOpers) || []
        const operFields = global.isAdmin || moduleOpers.indexOf(`${this.moduleName}_of`) !== -1
		const title = `配置【${dataUtil.showModuleName(this.props.data)}】字段`
		return <span>
			{operFields && createIcon('gear', title, () => this.setState({visible: true}))}
			{visible && <ModalEx
				title={title}
				visible={visible}
				onClose={() => this.setState({visible: false})}
				onOk={this.onSaveWrapper.bind(this)}
			>
				{this.operAddField && <div>
					<span style={{...utils.h2, marginRight: 16}}>请输入字段名称</span>
					<Input
						style={{width: global.modalWidth - 160}}
						value={nameStr}
						onChange={e => {this.setState({nameStr: e.target.value})}}
					/>
					<div>
						<span style={global.h3}>可新增或插入一个或多个字段， 多个字段名称以空格分隔</span>
					</div>
				</div>}
				<OOTableEx
					ref={inst => this.table = inst}
					value={config}
					fieldsConfig={fieldsConfig}
					iconOperation={this.iconOperation.bind(this)}
					specialColumn={this.specialColumn.bind(this)}
					specialItemProps={this.specialItemProps.bind(this)}
					getNewRecord={this.getNewRecord.bind(this)}
					isShowAdd={this.operAddField}
					isShowDel={this.operDeleteField}
				/>
				{this.state.paramSetVisible && <FieldParamSet
					visible={this.state.paramSetVisible}
					data={this.record} 
					config={this.props.data} 
					onChange={(value, isSelect) => {
						// 通过引用修改表格行数据
						if (isSelect) {
							this.record.params = value
						} else {
							this.record.otherParams = value
						}
						this.setState({paramSetVisible: false})
					}}
				/>}
				{(global.isAdmin || process.env.REACT_APP_SYSTEMADMIN === '1' || global.localfrontEnd)&& 
				<div>
					<div>{createButton('edit', '直接修改', this.modifyDirect.bind(this), 
						{type: 'primary', ghost: true, style: {marginTop: 16, marginBottom: 16}})
					}</div>
					<Input.TextArea style={{width: '100%', height: 300}} value={configJSON} onChange={e => {this.setState({configJSON: e.target.value})}}/>
				</div>}
			</ModalEx>}
		</span>
	}
}
