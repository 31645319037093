/**
 * 拆分表格记录
 */
import React, { Component } from 'react'
import ModalEx from './ModalEx'
import OOForm from './OOForm'
import IconEx from './IconEx'
import * as utils from '@utils/index'
import * as dataUtil from '@utils/DataUtil'

export default class SplitRecord extends Component {
    constructor() {
        super()
        this.state = {
            showModal: false,
        }
    }
    /**
     * 关闭弹窗回调
     */
    onCancel() {
        this.setState({showModal: false})
    }
    onOk() {
        if (dataUtil.formError(this.form)) {
            return
        }
        const {splitField, record} = this.props
        const values = this.form.getFieldsValue()
        const {splitNum} = values
        if (splitNum >= record[splitField]) {
            utils.warning('拆分数量必须小于原数量')
            return
        }
        this.props.onChange && this.props.onChange(values)
        this.setState({showModal: false})
    }
    onShow() {
        const {moduleName, fieldsConfig, splitField, record} = this.props
        let config = null
        if (fieldsConfig instanceof Array) {
            config = utils.getFieldConfig(fieldsConfig, splitField)
        } else if (moduleName) {
            config = utils.getConfig(moduleName, splitField)
        }
        if (!config) {
            utils.warning('未找到拆分字段配置！')
            return
        }
        const showName = dataUtil.showName(config)
        this.data = {}
        this.data[splitField] = record[splitField]
        if (!(record[splitField] > 0)) {
            utils.warning(showName + '必须大于0')
            return
        }
        this.fieldsConfig = [
            Object.assign({}, config, {isDisabled: '1'}),
            Object.assign({}, config, 
                {name: 'splitNum', alias: '拆分数量'},
                {
                    isRequire: '1',
                    otherParams: {
                        onChange: (value) => {
                            let restNum = record[splitField] - value
                            this.form.setFieldsValue({restNum})
                        }
                    }
                }
            ),
            Object.assign({}, config, {name: 'restNum', alias: '剩余数量', isDisabled: '1'}),
        ]
        this.setState({showModal: true})
    }
    render () {
        const width = 400
        return  <span>
            <IconEx style={global.iconStyle} type='divide' name='拆分'
                onClick={this.onShow.bind(this)}
            />
            {this.state.showModal && <ModalEx
                title={`拆分`}
                visible={this.state.showModal}
                onCancel={this.onCancel.bind(this)}
                onClose={this.onCancel.bind(this)}
                onOk={this.onOk.bind(this)}
                maskClosable={true}
                width={width}
            >
                <OOForm style={{width: width - 32}}
                    wrappedComponentRef={(inst) => 
                        this.form = inst && inst.props && inst.props.form
                    }
                    cols={1}
                    fieldsConfig={this.fieldsConfig}
                    data={this.data}
                />
            </ModalEx>}
        </span>
    }
}