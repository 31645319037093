/**
 * <span/> 封装，可以接收form表单数据
 */
import React, { Component } from 'react'
import Popover from 'antd/lib/popover'
export default class Span extends Component {
    render () {
        const {hide, label, value, className, style, notip} = this.props
        if (hide) {
            return <span/>
        }
        const spanItem = <span className={className || ''} style={style || {}}>{label || value}</span>
        if (notip) {
            return spanItem
        }
        return <Popover
            content={<div>
                {label && label !== value && <div style={{marginBottom: 16}}>{label}</div>}
                {value && <div>{value}</div>}
            </div>}
        >
            {spanItem}
        </Popover>
    }
}