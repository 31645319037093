import React, { Component } from 'react'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Button from 'antd/lib/button'
import ButtonIcon from '@com/ButtonIcon'
import {modifyPassword} from './PlatformAction'
import {sha1} from '@utils/crypto'
import {getCookie} from '@utils/cookie'
import * as utils from '@utils/index'
import * as dataUtil from '@utils/DataUtil'

const tailFormItemLayout = {
	wrapperCol: {
		xs: {
			span: 24,
			offset: 0,
		},
		sm: {
			span: 16,
			offset: 8,
		},
	},
}

const formItemLayout = {
	labelCol: {
		xs: {
			span: 24,
		},
		sm: {
			span: 8,
		},
	},
	wrapperCol: {
		xs: {
			span: 24,
		},
		sm: {
			span: 16,
		},
	},
}

class ModifyPassword extends Component {
    onOk() {
        if (dataUtil.formError(this.props.form)) return
        let password = this.props.form.getFieldValue('password')
        let oldPassword = this.props.form.getFieldValue('oldPassword')
        modifyPassword(getCookie('username'), sha1(password), sha1(oldPassword), () => {
            utils.success('密码修改成功，请重新登录！', '',
                () => {
                    global.closepage()
                    setTimeout(() => {
                        this.props.history.replace('/login')
                    }, 200)
                }
            )
        })
    }
    compareToFirstPassword(rule, value, callback) {
        const { form } = this.props
        if (value && value !== form.getFieldValue('password')) {
            callback('两次输入密码不一致')
        } else {
            callback()
        }
    }
    compareToOldPassword(rule, value, callback) {
        const { form } = this.props
        var re = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z!@#$%^&*]{8,20}$/
        if (!re.test(value)) {
            callback('密码必须由字符和数字组成且长度8-20位')
        } else if (value && value === form.getFieldValue('oldPassword')) {
            callback('新密码不能和旧密码相同')
        } else {
            callback()
        }
    }
    render() {
        const {getFieldDecorator} = this.props.form
        return <Form
            style={{marginTop: 32}}
            {...formItemLayout}
        >
            <Form.Item
                label="旧密码"
                hasFeedback
            >
                {getFieldDecorator('oldPassword', {
                    rules: [
                        {
                            required: true,
                            message: '请输入旧密码！',
                        },
                    ]
                })(<Input.Password style={{height: 40, width: 300}} />)}
            </Form.Item>
            <Form.Item
                label="新密码"
                hasFeedback
            >
                {getFieldDecorator('password', {
                    rules: [
                        {
                            required: true,
                            message: '请输入新密码！',
                        },
                        {
                            validator: this.compareToOldPassword.bind(this),
                        },
                    ]
                })(<Input.Password style={{height: 40, width: 300}} />)}
                
            </Form.Item>
            <Form.Item
                label="确认密码"
                hasFeedback 
            >
                {getFieldDecorator('confirm', {
                    rules:[
                        {
                            required: true,
                            message: '请再次输入密码！',
                        },
                        {
                            validator: this.compareToFirstPassword.bind(this),
                        },
                    ]
                }
                )(<Input.Password style={{height: 40, width: 300}} />)}
                
            </Form.Item>
            <Form.Item {...tailFormItemLayout}>
                <Button type="primary" onClick={this.onOk.bind(this)}><ButtonIcon type='save'/>确定</Button>
                <Button style={{marginLeft: 32}} onClick={() => {
                    if (getCookie('password') === sha1('88888888')) {
                        utils.warning('密码为初始密码，请修改密码！')
                        return
                    }
                    global.closepage()
                }}><ButtonIcon type='close'/>取消</Button>
            </Form.Item>
        </Form>
    }
}

export default Form.create()(ModifyPassword)