import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Icon from 'antd/lib/icon'
import * as utils from '@utils/index'

export default class OOIcon extends Component {
    render() {
        const type = this.props.type
        if (utils.isBlank(type)) {
            return <span/>
        }
        const {antd, ...restProps} = this.props
        if (antd) {
            return <Icon {...restProps} />
        }
        let className = this.props.className ? " " + this.props.className : ""
        if (type === 'spinner') {
            return <i  
                {...restProps} 
                style={Object.assign({fontSize: 16}, this.props.style || {})}
                className={`fa fa-spinner fa-pulse fa-3x fa-fw${className}`}
            ></i>
        }
        return <i  
            {...this.props} 
            style={Object.assign({fontSize: 16}, this.props.style || {})}
            className={`fa fa-${type}${className}`}
        ></i>
    }
}

OOIcon.propTypes = {
	antd: PropTypes.bool,
    type: PropTypes.string
}

/**
 * Antd Icon
 * width, height, fill, className, style
 *  <Icon type="home" />
    <Icon type="setting" theme="filled" />
    <Icon type="smile" theme="outlined" />
    <Icon type="sync" spin />
    <Icon type="smile" rotate={180} />
    <Icon type="loading" />
    双色
    <Icon type="smile" theme="twoTone" />
    <Icon type="heart" theme="twoTone" twoToneColor="#eb2f96" />
    <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />
  
 */