/**
 * <span/> 封装，可以接收form表单数据
 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import InputNumber from 'antd/lib/input-number'

export default class Percent extends Component {
    constructor(props) {
        super()
        this.state = {
            value: null
        }
    }
    componentDidMount() {
        if (this.props.value != null) {
            this.setState({value: this.props.value * 100})
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.value !== nextProps.value) {
            if (nextProps.value == null) {
                this.setState({value: null})
            } else {
                this.setState({value: nextProps.value * 100})
            }
        }
    }
    onChange(value) {
        this.setState({value})
        this.props.onChange(value / 100)
    }
    render () {
        return <InputNumber 
            {...this.props}
            value={this.state.value} 
            onChange={this.onChange.bind(this)}
            onBlur={this.props.onBlur}
        />
    }
}

Percent.propTypes = {
    value: PropTypes.number
}