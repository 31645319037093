import React, { Component } from 'react'
import Card from 'antd/lib/card'
import {codeItemConfig, codeitemtypeOptions} from '../modules/code_scheme/Config'
import OOTableEx from './OOTableEx'
import * as utils from '@utils/index'

export default class CodeItemOOTable extends Component {

	formError() {
        return this.table && this.table.formError && this.table.formError()
    }
	
	/**
	 * 渲染函数
	 */
	render(){
		const {value, disabled} = this.props
		if (disabled) {
            return <div>
                {(value || []).map((codeItem, index) => {
                    let code = ''
                    switch (codeItem.codeitemtype) {
                        case 'autoincrease': {
                            let currentNum = codeItem.codeitemvalue || codeItem.startvalue
                            if (currentNum < codeItem.startvalue) {
                                currentNum = codeItem.startvalue
                            }
                            code = utils.fillChar(currentNum, codeItem.codeitemlength, codeItem.fillchar)
                            break
                        }
                        case 'date': {
                            const moment = require('moment')
                            code = moment().format(codeItem.dateformat)
                            break
                        }
                        case 'fixed': {
                            code = codeItem.codeitemvalue
                            break
                        }
                        case 'precode': {
                            code = '______'
                            break
                        }
                        default: 
                    }
                    return <Card key={index} size="small" title={code} style={{float: 'left' }}>
                        <span>{ utils.valueToLabel(codeItem.codeitemtype, utils.getOptionArray(codeitemtypeOptions)) }</span>
                    </Card>
                })}
            </div>
		}
		return <OOTableEx
			{...this.props}
			ref={inst => this.table = inst}
			fieldsConfig={codeItemConfig}
			hideSearch
			isShowInsert
		/>
	}
}
