import {sysCreateField} from '../../init/sysfields'
import { GROUP, SELECT, STRING, NUMBER, CodeItemOOTable } from '@utils/constants'


export const codeitemtypeOptions = "autoincrease,自增/date,日期/fixed,固定值/precode,预编码值"
/**
 * 编码方案码段信息字段配置
 */
export const codeItemConfig = [
    {name: "codeitemtype", alias: "码段类型", isRequire: "1", width: 160, dataType: SELECT, params: codeitemtypeOptions, defaultValue: "autoincrease"},
    {name: "codeitemvalue", alias: "码段值", width: 120, dataType: STRING},
    {name: "dateformat", alias: "日期格式", width: 160, dataType: STRING, defaultValue: "YYYYMMDDHHmmssSSS"},
    {name: "codeitemlength", alias: "码段长度", isShowF: "0", width: 80, dataType: NUMBER, defaultValue: "5"},
    {name: "startvalue", alias: "起始值", isShowF: "0", width: 120, dataType: NUMBER, defaultValue: "1"},
    {name: "fillchar", alias: "填充字符", isShowF: "0", width: 80, dataType: STRING, defaultValue: "0"},
]

/**
 * 编码方案基本信息字段配置
 */
export const tableConfig = [
    {name: "基本信息", dataType: GROUP},
    {name: "schemename", alias: "方案名称", dataType: STRING, isQuery: '2', isRequire: "1", isDisabled: '1', width: 120},
    {name: "remark", alias: "描述", dataType: STRING, isRequire: "0", width: 120},
    {name: "codeitems", alias: "码段信息", dataType: CodeItemOOTable, otherParams: {
        fieldsConfig: codeItemConfig, hideSearch: true, isShowInsert: true,
    }, isQuery: "0", isSort: "0", width: 600},
    {name: "维护人员", dataType: GROUP},
].concat(sysCreateField)

