/**
 * ModuleDataTransfer 数据传递组件， 从选择模块中选择多条数据添加到目标模块
 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ModalEx from './ModalEx'
import ListModule from '@list/ListModule'
import * as Action from '@list/Action'
import * as dataUtil from '@utils/DataUtil'
import * as utils from '@utils/index'
import {createButton, createIcon, createA} from './PageCreator'
import * as p from '@utils/page'

export default class ModuleDataTransfer extends Component {
    constructor(props) {
        super()
        this.state = {
            showModal: false,
            fid: null,
            subFid: null,
            row: {}
        }
        this.config = utils.getModuleConfig(props.config.selectmodule)
        if (props.config.isSub) {
            this.subConfig = utils.getModuleConfig(props.config.selectsublist)
        }
        // 组装数据传递的查询条件
        this.searchConds = dataUtil.buildDatatransferSearchConds(props.config, props.fRecord)
    }

    componentDidMount() {
        // 延迟执行，等待表单页面渲染完成
        setTimeout(() => {
            // 从数据传递配置中取保存父模块编码的的字段名称
            const fcodeName = this.props.config.fcode
            //console.log('this.props.fmodule, fcodeName',this.props.fmodule, fcodeName)
            if (fcodeName && this.props.fid && this.props.fmodule) {
                // 使用全局方法从表单中获取编码的值
                // this.props.fmodule 子模块所在页面的父模块
                const fcode = dataUtil.getValue(this.props.fid, this.props.fmodule, fcodeName)
                //console.log('fcode', fcode)
                // 查询父模块数据
                // this.config.fmodule 当前选择组件的父模块
                fcode && Action.getOneByCode(this.config.fmodule, fcode, (record) => {
                    //console.log('getOneByCode',record)
                    // 设置fid
                    this.setState({fid: record.id})
                })
            }
        }, 500)
    }

    /**
     * 关闭弹窗回调
     */
    onClose() {
        this.setState({showModal: false})
    }
    
    /**
     * 选中回调
     */
    onSelect(record) {
        //console.log('onSelect---------------------------')
        if (this.props.config.isSub) {
            this.setState({row: record, subFid: record.id})
            return
        }
        this.onSelectSub(record)
    }
    /**
     * 单双层选择公用
     * @param {*} record 
     */
    onSelectSub(record) {
        /**
         * 给传递记录中添加来源单据信息
         * @param {*} item 
         */
        const setSourceInfo = (item) => {
            // 记录选中明细id
            item.sourceId = item.id
            if (this.props.config.isSub) {
                // 记录来源单据
                item.sourceModule = this.props.config.selectmodule
                item.sourceCode = this.state.row.code
                item.sourceCode_name = this.state.row.name
                item.sourceCode_id = this.state.row.id
            }
        } 
        //console.log('onSelectSub---------------------------')
        if (record instanceof Array) {
            for (let item of record) {
                // 添加来源单据信息
                setSourceInfo(item)
            }
            this.props.onSelect && this.props.onSelect(record)
        } else {
            // 添加来源单据信息
            setSourceInfo(record)
            //console.log('onSelectSub---------------------------')
            this.props.onSelect && this.props.onSelect([record])
        }
        this.onClose()
    }

    render() {
        const {config, buttonType} = this.props
        const {icon, name} = config
        let button
        const onClick = () => this.setState({showModal: true})
        if (buttonType === p.icon) {
            button = createIcon(icon, name, onClick)
        } else if (buttonType === p.a) {
            button = createA(name, onClick)
        } else {
            button = createButton(icon, name, onClick, {showName: true, type: 'primary', ghost: true })
        }
        return <span>
            {button}
            <ModalEx
                title={config.name}
                visible={this.state.showModal}
                onCancel={this.onClose.bind(this)}
                onClose={this.onClose.bind(this)}
                maskClosable={true}
                width={global.modalWidth}
                footer={[]}
            >
                <ListModule
                    selectOne={!config.isSub}
                    onRowClickSelect={!!config.isSub}
                    selectMulti={!config.isSub}
                    onSelect={this.onSelect.bind(this)}
                    cols={global.cols}
                    config={this.config}
                    searchPageKey={'_search_' + config.id}
                    fid={this.state.fid}
                    fixedSearchConds={this.searchConds}
                />
                {config.isSub && this.state.subFid && 
                <ListModule
                    disabled
                    selectOne
                    selectMulti
                    fid={this.state.subFid}
                    onSelect={this.onSelectSub.bind(this)}
                    cols={global.cols}
                    config={this.subConfig} 
                />}
            </ModalEx>
        </span>
    }
}
/**
 * config 字段传输配置
 */
ModuleDataTransfer.propTypes = {
    onSelect: PropTypes.func,
    config: PropTypes.object
}