const connected = 'connected'
const close = 'close'
const systemConfigUpdate = 'systemConfigUpdate'
const treeUpdate = 'treeUpdate'
const listUpdate = 'listUpdate'
const notify = 'notify'
const workflow = 'workflow'
const add = "add"
const update = "update"
const del = "del"

module.exports = {
    connected, close, 
    systemConfigUpdate,
    treeUpdate, listUpdate, 
    notify, workflow,
    add, update, del,
}