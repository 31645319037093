/**
 * Form 包装，给单个组件外包装一层form
 */
import React, { Component } from 'react'
import Form from 'antd/lib/form'

class FormWrapper extends Component {
    render () {
        const {id, decoratorProps, componentInst} = this.props
        const {getFieldDecorator} = this.props.form
        return  <Form.Item>
            {getFieldDecorator(id, decoratorProps)(componentInst)}
        </Form.Item>
    }
}
FormWrapper = Form.create()(FormWrapper)
export default FormWrapper