/**
 * 字段排布，新增、修改、详情公用部分
 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Anchor from 'antd/lib/anchor'
import Icon from '@com/Icon'
import {generateCode, generateCodeAsync} from '../code_scheme/Action'
import ListModule from './ListModule'
import Assy from '@com/Assy'
import * as dataUtil from '@utils/DataUtil'
import * as utils from '@utils/index'
import {inner, modal, nestedsublist} from '@utils/page'
import {sublistPrefix} from '@utils/constants'
import OOForm from '@com/OOForm'
import { filterConfigByModule, moduleSpecialItemProps } from './specialModule'
import A from '@com/A'

const { Link } = Anchor

export default class Fields extends Component {
    /**
     * 构造函数
     */
    constructor() {
        super()
        this.state = {
            data: {},
            fRecord: {},
            fieldsConfig: [],
            subListConfigArr: [],
            rootNode: {},
            treeSearchText: '',
            anchor: null,
            folded: true,
        }
        this.hideKeys = []
        this.transferModules = []
        // 子模块引用
        this.sublist = {}
    }
    /**
     * 组件加载完成时
     */
    componentDidMount() {
        this.unmount = false
        this.init(this.props)
    }
    componentWillUnmount() {
        this.unmount = true
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        const {config, moduleName, id, data, fRecord, action} = this.props
        if (
            // props.config 变化
            (nextProps.config && nextProps.config.modulename &&
                nextProps.config.modulename !== config.modulename) ||
            // props.moduleName 变化
            (nextProps.moduleName && nextProps.moduleName !== moduleName) ||
            // props.id 变化
            (nextProps.id && nextProps.id !== id) ||
            // props.data 变化
            (nextProps.data && !utils.equals(nextProps.data, data)) ||
            // props.fRecord 变化
            (nextProps.fRecord && !utils.equals(nextProps.fRecord, fRecord)) ||
            // action 变化
            (nextProps.action !== action && nextProps.action === 'new')
        ) {
            this.init(nextProps)
        }
    }
    async init(props) {
        const {moduleName, config, data, mode, id, fRecord, searchPageKey} = props 
        if (utils.isBlank(id) || 
            utils.isBlank(moduleName) || 
            config == null || utils.isBlank(config.modulename)
        ) {
            return
        }
        // 当前模块名称
        this.moduleName = moduleName
        // 分类模块名称
        this.classModuleName = config.catagory
        // 模型有分类则获取分类数据
        this.hasClass = utils.isNotBlank(this.classModuleName)
        if (this.hasClass) {
            // 分类模块配置信息
            this.classModuleConfig = utils.getConfigByModuleName(this.classModuleName)
        }
        const _fid = ((fRecord && fRecord.id) ? '_' + fRecord.id : '')
        this.pageKey = moduleName + '_Fields_' + id + _fid
        //console.log('init pageKey', this.pageKey)
        this.listPageKey = moduleName + '_list_' + (data.fid || global.nofid) + (searchPageKey || '')
        global[this.pageKey] = global[this.pageKey] || {}
        global[this.listPageKey] = global[this.listPageKey] || {}
        // 子模块引用
        this.sublist = {}
        let fieldsConfig = dataUtil.getMergeConfigByConfig(config).fields_config
        fieldsConfig = filterConfigByModule(moduleName, fieldsConfig, data)
        let subListConfigArr = []
        /**
         * 嵌入子模块，不显示表单基本信息
         */
        if (mode === nestedsublist) {
            subListConfigArr = dataUtil.getVisibleNestedSublist(config, data)
        } else {
            /**
             * normal: 默认模式
             * inner: 表格行内嵌入新增、详情
             * modal: 弹窗
             */
            subListConfigArr = dataUtil.getVisibleSublist(config, data)
        }
        
        // 渲染页面
        utils.storeData(this, this.pageKey, {
            subListConfigArr,
            fieldsConfig, 
            data, fRecord: data,
            rootNode: global[this.pageKey].rootNode || {},
            treeSearchText: global[this.pageKey].treeSearchText || '',
        })

        // 数据传递
        this.transferModules = await dataUtil.getDatatransferList(this.moduleName)

        // 自动生成编码和分类
        await this.fillClassAndCode(props)

        // 自动滚动锚点
        if (mode !== modal && (window.location.hash || this.state.anchor)) {
            let anchor = window.location.hash || this.state.anchor
            setTimeout(() => window.location.hash = anchor, 1000)
        }
    }
    /**
     * 自动生成编码和分类
     * @param {*} props 
     * @returns 
     */
    async fillClassAndCode(props) {
        const {action, mode} = props 
        // 手机端/嵌套表格 表格展开内部不生成编码
        if (mode === inner || mode === nestedsublist) {
            return
        }
        let classValue = null
        // 新增页面生成编码，详情页面不生成编码
        if (action === 'new') {
            let classCode = ''
            if (this.hasClass) {
                const rootNode = global[this.listPageKey].rootNode
                // 分类初始值处理
                let node = global[this.listPageKey].node || rootNode || {}
                let {value, code, title, classnames, classes} = node; 
                classValue = {
                    code, 
                    id: value, 
                    name: title, 
                    classnames, 
                    classes
                }
                classCode = code
            }
            // 重新生成编码
            let preCode = [classCode || '']
            const code = await generateCodeAsync(this.moduleName, preCode)
            if (utils.isNotBlank(code)) {
                if (this.hasClass) {
                    this.setFieldsValue({code, [this.classModuleName]: classValue})
                } else {
                    this.setFieldsValue({code})
                }
            }
        }
    }

    /**
     * 计算表达式
     */
    callExpr() {
        const {config, data, fRecord} = this.props
        // 获取form中的数据，记录要合计的字段变化前的值
        const oldValues = this.getFieldsValue()
        // 获取模块配置
        let fieldsConfig = dataUtil.getTableConfig(config)
        //console.log('record', record)
        const values = dataUtil.callExpr(oldValues, '', fieldsConfig, fRecord)
        this.setFieldsValue(values)
        // 修改合计类型字段
        const form = this.form && this.form.props && this.form.props.form
        dataUtil.setSumFieldsValue(data.fid, oldValues, form, this.moduleName)
        const newData = Object.assign({}, data, this.getFieldsValue())
        this.setState({fRecord: newData})
    }
    /**
     * moduleDataChange 模块选择数据传递
     * @param {*} moduleName 选择字段模块名
     * @param {*} name 字段名 
     * @param {*} stateRecord = {} 表格中的记录， 添加此参数和表格中的方法参数列表保持一致
     * @param {*} value 模块选择组件 数据值
     * @param {*} record 模块选择组件选择完整记录
     */
    moduleDataChange(moduleName, name, stateRecord = {}, value, record = {}) {
        const {action} = this.props
        //console.log('[ moduleName, value ] >', moduleName, name, value, record.name)
        // 分类变化时重新生成编码
        if (this.hasClass && action === 'new' && value && value.code) {
            let preCode = [value.code || '']
            generateCode(this.moduleName, preCode, code => {
                this.setFieldsValue({code})
            })
        }
        let transferModule = this.transferModules.find(item => item.selectmodule === moduleName)
        if (transferModule == null) {
            return
        }
        let map = dataUtil.getTransferMapByTransferModule(transferModule)
        if (map == null) {
            return
        }
        
        const formData = dataUtil.getTransferFormData(this.moduleName, moduleName, 
            name, record, this.props.fRecord, map)
        const data = Object.assign({}, this.state.data, formData)
        utils.storeData(this, this.pageKey, {data})
        this.setFieldsValue(formData)
        // 传递子模块
        dataUtil.tranferSublist(record.id, transferModule, this.sublist, this.getData())
    }

    /**
     * 获取页面数据
     * @returns 
     */
    getData() {
        // 获取form表单数据
        const values = this.getFieldsValue()
        // 合并props.data 和 form 数据
        return Object.assign({}, this.state.data, values) 
    }

    specialItemProps(formField, props, data) {
        const {specialItemProps} = this.props
        moduleSpecialItemProps(this.moduleName, formField, props, data)
        specialItemProps && specialItemProps(formField, props, data)
    }

    validateFields(cb) {
        const form = this.form.props.form
        if (form) {
            // 校验数据
            form.validateFields(cb)
        }
        const {fieldsConfig} = this.state
        // 校验OOTable类型字段数据
        for (const config of fieldsConfig) {
            const {name, dataType} = config
            if (dataUtil.isOOTableType(dataType)) {
                const ootableInst = this.form[name]
                if (ootableInst && ootableInst.formError) {
                    cb(ootableInst.formError())
                }
            }
        }
    }

    getFieldsValue() {
        if (this.form == null || this.form.props == null || this.form.props.form == null) {
            return {}
        }
        return this.form.props.form.getFieldsValue()
    }
    getFieldValue(key) {
        if (this.form == null || this.form.props == null || this.form.props.form == null) {
            return null
        }
        return this.form.props.form.getFieldValue(key)
    }
    setFieldsValue(values) {
        if (this.form == null || this.form.props == null || this.form.props.form == null) {
            return
        }
        this.form.props.form.setFieldsValue(values)
    }
    resetFields() {
        if (this.form == null || this.form.props == null || this.form.props.form == null) {
            return
        }
        this.form.props.form.resetFields()
        const {fieldsConfig, data} = this.state
        // OOTable使用state数据重置
        for (const config of fieldsConfig) {
            const {name, dataType} = config
            if (dataUtil.isOOTableType(dataType)) {
                this.setFieldsValue({[name]: data[name]})
            }
        }
    }

    /**
     * 页面渲染
     */
    render() {
        //console.log('Fields render')
        const {
            action, mode, config, moduleName, id, cols
        } = this.props
        const { fieldsConfig, subListConfigArr } = this.state
        // render 阻断  表格配置为空
        if (
            // id 为空
            utils.isBlank(id) || 
            // moduleName 为空
            utils.isBlank(moduleName) || 
            // 模块配置为空
            config == null || config.modulename == null ||
            // 表格配置为空
            !(fieldsConfig instanceof Array) || fieldsConfig.length === 0
        ) {
            return <div/>
        }

        // 权限控制
        let moduleOpers = (global.role && global.role.moduleOpers) || []
        this.operUpdate = global.isAdmin || moduleOpers.indexOf(`${this.moduleName}_ou`) !== -1

        // 模型是否有子模块
        const hasSubList = subListConfigArr.length > 0
        // 只有一个嵌套表格
        const hasOneNestedSublist = subListConfigArr.length === 1
        // 表单数据
        const data = this.state.data || {}
        // 合并props.data 和 form 数据， 传递到子模块
        const fRecord = this.state.fRecord
        fRecord.id = id
        //console.log('render------------------',data)
        //console.log('fieldsConfig------------------',fieldsConfig)
        /**
         * 基本信息处理
         */
        let linkList = []
        const basicInfoKey = 'basic-info' + this.pageKey
        if (utils.isNotBlank(id)) {
            linkList = subListConfigArr.map((subListConfig, index) => {
                let config = global.getConfigByModuleName(subListConfig.moduleName || subListConfig)
                const key = `sublist${index + this.pageKey}`
                return <Link key={key} href={"#" + key} title={global.showModuleName(config)}/>
            })
        }
        if (mode !== nestedsublist) {
            linkList.unshift(<Link key={basicInfoKey} href={'#' + basicInfoKey} title="基本信息" />)
        }
        let marginTop = 8
        if (mode === nestedsublist || mode === modal) {
            marginTop = 0
        }
        return <div style={{marginTop, width: 'calc(100% - 4px)'}}>
            {hasSubList && !this.state.folded && mode !== nestedsublist &&
            <div className='anchor-div'>
                <Anchor getContainer={() => document.getElementById('layout-container')} 
                    showInkInFixed affix={false} 
                    onChange={ anchor => {
                        utils.storeData(this, this.pageKey, {anchor})
                    }}
                    onClick={() => utils.storeData(this, this.pageKey, {folded: true})}
                >
                    {linkList}
                </Anchor>
            </div>}
            {hasSubList && !global.isPhoneVertical && <div className='anchor-folder'>
                <A onClick={() => {
                    utils.storeData(this, this.pageKey, {folded: !this.state.folded})
                }}>
                    <Icon type={this.state.folded ? 'indent' : 'outdent'}  style={{ fontSize: 24 }} />
                </A>
            </div>}
            <div style={{display: mode === nestedsublist? 'none' : 'block'}}>
                {hasSubList && 
                <h2 id={basicInfoKey}>
                    <span>基本信息</span>
                    <a href="#basic-info" className="anchor">#</a>
                </h2>
                }
                <div>
                    <OOForm
                        thisPage={this}
                        wrappedComponentRef={inst => this.form = inst }
                        disabled={!(this.operUpdate && action !== 'detail')}
                        fieldsConfig={fieldsConfig}
                        data={data}
                        moduleName={this.moduleName}
                        specialItemProps={this.specialItemProps.bind(this)}
                        callExpr={this.callExpr.bind(this)}
                        moduleDataChange={this.moduleDataChange.bind(this)}
                    />
                    {config.hasattachment  && 
                    config.showtype !== "card" &&
                    id &&
                    <Assy 
                        businessId={id}
                        fileType='picture'
                        disabled={!(this.operUpdate && action !== 'detail')}
                    />}
                </div>
            </div>
            {hasSubList && config.showtype !== "card" && 
            <div>
                {id && subListConfigArr.map((subListConfig, index) => {
                    let moduleName = subListConfig.moduleName
                    // 子模块配置参数
                    const {
                        // 不能为空
                        notEmpty,
                        // 禁用编辑时， 显示修改
                        disabledShowModifyConds,
                        // 隐藏工具类
                        hideToolbar,
                        // 隐藏搜索
                        hideSearch,
                    } = subListConfig
                    const disabledShowModify = dataUtil.condsValue(disabledShowModifyConds, data)
                    let config = utils.getConfigByModuleName(moduleName)
                    const key = `sublist${index + this.pageKey}`
                    //console.log('config.modulename, key', config.modulename, key)
                    let marginTop = 16
                    if (mode === nestedsublist) {
                        marginTop = 0
                    }
                    return <div key={key} style={{marginTop}}>
                        {!(mode === nestedsublist && hasOneNestedSublist) && 
                        <h2 id={key}>
                            <span>{dataUtil.showModuleName(config)}</span>
                            <a href={"#" + key} className="anchor">#</a>
                        </h2>}
                        {config && config.modulename && 
                        <ListModule
                            mode={mode}
                            ref={(inst) => this.sublist[moduleName] = inst} 
                            fid={id}
                            fRecord={fRecord}
                            cols={cols}
                            action={action} 
                            list={data[sublistPrefix + moduleName]}
                            config={config}
                            disabled={!(this.operUpdate && action !== 'detail')}
                            notEmpty={notEmpty}
                            disabledShowModify={disabledShowModify}
                            hideToolbar={hideToolbar}
                            hideSearch={hideSearch}
                            sublistMode
                        />}
                    </div>
                })}
            </div>}
        </div>
    }
}
Fields.propTypes = {
    /**
     * 页面类型
     * new： 新增
     * detail： 详情
     * modify： 修改
     */
    action: PropTypes.string,
    // 数据ID
    id: PropTypes.string,
    // 父组传入数据
    data: PropTypes.object,
    // 父模块数据
    fRecord: PropTypes.object,
    // 模块名称
    moduleName: PropTypes.string,
    // 模块配置
    config: PropTypes.object,
    /**
     * 页面展示模式
     * normal: 默认模式，路由
     * inner: 表格行内嵌入新增、详情
     * modal: 弹窗
     * nestedsublist： 嵌入子模块，不显示表单基本信息
     */
    mode: PropTypes.string,
}

/**
 * 子模块配置参数
 * 详细配置见组件 src/components/SubListConfig
 * config.sublist
SubListConfigItem: {
    // 不能为空
    notEmpty,
    // 禁用编辑时显示新增
    disabledShowAddConds, 
    // 禁用编辑时显示删除
    disabledShowDelConds, 
}

*/
