/**
 * oomessage
 * 前端消息处理
 */
import {v1} from 'uuid'
import {ooMessageObj} from './object'
//import {quietly} from './page'

/**
 * 发送消息
 * @param {*} msgBody 
 */
export function sendMessage(msgBody) {
    const id = v1()
    ooMessageObj[id] = msgBody
}

/**
 * 接收消息
 */
export function receiveMessage() {
    for (let id in ooMessageObj) {
        const msgBody = ooMessageObj[id]
        console.log('receiveMessage: ', msgBody)
        if (msgBody == null || !(msgBody instanceof Object) || msgBody instanceof Array) {
            delete msgBody[id]
            continue
        }
        /*
        const {moduleName, type} = msgBody
        }*/
        delete ooMessageObj[id]
    }
}

export const ooMessageInterval = setInterval(receiveMessage, 200)

