/*****************************************************************************
 * 常量定义
 *****************************************************************************/

/**
 * 数据类型， 用户可配置
 */
const STRING = 'STRING'             // 文本
const TEXT = 'TEXT'                 // 文本域
const SELECT = 'SELECT'				// 选择
const SELECTMULTI = 'SELECTMULTI'   // 多选
const OPTION = 'OPTION'				// 选项组
const INPUTSELECT = 'INPUTSELECT'   // 可输可选
const NUMBER = 'NUMBER'				// 数字
const PERCENT = 'PERCENT'			// 百分比
const MONEY = 'MONEY'               // 金额
const DATE = 'DATE'                 // 日期
const TIME = 'TIME'                 // 时间
const CHECKBOX = 'CHECKBOX'         // 复选框
const PHONE = 'PHONE'               // 手机
const LISTMODULE = 'LISTMODULE'		// 列表模块
const TREEMODULE = 'TREEMODULE'		// 树模块
const TABLE = 'TABLE'               // 表格
const FILE = 'FILE'                 // 文件，图片
const EXPR = 'EXPR'                 // 表达式
const SUM = 'SUM'                   // 合计
const FFIELD = 'FFIELD'             // 默认父列
const TAG = 'TAG'                   // 标签
const GROUP = 'GROUP'               // 分组
const COLOR = 'COLOR'               // 颜色选择器
// 系统核心组件，不直接对用户开放
const IconSelect = 'IconSelect'         //图标选择组件
const SubListConfig = 'SubListConfig'   //子模块配置组件
const CondOOTable = 'CondOOTable'       //条件表格组件
const CodeItemOOTable = 'CodeItemOOTable' // 编码方案码段组件
const Pipeline = 'Pipeline'             //管道配置组件
const Mapping = 'Mapping'               //映射组件
const CondEdit = 'CondEdit'             //条件组件
const FieldSelect = 'FieldSelect'       //字段选择组件
const ModuleSelect = 'ModuleSelect'     //模块选择组件
// 精确搜索标记
const ID = 'ID'
/**
 * 无父模块path显示self
 */
const nofid = 'self'
/**
 * 无分类path显示self
 */
const noClass = 'self'
/**
 * 系统内置模块
 */
const file = 'file'
const store = 'store'
const flow = 'flow'
const todo = 'todo'
const done = 'done'
const datatransfer = 'datatransfer'
const orchestrate = 'orchestrate'
const reportengine = 'reportengine'
const chartdesign = 'chartdesign'
const setting = 'setting'
const tables_config = 'tables_config'
const code_scheme = 'code_scheme'
const auth = 'auth'
const staff = 'staff'
const dept = 'dept'
const menu = 'menu'
// 相册
const photoalbum = 'photoalbum'
// 平台内置模块
const server = 'server'
const db = 'db'
const enterprise = 'enterprise'
const user = 'user'
/**
 * 系统内置模块字段
 */
// tables_config
const fields_config = 'fields_config'
const workflow = 'workflow'
const disableEditConds = 'disableEditConds'
// orchestrate 业务编排配置保存字段
const bizflow = 'bizflow'
// 动态生成表内置字段
const code = 'code'
const name = 'name'
const createtime = 'createtime'
const updatetime = 'updatetime'
const createuser = 'createuser'
const createdept = 'createdept'
const updateuser = 'updateuser'
const updatedept = 'updatedept'
// apiBatch 支持的方法名称
const apiBizflow = 'apiBizflow'
const processStart = 'processStart'
const processPass = 'processPass'
const processReject = 'processReject'
const processCancel = 'processCancel'
const add = 'add'
const update = 'update'
const del = 'del'
const batchDelete = 'batchDelete'
const batchSave = 'batchSave'
const addTreeNode = 'addTreeNode'
const deleteTreeNode = 'deleteTreeNode'
const batchDeleteByFid = 'batchDeleteByFid'

/**
 * 管道操作类型
 * datatransfer 数据传递
 * orchestrate  业务编排
 * openpage     打开页面
 */
const openpage = 'openpage'

/**
 * 子模块数据挂载 key 前轴
 */
const sublistPrefix = 'sublist_'

module.exports = {
    // 数据类型
    STRING,		// 文本
    TEXT,		// 文本域
    SELECT,		// 选择
    SELECTMULTI,// 多选
    OPTION,		// 选项组
    INPUTSELECT,// 可输可选
    NUMBER,		// 数字
    PERCENT,	// 百分比
    MONEY,		// 金额
    DATE,		// 日期
    TIME,		// 时间
    CHECKBOX,	// 复选框
    PHONE,		// 手机
    LISTMODULE,	// 列表模块
    TREEMODULE,	// 树模块
    TABLE,		// 表格
    FILE,		// 图片
    EXPR,		// 表达式
    SUM,		// 合计
    FFIELD,		// 默认父列
    TAG,		// 标签
    GROUP,		// 分组
    COLOR,      // 颜色选择器
    // 系统内置组件
    IconSelect, 	 // 图标选择组件
    SubListConfig, 	 // 子模块配置组件
    CondOOTable, 	 // 条件表格组件
    CodeItemOOTable, // 编码方案码段组件
    Pipeline, 	     // 管道配置组件
    Mapping, 	     // 映射组件
    CondEdit, 	     // 条件组件
    FieldSelect, 	 // 字段选择组件
    ModuleSelect, 	 // 模块选择组件
    ID,
    nofid, noClass,
    tables_config, code_scheme, 
    datatransfer, orchestrate, reportengine, chartdesign,
    flow, todo, done, setting, file, store, 
    auth, staff, dept, menu, photoalbum,
    server, db, enterprise, user, 
    // tables_config
    fields_config, workflow, disableEditConds,
    // 动态生成表内置字段
    code, name, createtime, updatetime, createuser, createdept, updateuser, updatedept,
    bizflow,
    /**
     * apiBatch 支持的方法名称
     */
    // 接口业务编排
    apiBizflow,
    // 流程
    processStart, processPass, processReject, processCancel,
    // 动态模块增删改查
    add, del, update, batchDelete, batchSave, addTreeNode, deleteTreeNode, batchDeleteByFid,
    // 管道操作类型
    openpage,
    // 子模块
    sublistPrefix,
}