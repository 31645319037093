
export function isPromise (value) {
	if (value !== null && typeof value === 'object') {
	return value.promise && typeof value.promise.then === 'function'
	}
}

export function getCookie (name) {
	var value = "; " + document.cookie
	var parts = value.split("; " + name + "=")
	if (parts.length === 2) {
		let ret = parts.pop().split(";").shift()
		ret = unescape(ret)
		return ret
	}
	return null; 
}

export function setCookie (name,value,expiredays) {
	var exdate = null
	if (expiredays) {
		var utc = new Date().getTime() + expiredays * 24 * 60 * 60 * 1000
		exdate = new Date(utc).toUTCString()
		document.cookie = name + "=" + escape(value) + ";expires=" + exdate + "; SameSite=Lax; path=/"
	} else {
		document.cookie = name + "=" + escape(value) + "; SameSite=Lax; path=/"
	}
}

export function delCookie (name) {
	var exp = new Date(0)
	var cval = getCookie(name)
	if(cval != null) {
		document.cookie = name + "="+cval+";expires="+exp.toUTCString() + "; SameSite=Lax; path=/"
	}
}