/** 参数设置 */
export const setting = {
    numberdecimalplaces: 6,
    moneydecimalplaces: 6,
}
/** 数据传递表全量数据 */
export const datatransferList = []
/** 业务编排表全量数据 */
export const orchestrateList = []
/** 报表引擎表全量数据 */
export const reportengineList = []
/** 图表引擎表全量数据 */
export const chartdesignList = []
/** 消息 */
export const ooMessageObj = {}

/** 
 * 全局表单 moduleName 和 id 对应的 inst 相同
 *  {
 *      [moduleName]: inst 表单所在页面实例
 *      [id]: inst 表单所在页面实例
 *  }
*/
export const forms = {}

/** 
 * 系统树缓存 
 * moduleName 树数据
 * moduleName + 'update'  更新标记
 *  {
 *      [moduleName]: treeData,       
 *      [moduleName + 'update']: bool 
 *  }
 */
export const treeDataObj = {}

/**
 * 打开页签的所有页面实例
 * path pathname + searchStr
 * inst 页面实例
 *  {
 *      [path]: inst       
 *  }
 */
export const pageInstObj = {}

/**
 * 打开的弹窗实例数组
 */
export const modalInstArray = []