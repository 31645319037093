/**
 * 参数设置 页面
 */
import React, { Component } from 'react'
import Form from 'antd/lib/form'
import {createItems, configToItemProps, createButton} from '@com/PageCreator'
import {callModifyByApiBatch, callAddByApiBatch} from '../modules/layout/Action'
import * as utils from '@utils/index'
import * as dataUtil from '@utils/DataUtil'
import * as o from '@utils/object'
import c from '@utils/constants'

const { setting } = o

class ParamSetting extends Component {
    /**
     * 构造函数
     */
    constructor() {
        super()
        this.state = {
            data: {},
        }
        this.moduleName = c.setting
        this.pageKey = this.moduleName + 'list'
    }
    /**
     * 组件加载完成时
     */
    componentDidMount() {
        this.setState({data: setting})
    }

    onSave() {
        if (dataUtil.formError(this.props.form)) return
        let fieldsValue = this.props.form.getFieldsValue()
        if (this.state.data.id) {
            callModifyByApiBatch(this.moduleName, this.state.data.id, fieldsValue)
        } else {
            callAddByApiBatch(this.moduleName, fieldsValue)
        }
    }

    /**
     * 页面渲染
     */
    render() {
        const config = utils.getConfigByModuleName("setting")
        const {getFieldDecorator} = this.props.form
        /**
         * 参数配置
         */
        const data = this.state.data
        let formFields = []
        config.fields_config.forEach(config => {
            if (!utils.isSysFields(config.name)) {
                config.dataIndex = config.name
                data[config.name] = data[config.name] || config.defaultValue
                formFields.push(configToItemProps(this, config, data, data[config.dataIndex]))
            }
        })

        let formItems = createItems(getFieldDecorator, null, 8, formFields)
        const saveButton = createButton('save', '保存', this.onSave.bind(this), {type: 'primary'})
        return <div>
            {saveButton}
            <div style={{marginTop: 16}}>
                {formItems}
            </div>
        </div>
    }
}
export default Form.create()(ParamSetting)