import React, { Component } from 'react'
import Tabs from 'antd/lib/tabs'
import Menu from 'antd/lib/menu'
import Dropdown from 'antd/lib/dropdown'
import Icon from '@com/Icon'
import {pageInstObj} from '@utils/object'
import {getCookie} from '@utils/cookie'
import * as utils from '@utils/index'
import A from '@com/A'
import {isNoTokenRoute} from '@/config/route'

const { firstPage } = utils
const { TabPane } = Tabs
export default class OOHead extends Component {

	constructor() {
		super()
		this.state = {
			update: false
		}
	}

	componentDidMount() {
		global.openpage = this.openpage.bind(this)
		global.closepage = this.closepage.bind(this)
	}

	/**
	 * 将要离开组件时
	 */
	componentWillUnmount() {
		global.openpage = null
		global.closepage = null
	}

    reRender() {
        this.setState({update: !this.state.update})
    }

    onTabChange(activeKey) {
		const findTab = global.tabs.find(item => item.key === activeKey)
		global.activeTabKey = activeKey
		this.reRender()
		if (findTab) {
			this.props.history.push(findTab.key)
		}
		localStorage.setItem(global.tabKey, activeKey)
	}
	onTabEdit(targetKey, action) {
		this[action](targetKey)
	}
	/**
	 * 打开页面
	 * @param {*} path 路由
	 * @param {*} title 页签名称
	 * @param {*} closable 页签可以关闭
	 */
	openpage(path, title, closable, init = false) {
		const {activeTabKey, tabs} = global
		if (activeTabKey === path && !init) {
			return
		}
		this.props.history.push(path)
		const findTab = tabs.find(item => item.key === path)
		if (findTab) {
			if (title && title !== findTab.title && !init) {
				findTab.title = title
			}
		} else {
			const tab = {key: path, title, closable}
			let activeIndex = tabs.findIndex(item => item.key === activeTabKey)
			if (activeIndex !== -1) {
				tabs.splice(activeIndex + 1, 0, tab)
			} else {
				tabs.push(tab)
			}
		}
		global.activeTabKey = path
		global.tabs = tabs
		this.props.reRender()
		localStorage.setItem(global.tabKey, path)
		localStorage.setItem(global.tabsKey, JSON.stringify(tabs))
	}
	remove(targetKey){
		let {tabs, activeTabKey} = global
		let lastIndex = -1
		tabs.forEach((tab, i) => {
			if (tab.key === targetKey) {
				lastIndex = i - 1
			}
		})
		let activeTab
		if (lastIndex >= 0) {
			activeTab = tabs[lastIndex]
		} else {
			activeTab = tabs[0]
		}
		tabs = tabs.filter(item => item.key !== targetKey)
		global.tabs = tabs
		if (activeTabKey === targetKey) {
			global.activeTabKey = activeTab.key
			this.props.history.push(activeTab.key)
		}
		this.reRender()
		localStorage.setItem(global.tabKey, global.activeTabKey)
		localStorage.setItem(global.tabsKey, JSON.stringify(global.tabs))
	}
	closeAllTab() {
		global.activeTabKey = '/'
		global.tabs = [firstPage]
		this.reRender()
		this.props.history.push('/')
		localStorage.setItem(global.tabKey, '/')
		localStorage.setItem(global.tabsKey, JSON.stringify([firstPage]))
	}

	closepage() {
		const {activeTabKey} = global
		if (activeTabKey === '/') {
			return
		}
		this.remove(activeTabKey)
		delete pageInstObj[activeTabKey]
	}

	closeTab(action) {
		let { activeTabKey, tabs } = global
		let activeIndex = tabs.findIndex(tab => tab.key === activeTabKey)
		if (activeIndex === -1) return
		switch (action) {
			case 'other': {
				if (activeIndex === 0) {
					tabs = [firstPage]
				} else {
					tabs = [firstPage, tabs[activeIndex]]
				}
				break
			}
			case 'left': {
				if (activeIndex !== 0) {
					tabs = [firstPage, ...tabs.slice(activeIndex)]
				}
				break
			}
			case 'right': {
				tabs = tabs.slice(0, activeIndex + 1)
				break
			}
			case 'all': {
				this.closeAllTab()
				return
			}
			default: break
		}
		global.tabs = tabs
		this.reRender()	
		localStorage.setItem(global.tabsKey, JSON.stringify(tabs))
	}

    render() {
		const {menu, personalMenus, location} = this.props
        const {activeTabKey, tabs} = global
		if (isNoTokenRoute(location.pathname)) {
			return <div/>
		}
        return <div className={'layout-header flex-row'}>
			{global.isPhoneVertical ?
			<div className={'space-between'}
				style={{
					width: global.clientWidth,
					height: '100%', 
				}}
			>
				<div style={{height: 24}}>
					{menu}
					<A onClick={this.closeAllTab.bind(this)}>
						<Icon type='home'  style={{ fontSize: 24, marginLeft: 16 }} />
					</A>
					<A onClick={this.closepage.bind(this)}>
						<Icon type='arrow-left'  style={{ fontSize: 24, marginLeft: 16 }} />
					</A>
				</div>
				<div style={{height: 24}}>
					<A onClick={() => this.openpage('/qrcode-scan', '扫码')}>
						<Icon type='expand'  style={{ fontSize: 24, marginRight: 16 }} />
					</A>
				</div>
			</div> 
			:
			<div className={'space-between'}
				style={{
					width: global.clientWidth,
					height: '100%', 
				}}
			>
				<div className='flex-row'>
					<div style={{height: 24, width: 40}}> {menu} </div>
					<Tabs
						hideAdd
						style={{ height: 36, width: global.clientWidth - 200, marginTop: 4 }}
						onChange={this.onTabChange.bind(this)}
						activeKey={activeTabKey}
						type="editable-card"
						onEdit={this.onTabEdit.bind(this)}
					>
					{tabs.map(tab => 
						<TabPane tab={tab.title} key={tab.key} closable={tab.closable}/>
					)}
					</Tabs>
				</div>
				<div style={{height: 24, marginLeft: 20}}>
					<Dropdown trigger={global.trigger} overlay={
						<Menu>
							<Menu.Item onClick={this.closeTab.bind(this, 'other')}>
								<A>关闭其他标签页</A>
							</Menu.Item>
							<Menu.Item onClick={this.closeTab.bind(this, 'left')}>
								<A>关闭左侧标签页</A>
							</Menu.Item>
							<Menu.Item onClick={this.closeTab.bind(this, 'right')}>
								<A>关闭右侧标签页</A>
							</Menu.Item>
							<Menu.Item onClick={this.closeTab.bind(this, 'all')}>
								<A>关闭所有标签页</A>
							</Menu.Item>
						</Menu>}
					>
						<A><Icon type="ellipsis-h"  style={{ fontSize: 24}}/></A>
					</Dropdown>
					<Dropdown trigger={global.trigger} 
						overlay={<Menu> {personalMenus} </Menu>}
					>
						<span style={{marginLeft: 16, marginRight: 16, fontSize: 20}}>
							{global.emp.name || getCookie('username')}
						</span>
					</Dropdown>
				</div>
			</div>}
		</div>
    }
}