/**
 * 模块主页面
 * 根据模块配置生成模块
 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import InfiniteScroll from 'react-infinite-scroller'
import message from 'antd/lib/message'
import Upload from '@com/Upload'
import Menu from 'antd/lib/menu'
import Popover from 'antd/lib/popover'
import Dropdown from 'antd/lib/dropdown'
import List from 'antd/lib/list'
import Card from 'antd/lib/card'
import TableEx from '@com/TableEx'
import Search from '@com/Search'
import Image from '@com/Image'
import * as Action from './Action'
import { 
    configToColumn, getPagination, 
    createButton, createIcon, createA, addMenuItem,
} from '@com/PageCreator'
import Tree from '@com/Tree'
import { getTree } from '@tree/Action'
import { generateCodeAsync } from '../code_scheme/Action'
import New from './New'
import Detail from './Detail'
import { getItem, setItem } from '@utils/common/store'
import SetTable from '@com/SetTable'
import Icon from '@com/Icon'
import ResizeableTitle from '@com/ResizeableTitle'
import { deleteFilesByBusinessId } from '../file/Action'
import ModuleDataTransfer from '@com/ModuleDataTransfer'
import * as utils from '@utils/index'
import * as dataUtil from '@utils/DataUtil'
import { callApiBatch, getAddApiItem, getApiItem, getUpdateApiItem } from '../layout/Action'
import ModalEx from '@com/ModalEx'
import SplitRecord from '@com/SplitRecord'
import * as p from '@utils/page'
import {inner, modal, nestedsublist} from '@utils/page'
import * as o from '@utils/object'
import c, { LISTMODULE } from '@utils/constants'
import * as tableUtil from '@utils/tableUtil'
import { 
    moduleSpecialItemProps, 
    moudleSpecialColumn,
    moduleSpecialOperationRender,
    moduleIconOperation, 
    beforeAdd, beforeSave, canDelete, moduleOnNew 
} from './specialModule'
import A from '@com/A'

const { apiBizflow, processStart, del, batchDelete, batchSave, batchDeleteByFid } = c
const { setting } = o

export default class ListModule extends Component {
    /**
     * 使用全局变量保存页面状态
     */
    constructor(props) {
        super()
        // state 初始化
        this.state = {
            page: 1,
            pageSize: 10,
            list: [],
            totalElements: 0,
            sorter: {},
            query: {
                searchFields: {},
                mainFields: [],
                moreFields: []
            },
            rootNode: {},
            node: {},
            treeData: [],
            expandedKeys: [],
            folded: true,
            selectedRowKeys: [],
            selectedRows: [],
            colWidthObj: {},
            fieldsConfig: [],
            deleteRows: [],
            buttonInfoList: [],
            showModal: false,
            expandedRowKeys: [],
        }
        // 动态控制表格列显示或隐藏
        this.hideKeys = []
        // 数据传递模块列表
        this.transferModules = []
        // 重复校验字段
        this.keys = []
        // 表格内部新增界面实例map<id, this>
        this.innerNew = {}
        // 表格内部详情界面实例map<id, this>
        this.innerDetail = {}
        this.sysModule = false
    }

    /**
     * componentDidMount 页面加载完成后获取数据
     */
    async componentDidMount() {
        this.unmount = false
        await this.init(this.props)
    }
    componentWillUnmount() {
        this.unmount = true
    }
    /**
     * 获取系统配置，初始化页面
     * @returns 
     */
    async init(props) {
        const {
            config, fid, onRowClickSelect, selectedRowKeys, 
            action, list, searchPageKey
        } = props
        if (config == null || utils.isBlank(config.modulename)) {
            return
        }
        // 模块配置
        this.moduleConfig = config
        let {
            modulename, 
            catagory, 
            fmodule, 
            sublist, 
            edittype, 
            duplicationcheck,
            disableEditConds,
            showbatchset,
        } = config
        // 模块名称
        this.moduleName = modulename
        // 系统模块
        this.sysModule = utils.isSysModule(modulename)
        // 分类模块名称
        this.classModuleName = catagory
        // 模型有分类则获取分类数据
        this.hasClass = utils.isNotBlank(this.classModuleName)
        if (this.hasClass) {
            // 分类模块配置信息
            this.classModuleConfig = utils.getConfigByModuleName(this.classModuleName)
        }
        // 父模块ID
        this.fid = fid || global.nofid
        // pageKey 存储页面状态
        this.pageKey = this.moduleName + '_list_' + this.fid + (searchPageKey || '')

        // 数据传递模块列表
        this.transferModules = await dataUtil.getDatatransferList(modulename)

        // 业务编排按钮获取
        // toolbar,工具栏/operation,操作列
        const buttonInfoList = await dataUtil.getOrchestrateButton(this.moduleName) || []
        utils.storeData(this, this.pageKey, {buttonInfoList})

        //console.log('init pageKey', this.pageKey)
        // 是否为子模块
        this.isSub = global.hasfid(this.fid)
        // 父模块
        this.fModuleName = fmodule
        //console.log('this.fModuleName', this.pageKey, this.fModuleName)
        // 子模块独立存在标记， 有父模块，无父模块id
        this.sublistIndependent = false
        if (utils.isNotBlank(this.fModuleName) && !this.isSub) {
            this.sublistIndependent = true
        }
        // 模型是否有子模块
        this.hasSubList = false
        if (sublist instanceof Array && sublist.length > 0) {
            this.hasSubList = true
        }
        // 模块中是否存在嵌套子模块
        this.hasNestedSublist = dataUtil.hasNestedSublist(config)
        // 获取嵌入子模块配置
        this.nestedTransferModules = []
        if (this.hasNestedSublist) {
            const nestedsublistArray = dataUtil.getNestedSublist(config)
            // 如果只有一个, 获取嵌入子模块数据传递配置
            if (nestedsublistArray.length === 1) {
                this.hasOneNestedSublist = true
                const nestedModuleName = nestedsublistArray[0].moduleName
                this.nestedTransferModules = await dataUtil.getDatatransferList(nestedModuleName)
            }
        }
        // 表格编辑方式为可编辑表格
        this.tableEdit = ["directEdit", "alwaysEdit"].indexOf(edittype) !== -1
        // 其余编辑方式默认为form表单
        this.formEdit = !this.tableEdit
        // 批录
        this.showbatchset = showbatchset
        // 重复校验字段
        this.keys = duplicationcheck || []
        // 数据传递从子模块中选，表格增加来源单据列
        this.hasTransferSubModules = this.transferModules.some(item => item.isSub)
        // 禁用编辑条件
        this.disableEditConds = disableEditConds
        // 卡片模式
        this.cardType = config.showtype === "card"
        global[this.pageKey] = global[this.pageKey] || {}
        let {page, pageSize, totalElements, query, sorter, 
            rootNode, node, treeData, expandedKeys, folded, selectedRows
        } = global[this.pageKey]
        page = page || 1
        pageSize = pageSize || (onRowClickSelect ? 5 : 10)
        totalElements = totalElements || 0
        query = query || {
            searchFields: {},
            mainFields: [],
            moreFields: []
        }
        sorter = sorter || {}
        rootNode = rootNode || {}
        node = node || {}
        treeData = treeData || []
        expandedKeys = expandedKeys || []
        selectedRows = selectedRows || []
        folded = global.isPhoneVertical ? true : (folded || false)
        utils.storeData(this, this.pageKey, {
            page, pageSize, totalElements, query, sorter,
            rootNode, node, treeData, expandedKeys, folded, selectedRows,
        })
        
        let fieldsConfig = dataUtil.getTableConfig(config)
        if (this.hasTransferSubModules) {
            const sourceConfig = dataUtil.getConfig('sourceCode', '来源单据', LISTMODULE, {
                params: '$sourceModule',
                isDisabled: '1',
            })
            let index = fieldsConfig.findIndex(config => config.name === 'createUser')
            if (index !== -1) {
                fieldsConfig.splice(index, 0, sourceConfig)
            } else {
                fieldsConfig.push(sourceConfig)
            }
        }

        // 子模块独立存在，添加父模块列
        if (this.sublistIndependent) {
            const foduleConfig = utils.getConfigByModuleName(this.fModuleName)
            fieldsConfig.unshift({
                "name": 'fmodule',"alias": dataUtil.showModuleName(foduleConfig), "isRequire": "1", "width": 160, fixed: 'left', "dataType": "LISTMODULE", "params": this.fModuleName
            })
        }

        // 目前只为树模块获取数据，存入当前页面state中，以模块名称为key，treeData为值
        // TREEMODULE  分类等树形结构数据在表格中获取数据，每一列只需获取一次数据，
        // 如果进入 ModuleDataSelect 模块选择器组件后，再获取数据的话，每个单元格就会
        // 创建一个 ModuleDataSelect 实例，表格有多少行数据就会发多少次请求，所以表格中
        // ModuleDataSelect 所需的数据尽量在表格模型组件中获取，尤其是树形结构数据，不能分页，数据量会很大
        fieldsConfig.forEach(config => {
            if (config.isShowT !== '0') {
                if (dataUtil.isFFieldType(config.dataType)) {
                    config = utils.copy(config)
                    dataUtil.mergeFConfig(config)
                }
                if (utils.isNotBlank(config.params) && dataUtil.isTreeType(config.dataType)) {
                    getTree(config.params, treeData => {
                        utils.storeData(this, this.pageKey, { [config.params]: { treeData } })
                        if (this.classModuleName === config.params) {
                            this.initClassTreeData(treeData)
                        }
                    })
                }
            }
        })
        // 全部keys
        let allKeys = []
        // 全部keyLabel
        let allKeyLabels = []
        // 获取关联模块相关数据
        fieldsConfig.forEach(config => {
            // 不显示权限中隐藏字段
            if (config.isShowT !== '0' && this.hideKeys.indexOf(config.name) === -1) {
                // 默认隐藏系统内置字段
                if (!utils.isSysFields(config.name)) {
                    allKeys.push(config.name)
                }
                allKeyLabels.push({
                    key: config.name,
                    label: dataUtil.showName(config)
                })
            }
        })

        // 表格显示列key数组
        let showKeys = getItem('showTableKeys', this.moduleName)
        if (!showKeys) {
            showKeys = allKeys
        }
        // 表格宽度对象
        //let colWidthObj = getItem('colWidthObj', this.moduleName) || {}
        // 先查询数据
        await this.getList(this.fid, page, pageSize, query, sorter)
        // 再渲染表头， 才能使 defaultExpandAllRows 默认展开所有行参数生效
        utils.storeData(this, this.pageKey, { fieldsConfig, showKeys, allKeyLabels })
        // 300毫秒后重新渲染，解决固定列错行问题
        setTimeout(this.reRender.bind(this), 300)
        // selectedRowKeys
        if (selectedRowKeys) {
            utils.storeData(this, this.pageKey, {selectedRowKeys: selectedRowKeys})
        }

        // 新增页面下的子模块
        if (action === 'new') {
            if (list) {
                // 复用新增传值
                utils.storeData(this, this.pageKey, {list: list || []})
            }
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const {config, moduleName, fid, selectedRowKeys, list, disabled} = this.props
        if ((nextProps.config && nextProps.config.modulename &&
            nextProps.config.modulename !== config.modulename) ||
            // moduleName 变化
            (nextProps.moduleName && nextProps.moduleName !== moduleName)
        ) {
            this.init(nextProps)
            return
        }
        // console.log('this.fid, nextProps.fid',this.fid, nextProps.fid)
        if (nextProps.fid && nextProps.fid !== fid) {
            //console.log('nextProps.fid', nextProps.fid, this.fid)
            this.fid = nextProps.fid
            this.isSub = global.hasfid(this.fid)
            this.refresh()
        }
        // selectedRowKeys
        if (nextProps.selectedRowKeys !== selectedRowKeys) {
            utils.storeData(this, this.pageKey, {selectedRowKeys: nextProps.selectedRowKeys})
        }
        // 新增页面下的子模块
        if (nextProps.action === 'new') {
            if (nextProps.list instanceof Array && nextProps.list !== list) {
                // 复用新增传值
                utils.storeData(this, this.pageKey, {list: nextProps.list})
            }
        }
        if (nextProps.disabled !== disabled) {
            // 二次渲染，解决固定列错行问题
            setTimeout(this.reRender.bind(this), 600)
        }
    }

    /**
     * 获取列表（分页）
     * @param {*} page          当前页
     * @param {*} pageSize      分页条数
     * @param {*} query         查询条件
     * @param {*} sorter        排序条件
     * @param {*} value 点击树节点 分类id
     */
    async getList(fid, page, pageSize, query, sorter, value) {
        query = query || {}
        query.searchFields = query.searchFields || {}
        query.moreFields = query.moreFields || []
        const {action, selectOne, onRowClickSelect, selectMulti, searchUrlParams} = this.props
        // 单选模式 或 多选模式
        const isSelect = selectOne || onRowClickSelect || selectMulti
        // 新增页面下的子模块， 不查询
        if (action === 'new') {
            utils.storeData(this, this.pageKey, {
                page, pageSize
            })
            return
        }

        // searchUrlParams 查询url携带参数
        if (searchUrlParams) {
            const urlParams = utils.parseSearchStr()
            dataUtil.queryAdd(query, urlParams)
        }

        // 添加分类搜索条件
        if (this.hasClass) {
            value = value || this.state.node.value
            const classesKey = `${this.classModuleName}_classes`

            if (value === this.state.rootNode.value) {
                dataUtil.queryDelKey(query, classesKey)
            } else {
                dataUtil.queryAdd(query, {[classesKey]: value}, true)
            }
        }
        
        // 注入数据传递查询条件
        const fixedSearchConds = this.props.fixedSearchConds || {}
        dataUtil.queryAdd(query, fixedSearchConds)
        // 列表 树 模型使用名称排序
        if (sorter && sorter.column) {
            // 列表模块显示字段是否为编码, 如果显示编码，不转换排序字段
            const isShowCode = dataUtil.isShowCode(sorter.column.params)
            if (!isShowCode && dataUtil.isListTreeType(sorter.column.dataType)) {
                sorter.field += '_name'
            }
        }
        /**
         * 作为子模块时添加查询条件{fid: this.fid}
         */
        if (global.hasfid(fid)) {
            dataUtil.queryAdd(query, {fid})
        } else {
            delete query.searchFields.fid
            query.moreFields = query.moreFields.filter(it => it.id !== 'fid')
        }
        //console.log(this.moduleName, page, pageSize, query, sorter)
        // 查询开始，将页面状态设为加载中
        !isSelect && p.startLoad()
        //console.log('getlist', this.moduleName)
        let list = []
        let totalElements = 0
        let getListAction = Action.getListPromise
        let getListAllAction = Action.getListAllPromise
        let getAll = false
        // 自定义分页查询接口
        if (this.props.getListPromise instanceof Function) {
            getListAction = this.props.getListPromise
        }
        // 自定义全量查询接口
        if (this.props.getListAllPromise instanceof Function) {
            getListAllAction = this.props.getListAllPromise
            getAll = true
        }
        if (this.isSub || getAll) {
            list = await getListAllAction(this.moduleName, query, sorter)
            totalElements = list.length
        } else {
            const body = await getListAction(this.moduleName, page, pageSize, query, sorter)
            list = body.list || []
            totalElements = body.totalElements || 0
        }

        // 查询开始，将页面状态设为正常并更新数据
        utils.storeData(this, this.pageKey, {
            // 卡片滚动拼接
            list: (!this.cardType || page === 1) ? 
                list : [].concat(this.state.list).concat(list), 
            page, pageSize, query, sorter, totalElements
        })
        !isSelect && p.stopLoad()
    }

    /**
     * 搜索按钮响应函数
     * @param {*} query 
     * @param {*} value 点击树节点 分类id
     */ 
    onSearch(query, value) {
        const { pageSize, sorter } = this.state
        this.getList(this.fid, 1, pageSize, query, sorter, value)
    }

    /**
     * 表格发生变化回调函数
     * @param {*} pagination    分页信息
     * @param {*} filters       过滤条件
     * @param {*} sorter        排序信息
     */
    handleTableChange(pagination, filters, sorter) {
        let page = pagination.current
        let pageSize = pagination.pageSize
        if (pageSize !== this.state.pageSize) {
            page = 1
            // 保存pageSize到数据库
            setItem('pageSize', pageSize, this.moduleName)
        }
        if (sorter && (sorter.field !== this.state.sorter.field || sorter.order !== this.state.sorter.order)) {
            page = 1
        }
        let { query } = this.state
        this.getList(this.fid, page, pageSize, query, sorter)
    }

    async deleteAction(record) {
        const getApiArray = async (apiArray) => await this.onDelete(apiArray, record)
        await callApiBatch(getApiArray, '删除成功')
    }

    /**
     * 删除操作
     */
    async onDelete(apiArray, record) {
        const {disabledShowModify, config} = this.props
        const {selectedRowKeys, selectedRows} = this.state
        // 删除前注入是否能删除逻辑
        if (!await canDelete(this.moduleName, apiArray, record)) {
            return false
        }
        // 子模块不调用删除接口，添加删除标记, 子模块配置disabledShowDel时直接删除
        if (this.isSub && !disabledShowModify) {
            // 修改父模块合计值, 删除行时，合计值减去当前行的值
            dataUtil.sumSubtract(this.fid, this.moduleName, [record], this.table)
            const list = this.state.list.filter(item => item.id !== record.id)
            const deleteRows = this.state.deleteRows
            deleteRows.push(record)
            utils.storeData(this, this.pageKey, { 
                deleteRows, list, 
                selectedRowKeys: selectedRowKeys.filter(key => key !== record.id),
                selectedRows: selectedRows.filter(it => it.id !== record.id),
                totalElements: this.state.totalElements - 1 
            })
            return true
        }
        const success = () => {
            delete this.innerDetail[record.id]
            utils.storeData(this, this.pageKey, {
                selectedRowKeys: selectedRowKeys.filter(key => key !== record.id),
                selectedRows: selectedRows.filter(it => it.id !== record.id),
            })
            config.hasattachment  && deleteFilesByBusinessId(record.id)
        }
        if (apiArray instanceof Array) {
            await dataUtil.delSubList(apiArray, [record.id], config)
            apiArray.push(getApiItem(del, this.moduleName, [record.id], success))
        }
        return true
    }

    /**
     * 批量删除
     */
    async batchDelete(apiArray) {
        const {config, disabledShowModify, } = this.props
        if (this.state.selectedRowKeys.length === 0) {
            utils.warning('提示', '未选择数据！')
            return false
        }
        // 校验是否可以删除
        for (let record of this.state.selectedRows) {
            if (!await canDelete(this.moduleName, apiArray, record)) {
                return false
            }
        }
        for (let data of this.state.selectedRows) {
            // 禁用编辑条件
            let disableEditMessage = dataUtil.condsValue(this.disableEditConds, data, this.moduleName)
            if (disableEditMessage) {
                utils.warning(`编码：${data.code} 名称：${data.name || ''} ${disableEditMessage}，不能删除`)
                return false
            }
        }
        let ids = this.state.selectedRowKeys
        // 子模块不调用删除接口，添加删除标记, 子模块配置disabledShowDel时直接删除
        if (this.isSub && !disabledShowModify) {
            const deleteRows = this.state.deleteRows
            // 修改父模块合计值, 删除行时，合计值减去当前行的值
            dataUtil.sumSubtract(this.fid, this.moduleName, this.state.selectedRows, this.table)
            const list = this.state.list.filter(item => ids.indexOf(item.id) === -1)
            utils.storeData(this, this.pageKey, {
                deleteRows: deleteRows.concat(this.state.selectedRows),
                list,
            })
            return true
        }
        const success = () => {
            utils.storeData(this, this.pageKey, {
                selectedRowKeys: [],
                selectedRows: [],
            })
            ids.forEach(id => {
                delete this.innerDetail[id]
                config.hasattachment  && deleteFilesByBusinessId(id)
            })
        }
        if (apiArray instanceof Array) {
            apiArray.push(getApiItem(batchDelete, this.moduleName, [ids], success))
            await dataUtil.delSubList(apiArray, ids, config)
        }
        return true
    }

    /**
     * 刷新页面
     * @param {*} fid 
     * @param {*} body 
     */
    async refresh(page) {
        if (this.state.list.length === 1) {
            page = this.state.page - 1 <= 0 ? 0 : this.state.page - 1
        }
        let { pageSize, query, sorter } = this.state
        await this.getList(this.fid, page || this.state.page, pageSize, query, sorter)
    }
    /**
     * oows 收到消息后，后台主动刷新页面
     * @param {*} clientId 修改数据的客户端id
     * @param {*} page 收到新增消息时值为1， 更新和删除时为空
     * @returns 
     */
    async refreshPage(clientId, page) {
        await this.refresh(page)
        await dataUtil.refreshInnerPage(this.innerDetail, clientId)
        return true
    }

    /**
     * 导出数据
     */
    exportData() {
        const { page, pageSize, query, sorter } = this.state
        dataUtil.exportData(this.moduleName, { 
            page, pageSize, query, sorter 
        }, this.fid)
    }

    //导入数据
    handleChange(info) {
        //处理上传文件失败
        if (info.file.error) {
            utils.error('导入数据失败【' + info.file.name + '】' + (info.file.response && info.file.response.message))
            p.stopLoad()
        } else if (info.file.status === "uploading") {
            p.startLoad()
        } else if (info.file.status === "done") {
            //const response = info.file.response
            p.stopLoad()
            this.refresh()
        }
    }

    /**
     * 获取树模块数据
     */
    initClassTreeData(treeData) {
        p.stopLoad()
        treeData = treeData || []
        if (global.equals(this.state.node, {})) {
            // 默认展开根节点
            let expandedKeys = this.state.expandedKeys

            if (expandedKeys.length === 0 && treeData.length > 0) {
                expandedKeys = [treeData[0].key]
            }
            // rootNode 为空时取树根节点
            if (global.equals(this.state.rootNode, {})) {
                utils.storeData(this, this.pageKey, {
                    rootNode: treeData[0],
                    node: treeData[0],
                    expandedKeys: expandedKeys,
                    treeData: treeData,
                })
                p.stopLoad()
            } else {
                utils.storeData(this, this.pageKey, {
                    node: treeData[0],
                    expandedKeys: expandedKeys,
                    treeData: treeData,
                })
            }
        } else {
            utils.storeData(this, this.pageKey, {
                treeData
            })
        }
    }

    /**
     * 点击树节点
     */
    onSelect(selectedKeys, info) {
        utils.storeData(this, this.pageKey, {
            node: info.node
        })
        let { query } = this.state
        this.onSearch(query, info.node.key)
        if (global.isPhoneVertical) {
            utils.storeData(this, this.pageKey, {folded: true})
        }
    }

    /**
     * 展开树节点
     * @param {} expandedKeys 
     */
    onExpand(expandedKeys) {
        utils.storeData(this, this.pageKey, {
            expandedKeys: expandedKeys
        })
    }

    toDetail(id, record) {
        p.toDetail(this.moduleName, id, record)
    }

    /**
     * 生成分类信息
     * @param {*} record 
     */
    fillClass(record) {
        let node = (global[this.pageKey] && global[this.pageKey].node) || this.state.rootNode || {}
        if (this.hasClass) {
            let {value, code, title, classnames, classes} = node; 
            let key = this.classModuleName
            let idKey = `${key}_id`
            let nameKey = `${key}_name`
            let classesKey = `${key}_classes`
            let classnamesKey = `${key}_classnames`
            record[idKey] = value
            record[nameKey] = title
            record[classesKey] = classes
            record[classnamesKey] = classnames
            record[key] = code
        }
    }
    /**
     * 生成编码
     * @param {*} record 
     */
    async fillCode(record) {
        let node = (global[this.pageKey] && global[this.pageKey].node) || this.state.rootNode || {}
        // 新增时自动生成编码
        let preCode = []
        if (this.hasClass) {
            preCode.push(node.code || '')
        }
        let code = await generateCodeAsync(this.moduleName, preCode)
        if (utils.isNotBlank(code)) {
            record.code = code
        }
    }

    /**
     * 新增一条数据，准备默认数据
     * 默认父列
     * 自增数字
     * 系统配置默认值
     * @param {*} totalElements 表格记录总数，自增类型使用
     * @param {*} insertIndex 插入位置
     * @returns record
     */
    getNewRecord(totalElements, insertIndex) {
        let record = dataUtil.getNewRecord()
        // 如果关联父模块，保存父模块id
        if (this.isSub) {
            record.fid = this.fid
            record.fcode = dataUtil.getValue(this.fid, this.fModuleName, 'code')
        }
        
        // 组装数据父列默认传递的字段段映射
        const fModuleTransferMap = {}
        let fieldsConfig = this.state.fieldsConfig || []
        fieldsConfig.forEach(config => {
            if (dataUtil.isFFieldType(config.dataType)) {
                const otherParams = config.otherParams || {}
                const fmoduleFieldName = otherParams.name
                if (utils.isNotBlank(fmoduleFieldName)) {
                    fModuleTransferMap[config.name] = fmoduleFieldName
                }
            }
            const initialValue = dataUtil.getInitialValue(config, {}, totalElements, insertIndex)
            if (initialValue != null) {
                record[config.name] = initialValue
            }
        })
        // 初始化父列默认类型数据
        //console.log('初始化父列默认类型数据')
        //console.log(this.pageKey, this.moduleName, this.fModuleName, this.props.fRecord, fModuleTransferMap)
        const fModuleTransferData = dataUtil.getTransferData(this.moduleName, this.fModuleName, 
            this.props.fRecord, fModuleTransferMap)
        Object.assign(record, fModuleTransferData)
        //console.log('fModuleTransferData', fModuleTransferData)
        return record
    }

    /**
     * 表格增加一条数据
     */
    async listAddOneRecord(copyRecord, insertIndex) {
        const {config} = this.props
        // 不跳转保持当前页
        let {totalElements} = this.state
        let dataSource = this.state.list
        // 新增一条数据，总数加一
        totalElements++
        const newRecord = this.getNewRecord(totalElements, insertIndex)
        // 新增时自动生成编码
        if (!copyRecord) {
            // 复制新增时不重新生成分类信息
            this.fillClass(newRecord)
        }
        await this.fillCode(newRecord)
        const record = Object.assign({}, copyRecord, newRecord)
        // 表格新增一条数据回调
        moduleOnNew(this.moduleName, record)
        //console.log('[ record ] >', record)
        if (insertIndex >= 0) {
            dataSource.splice(insertIndex, 0, record)
        } else {
            dataUtil.listAdd(dataSource, record, config)
        }
        utils.storeData(this, this.pageKey, { list: dataSource, totalElements })
        if (global.isPhoneVertical) {
            this.openKey(record.id)
        }
    }

    /**
     * 新增按钮回调
     */
    async onNew() {
        const {config} = this.props
        if (this.tableCanEdit) {
            // 直接编辑
            await this.listAddOneRecord()
        } else {
            // 表单，跳转到新增路由
            if ("form" === config.edittype) {
                p.toNew(this.moduleName, this.fid)
            }
        }
    }
    /**
     * 复用新增
     * @param {*} id 
     */
    async onCopy(id) {
        const {config, beforeCopy} = this.props
        const record = await Action.getOnePromise(this.moduleName, id)
        beforeCopy && await beforeCopy(record)
        // 复制数据，跳过禁止编辑字段
        dataUtil.copyRecord(record, this.moduleName)
        if (this.tableCanEdit) {
            const newRecord = Object.assign(record, this.getNewRecord(this.state.totalElements + 1))
            // 直接编辑
            await this.listAddOneRecord(newRecord)
            await dataUtil.mergeSubList(id, record, config)
        } else {
            // 弹窗新增
            const newRecord = Object.assign(record, this.getNewRecord(this.state.totalElements + 1))
            await dataUtil.mergeSubList(id, newRecord, config)
            // 表格新增一条数据回调
            moduleOnNew(this.moduleName, newRecord)
            utils.storeData(this, this.pageKey, {showModal: true, newData: newRecord})
        }
    }

    /**
     * 新增保存
     * @param {*} record 
     */
    async onAdd(apiArray, record) {
        const {config} = this.props
        //console.log('record', record)
        // 转换表单数据
        dataUtil.convertData(config, record)
        // 新增保存前注入是否能新增保存逻辑
        if (await beforeAdd(this.moduleName, apiArray, record) === false) {
            return false
        }

        const success = (newRecord) => {
            this.replaceRecordAndRender(newRecord)
        }
        if (apiArray instanceof Array) {
            apiArray.push(getAddApiItem(this.moduleName, record, success))
        }
        return true
    }

    /**
     * replaceRecordAndRender 替换记录并刷新页面
     * @param {*} newRecord 
     */
    replaceRecordAndRender(newRecord) {
        const list = this.state.list
        tableUtil.replaceRecord(list, newRecord)
        utils.storeData(this, this.pageKey, {list})
    }

    /**
     * mergeRecord 合并一条记录
     * @param {*} list 
     * @param {*} newRecord 
     */
    mergeRecord(list, newRecord) {
        let index = list.findIndex(item => item.id === newRecord.id)
        const oldRecord = list[index]
        newRecord = Object.assign({}, oldRecord, newRecord)
        if (index !== -1) {
            list.splice(index, 1, newRecord)
        }
    }

    /**
     * mergeRecordAndRender 合并记录并刷新页面
     * @param {*} newRecord 
     */
    mergeRecordAndRender(newRecord) {
        const list = this.state.list
        this.mergeRecord(list, newRecord)
        utils.storeData(this, this.pageKey, {list})
    }

    /**
     * 嵌入子模块，修改父模块列表记录时调用
     * modifyRecord 修改一条记录，并计算表达式
     * @param {*} newRecord 
     */
    modifyRecord(newRecord) {
        //console.log('modifyRecord', this.pageKey, newRecord)
        const list = this.state.list
        const record = dataUtil.getValues(newRecord.id)
        //const fRecord = dataUtil.getValues(newRecord.id)
        // 直接计算表达式
        const values = dataUtil.callExpr(record, '', this.state.fieldsConfig, this.props.fRecord)
        //console.log('modifyRecord callExpr values', values)
        // 计算表达式后再合并数据
        Object.assign(record, values)
        // 先合并数据
        this.mergeRecord(list, Object.assign(newRecord, values))
        // 获取嵌入子模块所在内嵌表单实例
        const inst = this.innerDetail[newRecord.id] || this.innerNew[newRecord.id]
        // 修改内嵌表单数据
        const form = inst && inst.fields
        const formValues = Object.assign({}, newRecord)
        delete formValues.id
        if (form && form.setFieldsValue) {
            //console.log('modifyRecord formValues', this.pageKey, formValues)
            form.setFieldsValue(formValues)
        }
        // 获取当前表格表单实例，修改表格form表单数据
        // 重新组装表格表单数据，给key拼接rowKey
        const tableFormValues = dataUtil.buildTableFormData([newRecord])
        this.table.setFieldsValue(tableFormValues)
        // 修改表格记录数据
        utils.storeData(this, this.pageKey, {list})
    }

    /**
     * 表格新增或编辑取消
     */
    onCancle(id, record, index) {
        let list = this.state.list
        if (record.isNew) {
            // 修改父模块合计值, 删除行时，合计值减去当前行的值
            dataUtil.sumSubtract(this.fid, this.moduleName, [record], this.table)
            list.splice(index, 1)
            utils.storeData(this, this.pageKey, {totalElements: this.state.totalElements - 1})
        }
        utils.storeData(this, this.pageKey, {list })
    }

    /**
     * 获取当前修改行的数据库中的数据
     */
    getStateRecord(record) {
        return this.state.list.find(item => item._id === record._id)
    }

    /**
     * 重新渲染页面
     */
    reRender() {
        utils.storeData(this, this.pageKey, {update: !this.state.update})
    }

    /**
     * 保存
     * @param {*} id 
     * @param {*} record 
     */
    async onSave(apiArray, id, record) {
        const {config} = this.props
        let stateRecord = this.getStateRecord(record) || {}
        // 转换表单数据
        dataUtil.convertData(config, record, stateRecord)
        // 保存前注入是否能保存逻辑
        if (await beforeSave(this.moduleName, apiArray, record, stateRecord) === false) {
            return false
        }

        const success = (newRecord) => {
            this.replaceRecordAndRender(newRecord)
        }
        if (apiArray instanceof Array) {
            apiArray.push(getUpdateApiItem(this.moduleName, id, record, success))
        }
        return true
    }

    /**
     * 表格复选变化
     * @param {*} selectedRowKeys 
     * @param {*} selectedRows 
     */
    onSelectChange(selectedRowKeys, selectedRows) {
        const {onSelectChange} = this.props
        if (selectedRowKeys.length > 100) {
            utils.warning('提示', '最多可选择100条！')
            return
        }
        onSelectChange && onSelectChange(selectedRowKeys, selectedRows)
        utils.storeData(this, this.pageKey, {
            selectedRowKeys: selectedRowKeys,
            selectedRows: selectedRows,
        })
    }

    /**
     * 列宽变化回调
     * @param {*} dataIndex 
     */
    handleResize(dataIndex) {
        return (e, { size }) => {
            //console.log(this.moduleName, dataIndex, size.width)
            let colWidthObj = this.state.colWidthObj || {}
            colWidthObj[dataIndex] = size.width
            utils.storeData(this, this.pageKey, {colWidthObj: colWidthObj})
        }
    }

    handleInfiniteOnLoad() {
        let { page, pageSize, query, sorter } = this.state
        this.getList(this.fid, page + 1, pageSize, query, sorter)
    }

    /**
     * 提交
     */
    async commit(apiArray, record) {
        const success = (newData) => {
            this.replaceRecordAndRender(newData)
        }
        const data = {
            id: record.id,         // 业务记录id
            code: record.code,     // 业务记录编码
            name: record.name,     // 业务记录编码
            moduleName: this.moduleName,   // 业务模块名称
        }
        record.flowstatus = "reviewing"
        if (apiArray instanceof Array) {
            apiArray.push(getApiItem(processStart, this.moduleName, [data]))
            apiArray.push(getUpdateApiItem(this.moduleName, record.id, record, success))
        }
        return true
    }

    /**
     * 是否可以保存，先查前端分页中的数据，再查数据库
     * @param {*} record 要保存的数据
     * @param {*} map 数据传递字段映射
     * @param {*} stateRecord moduleDataChange专用 传递前的数据
     * @returns 
     */
    canTransferData(record, map, stateRecord = null, notip) {
        let warnText = []
        /**
         * findOne
         * 查找数据
         * @param {*} list 查找列表
         * @returns 
         */
        const findOne = (list) => {
            return list.find(item => {
                warnText = []
                // 跳过当前行数据
                if (stateRecord && stateRecord.id === item.id) {
                    return false
                }
                // 数据传递时校验sourceId， 模块选择器数据变化不校验（stateRecord === null）
                if (record.sourceId === item.sourceId && stateRecord === null) {
                    warnText.push(`记录${item.code} ${item.name} 已添加`)
                    return true
                }
                // 来源单据不同视为不同
                if (record.sourceCode) {
                    if (item.sourceModule !== record.sourceModule) {
                        return false
                    }
                    if (item.sourceCode !== record.sourceCode) {
                        return false
                    }
                    const {sourceCode, sourceCode_name, sourceModule} = record
                    warnText.push(`来源单据: ${sourceModule} ${sourceCode} ${sourceCode_name}`)
                }
                
                // 重复校验字段，默认为 code
                for (let key of this.keys) {
                    if (utils.isBlank(map[key])) continue
                    let name = key
                    let recordName = map[key]
                    if (item[name] !== record[recordName]) {
                        return false
                    }
                    let showName = dataUtil.showName(utils.getConfig(this.moduleName, name))
                    warnText.push(`${showName}: ${item[name] || ''}`)
                }
                return warnText.length > 0
            })
        }
        let findItem = findOne(this.state.list)
        if (findItem) {
            !notip && utils.notify('重复添加', warnText.join(', '))
            return false
        }
        return true
    }

    /**
     * 数据传递
     * @param {*} records 
     */
    async addTransferData(row, records, notip) {
        const {fRecord, config} = this.props
        if (!(row && (records instanceof Array) && records.length > 0)) {
            return
        }
        let selectmodule = row.isSub ? row.selectsublist : row.selectmodule
        let list = this.state.list
        let map = dataUtil.getTransferMapByTransferModule(row) || {}
        if (this.keys.length > 0) {
            let canTransferRecords = []
            for (let record of records) {
                // 判断是否可以添加
                let ret = this.canTransferData(record, map, null, notip)
                if (ret) canTransferRecords.push(record)
            }
            records = canTransferRecords
        }
        let totalElements = this.state.totalElements
        const newRecords = []
        for (let record of records) {
            ++totalElements
            const newRecord = this.getNewRecord(totalElements)
            // 新增时自动生成编码
            this.fillClass(newRecord)
            if (config.autocode) {
                await this.fillCode(newRecord)
            }
            Object.assign(newRecord, {
                sourceId: record.sourceId,
                sourceCode: record.sourceCode,
                sourceCode_name: record.sourceCode_name,
                sourceCode_id: record.sourceCode_id,
                sourceModule: record.sourceModule,
            })
            // 合并传递数据
            dataUtil.transferData(this.moduleName, newRecord, selectmodule, record, map, fRecord)
            // 计算表达式
            const values = dataUtil.callExpr(newRecord, '', this.state.fieldsConfig, fRecord)
            Object.assign(newRecord, values)
            newRecords.push(newRecord)
        }
        // 修改父模块合计值, 添加行时，合计值加上当前行的值
        dataUtil.sumAdd(this.fid, this.moduleName, newRecords)
        //console.log('[ newRecord ] >', newRecord)
        dataUtil.listAdd(list, newRecords, config)
        utils.storeData(this, this.pageKey, {list, totalElements}) 
    }
    /**
     * moduleDataChange 模块选择数据传递
     * @param {*} selectmodule 选择字段模块名
     * @param {*} name 字段名 
     * @param {*} stateRecord 表格中的记录
     * @param {*} value 模块选择组件 数据值
     * @param {*} record 模块选择组件选择完整记录
     */
    async moduleDataChange(selectmodule, name, stateRecord, value, record) {
        let list = this.state.list
        let tableRecord = this.state.list.find(item => item.id === stateRecord.id)
        if (tableRecord === undefined) return
        //console.log('this.transferModules', this.transferModules)
        //console.log('selectmodule', selectmodule)
        let map = dataUtil.getTransferMap(selectmodule, this.transferModules)
        if (map == null) {
            return
        }
        //console.log('value, map',value, map)
        if (value == null) return
        if (value.code !== stateRecord[name]) {
            let ret = await this.canTransferData(record, map, stateRecord)
            if (!ret) {
                return
            }
        }
        // 修改表格 form 数据
        const values = dataUtil.getTransferFormData(this.moduleName, selectmodule, 
            name, record, this.props.fRecord, map, tableRecord.id)
        if (this.table) {
            this.table.setFieldsValue(values)
            this.table.callExpr(tableRecord)
        }
        // 通过模块数据选择组件的数据，不存在来源单据，故删除
        dataUtil.deleteSourceCode(tableRecord)
        const {fRecord} = this.props
        // 合并传递数据
        dataUtil.transferData(this.moduleName, tableRecord, selectmodule, record, map, fRecord)
        utils.storeData(this, this.pageKey, {list})
    }

    /**
     * onRow
     * @param {*} record 
     * @returns 
     *  TableEventListeners {
            onClick?: (arg: React.MouseEvent) => void;
            onDoubleClick?: (arg: React.MouseEvent) => void;
            onContextMenu?: (arg: React.MouseEvent) => void;
            onMouseEnter?: (arg: React.MouseEvent) => void;
            onMouseLeave?: (arg: React.MouseEvent) => void;
            [name: string]: any;
        }
     */
    onRow(record) {
        return {
            onClick: () => {
                this.onRowClick(record)
            }
        }
    }

    /**
     * 单击行
     * @param {*} record 
     */
    onRowClick(record) {
        const {
            disabled, config, onSelectChange, onRowClickSelect, selectOne, selectMulti, onSelect
        } = this.props
        const alwaysEdit = config.edittype === "alwaysEdit"
        // 永远编辑模式，编辑状态下禁用单击行选中功能, 手机端例外
        if (!disabled && alwaysEdit && !global.isPhoneVertical) {
            return
        }
        // 直接编辑模式，编辑状态下的行禁用单击行选中功能, 手机端例外
        const directEdit = config.edittype === "directEdit"
        // console.log('disabled', disabled)
        // console.log('directEdit', directEdit)
        // console.log('record.disabled', record.disabled)
        if (!disabled && (directEdit && record.disabled === false) && !global.isPhoneVertical) {
            return
        }
        if (global.isPhoneVertical) {
            if (this.state.expandedRowKeys.indexOf(record.id) === -1) {
                this.openKey(record.id)
            } else {
                !record.isNew && this.closeKey(record.id)
            }
        } else if (!(selectOne || selectMulti)) {
            let selectedRowKeys = [record.id]
            let selectedRows = [record]
            utils.storeData(this, this.pageKey, {
                selectedRowKeys: selectedRowKeys,
                selectedRows: selectedRows,
            })
            onSelectChange && onSelectChange(selectedRowKeys, selectedRows)
            onRowClickSelect && onSelect && onSelect(record)
        }
    }

    /**
     * 批量保存 
     * "directEdit" record.disabled === false
     * "alwaysEdit" record.change === true
     */
    async batchSave(apiArray) {
        const {notEmpty, config} = this.props
        if (this.isSub && notEmpty && this.state.list.length === 0) {
            utils.warning(`${this.moduleName}不能为空`)
            return false
        }
        // setData 后删除子模块全部数据
        if (this.deleteAll) {
            const success = () => this.deleteAll = false
            apiArray.push(getApiItem(batchDeleteByFid, this.moduleName, [[this.fid]], success))
            // 递归删除表格记录子模块数据
            dataUtil.batchDeleteSubListByFid(apiArray, this.moduleName, this.state.list.map(it => it.id))
            // 递归添加子模块数据
            dataUtil.saveInnerSubList(apiArray, this.moduleName, this.state.list)
        }
        if (this.isSub && this.state.deleteRows.length > 0) {
            const success = () => {
                utils.storeData(this, this.pageKey, {deleteRows: []})
            }
            if (apiArray instanceof Array) {
                const ids = this.state.deleteRows.map(it => it.id)
                apiArray.push(getApiItem(batchDelete, this.moduleName, [ids], success))
            }
        }
        let list = []
        // 手机端保存表格内部展开表单
        if (global.isPhoneVertical) {
            /**
             * addInnerSaveList
             * 手机端子模块明细修改由父组件整体保存, onOk并未调用后端接口, 由回调函数
             * 将新记录回传到表格，替换state中list的对应记录, 然后手动添加新增或修改标记，
             * 后端批量保存接口才能识别
             * @param {*} innerInsts 新增或详情页面实例
             * @param {*} isNew 新增页面实例标记
             */
            const addInnerSaveList = (innerInsts, isNew) => {
                for (let key in innerInsts) {
                    console.log('addInnerSaveList',key, isNew)
                    let record = this.state.list.find(it => it.id === key)
                    if (record) {
                        if (isNew) {
                            record.isNew = true
                        } else {
                            record.change = true
                        }
                        list.push(record)
                    }
                }
            }
            // 合并内部新增页面数据到表格list
            if (!await dataUtil.saveInnerNewOrDetailPage(this.innerNew, apiArray)) {
                return false
            }
            addInnerSaveList(this.innerNew, true)
            // 合并内部修改页面数据到表格list
            if (!await dataUtil.saveInnerNewOrDetailPage(this.innerDetail, apiArray)) {
                return false
            }
            addInnerSaveList(this.innerDetail, false)
            // 过滤修改的数据
            list = this.state.list.filter(record => record.isNew || record.change)
        } else {
            // 电脑，平板等设备
            if (dataUtil.formError(this.table)) {
                return false
            }

            // 如果存在嵌套表格，调用嵌套表格页面所在的保存方法
            if (this.hasNestedSublist) {
                // 合并内部新增页面数据到表格list
                if (!await dataUtil.saveInnerNewOrDetailPage(this.innerNew, apiArray)) {
                    return false
                }
                // 合并内部修改页面数据到表格list
                if (!await dataUtil.saveInnerNewOrDetailPage(this.innerDetail, apiArray)) {
                    return false
                }
            }
            // 可编辑表格编辑
            list = dataUtil.getTableList(null, 
                this.table, 
                this.state.list.filter(record => record.isNew || record.change), 
                'id', this.moduleName
            )
            if (!(list instanceof Array)) {
                console.error('batchsave not array', list)
                return false
            }

            for (let record of list) {
                // 数据校验
                if (!dataUtil.dataCheck(config, record)) {
                    return false
                }
            }

            if (list.length === 0) {
                // 表格组件内部批量保存，取消编辑状态
                this.table.batchSave(this.state.list)
                return true
            }
        }
        
        const success = (body) => {
            // 表格组件内部批量保存，取消编辑状态
            this.table.batchSave(this.state.list)
            // 不刷新，替换保存后的记录
            if (body instanceof Array) {
                for (let newRecord of body) {
                    tableUtil.replaceRecord(this.state.list, newRecord)
                }
            }
            // 手机端收起展开的明细
            if (global.isPhoneVertical) {
                let expandedRowKeys = this.state.expandedRowKeys
                    .filter(key => list.map(it => it.id).indexOf(key) === -1)
                utils.storeData(this, this.pageKey, {expandedRowKeys})
            }
            // 刷新页面
            this.reRender()
        }

        if (apiArray instanceof Array) {
            for (let record of list) {
                dataUtil.deleteSublistKey(record)
            }
            apiArray.push(getApiItem(batchSave, this.moduleName, [list], success))
        }
        return true
    }

    /**
     * 业务编排按钮点击
     * @param {Array<ApiItem>} apiArray 
     * @param {*} buttonInfo 业务编排配置数据
     * @param {*} record 行记录
     * @returns 
     */
    async onCustomClick(apiArray, buttonInfo, record) {
        if (apiArray instanceof Array) {
            apiArray.push(getApiItem(apiBizflow, this.moduleName, [buttonInfo.id, record], 
                this.customClickSuccess.bind(this, buttonInfo)
            ))
        }
        return true
    }
    
    /**
     * 业务编排成功回调
     * @param {*} buttonInfo 
     * @param {*} res 业务编排返回结果
     */
    customClickSuccess(buttonInfo, res) {
        dataUtil.listPageBizFlowSuccess(buttonInfo, res, this)
        utils.storeData(this, this.pageKey, {selectedRowKeys: [], selectedRows: []})
    }
    
    /**
     * 手机端， 展开收起
     */
    openKey(id) {
        utils.storeData(this, this.pageKey, {expandedRowKeys: this.state.expandedRowKeys.concat([id])})
    }
    closeKey(id) {
        utils.storeData(this, this.pageKey, {expandedRowKeys: this.state.expandedRowKeys.filter(it => it !== id)})
    }

    async onSplit(record, values) {
        const {splitField} = this.props.config
        const {splitNum, restNum} = values
        let list = this.state.list
        record[splitField] = restNum
        record.change = true
        tableUtil.replaceRecord(list, record)
        let splitRecord = utils.copy(record)
        splitRecord[splitField] = splitNum
        let index = list.findIndex(it => it.id === record.id)
        await this.listAddOneRecord(splitRecord, index)
    }

    /**
     * onBatchCustomClick
     * @param {*} button 业务编排按钮信息
     * @returns 
     */
    async onBatchCustomClick(button) {
        if (this.state.selectedRows.length === 0) {
            utils.warning('请选择数据')
            return
        }

        const getApiArray = async (apiArray) => {
            for (let row of this.state.selectedRows) {
                // 禁用条件
                let disabledMessage = dataUtil.condsValue(button.disableEditConds, row, this.moduleName)
                if (disabledMessage) {
                    const {code, name} = row
                    utils.warning(`${code}${name ? ' - ' + name : ''} ${disabledMessage}，不能进行${button.name}`)
                    return false   
                } else {
                    await this.onCustomClick(apiArray, button, row)
                }
            }
            return true
        }
        
        await callApiBatch(getApiArray, `${button.name}成功`)
    }
    /**
     * 获取业务编排按钮
     * @param {record | Array} record | this.state.slectedRows 数据
     * @param {*} toolbar true 工具栏， false 操作列
     * @returns 
     */
    getBizButtons(record, toolbar) {
        // 权限控制
        const moduleOpers = (global.role && global.role.moduleOpers) || []
        // 管理员权限
        const adminOper = global.isAdmin || moduleOpers.indexOf(`${this.moduleName}_oau`) !== -1
        // 业务编排按钮
        const buttons = this.state.buttonInfoList.filter(button => {
            // 禁用条件
            let disabled
            if (record instanceof Array) {
                disabled = false
            } else {
                disabled = dataUtil.condsValue(button.disableEditConds, record)
            }
            // 权限控制 
            const operName = `${this.moduleName}_${button.name}`
            const operAuth = adminOper || moduleOpers.indexOf(operName) !== -1
            const insertplace = button.insertplace || []
            let posCond = insertplace.indexOf('operation') !== -1 
            if (toolbar) {
                posCond = insertplace.indexOf('toolbar') !== -1 
            }
            return !disabled && operAuth && posCond
        })
        return buttons.map((button, index) => {
            if (toolbar) {
                return createButton(button.icon, button.name, 
                    this.onBatchCustomClick.bind(this, button),
                    {key: index, showName: true, type: 'primary', ghost: true})
            }
            return createA(button.name, async () => {
                const getApiArray = async (apiArray) => 
                    await this.onCustomClick(apiArray, button, record)
                await callApiBatch(getApiArray, `${button.name}成功`)
            }, {key: index})
        })
    }

    /**
     * newDiv
     * New 新增页面
     * @param {Object} data 数据
     * 页面展示模式
     * normal: 默认模式
     * inner: 表格行内嵌入
     * modal: 弹窗
     * nestedsublist： 嵌入子模块，不显示表单基本信息
     * @param {String} mode 弹窗
     * @returns New 新增页面
     */
    newDiv(data, mode) {
        const {cols, config, fRecord, searchPageKey} = this.props
        return <New
            ref={(inst) => {
                if(mode === inner || mode === nestedsublist) {
                    this.innerNew[data.id] = inst
                }
            }}
            searchPageKey={searchPageKey}
            mode={mode}
            refreshPage={this.refreshPage.bind(this)}
            fRecord={fRecord}
            isSub={this.isSub}
            onDataChange={newData => {
                if (mode === nestedsublist) {
                    newData.change = true
                }
                this.replaceRecordAndRender(newData)
                this.closeKey(data.id)
            }}
            onDataDelete={() => this.deleteAction(data)}
            showModal={mode === modal && this.state.showModal}
            close={mode === modal ? 
                () => utils.storeData(this, this.pageKey, {showModal: false, newData: {}}) 
                :
                null
            }
            id={data && data.id}
            data={data}
            fid={this.fid}
            cols={cols}
            config={config}
            moduleName={this.moduleName}
        />
    }
    /**
     * detailDiv
     * Detail 详情页面
     * @param {Object} data 数据
     * 页面展示模式
     * normal: 默认模式
     * inner: 表格行内嵌入
     * modal: 弹窗
     * nestedsublist： 嵌入子模块，不显示表单基本信息
     * @param {String} mode 弹窗
     * @param {boolean} readonly 只读
     * @returns Detail 详情页面
     */
    detailDiv(data, mode, readonly) {
        const {cols, config, fRecord, disabled} = this.props
        let {action, } = this.props
        action = action || 'detail'
        if (mode === modal || disabled === false) {
            action = 'modify'
        }
        return <Detail 
            ref={(inst) => {
                if(mode === inner || mode === nestedsublist) {
                    this.innerDetail[data.id] = inst
                }
            }}
            readonly={readonly}
            mode={mode}
            refreshPage={this.refreshPage.bind(this)}
            isSub={this.isSub}
            fRecord={fRecord}
            close={this.closeKey.bind(this, data.id)}
            onDataChange={newData => {
                if (mode === nestedsublist) {
                    newData.change = true
                }
                this.replaceRecordAndRender(newData)
            }}
            onDataDelete={() => {
                if (this.isSub && mode === inner) {
                    this.deleteAction(data)
                }
            }}
            cols={cols}
            id={data.id} 
            data={data} 
            moduleName={this.moduleName} 
            config={config} 
            action={action}
        />
    }

    /**
     * 表格行内嵌套渲染
     * @param {*} data 
     * @returns 
     */
    expandedRowRender(data) {
        const mode = this.hasNestedSublist ? nestedsublist : inner
        return data.isNew ? this.newDiv(data, mode) : this.detailDiv(data, mode)
    }

    // 查找嵌入子模块页面实例
    findNestedSublistInst(id) {
        const findInst = innerInstObj => {
            for(let key in innerInstObj) {
                if (key === id) {
                    return innerInstObj[key]
                }
            }
            return null
        }
        let inst = findInst(this.innerNew)
        if (inst == null) {
            inst = findInst(this.innerDetail)
        }
        return inst
    }

    /**
     * 嵌入子模块数据传递
     * @param {*} fRecord 数据传递嵌入行数据
     * @param {*} row 数据传递配置
     * @param {*} records 数据传递的数据
     */
    async nestedSublistAddTranferData(fRecord, row, records, notip) {
        const id = fRecord.id
        let inst = this.findNestedSublistInst(id)
        // 实例未找到
        if (inst == null || inst.fields == null || !(inst.fields.sublist instanceof Object)) {
            return
        }
        // 获取子模块实例对象keys
        const keys = Object.keys(inst.fields.sublist)
        // 如果实例key的数量不等于1，直接返回，否则会出现不可预测的异常，目前嵌入子模块数据传递只支持一个
        if (keys.length !== 1) {
            return
        }
        for (let record of records) {
            let formValues = null
            // 获取表单数据
            if (inst.getRecord instanceof Function) {
                formValues = inst.getRecord()
            }
            // 数据传递条件
            if (!dataUtil.canTransfer(this.props.config, Object.assign({}, fRecord, formValues), notip)) {
                return
            }
            // 获取嵌入子模块列表页面实例
            const listModule = inst.fields.sublist[keys[0]]
            if (listModule && (listModule.addTransferData instanceof Function)) {
                // 调用嵌入子模块列表页面实例的数据传递
                await listModule.addTransferData(row, [record], notip)
            }
        }
    }

    /**
     * 获取列表所有数据
     * @returns this.state.list
     */
    getData() {
        return this.state.list
    }

    /**
     * 数据传递子模块时使用，清空现有数据，添加数据传递子模块
     */
    setData(list) {
        //setData 后删除子模块全部数据
        this.deleteAll = true
        utils.storeData(this, this.pageKey, {list})
    }

    specialColumn(column) {
        moudleSpecialColumn(this.moduleName, column)
        const {config, disabled} = this.props
        const alwaysEdit = config.edittype === "alwaysEdit"
		if (dataUtil.isNumberType(column.dataType) && alwaysEdit && !disabled &&
            column.otherParams && column.otherParams.autoincrease === '1'
        ) {
            column.sorter = false
            column.title = <div className='flex-row'>
                <div style={{marginRight: 8}}><span>{column.title}</span></div>
                <div className='circle' onClick={e => {
                    tableUtil.autoIncrease(column, this, false)
                }}><span>顺</span></div>
            </div>
        } else {
            this.props.specialColumn && this.props.specialColumn(column)
        }
	}

    specialItemProps(formField, props, data) {
        const {specialItemProps} = this.props
        moduleSpecialItemProps(this.moduleName, formField, props, data)
        specialItemProps && specialItemProps(formField, props, data)
    }

    /**
     * 操作列操作注入， 在外面
     * @param {*} text 
     * @param {*} record 
     * @param {*} index 
     */
    iconOperation(text, record, index) {
        const operArr = []
        const addOper = (oper) => {
            if (oper instanceof Array) {
                operArr.push(...oper)
            } else if (oper) {
                operArr.push(oper)
            }
        }
        const moduleOper = moduleIconOperation(this.moduleName, text, record, index)
        addOper(moduleOper)
        if (this.props.iconOperation) {
            const oper = this.props.iconOperation(text, record, index)
            addOper(oper)
        }
        return operArr
    }
    /**
     * 页面渲染
     */
    render() {
        const {
            // 模块配置
            config, 
            // 禁用编辑
            disabled, 
            // 作为选择组件参数
            selectOne, onRowClickSelect, selectMulti, onSelect, 
            // 不显示复选框栏
            hideSelect, 
            /**
             * 作为子模块时的配置参数
             */
            // 父组件id
            fid,
            // 禁用编辑时， 显示修改
            disabledShowModify,
            // 隐藏工具类
            hideToolbar,
            // 隐藏搜索
            hideSearch,
            // 父组件工具栏注入按钮
            buttons,
            // 操作列操作注入， 在更多里
            moreOperation,
            // 父模块数据
            fRecord,
            // 父组件展示模式
            mode,
            // 数据传递查询条件
            fixedSearchConds,
            // 子模块模式
            sublistMode,
        } = this.props
        // 合并后的表格配置
        let fieldsConfig = this.state.fieldsConfig || []
        // render 阻断  表格配置为空
        if (config == null || config.modulename == null ||
            !(fieldsConfig instanceof Array) || fieldsConfig.length === 0
        ) {
            return <div/>
        }

        const {
            // 可查看
            canview = true,
            // 可新增
            canadd = true,
            // 可修改
            canedit = true, 
            // 可删除
            candelete = true,
            // 可复用
            cancopy,
            // 显示插入
            showinsert,
            // 默认行间距
            size,
            // 隐藏表头
            hidehead,
            // 隐藏内置序号列
            hideindex,
            // 拆分字段
            splitField,
        } = config
        
        // 弹窗模式条件 单选模式 或 多选模式
        const isSelect = selectOne || onRowClickSelect || selectMulti
        // 权限控制
        const moduleOpers = (global.role && global.role.moduleOpers) || []
        // 字段权限，不显示隐藏字段
        const hideFields = (global.role && global.role.hideFields) || {}
        this.hideKeys = hideFields[this.moduleName] || []
        // 管理员权限
        const adminOper = global.isAdmin || moduleOpers.indexOf(`${this.moduleName}_oau`) !== -1
        if (adminOper) {
            this.hideKeys = []
        }
        // 查看权限
        const operView = canview && (adminOper || moduleOpers.indexOf(`${this.moduleName}_ov`) !== -1)
        // 新增权限
        const operAdd = canadd && (adminOper || moduleOpers.indexOf(`${this.moduleName}_oa`) !== -1)
        // 修改权限
        const operUpdate = canedit && (adminOper || moduleOpers.indexOf(`${this.moduleName}_ou`) !== -1)
        // 删除权限
        const operDelete = candelete && (adminOper || moduleOpers.indexOf(`${this.moduleName}_od`) !== -1)
        // 导入权限
        const operImport = adminOper || moduleOpers.indexOf(`${this.moduleName}_oi`) !== -1
        // 导出权限
        const operExport = adminOper || moduleOpers.indexOf(`${this.moduleName}_oe`) !== -1
        let hasFixCol = true

        // 显示编辑类按钮条件
        const showEditButtons = operUpdate && !disabled

        // 只选中一条数据时
        this.isSelectedOne = this.state.selectedRows.length === 1
        this.selectedRow = this.isSelectedOne && this.state.selectedRows[0]

        // 模型是否有分类
        let classWidth = global.o2i(config.treewidth, 10) || 300
        const {
            colWidthObj, totalElements, page, pageSize, query, 
            treeData, node, expandedKeys
        } = this.state
        const searchFields = (query && query.searchFields) || {}
        let { list } = this.state
        list = list || []
        let scrollx = 60; // 60为复选框列宽度
        let columns = []
        const alwaysEdit = config.edittype === "alwaysEdit"
        this.tableCanEdit = ["directEdit", "alwaysEdit"].indexOf(config.edittype) !== -1
            // 表格可修改    
            && operUpdate
            // 父组件编辑状态
            && !disabled
            // 子模块独立时不可编辑
            && !this.sublistIndependent
            // 组件选择时禁用编辑
            && !isSelect
        fieldsConfig = fieldsConfig.filter(config => !dataUtil.isGroupType(config.dataType))
        fieldsConfig.forEach(config => {
            if (config.isShowT !== '0' && this.hideKeys.indexOf(config.name) === -1) {
                config.dataIndex = config.name
                let columnDisable = false
                if (config.name === 'fid') {
                    columnDisable = true
                }
                if (this.state.showKeys.indexOf(config.name) !== -1 &&
                    !dataUtil.isDisabledFFieldType(config)
                ) {
                    // 字段类型为 列表 或 树 时， config.params 为目标模块名称
                    // 下面以目标模块名称为 key
                    let isEdit = columnDisable || this.tableCanEdit
                    if (config.name === 'fmodule') {
                        isEdit = false
                    }
                    
                    columns.push(configToColumn(this, config, this.specialColumn.bind(this), isEdit, {
                        moduleName: this.moduleName,    // 当前模块名称， 表格页面模块名称
                        state: this.state,              // 当前页面 state
                        /** 组件用到 state 的数据
                        {   key 为 bindModuleName 目标模块名称， value为树数据
                            [config.params]: {treeData}   // 存放表格所有树类型列的treeData
                        }
                        */
                    }, null, this.specialItemProps.bind(this)))
                }
            }
        })
        if (!hideindex && fieldsConfig.findIndex(config => config.name === 'index') === -1) {
            columns.unshift({
                title: '序号',
                key: 'index',
                dataIndex: 'index',
                width: 80,
                fixed: 'left',
                render: (text, record, index) => {
                    let ret = index + 1 + (page - 1) * pageSize
                    return ret
                }
            })
        }

        // 默认显示操作列条件： 有修改或查看操作权限
        let showOperation = operUpdate || operView || (this.props.iconOperation instanceof Function)
        /**
         * onRowClickSelect 数据传递组件双层表格界面，父模块点击选择，展示子模块表格，此时父模块无操作列
         * isSelect 数据传递组件或模块选择器支持单选时需要操作列
         */
        let normalShowOper = !onRowClickSelect && (showOperation || isSelect)
        let phoneShowOper = selectOne
        let showOper = global.isPhoneVertical ? phoneShowOper : normalShowOper
        //console.log(this.pageKey, operUpdate, this.sublistIndependent)
        const operSplit = operAdd && operUpdate && utils.isNotBlank(splitField)
        // 需要注入嵌入子模块传递按钮
        const hasNestedOper = this.hasNestedSublist && this.nestedTransferModules.length > 0
        // 最小两个图标的宽度
        const operIconWidth = 32
        let operWidth = 120
        if (this.sysModule) {
            operWidth = 150
        }
        if (this.isSub || selectOne || selectMulti) {
            // 单元格左右边距一共16
            // 只有一个操作的操作列列宽
            operWidth = 16 + operIconWidth
            if (hasNestedOper) {
                // 有删除和嵌套子模块的数据传递操作，数量this.nestedTransferModules.length
                operWidth += this.nestedTransferModules.length * operIconWidth
            } else if (operSplit) {
                // 有删除和拆分两个操作
                operWidth += operIconWidth
            } else {
                // 只有删除一个操作
            }
            if (disabled === false) {
                operWidth += operIconWidth
                if (showinsert) {
                    operWidth += operIconWidth
                }
            }
            // 单选按钮
            if (selectOne) {
                operWidth += operIconWidth
            }
            // 子模块弹窗编辑
            if (config.modaledit) {
                operWidth += operIconWidth
            }
            //console.log('hasNestedOper, operSplit, disabled, selectOne, config.modaledit', hasNestedOper, operSplit, disabled, selectOne, config.modaledit)
            //console.log('operWidth', operWidth)
        }
        //console.log(this.pageKey, showOper, disabled, alwaysEdit)
        if (showOper) {
            const operationColumn = {
                title: <SetTable
                    config={config}
                    hasFixCol={hasFixCol}
                    showKeys={this.state.showKeys}
                    allKeyLabels={this.state.allKeyLabels}
                    onChange={(showKeys) => {
                        utils.storeData(this, this.pageKey, { showKeys: showKeys })
                        setItem('showTableKeys', showKeys, this.moduleName)
                    }}
                />,
                dataIndex: 'id',
                key: 'operation',
                width: operWidth,
                fixed: 'left',
                render: (text, record, index) => {
                    const specialOper = moduleSpecialOperationRender(this.moduleName, text, record, index)
                    if (specialOper !== undefined) {
                        return specialOper
                    }
                    let commitOper = createIcon('arrow-up', '提交', async () => {
                        const getApiArray = async (apiArray) => 
                            await this.commit(apiArray, record)
                        await callApiBatch(getApiArray, '提交成功')
                    }, {needConfirm: true, title: "确定要提交吗？"})
                    let editStatus = !record.flowstatus || record.flowstatus === "editing"
                    let showCommit = !config.hasflow || (config.hasflow  && editStatus)
                    // 禁用编辑条件
                    let disableEdit = dataUtil.condsValue(this.disableEditConds, record)
                    let del = !disableEdit && showCommit && createIcon('trash', '删除', 
                        this.deleteAction.bind(this, record), 
                        {needConfirm: true, title: "确定要删除这条数据吗？"})
                    let copyOper = createIcon('copy', `复用【${utils.getTitle(record)}】`,
                        this.onCopy.bind(this, text) )
                    let detail = createIcon('eye', '查看', this.toDetail.bind(this, text, record))
                    if ((config.modaledit && !this.sublistIndependent) || isSelect) {
                        let readonly = disabled || !operUpdate
                        if (disabled && operUpdate && disabledShowModify) {
                            readonly = false
                        }
                        detail = this.detailDiv(record, modal, isSelect || readonly)
                    }
                    // 单选
                    if (selectOne) {
                        return <span>
                            {createIcon('hand-pointer', '选择', onSelect.bind(this, record))}
                            {operView && detail}
                            {!global.isPhoneVertical && operDelete && this.tableCanEdit && del}
                        </span>
                    }

                    let moreOptions = []
                    
                    // 拆分操作
                    let splitOper = <SplitRecord key='split'
                        moduleName={this.moduleName}
                        record={record}
                        splitField={splitField}
                        onChange={this.onSplit.bind(this, record)}
                    />
                    
                    // 注入更多操作
                    if (moreOperation instanceof Function) {
                        /**
                         * moreOptions 操作数组 
                         * text id
                         * record 行数据 
                         * this 传递 当前组件 this引用
                         */
                        moreOperation(moreOptions, text, record, this)
                    }
                    // 子模块 操作栏不显示业务编排操作
                    if (!this.isSub) {
                        const bizButtons = this.getBizButtons(record)
                        moreOptions = moreOptions.concat(bizButtons)
                    }
                    moreOptions = moreOptions.map((it, index) => 
                        <Menu.Item key={index} >
                            {it}
                        </Menu.Item>
                    )
                    if (showinsert && !disabled && !this.sublistIndependent) {
                        tableUtil.addMoveOpers(moreOptions, index, list, this.reRender.bind(this))
                        addMenuItem(moreOptions, '插入', this.listAddOneRecord.bind(this, null, index))
                    }
                    let dropdownMore = <Dropdown trigger={global.trigger} overlay={<Menu>{moreOptions}</Menu>}>
                        <A className="ant-dropdown-link"><Icon type="ellipsis-h" /></A>
                    </Dropdown>
                    // 子模块独立展示
                    if (this.sublistIndependent) {
                        return <span>
                            {operView && detail}
                            {moreOptions.length > 0 && dropdownMore}
                        </span>
                    }
                    // 不可编辑状态
                    if (disabled) {
                        return <span>
                            {operView && detail}
                            {operDelete && disabledShowModify && del}
                            {moreOptions.length > 0 && dropdownMore}
                        </span>
                    }
                    
                    if (this.isSub) {
                        if (!this.hasSubList) {
                            // 作为子模块存在且子模块下没有子模块
                            return <span>
                                {operSplit && splitOper}
                                {operView && detail}
                                {operDelete && del}
                                {moreOptions.length > 0 && dropdownMore}
                            </span>
                        }
                        // 将嵌入子模块的数据传递注入到父模块操作列
                        if (hasNestedOper) {
                            const transferIcons = this.nestedTransferModules.map(row =>
                                <ModuleDataTransfer 
                                    buttonType={p.icon}
                                    fRecord={record}
                                    key={row.id} 
                                    config={row} 
                                    onSelect={this.nestedSublistAddTranferData.bind(this, record, row)}
                                />
                            )
                            return <span>
                                {operView && detail}
                                {transferIcons}
                                {operDelete && del}
                            </span>
                        }
                        return <span>
                            {operView && detail}
                            {operDelete && del}
                            {moreOptions.length > 0 && dropdownMore}
                        </span>
                    }
                    
                    return <span>
                        {config.hasflow  && editStatus && <Popover
                            content={<div>
                                <span>提交</span>
                            </div>}
                        >
                            {commitOper}
                        </Popover>}
                        {operView && detail}
                        {operAdd && cancopy && copyOper}
                        {operSplit && splitOper}
                        {operDelete && del}
                        {this.iconOperation(text, record, index)}
                        {moreOptions.length > 0 && dropdownMore}
                    </span>
                }
            }
            moudleSpecialColumn(this.moduleName, operationColumn)
            columns.unshift(operationColumn)
        }

        // 表格列可伸缩
        columns.forEach(col => {
            if (colWidthObj && colWidthObj[col.dataIndex]) {
                col.width = colWidthObj[col.dataIndex]
            }
            col.onHeaderCell = column => ({
                width: column.width,
                onResize: this.handleResize(column.dataIndex),
            })
        })

        // 计算表格总宽度
        columns.forEach(col => {
            scrollx += utils.o2i(col.width, 10)
        })

        let contentWidth = scrollx
        if (this.hasClass && !this.state.folded) {
            contentWidth += classWidth + /*marginRight*/4
        }

        // 如果表格宽度小于正文宽度，去掉固定列设置
        let widthEnough = contentWidth < global.clientWidth
        // 嵌套表格不能使用固定列
        // Warning: [antd: Table] `expandedRowRender` and `Column.fixed` are not compatible. Please use one of them at one time.
        if ((widthEnough) || this.hasNestedSublist || mode === nestedsublist) {
            hasFixCol = false
            columns.forEach(col => {
                delete col.fixed
            })
        }
        //console.log('render hasFixCol', this.pageKey, hasFixCol)
        if (global.isPhoneVertical) {
            columns = columns.filter(col => {
                //console.log(col.dataIndex)
                return ['name', 'id'].indexOf(col.dataIndex) !== -1
            })
            columns.forEach(col => {
                delete col.fixed
                if (col.dataIndex === 'id') {
                    col.title = '操作'
                    col.width = 60
                } else if (col.dataIndex === 'name') {
                    col.width = columns.length === 1 ? 270 : 160
                    col.sorter = null
                    col.render = (text, record, index) => {
                        return <span className='autobr'>
                            <span style={{marginRight: 8}}>{index + 1}</span>
                            {text || record.code}
                        </span>
                    }
                }
            })
        }
        let pagination = getPagination(page, pageSize, totalElements)
        // 导入参数
        const importProps = {
            action: `${global.getApi().opts.baseURI}/${this.moduleName}/upload?fid=${this.fid}`,
            onChange: this.handleChange.bind(this),
            showUploadList: false,
        }
        const toggermenustyle = this.state.folded ? 'indent' : 'outdent'

        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            selectedRows: this.state.selectedRows,
            onChange: this.onSelectChange.bind(this),
            getCheckboxProps: record => {
                let disabled = this.state.selectedRowKeys.length === 100 && this.state.selectedRowKeys.indexOf(record.id) === -1
                return { disabled: disabled }
            },
        }        
        // 表格高度，包含分页区域
        let tableHeight = this.hasClass ? global.tableHasClassHeight : global.tableHeight
        // 表格高度，不包含分页区域
        let tableContentHeight = tableHeight - global.paginationHeight - 12/*x轴滚动条高度*/
        // 树高度
        let treeHeight = global.treeHasClassHeight
        // 非独立模块模式高度减 100
        if (isSelect) {
            tableContentHeight -= 50
            treeHeight -= 50
        }
        // 卡片模式卡片宽度
        let cardWidth = (document.body.clientWidth - 32) / 2
        // 更多操作
        let moreOpers = []
        // 业务编排按钮
        let buttonList = !isSelect && this.getBizButtons(this.state.selectedRows, true)
        // 页面禁用编辑下， 子模块 工具栏不显示业务编排操作
        if (disabled && this.isSub) {
            buttonList = []
        }
        // 数据传递
        let transferButtons = (showEditButtons || disabledShowModify) && !isSelect && fid &&
            this.transferModules.length > 0 && 
            this.transferModules.map(row =>
                <ModuleDataTransfer 
                    fid={this.fid}
                    fRecord={fRecord}
                    fmodule={this.fModuleName}
                    key={row.id} 
                    config={row} 
                    onSelect={this.addTransferData.bind(this, row)}
                />
            )
        if (global.isPhoneVertical) {
            moreOpers = moreOpers
                // 数据传递按钮
                .concat((showEditButtons && transferButtons) || [])
                // 父组件工具栏注入按钮, 非编辑状态也显示
                .concat(buttons || [])
                // 业务编排按钮组, 非编辑状态也显示
                .concat(buttonList || [])
                .map((button, index) => 
                    <Menu.Item key={'oper' + index} >{button}</Menu.Item>
                )
        }
        // 作为子模块时不显示导出
        if (!global.isPhone && operExport && !this.isSub) {
            moreOpers.push(<Menu.Item key={moreOpers.length + 1} >
                {createButton('cloud-download', '导出', this.exportData.bind(this), {showName: true} )}
            </Menu.Item>)
        } 
        // 作为子模块时不显示导入
        if (!global.isPhone && showEditButtons && operImport && !this.isSub) {
            moreOpers.push(<Menu.Item key={moreOpers.length + 1} >
                {createButton('file-download', '导入模板', 
                    dataUtil.downloadImportTemplate.bind(null, this.moduleName, this.fid), 
                    {showName: true} 
                )}
            </Menu.Item>)
            moreOpers.push(<Menu.Item key={moreOpers.length + 1} >
                <Upload {...importProps}>
                    {createButton('cloud-upload', '导入', null, {showName: true} )}
                </Upload>
            </Menu.Item>)
        }

        const treeDiv = <Tree
            defaultExpandRoot
            treeData={treeData || []}
            onSelect={this.onSelect.bind(this)}
            selectedKeys={[node && node.key]}
            expandedKeys={expandedKeys}
            onExpand={this.onExpand.bind(this)}
        />

        // 新增弹窗条件
        let newModal = (isSelect && !this.isSub) || this.state.showModal
        if (global.isPhoneVertical) {
            newModal = isSelect
        }
        
        // 表格参数
        const tableProps = {
            /**
             * 页面展示模式
             * normal: 默认模式，路由
             * inner: 表格行内嵌入新增、详情
             * modal: 弹窗
             * nestedsublist： 嵌入子模块，不显示表单基本信息
             */
            mode: mode,
            // 有且只有一个内嵌子模块
            hasOneNestedSublist: this.hasOneNestedSublist,
            // 可编辑表格禁用编辑参数
            disabled: !this.tableCanEdit,
            // 可编辑表格行修改保存按钮回调函数
            onSave: async (id, record) => {
                // 子模块下替换记录，不直接保存
                // 父模块页面不可编辑时，单独保存子模块数据
                if (this.isSub && disabled !== true) {
                    this.replaceRecordAndRender(record)
                    return true
                }
                const getApiArray = async (apiArray) => 
                    await this.onSave(apiArray, id, record)
                return await callApiBatch(getApiArray, '保存成功')
            },
            // 可编辑表格行新增保存按钮回调函数
            onAdd: async (record) => {
                // 子模块下替换记录，不直接保存
                // 父模块页面不可编辑时，单独保存子模块数据
                if (this.isSub && disabled !== true) {
                    this.replaceRecordAndRender(record)
                    return true
                }
                const getApiArray = async (apiArray) => 
                    await this.onAdd(apiArray, record)
                return await callApiBatch(getApiArray, '保存成功')
            },
            // 可编辑表格行取消按钮回调函数
            onCancle: this.onCancle.bind(this),
            // 表头
            columns: columns,
            // 表格数据
            dataSource: list,
            // 表格变化回调，包括分页、排序和过滤操作
            onChange: this.handleTableChange.bind(this),
            // 分页
            pagination: pagination,
            // 模块名称
            moduleName: this.moduleName,
            // 表格始终编辑
            alwaysEdit: alwaysEdit,
            // 父模块数据
            fRecord,
        }
        if (this.props.pagination === false) {
            tableProps.pagination = false
        }
        tableProps.size = size || 'middle'
        // 隐藏表格边框
        if (setting.hideborder) {
            tableProps.bordered = false
        }
        // 隐藏表头
        if (hidehead) {
            tableProps.showHeader = false
        }
        // 隐藏工具栏, 子模块禁用编辑时
        // 表格不可选择， 也不添加点击行事件
        const tableCanSelect = selectMulti || !(hideToolbar || (this.isSub && disabled))
        if (tableCanSelect && !hideSelect) {
            if (!onRowClickSelect) {
                // 表格选择
                tableProps.rowSelection = rowSelection
            }
            // 点击行回调函数
            tableProps.onRow = this.onRow.bind(this)
        } else {
            // 表格总宽度减去复选框列的宽度
            scrollx -= 60
        }
        // 子模块不分页
        if (this.isSub) {
            tableProps.pagination = false
        }
        // 嵌套表格间隔色反向
        if (mode === nestedsublist) {
            tableProps.rowClassName = (record, index) => `tableRow-${index % 2 - 1}`
        }
        // 手机端或嵌套表格添加表格展开渲染
        if (global.isPhoneVertical || this.hasNestedSublist) {
            tableProps.expandedRowRender = this.expandedRowRender.bind(this)
        }
        // 嵌套表格添加展开所有行参数
        if (this.hasNestedSublist && sublistMode) {
            tableProps.defaultExpandAllRows = true
        }
        // 手机端添加 控制展开key参数
        if (global.isPhoneVertical) {
            // 点击行回调函数
            tableProps.onRow = this.onRow.bind(this)
            tableProps.onExpandedRowsChange = expandedRowKeys => {
                utils.storeData(this, this.pageKey, {expandedRowKeys: expandedRowKeys})
            }
            tableProps.expandedRowKeys = this.state.expandedRowKeys || []
            // 手机端表格只有垂直方向滚动参数
            tableProps.scroll = {y: tableContentHeight}
        } else {
            // 电脑或ipad等大屏设备有水平和垂直方向参数
            tableProps.scroll = {x: scrollx, y: tableContentHeight}
        }

        // 表格不需要水平滚动条
        if (widthEnough) {
            delete tableProps.scroll.x
        }
        // 表格记录较少时不需要垂直滚动条
        if (!global.isPhoneVertical && list.length <= 5) {
            delete tableProps.scroll.y
        }
        // 选中一条记录
        const isSelectedOne = !isSelect && this.isSelectedOne && this.selectedRow
        // render begin
        return <div>
            {!hideToolbar && <div style={{marginBottom: 16, marginTop: 16}}>
                <span>
                    {operAdd && 
                    // 非禁用状态或禁用显示新增
                    (showEditButtons || disabledShowModify) &&
                    // 子模块独立存在隐藏新增
                    !this.sublistIndependent && 
                    <span>
                        {(newModal || config.modaledit) ? 
                            this.newDiv(this.state.newData, modal) :
                            createButton('plus', '新增', this.onNew.bind(this), {type: 'primary'})
                        }
                    </span>
                    }
                    {showEditButtons && 
                    !isSelect && 
                    this.tableCanEdit && 
                    !this.sublistIndependent &&
                    !global.isPhoneVertical &&
                    this.showbatchset && createButton('copy', '批录', 
                        () => this.table.batchSet(this.state.selectedRowKeys))
                    }
                    {showEditButtons && 
                    !this.isSub && 
                    !isSelect && 
                    this.tableCanEdit && 
                    !this.sublistIndependent && createButton('save', '保存', 
                        async () => {
                            const getApiArray = async (apiArray) => 
                                await this.batchSave(apiArray)
                            await callApiBatch(getApiArray, '保存成功')
                        }, {type: 'primary'})
                    }
                    {(showEditButtons || disabledShowModify) && 
                    !isSelect && 
                    !this.sublistIndependent && 
                    !this.cardType &&
                    operDelete && createButton('trash', '删除', 
                        async () => {
                            const getApiArray = async (apiArray) => 
                                await this.batchDelete(apiArray)
                            await callApiBatch(getApiArray, '批量删除成功')
                        }, {type: 'danger', needConfirm: true, title: '确定要批量删除数据吗？'})
                    }
                    {isSelectedOne && operView && createButton('eye', '查看', 
                        this.toDetail.bind(this, this.selectedRow.id, this.selectedRow))
                    }
                    {isSelectedOne && operAdd && cancopy && createButton('copy', 
                        `复用【${utils.getTitle(this.selectedRow)}】`,
                        this.onCopy.bind(this, this.selectedRow.id))
                    }
                    {
                        // 数据传递按钮
                        !global.isPhoneVertical && transferButtons
                    }
                </span>
                {
                    // 父组件注入按钮组, 非编辑状态也显示
                    !global.isPhoneVertical && !isSelect && buttons
                }
                {
                    // 业务编排按钮组, 非编辑状态也显示
                    !global.isPhoneVertical && !isSelect && buttonList
                }
                <span>
                    {!isSelect && 
                    moreOpers.length > 0 &&
                        <Dropdown trigger={global.trigger} overlay={<Menu>{moreOpers}</Menu>}>
                            {createButton('ellipsis-h', '更多')}
                        </Dropdown>
                    }
                </span>
                {selectMulti && createButton('hand-pointer', '批量添加', 
                    () => {
                        if (this.state.selectedRows.length === 0) {
                            message.info("请选择数据")
                            return
                        }
                        onSelect && onSelect(this.state.selectedRows)
                        utils.storeData(this, this.pageKey, {
                            selectedRows: [], selectedRowKeys: []
                        })
                    }, {type: 'primary'})
                }
                {!hideSearch && <Search
                    searchFields={searchFields}
                    fieldsConfig={fieldsConfig}
                    fixedSearchConds={fixedSearchConds}
                    onSearch={this.onSearch.bind(this)}
                />}
            </div>}
            {this.cardType ? 
            <div className="infinite-container">
                <InfiniteScroll
                    initialLoad={false}
                    pageStart={0}
                    loadMore={this.handleInfiniteOnLoad.bind(this)}
                    hasMore={list.length < totalElements}
                    useWindow={false}
                >
                    <List
                        grid={{ gutter: 8, column: 2 }}
                        dataSource={list}
                        renderItem={item => (
                            <List.Item onClick={this.toDetail.bind(this, item.id, item)}>
                                <Card 
                                    style={{width: cardWidth}}
                                    cover={<Image disabled width={cardWidth} value={item.cover} />}
                                >
                                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                        <div><span>{item.name}</span></div>
                                        {/*<div><span>{item.total + '张'}</span></div> */}
                                    </div>
                                </Card>
                            </List.Item>
                        )}
                    />
                </InfiniteScroll>
            </div> 
            : 
            <div style={{ display: 'flex', width: '100%' }}>
                {this.hasClass && !this.state.folded && !global.isPhoneVertical &&
                <div style={{
                    width: classWidth,
                    flexShrink: 0,
                    marginRight: 4,
                    marginTop: 18,
                    height: treeHeight,
                    overflow: 'auto',
                    whiteSpace: 'nowrap'
                }}>
                    {treeDiv}
                </div>}
                <div style={{flex: 1, minWidth: 0}}>
                    <div style={{marginTop: 0}}>
                        {this.hasClass && 
                        <A onClick={() => { utils.storeData(this, this.pageKey, { folded: !this.state.folded }) }}>
                            <Icon type={toggermenustyle} style={{ fontSize: 24, marginRight: 32 }} />
                        </A>}
                    </div>
                    <TableEx
                        {...tableProps}
                        components={{
                            header: {
                                cell: ResizeableTitle
                            }
                        }}
                        ref={(inst) => this.table = inst}
                    />
                </div>
            </div>}
            {global.isPhoneVertical && <ModalEx visible={!this.state.folded}
                title={null}
                onClose={() => utils.storeData(this, this.pageKey, {folded: true})}
            >{treeDiv}</ModalEx>}
        </div>
    }
}

ListModule.propTypes = {
    // 模块配置 详情见 src/modules/module_config/Config
    config: PropTypes.object, 
    // 模块名称
    moduleName: PropTypes.string, 
    // 父模块ID
    fid: PropTypes.string, 
    // 创建表单时的列数
    cols: PropTypes.number,
    // 查询url携带参数
    searchUrlParams: PropTypes.bool,
    // 点击行任意位置选中行
    onRowClickSelect: PropTypes.bool,
    // 选中行key数组
    selectedRowKeys: PropTypes.array,
    // 选择行变化回调
    // onSelectChange(selectedRowKeys, selectedRows)
    onSelectChange: PropTypes.func,
    // 单选
    selectOne: PropTypes.bool,
    // 作为数据传递组件选中数据后回调函数
    // onSelect(record)
    onSelect: PropTypes.func,
    /**
     * 注入更多操作
     * moreOptions 操作数组 
     * text id
     * record 行数据 
     * this 传递 当前组件 this引用
     * moreOperation(moreOptions, text, record, this)
     */
    moreOperation: PropTypes.func,
    // 工具栏注入父组件按钮组, 非编辑状态也显示
    buttons: PropTypes.array,
    // 父组件页面，新增或详情页面
    action: PropTypes.string,
    // 复用新增传值， 新增页面下的子模块，action === 'new'
    list: PropTypes.array,
    /**
     * 复用新增前注入父组件复用逻辑
     * async beforeCopyrecord) : void
     */
    beforeCopy: PropTypes.func,
    // 父组件页面，新增或详情页面数据
    fRecord: PropTypes.object,
    /**************************************************************
     * 作为子模块参数                                              *
     *************************************************************/
    // 表格数据不能为空
    notEmpty: PropTypes.bool,
    // 禁止编辑时子列表可修改
    disabledShowModify: PropTypes.bool,
    // 隐藏工具类
    hideToolbar: PropTypes.bool,
    /**
     * 页面展示模式
     * normal: 默认模式，路由
     * inner: 表格行内嵌入新增、详情
     * modal: 弹窗
     * nestedsublist： 嵌入子模块，不显示表单基本信息
     */
    mode: PropTypes.string,
    /**
     * 数据传递查询条件
     */
    fixedSearchConds: PropTypes.object
}