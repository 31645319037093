/**
 * 详情页面
 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
// 取消路由拦截， 影响用户体验
//import { Prompt } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroller'
import Menu from 'antd/lib/menu'
import Dropdown from 'antd/lib/dropdown'
import List from 'antd/lib/list'
import Affix from 'antd/lib/affix'
import Image from '@com/Image'
import UploadImage from '@com/UploadImage'
import Icon from '@com/Icon'
import Fields from './Fields'
import ModalEx from '@com/ModalEx'
import Popover from 'antd/lib/popover'
import moment from 'moment'
import * as Action from './Action'
import * as PlatformAction from '../../extension/PlatformAction'
import { getByBusinessIdAndPagePromise, getFileByIdPromise } from '../../modules/file/Action'
import { processOnePromise} from '../workflow/Action'
import { callApiBatch, callModifyByApiBatch, getUpdateApiItem, getApiItem } from '../layout/Action'
import * as dataUtil from '@utils/DataUtil'
import * as utils from '@utils/index'
import { deleteFilesByBusinessId } from '../file/Action'
import {flowDetail, showFlowGraph} from '../workflow/FlowUtil'
import * as p from '@utils/page'
import {inner, modal, nestedsublist} from '@utils/page'
import {forms} from '@utils/object'
import { createButton } from '@com/PageCreator'
import c from '@utils/constants'
import { beforeSave, canDelete } from './specialModule'

const { apiBizflow, processStart, processPass, processReject, processCancel, del } = c

export default class Detail extends Component {
    /**
     * 使用全局变量保存页面状态
     */
    constructor(props) {
        super()
        this.state = {
            data: {},
            editing: false,
            showModal: false,
            page: 1,
            pageSize: 20,
            totalElements: 0,
            list: [],
            itemLoading: false,
            dayListMap: {},
            flowData: {},
            canOperFlow: false,
            canCancelFlow: false,
            buttonInfoList: [],
            fRecord: {},
        }
        // 路由上的id
        this.idOnRoute = utils.parseSearchStr().id
        //console.log('constructor----------------------------', props.id)
        this.hasFlow = false
    }

    /**
     * 组件加载时
     */
    async componentDidMount() {
        this.unmount = false
        await this.init(this.props)
    }
    componentWillUnmount() {
        this.unmount = true
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const {id, moduleName, config} = this.props
        if (
            // props.config 变化
            (nextProps.config && nextProps.config.modulename &&
                nextProps.config.modulename !== config.modulename) ||
            // props.moduleName 变化
            (nextProps.moduleName && nextProps.moduleName !== moduleName) ||
            // props.id 变化
            (nextProps.id && nextProps.id !== id)
        ) {
            this.init(nextProps)
        }
    }
    
    async init(props) {
        const {moduleName, config, data, mode, id, fRecord} = props
        if (utils.isBlank(moduleName) || 
            config == null || utils.isBlank(config.modulename)
        ) {
            return
        }
        //console.log('init pageKey', this.pageKey)
        this.moduleName = moduleName
        // 组件参数传递的id
        const propsId = id
        // 优先取组件参数上的id，如果不存在再取路由上的id
        this.id = propsId || this.idOnRoute
        this.path = '/' + moduleName + '/detail?id=' + this.id
        if (utils.isBlank(this.id)) {
            return
        }
        const _fid = ((fRecord && fRecord.id) ? '_' + fRecord.id : '')
        this.pageKey = moduleName + '_' + this.id + _fid
        
        // 禁用编辑条件
        this.disableEditConds = utils.getConfigByModuleName(this.moduleName).disableEditConds
        // 父模块名称
        this.fModuleName = config.fmodule
        //console.log('this.fModuleName modulename', this.fModuleName, config.modulename)
        // 模块编辑模式为 直接编辑或永远编辑
        this.tableCanEdit = ["directEdit", "alwaysEdit"].indexOf(config.edittype) !== -1
        // 模块编辑模式为 直接编辑或永远编辑，且有父模块，即作为子模块情况下，详情页面不可编辑
        this.disabled = this.tableCanEdit && utils.isNotBlank(this.fModuleName)
        if ([inner, modal, nestedsublist].indexOf(mode) !== -1) {
            if (data) {
                utils.storeData(this, this.pageKey, {data: data})
                if (data.fid) {
                    await this.getFRecord(data.fid)
                }
            }
        } else {
            await this.reload(props)
        }
        // 业务编排按钮获取
        const buttonInfoList = await dataUtil.getOrchestrateButton(this.moduleName) || []
        utils.storeData(this, this.pageKey, {buttonInfoList})
    }

    async getFRecord(fid) {
        if (this.props.fRecord) {
            utils.storeData(this, this.pageKey, {fRecord: this.props.fRecord})
            return
        }
        // 查询父模块数据
        if (global.hasfid(fid)) {
            global.notip = true
            const fRecord = await Action.getOnePromise(this.fModuleName, fid)
            global.notip = false
            if (utils.equals(fRecord, {})) {
                fRecord.id = this.fid
            }
            utils.storeData(this, this.pageKey, {fRecord})
        }
    }

    /**
     * 页面初始化
     */
    async reload(props = this.props) {
        const {mode, data} = props
        if (mode === inner || mode === nestedsublist) {
            if (!utils.equals(data, this.state.data)) {
                utils.storeData(this, this.pageKey, {data})
                return
            }
        }
        //console.log('reload', props)
        p.startLoad()
        if (mode !== modal) {
            await this.getData(props)
        }
        
        if (this.cardType) {
            await this.cardTypeRefresh(1, true)
        }
        p.stopLoad()
    }

    /**
     * oows 收到消息后，后台主动刷新页面
     * @param {*} clientId 修改数据的客户端id
     * @returns 
     */
    async refreshPage(clientId) {
        const activeTabKey = p.getActiveTabKey()
        // console.log('refreshPage=================================')
        // console.log('activeTabKey, this.path', activeTabKey, this.path)
        // console.log('clientId', clientId)
        // console.log('global.clientId',global.clientId)
        if (
            // 且当前页面处于激活状态
            activeTabKey === this.path && 
            // 且修改数据的客户端id不是当前客户端id
            clientId !== global.clientId
        ) {
            utils.info('你操作的页面已经被他人修改, 即将更新。', '', this.reload.bind(this))
            return true
        }
        await this.reload()
        await dataUtil.refreshSublist(this.fields)
        return true
    }
    /**
     * oows 收到删除消息后，后台主动推送页面已删除消息
     * @param {*} clientId 修改数据的客户端id
     * @returns 
     */
    deletePage(clientId) {
		const activeTabKey = p.getActiveTabKey()
        if (// 当前页面处于激活状态
            activeTabKey === this.path && 
            // 且修改数据的客户端id不是当前客户端id
            clientId !== global.clientId
        ) {
            utils.warning('你操作的页面已经被他人删除', '', p.closepage)
        }
    }

    /**
     * 获取流程数据
     */
    async getProcessData() {
        const flowData = await processOnePromise({id: this.id})
        if (flowData.status === 'end') {
            utils.storeData(this, this.pageKey, { flowData, canOperFlow: false, canCancelFlow: false })
            return
        }
        // 当前用户编码
        let code = global.emp.code || 'admin'
        // 是否可以撤销流程
        let canCancelFlow = flowData.status !== 'end' &&
            flowData.passedNodes instanceof Array && 
            flowData.passedNodes.length >= 1 && 
            flowData.passedNodes[0].code === code
        // 是否能审批流程
        let canOperFlow = false
        // 流程处理人列表包含当前用户
        //console.log('code', code)
        if (flowData.next.nextUsers.indexOf(code) !== -1) {
            // 如果是会签，passedUsers 当前用户不在已处理人列表中
            if (flowData.next.会签) {
                if(!(flowData.next.passedUsers instanceof Array) || undefined === flowData.next.passedUsers.find(code)) {
                    canOperFlow = true
                }
            } else {
                canOperFlow = true
            }
        }
        // 驳回情况
        if (flowData.next.nodeId === 'nodeStart') {
            canOperFlow = false
        }
        utils.storeData(this, this.pageKey, { flowData, canOperFlow, canCancelFlow })
    }

    /**
     * 获取页面数据
     */
    async getData(props = this.props) {
        let action = Action
        if (props.platform) {
            action = PlatformAction
        }
        const data = await action.getOnePromise(this.moduleName, this.id)
        utils.storeData(this, this.pageKey, { data })
        await this.getFRecord(data.fid)
        //console.log(data, 'data')
        // 相册封面
        const id = data.cover
        //console.log('id', id)
        if (id) {
            console.log('data.cover', id)
            const file = await getFileByIdPromise(id)
            if (file && file.filePath) {
                let imageUrl = global.getApi().opts.baseURI + file.filePath
                utils.storeData(this, this.pageKey, {
                    imageUrl
                })
            }
        }
        // 获取流程数据
        this.hasFlow = props.config.hasflow  && 
            data.flowstatus && 
            data.flowstatus !== "editing"
        if (this.hasFlow) {
            await this.getProcessData()
        } else {
            utils.storeData(this, this.pageKey, {flowData: {}, canCancelFlow: false, canOperFlow: false})
        }
    }

    getRecord() {
        if (dataUtil.formError(this.fields)) return null
        let record = this.fields.getFieldsValue()
        // 转换表单数据
        return dataUtil.convertData(this.props.config, record, this.state.data)
    }

    /**
     * 请求结束，取消编辑，关闭对话框
     */
    finish() {
        utils.storeData(this, this.pageKey, {editing: false, showModal: false})
    }

    /**
     * 确定
     */
    async onOk(apiArray, isCommit, onCustom) {
        let record = this.getRecord()
        if (record === null) {
            return false
        }
        // 数据校验
        if (!dataUtil.dataCheck(this.props.config, record)) {
            return false
        }

        const {mode, isSub} = this.props

        // 保存前注入是否能保存逻辑
        if (await beforeSave(this.moduleName, apiArray, record, this.state.data) === false) {
            return false
        }
        // 保存子模块
        if (!await dataUtil.saveSublist(apiArray, this.fields)) {
            return false
        }

        // 手机端子模块明细或嵌入子模块修改由父组件整体保存
        if ((mode === inner && isSub) || mode === nestedsublist) {
            this.dataChange(Object.assign({}, this.state.data, record))
            this.finish()
            return true
        }
        
        const data = this.getProcessStartData()
        if (isCommit === true) {
            const success = (newData) => {
                this.dataChange(newData)
                this.finish()
            }
            record.flowstatus = "reviewing"
            if (apiArray instanceof Array) {
                apiArray.push(getApiItem(processStart, this.moduleName, [data]))
                apiArray.push(getUpdateApiItem(this.moduleName, this.id, record, success))
            }
        } else {
            const success = (newData) => {
                this.dataChange(newData)
                if (onCustom !== true) {
                    this.finish()
                }
            }

            if (apiArray instanceof Array) {
                apiArray.push(getUpdateApiItem(this.moduleName, this.id, record, success))
            }
        }
        return true
    }

    /**
     * 提交
     */
    async commit(apiArray) {
        const data = this.getProcessStartData()
        const success = (newData) => {
            this.dataChange(newData)
            this.finish()
        }
        const error = () => this.finish()
        const record = {flowstatus: "reviewing"}
        if (apiArray instanceof Array) {
            apiArray.push(getApiItem(processStart, this.moduleName, [data]))
            apiArray.push(getUpdateApiItem(this.moduleName, this.id, record, success, error))
        }
        return true
    }

    /**
     * 获取流程开始数据
     * @returns 
     */
    getProcessStartData() {
        return {
            id: this.id,            // 业务记录id
            code: this.state.data.code,     // 业务记录编码
            name: this.state.data.name,     // 业务记录名称
            moduleName: this.moduleName,    // 业务模块名称
        }
    }

    /**
     * 改为关闭当前页面
     * 返回按钮响应函数
     */
    onGoBack() {
        const {mode, close} = this.props
        if (mode === inner || modal === nestedsublist) {
            close && close()
            return
        }
        if (mode === modal) {
            this.finish()
            return
        }
        p.closepage()
    }
    async handleInfiniteOnLoad() {
        let { page } = this.state
        await this.cardTypeRefresh(page + 1, false)
    }

    async cardTypeRefresh(page, reload, updateTotal) {
        let { pageSize } = this.state
        if (reload) {
            page = 1
        }
        utils.storeData(this, this.pageKey, {itemLoading: true})
        const body = await getByBusinessIdAndPagePromise({
            businessId: this.id, 
            page: page, 
            pageSize
        })
        const {totalElements, list} = body
        const listAll = (page === 1 ? list : this.state.list.concat(list))
        const dayListMap = {}
        let currentYear = moment().year()
        for (let item of listAll) {
            let createtime = moment(item.createtime)
            let day = createtime.format('YYYY/MM/DD')
            if (currentYear === createtime.year()) {
                day = createtime.format('MM/DD')
            }
            if (dayListMap[day] === undefined) {
                dayListMap[day] = [item]
            } else {
                dayListMap[day].push(item)
            }
        }
        utils.storeData(this, this.pageKey, {page: page, totalElements,
            list: listAll,
            dayListMap: dayListMap,
            itemLoading: false
        })
        if (updateTotal) {
            const success = (newData) => this.dataChange(newData)
            await callModifyByApiBatch(this.moduleName, this.id, {total: totalElements}, success, null, true)
        }
    }

    /**
     * 文件上传成功，刷新并更新相册总数
     */
    async doneCallback() {
        await this.cardTypeRefresh(1, true, true)
    }

    async flowPass(apiArray) {
        let id = this.state.flowData.id
        const success = async (newData) => {
            this.dataChange(newData)
        }
        if (apiArray instanceof Array) {
            apiArray.push(getApiItem(processPass, this.moduleName, [{id}], success))
        }
        return true
    }

    async flowReject(apiArray) {
        let id = this.state.flowData.id
        const success = async (newData) => {
            this.dataChange(newData)
        }
        if (apiArray instanceof Array) {
            apiArray.push(getApiItem(processReject, this.moduleName, [{id}], success))
        }
        return true
    }

    async cancelFlow(apiArray) {
        const id = this.state.flowData.id
        const success = async (newData) => {
            this.dataChange(newData)
        }
        if (apiArray instanceof Array) {
            apiArray.push(getApiItem(processCancel, this.moduleName, [id], success))
        }
        return true
    }

    async onCustomClick(apiArray, buttonInfo) {
        let record = this.state.data
        Object.assign(record, this.getRecord())
        if (this.state.editing && !buttonInfo.read) {
            let saveRet = await this.onOk(apiArray, false, true)
            if (!saveRet) {
                return false
            }
        }

        let success = (res) => {
            dataUtil.pageBizFlowSuccess(buttonInfo, res, this.fields)
            this.finish()
        }
        if (buttonInfo.read) {
            success = (res) => {
                dataUtil.pageBizFlowSuccess(buttonInfo, res, this.fields)
            }
        }
        
        if (apiArray instanceof Array) {
            // 业务编排记录绑定子模块
            dataUtil.bizflowRecordBindSublist(buttonInfo, record, this.fields)
            const args = [buttonInfo.id, record]
            apiArray.push(getApiItem(apiBizflow, this.moduleName, args, success))
        }
        return true
    }

    async deleteAction() {
        const getApiArray = async (apiArray) => await this.onDelete(apiArray)
        await callApiBatch(getApiArray, '删除成功')
    }

    /**
     * 删除操作
     */
    async onDelete(apiArray) {
        const {mode, isSub, config, onDataDelete} = this.props
        const record = this.state.data
        // 删除前注入是否能删除逻辑
        if (!await canDelete(this.moduleName, apiArray, record)) {
            return false
        }
        
        const success = () => {
            config.hasattachment  && deleteFilesByBusinessId(record.id)
            if (mode === modal) {
                this.finish()
                return
            }
            if (mode == null) {
                p.closepage()
            }
        }
        if (apiArray instanceof Array) {
            await dataUtil.delSubList(apiArray, [record.id], config)
            apiArray.push(getApiItem(del, this.moduleName, [record.id], success))
        }

        // 手机端子模块明细或嵌入子模块修改由父组件整体保存
        if ((mode === inner && isSub) || mode === nestedsublist) {
            onDataDelete && onDataDelete()
            return false
        }
        return true
    }

    /**
     * 内部显示，数据修改回调
     */
    dataChange(data) {
        const {onDataChange} = this.props
        onDataChange && onDataChange(data)
    }

    async onOkWrapper() {
        const getApiArray = async (apiArray) => await this.onOk(apiArray)
        await callApiBatch(getApiArray, '保存成功')
    }

    /**
     * 重新渲染页面
     */
    reRender() {
        this.setState({update: !this.state.update})
    }

    /**
     * 渲染函数
     */
    render() {
        //console.log('Detail render')
        const {showModal, fRecord} = this.state
        const {config, moduleName, mode, isSub, readonly} = this.props
        let { action } = this.props
        // 渲染阻断
        if (utils.isBlank(moduleName) || 
            config == null || utils.isBlank(config.modulename) ||
            utils.isBlank(this.id)
        ) {
            return mode === modal ? <span/> : <div/>
        }
        const {
            // 可修改
            canedit, 
            // 可删除
            candelete,
            showtype,
        } = config
        this.cardType = showtype === "card"
        this.formType = !this.cardType
    
        // 权限控制
        let moduleOpers = (global.role && global.role.moduleOpers) || []
        const adminOper = global.isAdmin || moduleOpers.indexOf(`${this.moduleName}_oau`) !== -1
        const operUpdate = canedit && (adminOper || moduleOpers.indexOf(`${this.moduleName}_ou`) !== -1)
        const operDelete = candelete && (adminOper || moduleOpers.indexOf(`${this.moduleName}_od`) !== -1)
        const {canOperFlow, canCancelFlow} = this.state
        const editing = !readonly && (mode === modal || 
            this.state.editing || 
            action === 'modify')
        const data = this.state.data
        const tableConfig = config.fields_config || []
        // 手机端子模块明细或嵌入子模块不显示流程和业务编排按钮
        let innerSub = (mode === inner && isSub) || mode === nestedsublist
        // 组装数据父列默认传递的字段段映射
        const fModuleTransferMap = {}
        tableConfig.forEach(config => {
            if (dataUtil.isFFieldType(config.dataType) && config.isDisabled === '1') {
                const otherParams = config.otherParams || {}
                const fmoduleFieldName = otherParams.name
                if (utils.isNotBlank(fmoduleFieldName)) {
                    fModuleTransferMap[config.name] = fmoduleFieldName
                }
            }
        })
        // 初始化父列默认类型数据
        const fModuleTransferData = dataUtil.getTransferData(this.moduleName, this.fModuleName, fRecord, fModuleTransferMap)
        Object.assign(data, fModuleTransferData)
        let editStatus = !data.flowstatus || data.flowstatus === "editing"
        let showCommit = config.hasflow 
        const closeOper = createButton('arrow-left', '关闭', this.onGoBack.bind(this))
        // 业务编排按钮
        let buttonList = this.state.buttonInfoList.filter((button) => {
            // 禁用条件
            let disable = dataUtil.condsValue(button.disableEditConds, data)
            // 权限控制 
            const operName = `${this.moduleName}_${button.name}`
            const operAuth = adminOper || moduleOpers.indexOf(operName) !== -1
            if (button.read && !this.state.editing) {
                return false
            }
            return !disable && operAuth
        }).map((button) => {
            return createButton(button.icon, button.name, 
                async () => {
                    const getApiArray = async (apiArray) => await this.onCustomClick(apiArray, button)
                    await callApiBatch(getApiArray, `${button.name}成功`)
                }, {key: button.id, showName: true, type: 'primary', ghost: true})
            }
        )
        // 禁用编辑
        let disableEdit = dataUtil.condsValue(this.disableEditConds, data)

        // 流程按钮
        const flowButtons = []
        if (canCancelFlow || (adminOper && this.hasFlow)) {
            flowButtons.push(createButton('close', '撤销', async () => {
                const getApiArray = async (apiArray) => await this.cancelFlow(apiArray)
                await callApiBatch(getApiArray, '撤销成功')
            }, {key: flowButtons.length, showName: true}))
        }
        if (showCommit && !editStatus) {
            flowButtons.push(createButton('file-text', '流程详情', 
                flowDetail.bind(this, this.state.flowData),
                {key: flowButtons.length, showName: true})
            )
        }
        if (showCommit && !editStatus) {
            flowButtons.push(createButton('random', '流程图', 
                showFlowGraph.bind(this, this.state.flowData),
                {key: flowButtons.length, showName: true})
            )
        }
        if (canOperFlow) {
            flowButtons.push(createButton('step-forward', '审批通过', async () => {
                const getApiArray = async (apiArray) => await this.flowPass(apiArray)
                await callApiBatch(getApiArray, '审批成功')
            }, {key: flowButtons.length, showName: true}))
        }
        if (canOperFlow) {
            flowButtons.push(createButton('times', '驳回', async () => {
                const getApiArray = async (apiArray) => await this.flowReject(apiArray)
                await callApiBatch(getApiArray, '驳回成功')
            }, {key: flowButtons.length, showName: true}))
        }
        buttonList = flowButtons.concat(buttonList)
        // 子模块的详情页不显示按钮组
        let buttons = !isSub && 
        <div>
            {mode !== modal && closeOper}
            {
            // 卡片模式， 目前只有相册使用
            this.cardType && 
            <UploadImage businessId={this.id} doneCallback={this.doneCallback.bind(this)} >
                {createButton('plus', '上传相片', null, {type: 'primary', ghost: true})}
            </UploadImage>}
            {(
                // 正常修改权限
                (operUpdate && editStatus && !disableEdit && !editing && this.formType) || 
                // 管理员修改
                (adminOper && !editing)
            ) && 
                createButton('edit', '修改', () => utils.storeData(this, this.pageKey, {editing: true}), {type: 'primary'})
            }
            {editing && createButton('save', '保存', this.onOkWrapper.bind(this), {type: 'primary'})}
            {editing && createButton('close', '取消', () => {
                this.fields.resetFields()
                if (action === 'modify' && (mode === inner || mode === nestedsublist)) {
                    this.onGoBack()
                } else {
                    this.finish()
                }
            })}
            {operDelete && editStatus && !disableEdit && 
            createButton('trash', '删除', this.deleteAction.bind(this), {
                type: this.cardType ? 'primary' : 'danger', 
                ghost: this.cardType,
                needConfirm: true, 
                title: this.cardType ? `确定要删除${dataUtil.showModuleName(this.moduleName)}吗？` : 
                '确定要删除这条数据吗？'
            })}
            {!innerSub && editing && showCommit && editStatus && createButton('arrow-up', '提交',
                async () => {
                    const getApiArray = async (apiArray) => await this.onOk(apiArray, true)
                    await callApiBatch(getApiArray, '提交成功')
                }, {type: 'primary', needConfirm: true, title: '确定要提交吗？' })
            }
            {!innerSub && !editing && showCommit && editStatus && createButton('arrow-up', '提交',
                async () => {
                    const getApiArray = async (apiArray) => await this.commit(apiArray)
                    await callApiBatch(getApiArray, '提交成功')
                }, {type: 'primary', needConfirm: true, title: '确定要提交吗？' })
            }
            {!innerSub && buttonList.length > 0 && 
            (mode !== inner ? 
                buttonList 
                : 
                <Dropdown trigger={global.trigger} 
                    overlay={<Menu>{
                        buttonList.map((button, index) => <Menu.Item key={index} >{button}</Menu.Item>)
                    }</Menu>}
                >{createButton('ellipsis-h', '更多')}</Dropdown>
            )}
        </div>
        // 非弹窗禁用编辑时只显示关闭按钮
        if (this.disabled && mode !== modal) {
            buttons = closeOper
        }
        let buttonDiv = (mode === inner || mode === nestedsublist) ? buttons :
        (buttons && <Affix offsetTop={0} style={{height: 40}} target={() => global.layoutContainerInst}>
            <div style={{paddingTop: 8, backgroundColor: '#fff'}}>{buttons}</div>
        </Affix>)
        if (mode === nestedsublist || readonly) {
            buttonDiv = <div/>
        }
        const {list, dayListMap, totalElements} = this.state
        let cardWidth = (document.body.clientWidth - 16) / 3
        let images = []
        for (let key in dayListMap) {
            let dayImage = <div key={key} style={{margin: 8}}>
                <div style={{marginBottom: 8}}><span style={{fontSize: 16, fontWeight: 900}}>{key}</span></div>
                <List
                    grid={{ gutter: 0, column: 3}}
                    dataSource={dayListMap[key]}
                    renderItem={item => (
                        <List.Item>
                            <Image width={cardWidth} value={item.id}/>
                        </List.Item>
                    )}
                />
            </div>
            images.push(dayImage)
        }

        action = editing ? 'modify': (action || 'detail')
        if (mode === modal) {
            action = 'modify'
        }
        if (readonly) {
            action = 'detail'
        }
        //console.log('render', data)
        let fieldsDiv = <Fields 
            {...this.props}
            ref={(inst) => {
                this.fields = inst
                forms[this.moduleName] = inst
                forms[this.id] = inst
            }} 
            action={action}
            data={data}
            fRecord={fRecord}
            id={this.id}
        />
        let operName = '编辑'
        if (readonly || action === 'detail' || this.disabled) {
            operName = '查看'
        }
        let modalView = <ModalEx
            refreshPage={this.refreshPage.bind(this)}
            title={`${operName}${dataUtil.showModuleName(this.moduleName)}`}
            visible={showModal}
            onCancel={() => utils.storeData(this, this.pageKey, {showModal: false})}
            onClose={() => utils.storeData(this, this.pageKey, {showModal: false})}
            maskClosable={true}
            width={global.modalWidth}
            onOk={readonly ? null : this.onOkWrapper.bind(this)}
        >
            <div>
                {buttonDiv}
                { data && showModal && fieldsDiv }
            </div>
        </ModalEx>
        if (this.cardType) {
            return <div className="item-infinite-container">
                {buttonDiv}
                <div onClick={() => utils.storeData(this, this.pageKey, {showModal: true})} 
                    style={{width: '100%', height: 240,
                    backgroundSize: 'cover', // contain  cover
                    backgroundImage: `url("${this.state.imageUrl}")`,
                    backgroundPosition: 'top center',
                    opacity: 0.6
                }}>
                    <div style={{height: 240, display: 'flex', 
                        justifyContent: 'center', alignItems: 'center',
                    }}>
                        <span style={{color: "#000", fontSize: 22, fontWeight: 900}}>{data.name}</span>
                    </div>
                </div>
                <InfiniteScroll
                    initialLoad={false}
                    pageStart={0}
                    loadMore={this.handleInfiniteOnLoad.bind(this)}
                    hasMore={!this.state.itemLoading && list.length < totalElements}
                    useWindow={false}
                >
                    {images}
                </InfiniteScroll>
                {showModal && modalView}
            </div>
        }
        if (this.formType && mode === modal) {
            // 弹窗模式
            return <nobr>
                <Popover content={<div> <span>{readonly ? '查看' : '修改'}</span> </div>} >
                    <Icon 
                        style={global.iconStyle} 
                        onClick={() => {
                            utils.storeData(this, this.pageKey, {showModal: true})
                        }}
                        type={readonly ? 'eye' : 'edit'}
                    />
                </Popover>
                {showModal && modalView}
            </nobr>
        }
        // 默认模式
        return <div>
            {buttonDiv}
            {data && fieldsDiv}
        </div>
    }
}
Detail.propTypes = {
    // 页面唯一标识
    id: PropTypes.string,
    // 父组传入数据
    data: PropTypes.object,
    // 父组件id
    fid: PropTypes.string,
    // 模块名称
    moduleName: PropTypes.string,
    // 模块配置
    config: PropTypes.object,
    /**
     * 页面展示模式
     * normal: 默认模式，路由
     * inner: 表格行内嵌入新增、详情
     * modal: 弹窗
     * nestedsublist： 嵌入子模块，不显示表单基本信息
     */
    mode: PropTypes.string,
    // 关闭当前页面回调函数
    close: PropTypes.func,
    // 父组件为子模块
    isSub: PropTypes.bool,
    // 嵌入页面保存回调函数，由父组件整体保存
    onDataChange: PropTypes.func,
}