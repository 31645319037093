import React, { Component } from 'react'
import ModalEx from '@com/ModalEx'
import Input from 'antd/lib/input'
import InputSelect from '@com/InputSelect'
import Button from 'antd/lib/button'
import Icon from '@com/Icon'
import Expression from '@com/Expression'
import A from '@com/A'

export default class GroupFieldExpression extends Component {
    constructor() {
        super()
        this.state = {
            dataSource: [],
            visible: false,
        }
    }

    componentDidMount() {
        this.setState({dataSource: this.props.dataSource || []})
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.dataSource !== nextProps.dataSource) {
            this.setState({dataSource: nextProps.dataSource || []})
        }
    }

    onCancel() {
        this.setState({visible: false})
    }

    render() {
        let obj = this.props.value || {}
        return <span>
            <A onClick={() => this.setState({visible: true})} style={this.props.style}>表达式</A>
            <ModalEx title='分组字段表达式编辑器' width={800} footer={[]} onCancel={this.onCancel.bind(this)} visible={this.state.visible} maskClosable>
                <div>
                    <h3>预览</h3>
                    <Input.TextArea value={obj.value && global.toString(obj.value)} autoSize={{ minRows: 5, maxRows: 5 }}/>
                    <div style={{height: 32}}></div>
                    <div>
                        <span style={{fontSize: 16, fontWeight: 800}}>表达式：</span>
                        <InputSelect style={{width: 200}} dataSource={this.state.dataSource} 
                        value={obj.value && global.toString(obj.value)} 
                        onChange={value => {
                            obj.value = value
                            this.props.onChange && this.props.onChange(obj)
                        }}/>
                        <Button onClick={() => this.setState({show: !this.state.show})}>
                            <Icon type={this.state.show ? 'caret-up' : "caret-down"}/>
                        </Button>
                    </div>
                    {this.state.show && 
                    <Expression dataSource={this.state.dataSource} value={obj} onChange={exprObj => {
                        if (exprObj && exprObj.expr) obj.value = exprObj.expr
                        this.props.onChange && this.props.onChange(obj)
                    }}/>}
                </div>
            </ModalEx>
        </span>
    }
}
