import React, { Component } from 'react'
import * as Action from './Action'
import {flowDetail, showFlowGraph} from './FlowUtil'
import {createIcon} from '@com/PageCreator'
import { callApiBatch, getApiItem } from '../layout/Action'
import ListModule from '@list/ListModule'
import {moduleConfig} from './Config'
import { processPass, processReject, flow } from '@utils/constants'

class Todo extends Component {

    async flowPass(apiArray, record) {
        apiArray.push(getApiItem(processPass, null, [{id: record.id}], 
            this.refresh.bind(this)
        ))
        return true
    }

    async flowReject(apiArray, record) {
        apiArray.push(getApiItem(processReject, null, [{id: record.id}], 
            this.refresh.bind(this)
        ))
        return true
    }

    iconOperation(text, record) {
        return <span key={record.id}>
            {createIcon('file-text', '流程详情', flowDetail.bind(this, record))}
            {createIcon('random', '流程图', showFlowGraph.bind(this, record))}
            {record.next.nodeId !== 'nodeStart' && createIcon('step-forward', '审批通过', async () => {
                const getApiArray = async (apiArray) => await this.flowPass(apiArray, record)
                await callApiBatch(getApiArray, '审批成功')
            })}
            {record.next.nodeId !== 'nodeStart' && createIcon('times', '驳回', async () => {
                const getApiArray = async (apiArray) => await this.flowReject(apiArray, record)
                await callApiBatch(getApiArray, '驳回成功')
            })}
        </span>
    }

    render() {
        return <ListModule 
            {...this.props}
            hideSelect
            getListPromise={Action.processTodo}
            moduleName={flow}
            config={moduleConfig}
            iconOperation={this.iconOperation.bind(this)}
        />
    }
}
export default Todo