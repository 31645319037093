import React, { Component } from 'react'
import Cascader from 'antd/lib/cascader'
import Popover from 'antd/lib/popover'
import * as utils from '@utils/index'

export default class CascaderPopover extends Component {
    constructor(props) {
        super()
        this.state = {
            name: null,
            classnames: [],
            classes: null,
        }
    }
    /**
     * 组件加载完成时
     */
    componentDidMount() {
        const {options, value} = this.props
        this.propsValue2State(value, options)
    }

    /**
     * props发生变化时
     * @param {*} nextProps 
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        const {value, options} = nextProps
        if (this.props.value !== value || !utils.equals(this.props.options, options)) {
            //console.log(value, 'value UNSAFE_componentWillReceiveProps')
            this.propsValue2State(value, options)
        }
    }

    propsValue2State(value, options) {
        value = value || {}
        let {name, classnames, classes} = value; 
        classes = classes || []
        classnames = classnames || []
        if (typeof classes === 'string') {
            classes = classes.split(',')
        }
        if (typeof classnames === 'string') {
            classnames = classnames.split(',').join(' / ')
        }
        this.setState({options, name, classnames, classes})
    }

    onChange(value) {
        if (value instanceof Array && value.length > 0) {
            let id = value[value.length - 1]
            let node = global.searchTreeNode(id, this.props.options, 'value')
            node.name = node.title
            node.id = id
            this.propsValue2State(node, this.props.options)
            if (id !== (this.props.value && this.props.value.id)) {
                this.props.onChange && this.props.onChange(node, node)
            }
        } else {
            this.propsValue2State(null, this.props.options)
            this.props.onChange && this.props.onChange(null)
        }
    }

    render() {
        const {name, classnames, classes} = this.state
        const {value, options, onChange, moduleName, disabled, ...restProps} = this.props
        return <Popover
            content={<div>
                <span>{classnames}</span>
            </div>}
        >
        {disabled ? 
            <span>{name}</span> 
            :
            <Cascader
                {...restProps}
                value={classes}
                options={options || []}
                onChange={this.onChange.bind(this)}
            />
        }</Popover>
    }
}
