import * as dataUtil from '@utils/DataUtil'
import * as utils from '@utils/index'

/**
 * 列特殊处理
 * @param {*} formField 
 * @param {*} props 
 * @param {*} row 
 */
export function specialItemProps(formField, props, row) {
    const {id} = formField
    const fmodule = utils.getConfigByModuleName(row.modulename).fmodule
    if (id === 'fcode') {
        // 'fcode', '父模块编码', 'FieldSelect'
        // 获取 moduleName
        props.moduleName = fmodule
    } else if (id === 'selectmodule') {
        props.onChange = value => {
            row.selectmodule = row.value = value
            row.name = `从${dataUtil.showModuleName(value)}中选`
            let config = utils.getModuleConfig(value)
            row.icon = config.icon
            if (config.sublist instanceof Array) {
                row.sublist = config.sublist.map(item => {
                    if (typeof item === 'string') {
                        return item
                    }
                    let moduleName = item.moduleName
                    let showModuleName = dataUtil.showModuleName(moduleName)
                    return {value: moduleName, label: showModuleName}
                })
            }
        }
    } else if (id === 'selectsublist') {
        props.onChange = value => {
            row.selectsublist = value
            if (utils.isNotBlank(row.value)) {
                row.isSub = true
            } else {
                row.isSub = false
            }
        }
    } else if (id === 'mapping') {
        // 数据传递
        const {selectmodule, selectsublist, modulename, sublistmapping} = row
        // 子模块映射不为空
        if (dataUtil.hasSublistMapping(row)) {
            props.config.dataType = 'MappingIncludeSublist'
            props.initData = () => {
                return {
                    leftModule: modulename, 
                    rightModule: selectmodule, 
                    sublistmapping: sublistmapping
                }
            }
        } else {
            props.config.dataType = 'Mapping'
            props.initData = () => {
                let leftConfig = utils.getModuleConfig(modulename)
                let rightConfig = utils.getModuleConfig(selectsublist || selectmodule)
                return {leftConfig, rightConfig}
            }
        }
    } else if (id === 'sublistmapping' || id === 'searchsublistmapping') {
        /**
         * 组件 Mapping
         * sublistmapping 数据传递关联子模块
         * searchsublistmapping 查询条件关联子模块
         */
        props.hide = () => {
            let leftModule = row.modulename
            let rightModule = row.selectmodule
            const leftModuleConfig = utils.getModuleConfig(leftModule)
            const rightModuleConfig = utils.getModuleConfig(rightModule)
            if (!(leftModuleConfig.sublist instanceof Array && leftModuleConfig.sublist.length > 0 &&
                rightModuleConfig.sublist instanceof Array && rightModuleConfig.sublist.length > 0)
            ) {
                return true
            }
            return false
        }
        // 子模块关联
        props.initData = () => {
            let leftModule = row.modulename
            let rightModule = row.selectmodule
            if (id === 'searchsublistmapping') {
                leftModule = row.selectmodule
                rightModule = row.modulename
            }
            const leftModuleConfig = utils.getModuleConfig(leftModule)
            const rightModuleConfig = utils.getModuleConfig(rightModule)
            if (!(leftModuleConfig.sublist instanceof Array && leftModuleConfig.sublist.length > 0 &&
                rightModuleConfig.sublist instanceof Array && rightModuleConfig.sublist.length > 0)
            ) {
                return null
            }
            const getSublist = (sublist, children) => {
                sublist = sublist || []
                sublist.forEach(it => {
                    children.push({name: it.moduleName, alias: it.alias})
                    getSublist(utils.getModuleConfig(it.moduleName).sublist, children)
                })

            }
            const leftChildren = []
            getSublist(leftModuleConfig.sublist, leftChildren)
            const leftConfig = {
                modulename: leftModule,
                alias: leftModuleConfig.alias,
                fields_config: leftChildren
            }
            const rightChildren = []
            getSublist(rightModuleConfig.sublist, rightChildren)
            const rightConfig = {
                modulename: rightModule,
                alias: rightModuleConfig.alias,
                fields_config: rightChildren
            }
            return {leftConfig, rightConfig}
        }
    } else if (id === 'searchmapping') {
        // 查询条件
        /**
         * 查询条件映射
         * 1. 默认情况，选择的模块或选择模块的子模块
         * 左边为选择模块或选择模块的子模块， 数据传递的数据来源
         * 右边为传递目标模块的父模块， 数据传递查询条件的数据来源
         * 分两种情况
         *   1.1 目标模块作为子模块时，使用模块数据传递组件 ModuleDataTransfer， 可多选
         * 在父模块的新增或修改页面，将选中模块的数据添加到目标模块的列表页面
         *   1.2 目标模块独立存在时，使用模块数据选择组件 ModuleDataSelect， 只能单选
         * 在目标模块的新增或修改页面， 模块数据选择组件选中时，将数据传递到表单其他字段
         * ----------------------------------------------------------------------------
         * 2. 选择的模块有子模块，在传递选择模块的数据时，同时传递子模块数据
         * 只有目标模块独立存在这一种情况，才可以进行子模块传递
         * 左边为选择模块以及选择模块的子模块， 数据传递的数据来源
         * 右边为传递目标模块以及目标模块的子模块，作为数据传递查询条件的数据来源
         * 当子模块作为查询条件数据源时，遍历子模块，匹配到数据时终止遍历
         * 在服务端通过管道自动进行数据传递时，将匹配到的第一条数据进行数据传递，如果未匹配到数据，给出提示
         */
        // 子模块映射不为空
        const {selectmodule, modulename, searchsublistmapping} = row
        if (dataUtil.hasSublistSearch(row)) {
            props.config.dataType = 'MappingIncludeSublist'
            props.isForSearch = true
            props.initData = () => {
                return {
                    leftModule: selectmodule, 
                    rightModule: modulename, 
                    sublistmapping: searchsublistmapping,
                }
            }
        } else {
            props.config.dataType = 'Mapping'
            props.isForSearch = true
            props.initData = () => {
                let leftConfig = utils.getModuleConfig(row.selectmodule)
                let rightConfig = utils.getModuleConfig(fmodule)
                return {leftConfig, rightConfig}
            }
        }
    }
}
