import React, { Component } from 'react'
import { Resizable } from 'react-resizable'
export default class ResizeableTitle extends Component {
    render() {
        const { onResize, width, ...restProps } = this.props

        if (!width) {
            return <th {...restProps} />
        }

        return <Resizable
            width={width}
            height={0}
            onResize={onResize}
            draggableOpts={{ enableUserSelectHack: false }}
        >
            <th {...restProps} />
        </Resizable>
    }
}