import {onmessage} from './oows'
import * as msgType from './msgType'
import {toString, sleep, isPlatform} from './index'
export default function wsClient(baseURI) {
    let arr = baseURI.split('://')
    let path = '/oows'
    if (isPlatform()) {
        let dbname = 'oo'
        if (process.env.REACT_APP_LOCALDB === '1') {
            dbname = 'gtoo'
        }
        path += '/' + dbname
    } else {
        if (global.localFrontEnd && global.localEntServer && !global.localGateway) {
            path += '/oo'
        }
    }
    let protocol = 'ws://'
    if (window.location.protocol === 'https:') {
        protocol = 'wss://'
    }
    
    let url = protocol + arr[1] + path
    //console.log(url)
    connect(url)
}
function connect(url) {
    if (global.ws) {
        return
    }
    const ws = new WebSocket(url)
    console.log(new Date(), 'connecting to oows server .....')
    function onOpen() {
        console.log(new Date(), 'connected to oows server.')
        global.ws = ws
        global.heartbeatInterval = setInterval(() => {
            ws.send(toString({type: msgType.connected, body: url}))
        }, 30000)
    }
    function onError() {}
    function onMessage(res) {
        onmessage(res.data)
    }
    function closews(ws){
        if (ws == null) {
            return
        }
        clearInterval(global.heartbeatInterval)
        global.heartbeatInterval = null
        global.ws = null
        console.log(new Date(), 'oows server closed.')
        // 移除目标websocket服务器监听
        ws.removeEventListener('message', onMessage)
        ws.removeEventListener('open', onOpen)
        ws.removeEventListener('error', onError)
        ws.removeEventListener('close', onClose)
        
    }
    global.closews = closews
    async function onClose() {
        closews(ws)
        await sleep(3000)
        connect(url)
    }
    ws.onopen = onOpen
    ws.onerror = onError
    ws.onclose = onClose
    ws.onmessage = onMessage
}