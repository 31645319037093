/**
 * 模块选择组件
 */
import React, { Component } from 'react'
import OOSelect from './OOSelect'

export default class SelectOne extends Component {
    constructor() {
        super()
        this.state = {
            dataSource: []
        }
    }

    componentDidMount() {
        this.setState({dataSource: this.props.dataSource || []})
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.dataSource !== nextProps.dataSource) {
            this.setState({dataSource: nextProps.dataSource || []})
        }
    }

    render() {
        const {dataSource} = this.state
        let style = Object.assign({display: 'inline-block', width: '100%'}, this.props.style)
        return <OOSelect
            {...this.props}
            style={style}
            options={dataSource}
            placeholder={this.props.placeholder || '请选择字段'}
        />
    }
}