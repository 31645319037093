/**
 * Mapping
 * 字段映射组件
 * LeftTree 映射目标
 * RightTree 映射数据源
 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {createButton, createIcon, createComponent} from './PageCreator'
import Span from './Span'
import ModalEx from './ModalEx'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Input from 'antd/lib/input'
import Tree from './Tree'
import * as dataUtil from '@utils/DataUtil'
import * as utils from '@utils/index'
import '../modules/orchestrate/flow.css'

const valueClass = 'mapping-left-node-value'
const bindClass = 'mapping-left-node-bind'
const operClass = 'mapping-left-operation'
export default class Mapping extends Component {
    constructor() {
        super()
        this.state = {
            value: null,
            leftConfig: {}, rightConfig: {}, 
            leftTreeData: [], treeDataRight: [], 
			editBindVisible: false, 
			config: {},
			bindValue: null,
        }
    }
	componentDidMount() {
		const {leftConfig, rightConfig, value, notModal} = this.props
		if (!notModal && !this.state.visible) {
			return
		}
		this.buildTree(leftConfig, rightConfig, value)
	}
	UNSAFE_componentWillReceiveProps(nextProps) {
		const {leftConfig, rightConfig, value, notModal} = nextProps
		if (!notModal && !this.state.visible) {
			return
		}
		if (this.props.leftConfig !== leftConfig || this.props.rightConfig !== rightConfig || 
			this.props.value !== value
		) {
			this.buildTree(leftConfig, rightConfig, value)
		}
	}
    openMapping() {
        const {initData, value} = this.props
        if (!(initData instanceof Function)) {
            utils.warning('请传入 initData 回调函数')
            return
        }
        const {leftConfig, rightConfig} = (this.props.initData() || {})
        this.buildTree(leftConfig, rightConfig, value)
	}
	buildTree(leftConfig, rightConfig, value) {
		const {type} = this.props
		if (type !== 'line' && (leftConfig == null || utils.equals(leftConfig, {}))) {
			//console.error('can not open Mapping leftConfig is null')
            return
        }
        if (rightConfig == null || utils.equals(rightConfig, {}) || utils.equals(rightConfig, [])) {
            //console.error('can not open Mapping rightConfig is null')
			return
        }
		let leftTreeData = []
		if (type !== 'line') {
			value = value || {}
			leftTreeData = this.buildLeftTree(leftConfig, value)
		}
		
		let treeDataRight = this.buildRightTree(rightConfig)
        //console.log('leftTreeData, treeDataRight')
        //console.log(leftTreeData, treeDataRight)
		this.setState({
            leftConfig, 
			rightConfig, 
            leftTreeData, 
			treeDataRight, 
            value, 
            visible: true
        })
	}

    buildRightTree(rightConfig) {
		const {isForSearch} = this.props
		const buildRightRootNode = (config, treeDataRight) => {
			config = dataUtil.getMergeConfigByConfig(config)
			//console.log('config', config)
			if (config.fields_config instanceof Array) {
				const value = config.modulename
				let label = dataUtil.showModuleName(config)
				if (config.key) {
					// name 画布节点名称
					label = config.key + ' ' + config.name
				}
				const isSingleValue = config.isSingleValue
				let treeData = {
					key: config.key || value, 
					value: value, 
					label: label, 
					title: <span style={{color: 'blue'}}
						draggable
						onDragStart={isSingleValue ? 
							this.onSingleDragStart.bind(this, config.key) : 
							this.onParentDragStart.bind(this, config)
						} 
					><Span value={value} label={label}/></span>
				}
				const addRightTreeNode = (name, alias) => {
					let optionConfig = {name, alias}
					treeData.children.push(this.buildRightNode(optionConfig, config.key))
				}
				if (!isSingleValue) {
					const fields_config = config.fields_config
					if (this.props.flow) {
						if (utils.isNotBlank(config.fmodule)) {
							fields_config.unshift({name: 'sourceModule', alias: '来源模块'})
							fields_config.unshift({name: 'sourceCode', alias: '来源单据编码'})
							fields_config.unshift({name: 'sourceId', alias: '来源id'})
							fields_config.unshift({name: 'fid', alias: '父模块id'})
						}
						fields_config.unshift({name: 'id'})
					}
					treeData.children = []
					for (let fieldConfig of fields_config) {
						const {name, alias} = fieldConfig
						const label = alias || name
						if (utils.isSysFields(name) || dataUtil.isDisabledFFieldType(fieldConfig)) {
							continue
						}
						treeData.children.push(this.buildRightNode(fieldConfig, config.key))
						if (!isForSearch && dataUtil.isSelectType(fieldConfig.dataType)) {
							addRightTreeNode(name + '_options', label + '_选项')
						} else if (['LISTMODULE', 'TREEMODULE'].indexOf(fieldConfig.dataType) !== -1) {
							addRightTreeNode(name + '_name', label + '_名称')
						}
					}
				}
				treeDataRight.push(treeData)
			}
		}
		rightConfig = rightConfig || this.state.rightConfig
		let treeDataRight = []
		if (rightConfig instanceof Array) {
			for (let config of rightConfig) {
				buildRightRootNode(config, treeDataRight)
			}
		} else if (rightConfig instanceof Object) {
			buildRightRootNode(rightConfig, treeDataRight)
		}
		return treeDataRight
	}

	buildRightNode(fieldConfig, nodeId) {
		const {name, alias} = fieldConfig
		let node = {}
		node.key = node.value = name
		node.alias = alias 
		node.label = alias || name
		node.title = <span
			draggable
			onDragStart={this.onDragStart.bind(this, fieldConfig, nodeId)} 
		><Span value={name} label={alias} style={{color: 'blue'}}/></span>
		return node
	}

	buildLeftTree(leftConfig, value) {
		const {flow, isForSearch} = this.props
        leftConfig = leftConfig || this.state.leftConfig
        const nodeConfig = dataUtil.getMergeConfigByConfig(leftConfig)
		const map = value || this.state.value
		//console.log('buildLeftTree',map)
		// ondragenter - 当被鼠标拖动的对象进入其容器范围内时触发此事件
		// ondragover - 当某被拖动的对象在另一对象容器范围内拖动时触发此事件
		// ondragleave - 当被鼠标拖动的对象离开其容器范围内时触发此事件
		// ondrop - 在一个拖动过程中，释放鼠标键时触发此事件
		let leftTreeData = []
		if (nodeConfig.fields_config instanceof Array) {
			nodeConfig.bindValue = map.key
			const value = nodeConfig.modulename
			let label = dataUtil.showModuleName(nodeConfig)
			if (nodeConfig.key) {
				// name 画布节点名称
				label = nodeConfig.key + ' ' + nodeConfig.name
			}
			const {bindValue, bindAlias} = nodeConfig
			leftTreeData = [{
				key: nodeConfig.key || value, 
				value, 
				label,
				title: <div
					style={{width: '100%'}}
					onDrop={this.onDropParent.bind(this, nodeConfig)}
					onDragOver={this.onDragOverParent.bind(this)}
				>
					<div className={valueClass}><Span value={value} label={label}/></div>
					<div className={bindClass} style={{marginLeft: 9}}>
						<Span value={bindValue} label={bindAlias}/>&nbsp;
					</div>
					<div className={operClass} style={{marginLeft: flow ? 24: 0}} >
						{createIcon('trash', '删除', this.delBindParent.bind(this, nodeConfig))}
					</div>
				</div>
			}]
			const fields_config = nodeConfig.fields_config
			if (this.props.flow) {
				if (utils.isNotBlank(nodeConfig.fmodule)) {
					fields_config.unshift({name: 'sourceModule', alias: '来源模块'})
					fields_config.unshift({name: 'sourceCode', alias: '来源单据编码'})
					fields_config.unshift({name: 'sourceId', alias: '来源id'})
					fields_config.unshift({name: 'fid', alias: '父模块id'})
				}
				if (nodeConfig.nodeKey && '新增' !== nodeConfig.nodeKey.split('_')[0]) {
					fields_config.unshift({name: 'id'})
				}
				
			}
			leftTreeData[0].children = []
			for(let config of fields_config) {
				if (utils.isSysFields(config.name) || dataUtil.isDisabledFFieldType(config)) {
					continue
				}
				leftTreeData[0].children.push(this.buildLeftNode(config, map))
				if (!isForSearch && dataUtil.isSelectType(config.dataType)) {
					let fieldConfig = {}
					const {name, alias} = config
					const label = alias || name
					fieldConfig.name = name + '_options'
					fieldConfig.alias = label + '_选项'
					leftTreeData[0].children.push(this.buildLeftNode(fieldConfig, map))
				}
			}
		}
		return leftTreeData
	}

	buildLeftNode(config, map) {
		const {flow, canEdit} = this.props
		let node = {}
		const {name, alias} = config
		node.config = config
		node.key = node.value = name
		node.label = node.alias = alias || name
		node.bindValue = map[config.name]
		node.bindAlias = map[config.name + 'alias']
		const {bindValue, bindAlias} = node
		//console.log(row.id, config.name)
		node.title = <div
			style={{width: '100%'}}
			onDrop={this.onDrop.bind(this, config)}
			onDragOver={this.onDragOver.bind(this)}
		>
			<div className={valueClass}><Span value={name} label={alias}/></div>
			<div className={bindClass}><Span value={bindValue} label={bindAlias}/>&nbsp;</div>
			<div className={operClass}>
				{(flow || canEdit) && createIcon('edit', '修改', this.editBind.bind(this, config))}
				{createIcon('trash', '删除', this.delBind.bind(this, config.name))}
			</div>
		</div>
		return node
	}

	/**
	 * 右侧树， 单值根节点开始拖拽
	 * onSingleDragStart
	 * @param {*} nodeId 画布节点id
	 */
	onSingleDragStart(nodeId) {
		this.dragFieldConfig = {isSingleValue: true}
		this.dragNodeId = nodeId
	}

	/**
	 * 右侧树， 字段节点开始拖拽
	 * @param {*} fieldConfig 拖拽节点字段配置
	 */
	onDragStart(fieldConfig, nodeId) {
		this.dragFieldConfig = fieldConfig
		this.dragNodeId = nodeId
	}

	/**
	 * 右侧树，根节点开始拖拽
	 * @param {*} config 画布节点的模块配置
	 */
	onParentDragStart(config) {
		this.config = config
	}

	/**
	 * 左侧树，字段节点拖拽结束
	 * @param {*} ev 
	 */
	onDragOver(ev) {
		ev.preventDefault()
	}

	/**
	 * 左侧树，根节点拖拽结束
	 * @param {*} ev 
	 */
	onDragOverParent(ev) {
		ev.preventDefault()
	}

	/**
	 * 根据字段配置进行映射字段
	 * @param {*} map 字段映射数据
	 * @param {*} config 左侧树字段配置
	 * @param {*} dragNodeId 拖拽的画布节点id
	 * @param {*} dragFieldConfig 拖拽的字段配置
	 */
	mappingField(map, config, dragNodeId, dragFieldConfig) {
		const {flow, isForSearch} = this.props
		let nodeIdPrefix = ''
		if (flow) {
			nodeIdPrefix = dragNodeId + '.'
		}
		const targetKey = config.name
		const sourceKey = dragFieldConfig.name
		const mapKey = (targetKey, sourceKey) => {
			map[targetKey] =  nodeIdPrefix + sourceKey
		}
		const copyExtraKey = suffix => mapKey(targetKey + suffix, sourceKey + suffix)
		mapKey(targetKey, sourceKey)
		// 如果字段有别名，需要传递别名
		if (dragFieldConfig.alias) {
			map[targetKey + 'alias'] = nodeIdPrefix + dragFieldConfig.alias
		}
		// 如果为选择类型，需要额外传递选项
		if (!isForSearch && dataUtil.isSelectType(dataUtil.getRealType(dragFieldConfig))) {
			copyExtraKey('_options')
			map[targetKey + '_optionsalias'] = nodeIdPrefix + (dragFieldConfig.alias || sourceKey) + '_选项'
		}
		/**
		 * 数据传递情况，不需要传递这些字段, 在dataUtil中统一处理
		 * dataUtil.getTransferData， 向表格传递数据
		 * dataUtil.getTransferFormData， 向表单传递数据
		 * 业务编排情况，需要额外传递列表模型和树模型的额外字段
		 */
		if (flow) {
			if (dataUtil.isListType(dataUtil.getRealType(dragFieldConfig))) {
				copyExtraKey('_id')
				copyExtraKey('_name')
			} else if (dataUtil.isTreeType(dataUtil.getRealType(dragFieldConfig))) {
				copyExtraKey('_id')
				copyExtraKey('_name')
				copyExtraKey('_classes')
				copyExtraKey('_classnames')
			}
		}
	}

	/**
	 * 拖拽节点落在左侧树字段节点
	 * @param {*} config 拖拽节点落下时的左侧节点字段配置
	 * @param {*} ev 拖拽事件
	 * @returns 
	 */
	onDrop(config, ev) {
		ev.preventDefault()
		if (this.dragFieldConfig == null){
			return
		}
		const map = this.state.value
		if (this.dragFieldConfig.isSingleValue) {
			map[config.name] = this.dragNodeId
		} else {
			this.mappingField(map, config, this.dragNodeId, this.dragFieldConfig)
		}
		//console.log(mapping)
		let leftTreeData = this.buildLeftTree(null, map)
		this.setState({leftTreeData, value: map})
		this.dragFieldConfig = null
		this.dragNodeId = null
	}
	/**
	 * 落在节点落在左侧树根节点
	 * @param {*} node 根节点数据
	 * @returns 
	 */
	onDropParent(node) {
		if (this.config == null) {
			return
		}
		const map = this.state.value
		
		map.key = this.config.modulename
		if (this.config.key) {
			map.key = this.config.key + ' ' + this.config.name
		}
		// 遍历拖拽节点字段配置， 进行同名字段映射
		for (let fieldConfig of this.config.fields_config) {
			if (utils.isSysFields(fieldConfig.name)){
				continue
			}
			// 拖拽节点配置中的字段在左侧树的字段配置中存在
			if (node.fields_config.find(item => item.name === fieldConfig.name)) {
				this.mappingField(map, fieldConfig, this.config.key, fieldConfig)
			}
		}

		//console.log(mapping)
		let leftTreeData = this.buildLeftTree(null, map)
		this.setState({leftTreeData, value: map})
		this.config = null
	}


	/**
	 * 根据字段名删除字段映射
	 * @param {*} map 字段映射
	 * @param {*} name 字段名
	 */
	delBindValue(map, name) {
		if (map == null) {
			return
		}
		delete map[name] 
		delete map[name + 'alias']
		delete map[name + '_options']
		delete map[name + '_optionsalias']
		delete map[name + '_id']
		delete map[name + '_name']
		delete map[name + '_classes']
		delete map[name + '_classnames']
	}
	/**
	 * 根节点递归删除字段映射
	 * @param {*} node 根节点数据
	 */
	delBindParent(node) {
		const map = this.state.value
		map.key = null
		for (let fieldConfig of node.fields_config) {
			const name = fieldConfig.name
			this.delBindValue(map, name)
		}
		let leftTreeData = this.buildLeftTree(null, map)
		this.setState({leftTreeData, value: map})
	}

	/**
	 * 删除单个字段的映射
	 * @param {*} name 
	 */
	delBind(name) {
		const map = this.state.value
		this.delBindValue(map, name)
		let leftTreeData = this.buildLeftTree(null, map)
		this.setState({leftTreeData, value: map})
	}

	/**
	 * 手工编辑字段映射的值
	 * @param {*} config 
	 */
	editBind(config) {
		const {name} = config
		const map = this.state.value || {}
		let bindValue = map[name]
		this.setState({editBindVisible: true, config, bindValue})
	}

	onEditBindOk() {
		const map = this.state.value || {}
		const {name} = this.state.config
		if (utils.isBlank(name)) return
		map[name] = this.state.bindValue
		let leftTreeData = this.buildLeftTree(null, map)
		this.setState({
			leftTreeData, map, 
			editBindVisible: false, config: {}, bindValue: null
		})
	}

	getValue() {
		return this.state.value
	}

    render () {
		const {value, leftConfig, leftTreeData, treeDataRight, 
			config, bindValue
		} = this.state
		const {name, alias} = config
        const {mode, title, disabled, notModal, fModal, type, showType, hide} = this.props
        let { icon, buttonName } = this.props
		if (buttonName == null) {
			buttonName = '数据传递'
		}
		if (utils.isBlank(icon)) {
			icon = 'exchange'
		}
        let iconMode = createIcon(icon, buttonName, this.openMapping.bind(this), 
			{disabled, showType, notip: true })
        let buttonMode = createButton(icon, buttonName, this.openMapping.bind(this), 
			{ disabled, type: "primary", ghost: true, showType, notip: true })
		const mappingDiv = <div>
			<Row>
				<Col span={16}>
					<div className='mapping-body-left'>
					{type === 'line' ? 
						<Input.TextArea 
							value={value}
							onChange={e => this.setState({value: e.target.value}) }
						/>
						:
						<Tree
							defaultExpandRoot showDivline
							treeData={leftTreeData || []} 
						/>}
					</div>
				</Col>
				<Col span={8}>
					<div className='mapping-body-right'>
						<Tree
							defaultExpandRoot showDivline
							treeData={treeDataRight || []} 
						/>
					</div>
				</Col>
			</Row>
			<ModalEx
				width={500}
				title={dataUtil.showModuleName(leftConfig)}
				visible={this.state.editBindVisible}
				onCancel={() => this.setState({editBindVisible: false})}
				onClose={() => this.setState({editBindVisible: false})}
				maskClosable={true}
				onOk={this.onEditBindOk.bind(this)}
			>
				<div>
					<h3>{alias || name}</h3>
					<div>
						{dataUtil.isAntdSelectType(config.dataType) ? 
							createComponent(this, {...config, isDisabled: '0'}, {}, bindValue, (formField, props) => {
								props.onChange = value => {
									this.setState({bindValue: value})
								}
							}) :
							<Input.TextArea 
								value={bindValue} 
								onChange={e => this.setState({bindValue: e.target.value}) }
							/>
						}
					</div>
				</div>
			</ModalEx>
		</div>
		if (notModal && !fModal) {
			return <div>
				<div className="flow-tooltar">
					{createButton('save', '保存', 
						() => this.props.onChange && this.props.onChange(this.state.value), 
						{type: "primary"}
					)}
				</div>
				{mappingDiv}
			</div>
		}
		if (notModal && fModal) {
			return mappingDiv
		}

        return <span>
            {!notModal && !(hide && hide()) && 
				(mode === 'icon' ? iconMode : buttonMode)
			}
			<ModalEx
				title={title || '数据传递'}
				visible={this.state.visible}
				onCancel={() => this.setState({visible: false})}
				onClose={() => this.setState({visible: false})}
				maskClosable={false}
				width={global.modalWidth}
				onOk={() => {
					this.props.onChange && this.props.onChange(this.state.value)
					this.setState({visible: false})
				}}
			>
				{mappingDiv}
			</ModalEx>
        </span>
    }
}

Mapping.propTypes = {
	/**
	 * 字段映射
	 */
	value: PropTypes.any,
	/**
	 * 弹窗打开时数据初始化回调函数
	 */
    initData: PropTypes.func,
	/**
	 * 弹窗按钮为图标或按钮，默认按钮
	 */
	mode: PropTypes.string,
	/**
	 * 弹窗标题
	 */
	title: PropTypes.string,
	/**
	 * 是否禁用弹窗按钮
	 */
	disabled: PropTypes.bool,
	/**
	 * 非弹窗模式, 默认弹窗模式
	 * 业务编排非弹窗模式
	 * 数据传递弹窗模式
	 */
	notModal: PropTypes.bool,
	/**
	 * 父组件为弹窗
	 */
	fModal: PropTypes.bool,
	/**
	 * 业务编排标记，此模式下字段映射前需要添加 节点id, 如：n0.
	 */
	flow: PropTypes.bool,
	/**
	 * 是否可直接编辑映射
	 */
	canEdit: PropTypes.bool,
	/**
	 * 映射类型
	 * line： 连线条件映射
	 */
	type: PropTypes.string,
	/**
	 * 查询映射
	 */
	isForSearch: PropTypes.bool,
}