/**
 * antd Tag 封装
 */
import React, { Component } from 'react'
import Tag from 'antd/lib/tag'
import { isBlank } from '@utils'
import * as c from '@utils/constants'
import { toDetail } from '@utils/page'
import * as utils from '@utils/index'

export default class OOTag extends Component {
    openModuleDetail(moduleName) {
        const moduleConfig = utils.getModuleConfig(moduleName)
        const id = moduleConfig.id
        toDetail(c.tables_config, id, moduleConfig)
    }
    render () {
        const {value, children, moduleName, ...props} = this.props
        if (isBlank(value) && isBlank(children)) {
            return <span/>
        }
        if (moduleName === c.tables_config) {
            props.onClick = this.openModuleDetail.bind(this, value)
        }
        return  <Tag color='blue' {...props}>{children || value }</Tag>
    }
}