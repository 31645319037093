import React, { Component } from 'react'
import Upload from 'antd/lib/upload'
import message from 'antd/lib/message'
import {getCookie} from '@utils/cookie'
import * as p from '@utils/page'


export default class UploadImage extends Component {
    constructor() {
        super()
        this.state = {
            fileList: []
        }
    }
	//文件上传
	handleChange(info) {
        console.log(info)
		if (info.file.status === 'done') {
            p.stopLoad()
			this.props.doneCallback && this.props.doneCallback()
		} else if (info.file.status === 'error') {
            p.stopLoad()
			message.error('附件上传失败！')
        } else {
            p.startLoad()
        }
        this.setState({
            fileList: info.fileList
        })
    }

    render() {
        //文件上传
		const props = {
			action: `${global.getApi().opts.baseURI}/file/upload`,
			listType: 'picture',
			onChange: this.handleChange.bind(this),
			multiple: true,
			fileList: this.state.fileList,
            showUploadList: false,
			data: {
                multiple: true,
                businessId: this.props.businessId,
            },
            headers: {token: getCookie('token')},
		}
		return <Upload {...props}>{this.props.children}</Upload>
    }
}