import React, { Component } from 'react'
import { createButton } from './PageCreator'
import { closepage } from '@utils/page'
import { delById } from '../modules/file/Action'
export default class FullImage extends Component{
	constructor() {
		super()
		const params = global.parseSearchStr()
		this.url = params.url
		this.id = params.id
	}
	render() {
		return <div>
			<div style={{
				height: global.clientHeight - 50, 
				overflowY: 'auto',
				backgroundColor: '#fff', 
			}}>
				<img src={this.url} alt="" style={{ width:'100%', objectFit: 'contain' }}/>
			</div>
			{global.isPhoneVertical && <div style={{padding: 8, height: 50}}>
				{createButton('arrow-left', '关闭', () => closepage())}
				{this.id && createButton('trash', '删除', () => delById(this.id), {needConfirm: true})}
			</div>}
		</div>
	}
}