/**
 * <span/> 封装，可以接收form表单数据
 */
import React, { Component } from 'react'
import Checkbox from 'antd/lib/checkbox'
export default class OOCheckBox extends Component {
    render () {
        return  <Checkbox {...this.props} 
            checked={this.props.value === true}
            onChange={info => {
                if (info.target.checked) {
                    this.props.onChange && this.props.onChange(true)
                } else {
                    this.props.onChange && this.props.onChange(false)
                }
            }}
        />
    }
}