import React, { Component } from 'react'
import Popover from 'antd/lib/popover'
import Icon from '@com/Icon'
import ModalEx from '@com/ModalEx'
import OOForm from '@com/OOForm'
import OptionOOTable from '@com/OptionOOTable'
import * as dataUtil from '@utils/DataUtil'
import * as utils from '@utils/index'


// 金额 MONEY
const moneyConfig = [
    {name: 'min', alias: '最小值', dataType: 'NUMBER'},
    {name: 'max', alias: '最大值', dataType: 'NUMBER'},
    {name: 'step', alias: '步长', dataType: 'NUMBER'},
    {name: 'decimalplaces', alias: '小数位数', dataType: 'NUMBER', min: 0, max: 11},
]
// 数字 NUMBER
const numberConfig = moneyConfig.concat([
    {name: 'autoincrease', alias: '自增', dataType: 'SELECT', params:'0,否/1,是'},
])
// EXPR 表达式
const exprConfig = [
    {name: 'dataType', alias: '格式化类型', dataType: 'SELECT', params:'NUMBER,数字/MONEY,金额'},
    {name: 'decimalplaces', alias: '小数位数', dataType: 'NUMBER'},
]
// OPTION 选项组
const optionConfig = [
    {name: 'valueType', alias: '值类型', dataType: 'SELECT', params:'NUMBER,数字/STRING,文本'},
]

export default class FieldParamSet extends Component {
    constructor() {
        super()
        this.state = {
            visible: false,
            // 合计 动态传递子模块模块名
            modulename: null,
            // Select 选项数据
            list: [],
        }
    }
    componentDidMount() {
        if (this.props.visible) {
            this.setState({visible: true})
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.visible !== nextProps.visible) {
            this.setState({visible: nextProps.visible})
        }
    }
    onCancel() {
        this.props.onChange && this.props.onChange(null)
        this.setState({visible: false})
    }
    onOk() {
        if (dataUtil.isAntdSelectType(this.props.data.dataType)) {
            const list = this.state.list
            this.props.onChange && this.props.onChange(list, true)
            this.setState({visible: false})
            return
        }
        if (dataUtil.formError(this.form)) {
            return
        }
        let values = dataUtil.getFormValues(this.form)
        //console.log(values)
        this.props.onChange && this.props.onChange(values)
        this.setState({visible: false})
    }
    render() {
        const config = this.props.config || {}
        const data = this.props.data || {}
        let otherParams = data.otherParams
        // SUM 合计
        const sumConfig = [
            {name: 'modulename', alias: '子模块', dataType: 'ModuleSelect', params: 'list', 
                otherParams: {sublist: dataUtil.getSublistNameArr(config.sublist)}
            },
            {name: 'name', alias: '字段名称', dataType: 'FieldSelect'},
        ]
        // FFIELD 默认父列
        const fFieldConfig = [
            {name: 'fmodule', alias: '父模块', dataType: 'STRING', isDisabled: '1'},
            {name: 'name', alias: '字段名称', dataType: 'FieldSelect',
                otherParams: {moduleName: config.fmodule}
            }
        ]
        const dataType = data.dataType
        const typeName = utils.getLabelByValue(dataType, dataUtil.dataTypeArray)
        const name = data.alias || data.name
        let fieldsConfig
        let component = <span>不需要设置额外参数</span>
        if (this.state.visible) {
            /**
             * 准备配置数据
             */
            if (dataUtil.isExprType(dataType)) {
                fieldsConfig = exprConfig
            } else if (dataUtil.isMoneyType(dataType)) {
                fieldsConfig = moneyConfig
            } else if (dataUtil.isNumberType(dataType)) {
                fieldsConfig = numberConfig
            } else if (dataUtil.isSumType(dataType)) {
                fieldsConfig = sumConfig
            } else if (dataUtil.isFFieldType(dataType)) {
                fieldsConfig = fFieldConfig
                otherParams = otherParams || {}
                otherParams.fmodule = config.fmodule
            } else if (dataUtil.isOptionType(dataType)) {
                fieldsConfig = optionConfig
            }

            /**
             * 创建界面
             */
            if (dataUtil.isAntdSelectType(dataType)) {
                component = <OptionOOTable 
                    value={this.state.list}
                    onChange={list => this.setState({list})}
                />
            } else if (fieldsConfig instanceof Array && fieldsConfig.length > 0) {
                const specialItemProps = (formField, props) => {
                    if (formField.id === 'modulename') {
                        // 合计
                        // 子模块变化，设置值到 state，给子模块字段传递属性
                        props.onChange = (value) => {
                            this.setState({modulename: value})
                        }
                    } else if (formField.id === 'name') {
                        if (dataUtil.isSumType(dataType)) {
                            console.log('this.state.modulename',this.state.modulename)
                            // 合计 动态传递子模块模块名
                            props.moduleName = this.state.modulename
                        }
                    }
                }
                component = <OOForm 
                    wrappedComponentRef={(inst) => 
                        this.form = inst && inst.props && inst.props.form
                    }
                    cols={1}
                    fieldsConfig={fieldsConfig}
                    data={otherParams}
                    specialItemProps={specialItemProps}
                />
            }
        }
        
        return <span>
            <Popover
                content={<div>
                    <span>{`设置【${name}】额外参数`}</span>
                </div>}
            >
                <Icon style={global.iconStyle} type='gear' onClick={() => { 
                    console.log('FieldParmSet props:', otherParams)
                    if (dataUtil.isAntdSelectType(dataType)) {
                        this.setState({
                            list: otherParams || data.params
                        })
                    } else if (dataUtil.isSumType(dataType)) {
                        this.setState({
                            modulename: otherParams.modulename
                        })
                    }
                    this.setState({visible: true})
                }}/>
            </Popover>
            {this.state.visible && <ModalEx
                title={`设置【${name}】额外参数`}
                visible={this.state.visible}
                onCancel={this.onCancel.bind(this)}
                onClose={this.onCancel.bind(this)}
                maskClosable={false}
                width={600}
                onOk={this.onOk.bind(this)}
            >
                <div>
                    <h2>{`数据类型： ${typeName}`}</h2>
                    {component}
                </div>
            </ModalEx>
            }
        </span>
    }
}
