/**
 * 条件编辑组件
 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ModalEx from './ModalEx'
import CondOOTable from './CondOOTable'
import {createButton} from './PageCreator'
import Popover from 'antd/lib/popover'

export default class CondEdit extends Component {
    /**
     * 构造函数 初始化
     */
    constructor() {
        super()
        this.state = {
            visible: false,
            title: '',
            groups: [[]],
            update: false
        }
    }

    componentDidMount() {
        this.init()
    }

    /**
     * 取消
     */
    onCancel() {
        this.setState({visible: false})
    }

    /**
     * 确定
     */
    onOk() {
        const { groups } = this.state
        this.props.onChange && this.props.onChange(groups)
        this.setState({visible: false})
    }

    init() {
        const {value, buttonName} = this.props
        let {title} = this.props
        let groups
        if (value == null) {
            groups = value || []
        } else if (value instanceof Array) {
            groups = value
        }
        title = title || buttonName || '条件编辑'
        
        // 一维数组转二维数组，保证至少展示一个分组
        if (groups.length === 0) {
            groups = [[]]
        }
        this.setState({groups, title})
    }

    onShow() {
        const {canShow} = this.props
        if (canShow && !canShow()) {
            return
        }
        this.init()
        this.setState({visible: true})
    }

    
    /**
     * 渲染函数
     */
    render() {
        const {moduleName, icon, disabled, showType, value} = this.props
        if (disabled && (value == null || value.length === 0)) {
            return <span/>
        }
        let {buttonName} = this.props
        if (buttonName == null) {
            buttonName = '编辑条件'
        }
        const condGroup = readonly => <div>
            <h3 style={{marginBottom: 8, color: '#1890ff'}}>
                同一分组内的条件全部成立，则分组条件成立，多个分组中只要有一个分组成立，则整体条件成立
            </h3>    
            {!disabled && !readonly && createButton('plus', '新增分组', () => {
                let groups = this.state.groups
                groups.push([{
                    fieldName: null,
                    oper: null,
                    value: null,
                }])
                this.setState({groups})
            }, {showName: true, type: 'primary', ghost: true})}
            {this.state.groups.map((group, i) => {
                const deleteButton = createButton('trash', '删除分组', () => {
                    let groups = this.state.groups
                    groups.splice(i, 1)
                    this.setState({groups})
                }, {showName: true, type: 'danger', ghost: true})
                return <div key={i} style={{border: 'solid 1px #efefef', borderRadius: 4, padding: 8, margin: 4}}>
                    <h3 style={{display: 'inline-block', marginRight: 32}}>{'分组' + (i + 1)}</h3>
                    {!disabled && !readonly && deleteButton}
                    <CondOOTable moduleName={moduleName} 
                        value={group} 
                        disabled={disabled || readonly}
                        onChange={value => {
                            let groups = this.state.groups
                            groups[i] = value
                            this.setState({groups})
                        }}
                    />
                </div>
            })}
        </div>
        const entryButton = createButton(icon || 'eye-slash', buttonName, 
            this.onShow.bind(this), 
            {type: 'primary', ghost: true, showType, notip: true})
        return <span>
            {disabled === false ? entryButton : 
            <Popover content={condGroup(true)}>
                {entryButton}
            </Popover>}
            {this.state.visible && <ModalEx
                disabled={disabled}
                title={this.state.title}
                visible={this.state.visible}
                onCancel={this.onCancel.bind(this)}
                onClose={this.onCancel.bind(this)}
                maskClosable={false}
                width={900}
                onOk={this.onOk.bind(this)}
            >
                {condGroup(false)}
            </ModalEx>}
        </span>
    }
}

CondEdit.propTypes = {
    canShow: PropTypes.func,
    moduleName: PropTypes.string, 
    icon: PropTypes.string, 
    disabled: PropTypes.bool,
}
