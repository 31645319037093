/**
 * 搜索通用组件
 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {createItems, configToItemProps, createButton} from './PageCreator'
import Icon from './Icon'
import Button from 'antd/lib/button'
import ModalEx from './ModalEx'
import Input from 'antd/lib/input'
import Form from 'antd/lib/form'
import * as dataUtil from '@utils/DataUtil'
import * as utils from '@utils/index'
import * as tableUtil from '@utils/tableUtil'

const ButtonGroup = Button.Group
class Search extends Component {
    constructor() {
        super()
        this.state = {
            showMore: false,
            showModal: false,
            fieldsConfig: [],
            mainFields: [],
            moreFields: [],
            moreSearchItemProps: [],
            placeholder: ''
        }
    }

    componentDidMount() {
        this.init()
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const {fieldsConfig, fixedSearchConds} = this.props
        if (nextProps.fieldsConfig !== fieldsConfig ||
            nextProps.fixedSearchConds !== fixedSearchConds
        ) {
            this.init(nextProps)
        }
    }
    init(props = this.props) {
        let {fieldsConfig, fixedSearchConds, searchFields} = props
        searchFields = searchFields || {}
        const {mainSearchFields, moreSearchFields} = 
            tableUtil.getSearchFields(fieldsConfig, fixedSearchConds)
        const moreSearchItemProps = moreSearchFields.map(config => 
            configToItemProps(this, config, searchFields, searchFields[config.name], null, true))
        //console.log(mainSearchFields, moreSearchFields)
        // 过滤数据类型
        const mainFields = dataUtil.filterDataType(mainSearchFields)
        const moreFields = dataUtil.filterDataType(moreSearchFields)
        //console.log(mainFields, moreFields)
        const placeholder = mainSearchFields.map(it => it.alias || it.name).join('或')
        this.setState({
            fieldsConfig, 
            mainFields,
            moreFields,
            moreSearchItemProps,
            placeholder
        })
    }
    /**
     * 回车键响应函数
     * @param {*} e 
     */
    onKeyDown(e) {
        if (e.keyCode === 13) {
            this.onSearch()
        }
    }

    // 仅过滤出字段名和数据类型，以供后端生成查询条件
    formatValue(searchFields = {}) {
        const {fieldsConfig} = this.state
        for (let key in searchFields) {
            if (key.endsWith('_options')) {
                delete searchFields[key]
            }
        }
        fieldsConfig.forEach(config => {
            let {name, dataType} = config
            dataType = dataUtil.getRealType(config)
            if (dataUtil.isListTreeType(dataType) && searchFields[name]) {
                if (utils.isNotBlank(searchFields[name].code)) {
                    searchFields[name] = searchFields[name].code
                } else {
                    delete searchFields[name]
                }
            } else if (dataUtil.isCheckboxType(dataType)) {
                const value = searchFields[name]
                if (value === 1) {
                    searchFields[name] = true
                } else if (value === 0) {
                    searchFields[name] = false
                }
            }
        })
    }

    /**
     * 搜索按钮回调函数
     */
    onSearch(e, dateFields) {
        //console.log(e, dateFields)
        const {fieldsConfig, mainFields, moreFields } = this.state
        const searchFields = {}
        let fieldsValue = this.props.form.getFieldsValue()
        this.formatValue(fieldsValue)
        //console.log('fieldsValue', fieldsValue)
        // 报表引擎生成的报表需要将嵌套对象扁平化为单层
        // 例如： {a: {b: value}} {'a.b': value}
        dataUtil.flatObj(fieldsValue, '', searchFields, fieldsConfig)
        //console.log('searchFields', searchFields)
        for (let key in searchFields) {
            if (typeof searchFields[key] === 'string') {
                searchFields[key] = searchFields[key].trim()
            }
        }
        const query = {
            searchFields: dateFields || searchFields, 
            mainFields, moreFields
        }
        this.props.onSearch && this.props.onSearch(query)
        global.isPhoneVertical && this.setState({showModal: false})
    }

    /**
     * 重置搜索条件
     */
    resetSearch() {
        const {moreFields} = this.state
        this.props.form.resetFields()
        let dateFields = {mainKey: null}
        for (let field of moreFields) {
            if (['DATE', 'TIME'].indexOf(field.dataType) !== -1) {
                dateFields[field.id] = [undefined, undefined]
            }
        }
        this.props.form.setFieldsValue(dateFields)
        // 等待父组件重新渲染完成，再用新的条件查询
        this.onSearch(null, dateFields)
        global.isPhoneVertical && this.setState({showModal: false})
    }

    /**
     * 更多查询条件
     */
    handleMore() {
        this.setState({showMore: !this.state.showMore})
    }

    /**
     * 页面渲染
     */
    render() {
        let {simple, searchFields} = this.props
        searchFields = searchFields || {}
        const {moreSearchItemProps, mainFields, showMore, placeholder} = this.state
        simple = moreSearchItemProps.length === 0 || simple
        const {getFieldDecorator} = this.props.form
        let searchItems = createItems(getFieldDecorator, null, 1, moreSearchItemProps, false, true)
        let arrow = <Icon type="caret-down"/>
        if (showMore) {
            arrow = <Icon type="caret-up"/>
        }
        let ret = <span onKeyDown={this.onKeyDown.bind(this)}>
            <nobr>
                {mainFields.length > 0 && <span>
                {getFieldDecorator('mainKey', {initialValue: searchFields.mainKey})(
                    <Input style={{width: 190, marginRight: 16}} 
                        placeholder={"请输入" + placeholder} 
                    />
                )}
                </span>}
                <span>
                    <ButtonGroup>
                        <Button type="primary" className='icon-button' onClick={this.onSearch.bind(this)}>
                            <Icon type="search"/>
                        </Button>
                        {!simple && !global.isPhoneVertical &&
                        <Button type="primary" className='icon-button' onClick={this.handleMore.bind(this)}>
                            {arrow}
                            {
                                // 解决左右按钮错开问题
                                //<span style={{color: '#fff', marginRight: -38}}>1</span>
                            }
                        </Button>}
                    </ButtonGroup>
                    <Button 
                        type="ghost" 
                        className='icon-button'
                        style={{marginLeft: global.isPhoneVertical ? 16 : 0}}
                        onClick={this.resetSearch.bind(this)}
                    >
                        <Icon type="redo" name='重置'/>
                    </Button>
                </span>
            </nobr>
            {(showMore || global.isPhoneVertical) && 
            <div style={{paddingTop: 16}}>
                {searchItems}
            </div>}
        </span>
        if (global.isPhoneVertical && !this.state.showModal) {
            ret = createButton('search', '查询', () => this.setState({showModal: true}), {type: 'primary'})
        }
        return <span>
            {!this.state.showModal && ret}
            {global.isPhoneVertical && <ModalEx
                title={'查询'}
                visible={this.state.showModal}
                onClose={() => this.setState({showModal: false})}
            >
                {ret}
            </ModalEx>}
        </span>
    }
}

Search.propTypes = {
    gutter: PropTypes.number, 
    onSearch: PropTypes.func,
    simpleText: PropTypes.string,
    moreText: PropTypes.string,
    simple: PropTypes.bool,
    // 固定查询条件，隐藏查询字段
    fixedSearchConds: PropTypes.object,
}
export default Form.create()(Search)