import c from '@utils/constants'
import * as dataUtil from '@utils/DataUtil'
import {sysCreateField} from '../../init/sysfields'

const {
    // 字段展示类型
    STRING,		    // 文本
    SELECT,		    // 选择
    NUMBER,		    // 数字
    CHECKBOX,		// 复选框
    TREEMODULE,		// 树模块
    TAG,            // 标签
    GROUP,		    // 分组
    // 系统内置组件
    IconSelect, 	 // 图标选择组件
    SubListConfig, 	 // 子模块配置组件
    CondEdit, 	     // 条件组件
    FieldSelect, 	 // 字段选择组件
    ModuleSelect, 	 // 模块选择组件
} = c
export const tableConfig = [
    {name: "基本配置", dataType: GROUP},
    {name: "modulename", alias: '模块名称', dataType: STRING, isRequire: "1", isQuery: "2", width: 120, fixed: 'left'},
    {name: "alias", alias: '别名', dataType: STRING, isQuery: "2", width: 120},
    {name: "moduletype", alias: "数据模型", isRequire: "1", width: 120, dataType: SELECT, params: "list,列表/tree,树", defaultValue: "list"},
    {name: "menuname", alias: '菜单名称', dataType: STRING, width: 120},
    {name: "createmenu", alias: "生成菜单", width: 80, dataType: CHECKBOX, isSort: "0", defaultValue: true},
    {name: "no", alias: "菜单序号", width: 120, dataType: NUMBER, isRequire: '1', isQuery: "0"},
    {name: "icon", alias: "图标", width: 80, dataType: IconSelect, isQuery: "0"},
    {name: "menu", alias: "菜单", width: 180, dataType: TREEMODULE, params: "menu"},
    {name: "展示配置", dataType: GROUP},
    {name: "edittype", alias: "表格编辑方式", width: 120, dataType: SELECT, params: "form,新页签/directEdit,表格直接编辑/alwaysEdit,表格永远编辑", defaultValue: "form"},
    {name: "modaledit", alias: "弹窗编辑", width: 80, dataType: CHECKBOX, isSort: "0"},
    //{name: "showtype", alias: "展示方式", width: 120, dataType: SELECT, params: "table,表格/card,卡片", defaultValue: "table"},
    {name: "size", alias: "行间距", width: 80, dataType: SELECT, isSort: "0", defaultValue: 'middle',
        params: [
            {value: 'small', label: '小'},
            {value: 'middle', label: '中'},
            {value: 'default', label: '大'},
        ],
        otherParams: {
            showType: 'radioButton',
            buttonStyle: "solid",
        }
    },
    {name: "hidehead", alias: "隐藏表头", width: 80, dataType: CHECKBOX, isSort: "0"},
    {name: "hideindex", alias: "隐藏序号列", width: 80, dataType: CHECKBOX, isSort: "0"},
    {name: "selectshowfield", alias: "选择显示字段", width: 120, dataType: SELECT, params: "code,编码/name,名称", defaultValue: "name"},
    {name: "分类配置", dataType: GROUP},
    {name: "catagory", alias: "分类", width: 120, dataType: ModuleSelect, params: 'tree'},
    {name: "catagorycolumnwidth", alias: "分类列宽", width: 120, dataType: NUMBER, defaultValue: 160, isQuery: "0", isSort: "0"},
    {name: "treewidth", alias: "树宽度", width: 80, dataType: NUMBER, defaultValue: 300, isQuery: "0", isSort: "0"},
    {name: "数据配置", dataType: GROUP},
    {name: "dataauth", alias: "启用数据权限", width: 80, dataType: CHECKBOX, isSort: "0"},
    {name: "datacheck", alias: "数据校验", dataType: CondEdit, isRequire: '0', width: 80,
        otherParams: {
            moduleName: '$modulename',
            title: '$modulename',
            buttonName: '',
            icon: 'check',
            showType: 'icon',
        }
    },
    {name: "disableEditConds", alias: "禁用编辑", dataType: CondEdit, isRequire: '0', width: 80,
        otherParams: {
            moduleName: '$modulename',
            title: '$modulename',
            buttonName: '',
            showType: 'icon',
        }
    },
    {name: "transferconds", alias: "数据传递条件", dataType: CondEdit, isRequire: '0', width: 80,
        otherParams: {
            moduleName: '$modulename',
            title: '$modulename',
            buttonName: '',
            icon: 'exchange',
            showType: 'icon',
        }
    },
    {name: "uniquefields", alias: "字段组合唯一", width: 160, dataType: FieldSelect, isRequire: '0',
        otherParams: {moduleName: '$modulename', mode: 'multiple'}
    },
    {name: "duplicationcheck", alias: "重复添加校验", width: 160, dataType: FieldSelect, isRequire: '0',
        otherParams: {moduleName: '$modulename', mode: 'multiple'}
    },
    {name: "canview", alias: "显示查看", width: 80, dataType: CHECKBOX, isSort: "0", defaultValue: true},
    {name: "canadd", alias: "显示新增", width: 80, dataType: CHECKBOX, isSort: "0", defaultValue: true},
    {name: "canedit", alias: "显示修改", width: 80, dataType: CHECKBOX, isSort: "0", defaultValue: true},
    {name: "candelete", alias: "显示删除", width: 80, dataType: CHECKBOX, isSort: "0", defaultValue: true},
    {name: "cancopy", alias: "显示复用", width: 80, dataType: CHECKBOX, isSort: "0"},
    {name: "showbatchset", alias: "显示批录", width: 80, dataType: CHECKBOX, isSort: "0"},
    {name: "showinsert", alias: "显示插入", width: 80, dataType: CHECKBOX, isSort: "0"},
    {name: "splitField", alias: "拆分字段", width: 120, dataType: FieldSelect, isRequire: '0',
        otherParams: {moduleName: '$modulename', dataType: NUMBER}
    },{name: "newplace", alias: "新增记录位置", width: 120, dataType: SELECT, params: "0,最前面/1,最后面", defaultValue: "0"},
    {name: "sortfield", alias: "默认排序字段", width: 120, dataType: FieldSelect, defaultValue: "createtime", isRequire: '0',
        otherParams: {showAll: true, moduleName: '$modulename'}
    },
    {name: "sortorder", alias: "默认排序顺序", width: 120, dataType: SELECT, params: "1,升序/-1,降序", defaultValue: "-1"},
    {name: "编码配置", dataType: GROUP},
    {name: "autocode", alias: "生成编码", width: 80, dataType: CHECKBOX, isSort: "0"},
    {name: "流程配置", dataType: GROUP},
    {name: "hasflow", alias: "流程", width: 80, dataType: CHECKBOX, isSort: "0"},
    {name: "remark", alias: "描述", width: 200, dataType: STRING},
    {name: "附件", dataType: GROUP},
    {name: "hasattachment", alias: "附件", width: 80, dataType: CHECKBOX, isSort: "0"},
    {name: "sublist", alias: "子模块", width: 120, dataType: SubListConfig, isRequire: '0'},
    {name: "其他配置", dataType: GROUP},
    {name: "fmodule", alias: "父模块", width: 100, dataType: TAG, isDisabled: '1', 
        otherParams: { moduleName: c.tables_config} 
    },
    {name: "维护人员", dataType: GROUP},
].concat(sysCreateField)

export const fieldsConfig = [
    {alias: '操作', name: 'id', dataType: STRING, width: 100, fixed: 'left' },
    {alias: '字段名称', name: 'name', dataType: STRING, isQuery: "2", isRequire: '1', width: 160, fixed: 'left', },
    {alias: '别名', name: 'alias', dataType: STRING, isQuery: "2", width: 160, },
    {alias: '数据类型', name: 'dataType', dataType: SELECT, isRequire: '1', width: 120, 
        params: dataUtil.dataTypeArray
    },
    {alias: '参数', name: 'params', dataType: STRING, isRequire: '0', width: 160, },
    {alias: '列宽', name: 'width', dataType: NUMBER, isRequire: '0', width: 100, 
        otherParams: { step: 1, min: 1, max: 3000, }
    },
    {alias: '表格显示', name: 'isShowT', dataType: SELECT, params: '0,不显示/1,显示', width: 120, },
    {alias: '表单显示', name: 'isShowF', dataType: SELECT, params: '0,不显示/1,显示', width: 120, },
    {alias: '必填', name: 'isRequire', dataType: SELECT, params: '0,选填/1,必填', width: 100, },
    {alias: '唯一', name: 'isUnique', dataType: SELECT, params: '0,不唯一/1,唯一', width: 120, },
    {alias: '修改', name: 'isDisabled', dataType: SELECT, params: '0,可修改/1,不可修改', width: 140, },
    {alias: '查询', name: 'isQuery', dataType: SELECT, params: '0,不查询/2,主要条件/1,更多条件', width: 120, },
    {alias: '排序', name: 'isSort', dataType: SELECT, params: '0,不排序/1,排序', width: 120, },
    {alias: '默认值', name: 'defaultValue', dataType: STRING, width: 160, },
    {alias: '禁止复用', name: 'noCopy', dataType: CHECKBOX, width: 120, },
    {alias: '列固定', name: 'fixed', dataType: SELECT, width: 120, 
        params: [
            {value: 'left', label: '左边'}, 
            {value: 'right', label: '右边'},
        ], 
    },
    {alias: '居中', name: 'centered', dataType: CHECKBOX, width: 120, },
]