import React, { Component } from 'react'
import AutoComplete from 'antd/lib/auto-complete'
import * as utils from '@utils/index'

export default class InputSelect extends Component {
    constructor() {
        super()
        this.state = {
            dataSource: [],
            dataSourceAll: []
        }
    }

    componentDidMount() {
        let dataSource
        if (this.props.options) {
            dataSource = this.props.options.split(utils.getOptionSplit(this.props.options))
        }
        if (this.props.dataSource) {
            dataSource = this.props.dataSource
        }
        dataSource = dataSource || []
        this.setState({dataSource, dataSourceAll: dataSource})
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.options !== nextProps.options) {
            const dataSource = (nextProps.options && nextProps.options.split(utils.getOptionSplit(nextProps.options))) || []
            this.setState({dataSourceAll: dataSource})
        }
        if (this.props.dataSource !== nextProps.dataSource) {
            const dataSource = nextProps.dataSource || []
            this.setState({dataSourceAll: dataSource})
        } 
    }

    render() {
        let style = Object.assign({display: 'inline-block', width: '100%'}, this.props.style)
        return <AutoComplete
            value={this.props.value}
            dataSource={this.state.dataSource}
            style={style}
            onChange={this.props.onChange}
            onSelect={this.props.onChange}
            onSearch={value => {
                if (utils.isBlank(value)) {
                    this.setState({dataSource: this.state.dataSourceAll})
                }
                let dataSource = this.state.dataSourceAll.filter(it => it.indexOf(value) !== -1)
                this.setState({dataSource})
            }}
            placeholder={this.props.placeholder || '请输入或选择'}
         />
    }
}