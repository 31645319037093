import React from 'react'
import ReactDOM from 'react-dom'
import './antd.css'
import ConfigProvider from 'antd/lib/config-provider'
import zh_CN from 'antd/lib/locale-provider/zh_CN'
import 'moment/locale/zh-cn'
import './index.css'
import '@utils'
import '@utils/DataUtil'
import '@utils/oomessage'
import App from './App'

ReactDOM.render(<ConfigProvider locale={zh_CN}><App /></ConfigProvider>, document.getElementById('root'))
