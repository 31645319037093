import { getListAllPromise, getOnePromise } from "@/modules/list_module/Action";
import { equals, isNotBlank, parseSearchStr } from "@/utils";
import { chartdesign } from "@utils/constants";
import { clientWidth, contentHeight, getActiveTabKey } from "@utils/page";
import React, {Component} from "react";
import { ComposedChart, 
	Bar, Line, Area,
	Pie, PieChart, Sector,
	RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Radar,
	Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, /*ResponsiveContainer*/ 
} from 'recharts';

const renderActiveShape = (props) => {
	const RADIAN = Math.PI / 180;
	const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
	const sin = Math.sin(-RADIAN * midAngle);
	const cos = Math.cos(-RADIAN * midAngle);
	const sx = cx + (outerRadius + 10) * cos;
	const sy = cy + (outerRadius + 10) * sin;
	const mx = cx + (outerRadius + 30) * cos;
	const my = cy + (outerRadius + 30) * sin;
	const ex = mx + (cos >= 0 ? 1 : -1) * 22;
	const ey = my;
	const textAnchor = cos >= 0 ? 'start' : 'end';
  
	return (
	  <g>
		<text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
		  {payload.name}
		</text>
		<Sector
		  cx={cx}
		  cy={cy}
		  innerRadius={innerRadius}
		  outerRadius={outerRadius}
		  startAngle={startAngle}
		  endAngle={endAngle}
		  fill={fill}
		/>
		<Sector
		  cx={cx}
		  cy={cy}
		  startAngle={startAngle}
		  endAngle={endAngle}
		  innerRadius={outerRadius + 6}
		  outerRadius={outerRadius + 10}
		  fill={fill}
		/>
		<path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
		<circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
		<text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{value}</text>
		<text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
		  {`(Rate ${(percent * 100).toFixed(2)}%)`}
		</text>
	  </g>
	);
};

const axisParamSuffix = 'Param'
export default class Chart extends Component {
	constructor() {
		super()
		this.state = {
			list: [],
			yCount: 0,
			chartConfig: {},
			// pie
			activeIndex: 0,
		}
		const path = getActiveTabKey()
		this.designing = true
		if (path.indexOf('/chart/design') === -1) {
			this.designing = false
		}
	}
	
	onPieEnter(data, index) {
		this.setState({
			activeIndex: index,
		})
	}
	
	async componentDidMount() {
		if (!this.designing) {
			const id = parseSearchStr().id
			const record = await getOnePromise(chartdesign, id)
			const yCount = record.yCount || 0
			const chartConfig = record.chartConfig || {}
			const name = record.name || '图表'
			this.setState({yCount, chartConfig, name})
			this.init({yCount, chartConfig})
		} else {
			this.init()
		}
	}
	UNSAFE_componentWillReceiveProps(nextProps) {
		if (!equals(this.props.chartConfig, nextProps.chartConfig) || 
		this.props.yCount !== nextProps.yCount || this.props.name !== nextProps.name) {
			this.init(nextProps)
		}
	}
	async init(props = this.props) {
		const {chartConfig, yCount, name} = props
		if (this.designing) {
			this.setState({chartConfig, yCount, name})
		}
		let moduleName = chartConfig && chartConfig.moduleName
		if (isNotBlank(moduleName)) {
			const list = await getListAllPromise(moduleName)
			this.setState({list})
		}
	}

	getCom(type) {
		switch (type) {
			case 'line': return Line
			case 'bar': return Bar
			case 'area': return Area
			default: return Line
		}
	}
	createCom(axis) {
		const chartConfig = this.state.chartConfig || {}
		const config = chartConfig[axis + axisParamSuffix] || {}
		//console.log(axis, config)
		const {type, fill, ...chartProps} = config
		const com = this.getCom(type)
		chartProps.stroke = chartProps.fill = fill || "#8884d8"
		chartProps.dataKey = chartConfig[axis]
		chartProps.type = "monotone"
		chartProps.activeBar = <Rectangle fill="pink" stroke="blue" />
		return React.createElement(com, chartProps)
	}

	render() {
		const {chartsDivWidth, chartsDivHeight} = this.props
		const {list, chartConfig, yCount, name} = this.state
		let width = chartsDivWidth || clientWidth() - 32
		let height = chartsDivHeight || contentHeight()
		height -= 50
		const options = {width, height}
		const {type, fill, xAxis, yAxis, xorientation, yorientation} = chartConfig
		const xProps = {orientation: xorientation}
		const yProps = {orientation: yorientation}
		const y0 = 'yAxis' + 0
		const y1 = 'yAxis' + 1
		const y2 = 'yAxis' + 2
		const y3 = 'yAxis' + 3
		const y4 = 'yAxis' + 4
		const y5 = 'yAxis' + 5
		const y6 = 'yAxis' + 6
		const y7 = 'yAxis' + 7
		const y8 = 'yAxis' + 8
		const y9 = 'yAxis' + 9
		console.log('type', type)
		return <div className="centered" style={{width: chartsDivWidth, height: chartsDivHeight}}>
			<div style={{height: 50}}><h2>{name}</h2></div>
			{type === 'line' && <ComposedChart
				{...options}
				data={list}
				margin={{
					top: 4,
					right: 16,
					left: 16,
					bottom: 4,
				}}
			>
				<CartesianGrid strokeDasharray="3 3" />
				<XAxis dataKey={xAxis} {...xProps}/>
				<YAxis {...yProps}/>
				<Tooltip />
				<Legend />
				{this.createCom('yAxis')}
				{yCount > 0 && this.createCom(y0)}
				{yCount > 1 && this.createCom(y1)}
				{yCount > 2 && this.createCom(y2)}
				{yCount > 3 && this.createCom(y3)}
				{yCount > 4 && this.createCom(y4)}
				{yCount > 5 && this.createCom(y5)}
				{yCount > 6 && this.createCom(y6)}
				{yCount > 7 && this.createCom(y7)}
				{yCount > 8 && this.createCom(y8)}
				{yCount > 9 && this.createCom(y9)}
			</ComposedChart>}
			{type === 'pie' && <PieChart width={height} height={height}>
				<Pie
					activeIndex={this.state.activeIndex}
					activeShape={renderActiveShape}
					data={list}
					cx="50%"
					cy="50%"
					innerRadius={60}
					outerRadius={80}
					fill={fill}
					nameKey={xAxis}
					dataKey={yAxis}
					onMouseEnter={this.onPieEnter.bind(this)}
				/>
			</PieChart>}
			{type === 'radar' && <RadarChart outerRadius={90} width={width} height={height} data={list}>
				<PolarGrid />
				<PolarAngleAxis dataKey={xAxis} />
				<PolarRadiusAxis angle={30} domain={[0, 150]} />
				<Radar name={yAxis} dataKey={yAxis} stroke={fill} fill={fill} fillOpacity={0.6} />
				<Legend />
			</RadarChart>}
		</div>
	}
}