/**
 * 模块配置主页面
 * 创建、修改、删除、查询模块配置，根据模块配置生成模块
 */
import React, { Component } from 'react'
import Popconfirm from 'antd/lib/popconfirm'
import Button from 'antd/lib/button'
import Modal from 'antd/lib/modal'
import Upload from '@com/Upload'
import ButtonIcon from '@com/ButtonIcon'
import Tree from '@com/Tree'
import Fields from '@list/Fields'
import * as Action from './Action'
import {getCookie} from '@utils/cookie'
import { deleteFilesByBusinessId } from '../file/Action'
import ModalEx from '@com/ModalEx'
import * as dataUtil from '@utils/DataUtil'
import * as p from '@utils/page'
import * as utils from '@utils/index'
import { callApiBatch, getApiItem, getUpdateApiItem } from '../layout/Action'
import {treeDataObj} from '@utils/object'
import c from '@utils/constants'
import A from '@com/A'

const { addTreeNode, deleteTreeNode } = c

class TreeModule extends Component {
    /**
     * 使用全局变量保存页面状态
     */
    constructor(props) {
        super()
        this.moduleName = props.moduleName
        this.pageKey = this.moduleName + 'list'
        global[this.pageKey] = global[this.pageKey] || {}
        this.state = {
            editing: false,
            adding: false,
            node: global[this.pageKey].node || {},
            treeData: global[this.pageKey].treeData || [],
            expandedKeys: global[this.pageKey].expandedKeys || [],
        }
        this.id = global.uuid()
    }

    /**
     * componentDidMount 页面加载完成后获取数据
     */
    componentDidMount() {
        this.getTree()
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.moduleName && nextProps.moduleName !== this.props.moduleName) {
            this.moduleName = nextProps.moduleName
            this.refresh()
        }
    }

    /**
     * 获取树数据
     */
    getTree() {
        p.startLoad()
        Action.getTree(this.moduleName, treeData => {
            treeData = treeData || []
            if (global.equals(this.state.node, {})) {
                // 默认展开根节点
                let expandedKeys = this.state.expandedKeys
                
                if (expandedKeys.length === 0 && treeData.length > 0) {
                    expandedKeys = [treeData[0].key]
                }
                utils.storeData(this, this.pageKey, {
                    node: treeData[0],
                    expandedKeys: expandedKeys,
                    treeData: treeData, 
                })
            } else {
                utils.storeData(this, this.pageKey, {
                    treeData
                })
            }
            p.stopLoad()
        })
    }

    /**
     * 刷新页面
     */
    refresh() {
        this.getTree()
    }

    /**
     * 点击树节点
     */
    onSelect(selectedKeys, info) {
        this.setState({visible: true})
        utils.storeData(this, this.pageKey, {
            node: info.node
        })
    }

    /**
     * 展开树节点
     * @param {} expandedKeys 
     */
    onExpand(expandedKeys) {
        utils.storeData(this, this.pageKey, {
            expandedKeys: expandedKeys
        })
    }

    nodeChange(data, pid) {
        if (data) {
            data.key = data.id
            data.label = data.name
            let expandedKeys = this.state.expandedKeys
            if (pid && expandedKeys.indexOf(pid) === -1) {
                expandedKeys.push(pid)
            }
            utils.storeData(this, this.pageKey, {
                node: data, expandedKeys
            })
        }
        this.refresh()
    }

    /**
     * 修改保存时
     */
    async onSave(apiArray) {
        if (dataUtil.formError(this.fields)) 
            return false
        // 保存子模块
        if (!await dataUtil.saveSublist(apiArray, this.fields)) {
            return false
        }
        let record = this.fields.getFieldsValue()
        // 转换表单数据
        dataUtil.convertData(this.props.config, record, this.state.node)
        const id = this.state.node.id
        const success = (newData) => {
            treeDataObj[this.moduleName + 'update'] = true
            this.nodeChange()
            this.setState({node: newData, editing: false})
        }
        if (apiArray instanceof Array) {
            apiArray.push(getUpdateApiItem(this.moduleName, id, record, success))
        }
        return true
    }

    /**
     * 新增保存时
     */
    async onAddSave(apiArray) {
        if (dataUtil.formError(this.fields)) 
            return false
        // 保存子模块
        if (!await dataUtil.saveSublist(apiArray, this.fields)) {
            return false
        }
        const success = (newData, pid) => {
            treeDataObj[this.moduleName + 'update'] = true
            this.nodeChange(newData, pid)
            this.setState({adding: false})
        }
        let record = this.fields.getFieldsValue()
        // 转换表单数据
        dataUtil.convertData(this.props.config, record)
        record.id = this.id
        const pid = this.state.node.id
        if (apiArray instanceof Array) {
            apiArray.push(getApiItem(addTreeNode, this.moduleName, [pid, record], 
                (newData) => success(newData, pid)
            ))
        }
        return true
    }

    async deleteAction() {
        const getApiArray = async (apiArray) => await this.onDelete(apiArray)
        await callApiBatch(getApiArray, '删除成功')
    }

    /**
     * 删除操作
     */
    async onDelete(apiArray) {
        if (this.state.node.pid === null) {
            Modal.warning({title: '根节点不能删除'})
            return
        }
        let id = this.state.node.id
        const success = () => {
            treeDataObj[this.moduleName + 'update'] = true
            deleteFilesByBusinessId(id)
            utils.storeData(this, this.pageKey, {
                node: this.state.treeData[0]
            })
            this.refresh()
        }
        if (apiArray instanceof Array) {
            await dataUtil.delSubList(apiArray, [id], this.props.config)
            apiArray.push(getApiItem(deleteTreeNode, this.moduleName, [id], success))
        }
        return true
    }

    //导入数据
	handleChange(info) {
		//处理上传文件失败
		if (info.file.error) {
			Modal.error({
				title: '导入数据失败【' + info.file.name + '】' + (info.file.response && info.file.response.message),
				okText: "知道了",
			})
			this.setState({ importing: false })
		} else if (info.file.status === "uploading") {
			this.setState({ importing: true })
		} else if (info.file.status === "done") {
			//const response = info.file.response
			this.setState({ importing: false})
			this.refresh()
		}
    }

    /**
     * 页面渲染
     */
    render () {
        // 权限控制
        let moduleOpers = (global.role && global.role.moduleOpers) || []
        this.operView = global.isAdmin || moduleOpers.indexOf(`${this.moduleName}_ov`)
        this.operAdd = global.isAdmin || moduleOpers.indexOf(`${this.moduleName}_oa`)
        this.operUpdate = global.isAdmin || moduleOpers.indexOf(`${this.moduleName}_ou`)
        this.operDelete = global.isAdmin || moduleOpers.indexOf(`${this.moduleName}_od`)
        this.operImport = global.isAdmin || moduleOpers.indexOf(`${this.moduleName}_oi`)
        this.operExport = global.isAdmin || moduleOpers.indexOf(`${this.moduleName}_oe`)
        
        let tableConfig = this.props.config.fields_config || []
        const {treeData, editing, adding, node, expandedKeys} = this.state
        let action = 'detail'
        if (editing) {
            action = 'modify'
        } else if (adding) {
            action = 'new'
        }
        let data = {}
        data.id = this.id
        tableConfig.forEach(config => {
            data[config.name] = config.defaultValue
        })

        // 导入参数
        const importProps = {
			action: `${global.getApi().opts.baseURI}/${this.moduleName}/upload`,
			onChange: this.handleChange.bind(this),
			showUploadList: false,
        }
        const buttonDiv = <div style={{margin: 16, marginTop: 0}}>
            {this.operAdd && !(editing || adding) && 
            <Button className={global.buttonClass} type='primary' 
                onClick={() => {
                    this.id = global.uuid()
                    this.setState({visible: true, adding: true})
                }}
            ><ButtonIcon type='plus' name='新增'/></Button>}
            {this.operUpdate && !(editing || adding) && 
            <Button className={global.buttonClass} 
                onClick={() => this.setState({visible: true, editing: true})} 
                style={{marginLeft: 16}}
            ><ButtonIcon type='edit' name='修改'/></Button>}
            {this.operDelete && !(editing || adding) && 
            <Popconfirm title="确定要删除这条数据吗？" onConfirm={this.deleteAction.bind(this)}>
                <Button className={global.buttonClass}  
                    style={{marginLeft: 16}}
                ><ButtonIcon type='trash' name='删除'/></Button>
            </Popconfirm>}
            {editing && <Button className={global.buttonClass} type='primary' 
                onClick={ async () => {
                    const getApiArray = async (apiArray) => await this.onSave(apiArray)
                    await callApiBatch(getApiArray, '保存成功')
                }}
                style={{marginLeft: 16}}
            ><ButtonIcon type='save' name='保存'/></Button>}
            {editing && <Button className={global.buttonClass}
                onClick={() => {
                    this.fields.resetFields()
                    this.setState({editing: false})
                }} 
                style={{marginLeft: 16}}
            ><ButtonIcon type='close' name='取消'/></Button>}
            {adding && <Button className={global.buttonClass}
                type='primary' 
                onClick={ async () => {
                    const getApiArray = async (apiArray) => await this.onAddSave(apiArray)
                    await callApiBatch(getApiArray, '保存成功')
                }}
                style={{marginLeft: 16}}
            ><ButtonIcon type='save' name='保存'/></Button>}
            {adding && <Button className={global.buttonClass}
                onClick={() => {
                    this.fields.resetFields()
                    deleteFilesByBusinessId(this.id)
                    this.setState({adding: false})
                }} 
                style={{marginLeft: 16}}
            ><ButtonIcon type='close' name='取消'/></Button>}
            {this.operImport && <Button className={global.buttonClass}
                style={{ marginLeft: 16 }} 
                onClick={() => {
                    let url = `${global.getApi().opts.baseURI}/${this.moduleName}/download/excel`
                    const oReq = new XMLHttpRequest()
                    oReq.open("GET", url, true)
                    oReq.setRequestHeader('token', getCookie('token'))
                    oReq.responseType = "blob"
                    oReq.onload = (() => {
                        p.stopLoad()
                        if(oReq.status !== 200) {
                            Modal.error({
                                title: '下载导入模板失败',
                                content: oReq.response,
                                okText: "知道了",
                            })
                            return
                        }
                        const content = oReq.response
                        const elink = document.createElement('a')
                        elink.download = this.moduleName + '导入模板.xlsx'
                        elink.style.display = 'none'
                        const blob = new Blob([content])
                        elink.href = URL.createObjectURL(blob)
                        document.body.appendChild(elink)
                        elink.click()
                        document.body.removeChild(elink)
                    })
                    oReq.setRequestHeader("Content-Type", "application/json")
                    oReq.send(null)
                    //console.log(url, 'url')
                    //window.location = url
                }}
            ><ButtonIcon type="cloud-download" name='导入模板'/></Button>}  
            {this.operImport && <Upload {...importProps}>
                <Button className={global.buttonClass}
                    style={{ marginLeft: 16 }}
                ><ButtonIcon type="cloud-upload" name='导入'/></Button></Upload>}
        </div>

        const fieldsDiv = <Fields
            {...this.props}
            cols={this.props.cols > 1 ? this.props.cols - 1 : 1}
            tableConfig={tableConfig}
            moduleName={this.moduleName}
            data={action === 'new' ? data : node}
            id={action === 'new' ? data.id : node.id}
            ref={(inst) => this.fields = inst} 
            action={action}
        />

        let operName = ''
        if (adding) {
            operName = ' — 新增子分类'
        } else if (editing) {
            operName = ' — 编辑'
        } else {
            operName = ' — 详情'
        }
        return <div>
            <div style={{display: 'flex', width: '100%'}}>
                <div style={{
                    width: global.o2i(this.props.config.treewidth, 10) || 300,
                    height: global.tableHeight,
                    overflow: 'auto',
                    whiteSpace: 'nowrap'
                }}>
                    {global.isPhoneVertical && buttonDiv}
                    <Tree
                        defaultExpandRoot 
                        treeData={treeData || []} 
                        onSelect={this.onSelect.bind(this)} 
                        selectedKeys={[node && node.key]}
                        expandedKeys={expandedKeys}
                        onExpand={this.onExpand.bind(this)}
                    />
                </div>
                {!global.isPhoneVertical && <div style={{margin: 8, flex: 1}}>
                    {buttonDiv}
                    {fieldsDiv}
                </div>}
            </div>
            {global.isPhoneVertical && <ModalEx visible={this.state.visible}
                title={<div className='space-between'>
                    <label>{node.name + operName}</label>
                    <label style={{padding: '0 16px'}}
                        onClick={async () => {
                            const getApiArray = async (apiArray) => {
                                if (adding) {
                                    return await this.onAddSave(apiArray)
                                } else if (editing) {
                                    return await this.onSave(apiArray)
                                }
                            }
                            await callApiBatch(getApiArray, '保存成功')
                            this.setState({visible: false})
                        }}
                    ><A>保存</A></label>
                </div>}
                onClose={() => this.setState({visible: false, adding: false, editing: false})}
            >{fieldsDiv}</ModalEx>}
        </div>
    }
}
export default TreeModule