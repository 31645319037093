import Api from './api'
import {getCookie} from '../cookie'

const getBaseUri = () => {
	let entid = getCookie('entid')
	// 前端运行环境
	let baseURI = `${document.location.protocol}//${window.location.host}/enterprise/${entid}`

	// 如果前端本地运行
	global.localFrontEnd = ['localhost', '127.0.0.1'].indexOf(window.location.hostname) !== -1
	if (global.localFrontEnd) {
		// 默认访问服务器nginx，nginx 将 /enterprise 前缀请求转发到平台网管
		// 平台网管解析企业id后, 经请求转发到 企业服务
		//baseURI = `${document.location.protocol}//192.168.0.10/enterprise/${entid}`
		baseURI = `${document.location.protocol}//47.107.127.79/enterprise/${entid}`
		//baseURI = `https://adamroberthall.yicp.io/enterprise/${entid}`
		// 访问本机企业后端， 不需要企业id，不经过网管，直接访问
		global.localEntServer = true
		if (global.localEntServer) {
			baseURI = `${document.location.protocol}//localhost:41787`

			// 访问本机网管，由本机网管解析企业id，转发到企业服务
			global.localGateway = false
			if (global.localGateway) {
				baseURI = `${document.location.protocol}//localhost:10087/${entid}`
			}
		}
	}
	return baseURI
}



/**
 * json数据格式
 */
const api = new Api({
	getBaseUri: getBaseUri,
	baseURI: getBaseUri(),
	headers: {
		'Accept': 'application/json',
		'Content-Type': 'application/json'
	}
})

export default api


