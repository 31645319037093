import { openpage } from '@utils/page'
import { createIcon } from '@com/PageCreator'


export function iconOperation(text, record) {
    return createIcon('pencil', '设计报表', () => {
        let url = `/report/design?id=${record.id}`
        let title = `报表设计_${record.name}`
        openpage(url, title)
    }, {key: text + 'pencil'})
}