/**
 * 模块选择组件
 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import OOSelect from './OOSelect'
import * as dataUtil from '@utils/DataUtil'
import * as utils from '@utils/index'
import { tables_config } from '@utils/constants'

export default class ModuleSelect extends Component {
    constructor() {
        super()
        this.state = {
            options: []
        }
    }
    componentDidMount() {
        this.refresh(this.props)
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.params !== this.props.params) {
            this.refresh(nextProps)
        }
    }
    refresh(props) {
        let moduleConfigs = utils.getModuleConfigs()
        if (moduleConfigs instanceof Array && moduleConfigs.length > 0) {
            let list = moduleConfigs.filter(it => !utils.isSysModule(it.modulename) )
            if (utils.isNotBlank(props.params)) {
                if (props.sublist instanceof Array) {
                    let sublistNameArr = dataUtil.getSublistNameArr(props.sublist)
                    list = list.filter(it => sublistNameArr.indexOf(it.modulename) !== -1)
                } else {
                    list = list.filter(it => it.moduletype === props.params)
                }
            }
            const options = list.map((module, index) => {
                const label = global.showModuleName(module)
                const value = module.modulename
                return {value, label}
            })
            this.setState({options})
        }
    }
    
    render() {
        const {style, ...restProps} = this.props
        const {options} = this.state
        const styleMerge = Object.assign({display: 'inline-block', width: '100%'}, style)
        return <OOSelect {...restProps}
            moduleName={tables_config}
            options={options} 
            style={styleMerge}
            placeholder='请选择模块'
        />
    }
}
ModuleSelect.propTypes = {
    // 禁止修改
    disabled: PropTypes.bool,
    // 数据
    value: PropTypes.any,
    // 数据变化回调
    onChange: PropTypes.func,
    // 子模块, 从指定子模块中选
    sublist: PropTypes.array,
    // 数据模型
    params: PropTypes.string,
    // 自定义样式
    style: PropTypes.object,
}