/**
 * SubListConfig
 * 子模块类型编辑器
 */
import React, { Component } from 'react'
import OOTableEx from './OOTableEx'
import Tag from './OOTag'
import Popover from 'antd/lib/popover'
import * as dataUtil from '@utils/DataUtil'
import * as utils from '@utils/index'
import { tables_config } from '@utils/constants'

export default class SubListConfig extends Component {
    constructor(props) {
        super()
        this.state = {
            list: [],
        }
    }

    componentDidMount() {
        this.init()
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.props.value) {
            this.init(nextProps)
        }
    }

    init(props = this.props) {
        this.fieldsConfig = [
            dataUtil.getConfig('moduleName', '模块名称', 'ModuleSelect', {
                isRequire: '1',
                params: 'list'
            }),
            dataUtil.getConfig('notEmpty', '不能为空', 'CHECKBOX', {width: 80}),
            dataUtil.getConfig('disabledConds', '隐藏条件', 'CondEdit', {
                width: 120,
                otherParams: {
                    moduleName: props.oodata.modulename, 
                    buttonName: '', title: '隐藏子模块条件',
                    style: {},
                    showType: 'icon'
                }
            }),
            dataUtil.getConfig('disabledShowModifyConds', '详情可修改', 'CondEdit', {
                width: 120,
                otherParams: {
                    icon: 'eye',
                    moduleName: props.oodata.modulename, 
                    buttonName: '', title: '详情可修改',
                    style: {},
                    showType: 'icon'
                }
            }),
            dataUtil.getConfig('disabledCopy', '禁止复用', 'CHECKBOX', {width: 80}),
            dataUtil.getConfig('innernested', '行内嵌套', 'CHECKBOX', {width: 80}),
            dataUtil.getConfig('hideToolbar', '隐藏工具栏', 'CHECKBOX', {width: 80}),
            dataUtil.getConfig('hideSearch', '隐藏搜索', 'CHECKBOX', {width: 80}),
        ]
        const {value} = props
        let list = []
        if (utils.isBlank(value)) {
            list = []
        } else if (value instanceof Array) {
            for (let i in value) {
                let item = value[i]
                if (typeof item === 'string') {
                    value[i] = {moduleName: item}
                }
            }
            list = value
        } else if (typeof value === 'string') {
            list = [{moduleName: value}]
        }
        this.setState({list})
    }

    formError() {
        return this.ootable && this.ootable.formError && this.ootable.formError()
    }

    render() {
        const {intable, ...restProps} = this.props
        if (intable === '1') {
            const tags = this.state.list.map((item, index) => {
                const children = dataUtil.showModuleName(item.moduleName)
                return <Tag key={index} moduleName={tables_config} value={item.moduleName}>{children}</Tag>
            })
            return <Popover
                content={<div>{tags}</div>}
            >{tags}</Popover>
        }
        return <OOTableEx 
            {...restProps} 
            ref={(inst) => this.ootable = this.ootable || inst}
            value={this.state.list}
            fieldsConfig={this.fieldsConfig}
        />
    }
}