let businessProcessMenuData = [{
	id: '1',
	type: 'conctrol',
	name: '控制组件',
	ico: 'setting',
	open: true,
	children: [
		{
			id: '11',
			type: 'process-start',
			name: '流程开始',
			ico: 'play-circle',
			// 自定义覆盖样式
			style: {
				background: '#7fecad'
			}
		},
		{
			id: '12',
			type: 'process-end',
			name: '流程结束',
			ico: 'pause',
			// 自定义覆盖样式
			style: {
				background: '#7fecad'
			}
		},
		{
			id: '13',
			type: 'process-branch',
			name: '分支',
			ico: 'random',
			// 自定义覆盖样式
			style: {
				background: '#d6ecf0'
			}
		},
		// {
		//	 id: '14',
		//	 type: 'process-async-start',
		//	 name: '并行开始',
		//	 ico: 'el-icon-s-operation',
		//	 // 自定义覆盖样式
		//	 style: {
		//		 background: '#fff2df'
		//	 }
		// },
		// {
		//	 id: '15',
		//	 type: 'process-async-end',
		//	 name: '并行结束',
		//	 ico: 'el-icon-s-operation',
		//	 // 自定义覆盖样式
		//	 style: {
		//		 background: '#fff2df'
		//	 }
		// },
		{
			id: '16',
			type: 'loop-start',
			name: '循环开始',
			ico: 'repeat',
			// 自定义覆盖样式
			style: {
				background: '#c0ebd7'
			}
		},
		{
			id: '17',
			type: 'loop-end',
			name: '循环结束',
			ico: 'stop-circle',
			// 自定义覆盖样式
			style: {
				background: '#c0ebd7'
			}
		},
	]
}]
export default businessProcessMenuData
