import React, { Component } from 'react'
import {createIcon} from '@com/PageCreator'
import * as Action from './Action'
import {flowDetail, showFlowGraph} from './FlowUtil'
import {moduleConfig} from './Config'
import ListModule from '@list/ListModule'
import { flow } from '@utils/constants'

class Done extends Component {
    constructor() {
        super()
        this.state = {
            list: [],
        }
    }

    iconOperation(text, record) {
        return <span key={record.id}>
            {createIcon('file-text', '流程详情', flowDetail.bind(this, record))}
            {createIcon('random', '流程图', showFlowGraph.bind(this, record))}
        </span>
    }

    render() {
        return <ListModule 
            {...this.props}
            hideSelect
            getListPromise={Action.processDone}
            moduleName={flow}
            config={moduleConfig}
            iconOperation={this.iconOperation.bind(this)}
        />
    }
}
export default Done