/**
 * 模块字段多选组件，下拉框形式
 */
import React, { Component } from "react"
import OOSelect from "./OOSelect"
import * as dataUtil from '@utils/DataUtil'
import * as utils from '@utils/index'

export default class FieldSelect extends Component {
    constructor() {
        super()
        this.state = {
            allKeyLabels: [],
            typeMap: {},
            fieldsConfig: [],
        }
    }

    componentDidMount() {
        this.init()
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.moduleName !== nextProps.moduleName) {
            this.init(nextProps)
        }
    }

    init(props = this.props) {
        const {moduleName} = props
        if (utils.isBlank(moduleName)) {
            return
        }
        const {
            allKeyLabels, typeMap, fieldsConfig
        } = dataUtil.getFieldOptions(moduleName, props)
        this.setState({allKeyLabels, typeMap, fieldsConfig})
    }

    onChange(value) {
        if (this.props.mode === 'multiple') {
            let dataTypes = value.map(key => this.state.typeMap[key])
            this.props.onChange && this.props.onChange(value, dataTypes)
        } else {
            const config = global.getFieldConfig(this.state.fieldsConfig, value) || {}
            let dataType = config.dataType
            this.props.onChange && this.props.onChange(value, dataType, config)
        }
    }

    render() {
        const {moduleName, ...restProps} = this.props
        const {allKeyLabels} = this.state
        return <OOSelect
            {...restProps}
            onChange={this.onChange.bind(this)}
            valueKey='key'
            options={allKeyLabels}
            placeholder='请选择字段'
        />
    }
}