/**
 * DataUtil
 * 数据处理工具
 */
import {sha1} from './crypto'
import {delById} from '../modules/file/Action'
import {getListAllPromise, getListPromise} from '@list/Action'
import {getApiItem} from '../modules/layout/Action'
import * as utils from './index'
import { pinyin } from '../pinyin'
import {getCookie} from './cookie'
import moment from 'moment'
import * as p from '@utils/page'
import c, { batchSave } from '@utils/constants'
import * as o from '@utils/object'

const {datatransferList, orchestrateList, forms, pageInstObj} = o

function jianpin(name) {
    if (utils.isBlank(name)) {
        return undefined
    }
    return pinyin(name, { pattern: 'first', toneType: 'none'}).replace(/[^a-z]/g, '')
}
function quanpin(name) {
    if (utils.isBlank(name)) {
        return undefined
    }
    return pinyin(name, {toneType: 'none'}).replace(/[^a-z]/g, '')
}
function addPinyin(record, name) {
    record[name + '_q'] = quanpin(record[name])
    record[name + '_j'] = jianpin(record[name])
}
function getQuanpinKey(name) {
    return name + '_q'
}
function getJianpinKey(name) {
    return name + '_j'
}

const {
    // 字段展示类型
    // 数据类型
    STRING,		// 文本
    TEXT,		// 文本域
    SELECT,		// 选择
    SELECTMULTI,// 多选
    OPTION,		// 选项组
    INPUTSELECT,// 可输可选
    NUMBER,		// 数字
    PERCENT,	// 百分比
    MONEY,		// 金额
    DATE,		// 日期
    TIME,		// 时间
    CHECKBOX,	// 复选框
    PHONE,		// 手机
    LISTMODULE,	// 列表模块
    TREEMODULE,	// 树模块
    TABLE,		// 表格
    FILE,		// 图片
    EXPR,		// 表达式
    SUM,		// 合计
    FFIELD,		// 默认父列
    TAG,		// 标签
    GROUP,		// 分组
    COLOR,      // 颜色选择器
    ID,         // 精确查询时使用
    // 系统内置组件
    IconSelect, 	 // 图标选择组件
    SubListConfig, 	 // 子模块配置组件
    CodeItemOOTable, // 编码方案码段组件
    CondOOTable, 	 // 条件表格组件
    Pipeline, 	     // 管道配置组件
    Mapping, 	     // 映射组件
    CondEdit,        // 条件编辑组件
    datatransfer, orchestrate, openpage, batchDeleteByFid, sublistPrefix
} = c

// 数据类型
const dataTypeArray = [
    {value: STRING, label: '文本'}, 
    {value: TEXT, label: '文本域'}, 
    {value: SELECT, label: '选择'}, 
    {value: SELECTMULTI, label: '多选'}, 
    {value: OPTION, label: '选项组'}, 
    {value: INPUTSELECT, label: '可输可选'}, 
    {value: NUMBER, label: '数字'}, 
    {value: PERCENT, label: '百分比'}, 
    {value: MONEY, label: '金额'}, 
    {value: DATE, label: '日期'}, 
    {value: TIME, label: '时间'},
    {value: CHECKBOX, label: '复选框'}, 
    {value: PHONE, label: '手机'}, 
    {value: LISTMODULE, label: '列表模块'},
    {value: TREEMODULE, label: '树模块'},
    {value: TABLE, label: '表格'},
    {value: FILE, label: '图片'},
    {value: EXPR, label: '表达式'},
    {value: SUM, label: '合计'},
    {value: FFIELD, label: '默认父列'},
    {value: TAG, label: '标签'},
    {value: GROUP, label: '分组'},
]

const numberConfig = {width:160,dataType: NUMBER}
const stringConfig = {width:160,dataType: STRING}
const flowstatusConfig = {name:'flowstatus', alias: "流程状态",width:120,
    dataType: SELECT,defaultValue:"editing",
    params: "editing,编制中/reviewing,审核中/published,已审核",
    isDisabled: "1"
}
function getFlowstatusConfig() {
    return utils.copy(flowstatusConfig)
}
function getStringConfig(name, alias, configObj = {}) {
    return Object.assign({name, alias, width:160, dataType: STRING, isSort: '0'}, configObj)
}
function getNumberConfig(name, alias, configObj = {}) {
    return Object.assign({name, alias, width:160, dataType: NUMBER, isSort: '1'}, configObj)
}
function getConfig(name, alias, dataType = "STRING", configObj = {}) {
    return Object.assign({name, alias, dataType, width:160, isSort: '0'}, configObj)
}
function getClassConfig(classModuleName, catagorycolumnwidth) {
    const classModuleConfig = utils.getConfigByModuleName(classModuleName)
    let width = utils.o2i(catagorycolumnwidth, 10) || 160
    return {
        name: classModuleName,
        alias: showModuleName(classModuleConfig), 
        isRequire: "1", isQuery: "0", 
        width: width, 
        fixed: 'left', 
        dataType: TREEMODULE, params: classModuleName
    }
}

/**
 * 操作选项
 */
const operOptions = [
    {value: '===', label: '等于'},
    {value: '!==', label: '不等于'},
    {value: '>', label: '大于'},
    {value: '>=', label: '大于等于'},
    {value: '<', label: '小于'},
    {value: '<=', label: '小于等于'},
]

/**
 * 数据类型全量名称
 */
const dataTypeNames = dataTypeArray.map(it => it.value)

/**
 * 数字类型
 */
const numberTypes = [NUMBER, MONEY, PERCENT, EXPR, SUM]

/**
 * ID类型
 */
function isIDType(dataType) { return dataType === ID }

/**
 * 系统定义数据类型
 */
function isSysDataType(dataType) { return dataTypeNames.indexOf(dataType) !== -1 }

/**
 * 选择类型组件
 */
function isSelectType(dataType) { return [SELECT, INPUTSELECT, SELECTMULTI].indexOf(dataType) !== -1 }

// 单选，可输可选
function isSelectOneType(dataType) { return [SELECT, INPUTSELECT].indexOf(dataType) !== -1 }

// 多选
function isSelectMultiType(dataType) { return [SELECTMULTI].indexOf(dataType) !== -1 }

// Antd Select
function isAntdSelectType(dataType) { return [SELECT, SELECTMULTI].indexOf(dataType) !== -1 }

// 可输可选
function isInputSelectType(dataType) { return [INPUTSELECT].indexOf(dataType) !== -1 }

/**
 * 表达式中可以解析为数字的类型
 */
function isExprSupportType(dataType) { 
    return [NUMBER, MONEY, PERCENT, SELECT, INPUTSELECT, EXPR, SUM]
    .indexOf(dataType) !== -1 
}

/**
 * 数字,金额类型
 */
function isNumberMoneyType(dataType) { return [NUMBER, MONEY].indexOf(dataType) !== -1 }
function isNumberType(dataType) { return dataType === NUMBER }
function isMoneyType(dataType) { return dataType === MONEY }
/**
 * 百分比类型
 */
function isPercentType(dataType) { return dataType === PERCENT }

/**
 * 日期时间类型
 */
function isDateTimeType(dataType) { return [DATE, TIME].indexOf(dataType) !== -1 }
function isDateType(dataType) { return dataType === DATE }
function isTimeType(dataType) { return dataType === TIME }

/**
 * 复选框
 */
function isCheckboxType(dataType) { return dataType === CHECKBOX }

/**
 * 文本类型
 */
function isStringTextType(dataType) { return [STRING, TEXT].indexOf(dataType) !== -1 }

/**
 * 文本域类型， 多行
 */
function isTextType(dataType) { return dataType === TEXT }
/**
 * 文本类型， 单行
 */
function isStringType(dataType) { return dataType === STRING }
/**
 * 输入类型， Input
 */
function isInputType(dataType) { return [STRING, PHONE].indexOf(dataType) !== -1 }
/**
 * 手机号， Input
 */
function isPhoneType(dataType) { return dataType === PHONE }

/**
 * 表达式类型， ExprSpan
 */
function isFileType(dataType) { return dataType === FILE }

/**
 * 表达式类型， ExprSpan
 */
function isExprType(dataType) { return dataType === EXPR }

/**
 * 列表或树模型
 */
function isListTreeType(dataType) { 
    return [LISTMODULE, TREEMODULE].indexOf(dataType) !== -1 
}

/**
 * 列表模型
 */
function isListType(dataType) { 
    return dataType === LISTMODULE 
}

/**
 * 树模型
 */
function isTreeType(dataType) { 
    return dataType === TREEMODULE 
}

/**
 * 表格
 */
function isTableType(dataType) { return dataType === TABLE }

/**
 * 子模块合计
 */
function isSumType(dataType) { return dataType === SUM }

/**
 * 使用父模块字段作为默认值
 */
function isFFieldType(dataType) { return dataType === FFIELD }

/**
 * 选项组类型
 */
function isOptionType(dataType) { return dataType === OPTION }

/**
 * 图标类型
 */
function isIconType(dataType) { return dataType === IconSelect }

/**
 * 子模块配置组件
 */
function isSubListConfigType(dataType) { return dataType === SubListConfig }

/**
 * 编码方案码段组件
 * @param {*} dataType 
 * @returns 
 */
function isCodeItemOOTableType(dataType) { return dataType === CodeItemOOTable }

/**
 * 条件编辑组件
 */
function isCondOOTableType(dataType) { return dataType === CondOOTable }

/**
 * 管道类型
 */
function isPipelineType(dataType) { return dataType === Pipeline }

/**
 * 数据传递组件
 */
function isMappingType(dataType) { return dataType === Mapping }
function isCondEditType(dataType) { return dataType === CondEdit }

/**
 * 分组类型
 */
function isTagType(dataType) { return dataType === TAG }

/**
 * 分组类型
 */
function isGroupType(dataType) { return dataType === GROUP }

/**
 * 颜色类型
 */
function isColorType(dataType) { return dataType === COLOR }


/**
 * 使用父模块字段作为默认值, 且不可修改
 */
function isDisabledFFieldType(config) {
    return isFFieldType(config.dataType) && config.isDisabled === '1'
}

/**
 * 是否为数字类型
 * @param {String} dataType 
 * @returns 
 */
function isNumber(dataType) {
    return isNumberMoneyType(dataType) || isPercentType(dataType) || 
        isExprType(dataType) || isSumType(dataType)
}

/**
 * 表达式中可以解析为数字的类型, 根据配置分析所有类型
 * @param {Config} config 
 * @returns 
 */
function isExprSupportConfig(config) {
    if (isFFieldType(config.dataType)) {
        let configCopy = utils.copy(config)
        mergeFConfig(configCopy)
        return isExprSupportType(config.dataType)
    }
    return isExprSupportType(config.dataType)
}

// 文件、文本域、表格、分组类型占一整行
function isRowOneType(dataType) {
    return isFileType(dataType) || isTextType(dataType) ||
        isOOTableType(dataType) || isGroupType(dataType)
}

// 搜索时隐藏的类型： 分组、文件、图标、选项、表格类型
function isHideOnSearchType(dataType) {
    return isGroupType(dataType) || isFileType(dataType) || isIconType(dataType) || 
        isOOTableType(dataType) || isMappingType(dataType)
}

// 表格类型：  选项、子模块配置、条件编辑、 管道
function isOOTableType(dataType) {
    return isTableType(dataType) || 
    isSubListConfigType(dataType) || isCodeItemOOTableType(dataType) ||
    isOptionType(dataType) || isCondOOTableType(dataType) || isPipelineType(dataType)
}

// 表格、分组类型 标签使用 h2
function isH2LabelType(dataType) {
    return isOOTableType(dataType) || isGroupType(dataType)
}

function isCenteredType(dataType) {
    return isCheckboxType(dataType) || isTagType(dataType) || isIconType(dataType) ||
    isMappingType(dataType) || isCondEditType(dataType)
}

// 需要在getFieldDecorator 中定义的参数
function isFieldDecoratorProps(key) { return ['initialValue', 'onChange', 'valuePropName', 'trigger', 'validateTrigger', 'exclusive', 'rules'].indexOf(key) !== -1 }

/**
 * 合并父列默认类型配置
 * @param {*} config 
 */
function mergeFConfig(config) {
    if (isFFieldType(config.dataType)) {
        // 替换原始字段配置， 值保留当前 config.name
        // 默认父列 使用父模块字段配置
        const otherParams = config.otherParams || {}
        const {fmodule, name} = otherParams
        const fConfig = utils.getConfig(fmodule, name)
        if (null == fConfig) {
            throw new Error(`默认父列${config.name}, 父列配置未找到， 参数 ${utils.toString(otherParams)}`)
        }
        if (isSumType(fConfig.dataType)) {
            mergeSumConfig(fConfig)
            Object.assign(config.otherParams, fConfig.otherParams)
        }
        config.params = fConfig.params
        config.dataType = fConfig.dataType
    }
}
/**
 * 合并合计类型配置
 * @param {*} config 
 */
function mergeSumConfig(config) {
    if (config && isSumType(config.dataType)) {
        // 合并合计子模块字段参数
        const {modulename, name} = config.otherParams
        const subConfig = utils.getConfig(modulename, name)
        if (null == subConfig) {
            throw new Error(`合计字段${config.name}, 配置未找到， 参数 ${utils.toString(config.otherParams)}`)
        }
        config.otherParams = config.otherParams || {}
        Object.assign(config.otherParams, subConfig.otherParams)
    }
}
// 获取字段真实类型
function getRealType(config) {
    if (config && config.dataType) {
        try {
            if (isFFieldType(config.dataType)) {
                const fConfig = utils.copy(config)
                mergeFConfig(fConfig)
                return fConfig.dataType          
            } else if (isSumType(config)) {
                const sumConfig = utils.copy(config)
                mergeSumConfig(sumConfig)
                return sumConfig.dataType
            }
        } catch (e) {
            console.error(e)
        }
        return config.dataType
    }
    return null
}

/**
 * getMergeConfig
 * 获取合并后的系统配置
 * 模块有流程则添加流程列配置
 * 模块有分类则添加分类列配置
 * @param {*} moduleName 
 * @returns 
 */
function getMergeConfig(moduleName) {
    let config = utils.getConfigByModuleName(moduleName)
    return getMergeConfigByConfig(config)
}
function getMergeConfigByConfig(config) {
    if (config == null || utils.equals(config, {})) {
        return {}
    }
    config = utils.copy(config)
    let fieldsConfig = config.fields_config || []
    // 模块有流程
    if (config.hasflow && fieldsConfig.findIndex(it => it.name === flowstatusConfig.name) === -1) {
        fieldsConfig.push(getFlowstatusConfig())
    }
    if (utils.isNotBlank(config.catagory)) {
        // 模块有分类
        const classConfig = getClassConfig(config.catagory, config.catagorycolumnwidth)
        if (classConfig && fieldsConfig.findIndex(it => it.name === config.catagory) === -1) {
            fieldsConfig.unshift(classConfig)
        }
    }
    config.fields_config = fieldsConfig
    return config
}
/**
 * getTableConfig
 * 获取合并后的表格配置
 * @param {*} moduleName 
 * @returns fields_config
 */
function getTableConfig(moduleName) {
    if (utils.isBlank(moduleName) || utils.equals(moduleName, {})) {
        return []
    }
    // moduleName 是模块名称
    if (typeof moduleName === 'string') {
        return getMergeConfig(moduleName).fields_config || []
    }
    // moduleName 是模块配置
    return getMergeConfigByConfig(moduleName).fields_config || []
}

/**
 * getFieldOptions
 * 获取模块字段配置 key， label数组及类型map， 供选择组件使用
 * @param {*} moduleName 
 * @returns 
 */
function getFieldOptions(moduleName, props = {}) {
    const {showAll, showId, dataType} = props
    const config = utils.getConfigByModuleName(moduleName)
    let fieldsConfig = getTableConfig(moduleName).filter(
        it => {
            let ret = showAll || !utils.isSysFields(it.name)
            if (dataType) {
                if (dataType instanceof Array) {
                    ret = ret && -1 !== dataType.indexOf(it.dataType)
                } else {
                    ret = ret && it.dataType === dataType
                }
            }
            return ret
        }
    )
    let allKeyLabels = []
    let typeMap = {}
    if (config.moduletype !== 'tree' && showId) {
        allKeyLabels.push({key: 'id', label: 'id'})
        allKeyLabels.push({key: 'fid', label: '父模块id'})
    }
    
    for (let field of fieldsConfig) {
        let key = field.name
        let label = showName(field)
        allKeyLabels.push({key, label})
        typeMap[key] = field.dataType
        if (isListTreeType(getRealType(field))) {
            key += '_name'
            label += '_名称'
            allKeyLabels.push({key, label})
            typeMap[key] = stringConfig.dataType
        }
    }
    return {allKeyLabels, typeMap, fieldsConfig}
}

// 仅过滤出字段名和数据类型，以供后端生成查询条件
function filterDataType(fieldsConfig) {
    return fieldsConfig.map(config => {
        let {name, dataType} = config
        dataType = getRealType(config)
        return {id: name, dataType}
    })
}

/**
 * 校验表单是否包含错误
 * @param {*} form 
 * @returns 
 */
function formError(form) {
    if (form == null) {
        return true
    }
    let hasError = false
    // 校验数据
    form.validateFields((errors, values) => {
        if (!!errors) {
            hasError = true
        }
    })
    return hasError
}

/**
 * 扁平化对象
 * 报表引擎生成的报表使用
 * @param {Object} obj 传入对象
 * @param {String} prefix key前缀，内部使用，调用是传空
 * @param {Object} newObj 扁平化后的对象
 * @param {Array<Config>} fieldsConfig 字段配置数组
 * @returns 
 */
function flatObj(obj, prefix = '', newObj, fieldsConfig) {
    if (obj instanceof Array) {
        return
    }
    if (obj instanceof Object) {
        for (let key in obj) {
            //console.log(key, 'key---------------------')
            let value = obj[key]
            // 列表模型和树模型的值为对象，不扁平化
            let config = fieldsConfig.find(it => it.id === key)
            if (config && isListTreeType(config.dataType)) {
                newObj[prefix + key] = value
            } else if (value instanceof Array) {
                newObj[prefix + key] = value
            } else if (value instanceof Object) {
                flatObj(value, prefix + key + '.', newObj, fieldsConfig)
            } else if (value != null) {
                newObj[prefix + key] = value
            }
        }
    }
}

/**
 * 转化表单数据
 * @param {Object | String} moduleConfig 模块配置 或 moduleName模块名称
 * @param {*} record 表单收集的数据
 * @param {*} stateRecord 页面state中的数据
 */
function convertData(moduleConfig/* 或 moduleName */, record, stateRecord = {}) {
    if (typeof moduleConfig === 'string') {
        moduleConfig = utils.getModuleConfig(moduleConfig)
        if (!(moduleConfig.fields_config instanceof Array && moduleConfig.fields_config.length > 0)) {
            console.error('模块配置未找到，模块名称： ', moduleConfig)
            return {}
        }
    }
    const tableConfig = utils.copy((moduleConfig && moduleConfig.fields_config) || [])
    // 分类模块名称
    const classModuleName = moduleConfig.catagory
    // 模型有分类则获取分类数据
    const hasClass = utils.isNotBlank(classModuleName)
    for (let fieldConfig of tableConfig) {
        const {name, dataType, params, otherParams} = fieldConfig
        if (isGroupType(dataType)) {
            delete record[name]
            continue
        }
        if (utils.isNotBlank(params)) {
            delete record[name + '_options']
        }
        if (isFFieldType(dataType)) {
            // 获取 默认父列 的数据类型
            fieldConfig.dataType = getFDataType(otherParams)
        } else if (isStringTextType(dataType)) {
            // 额外存储全拼和简拼
            record[name + '_q'] = quanpin(record[name])
            record[name + '_j'] = jianpin(record[name])
        }
    }
    // 建立字段-数据类型map
    const nameTypeMap = {}
    for (let config of tableConfig) {
        nameTypeMap[config.name] = config.dataType
    }

    const setModuleField = (key, record, isTree) => {
        //console.log('key value:', key, record[key])
        let idKey = `${key}_id`
        let nameKey = `${key}_name`
        let classesKey = `${key}_classes`
        let classnamesKey = `${key}_classnames`
        if (record[key] instanceof Object) {
            let value = record[key] 
            record[idKey] = value.id
            record[nameKey] = value.name
            if (isTree) {
                record[classesKey] = value.classes
                record[classnamesKey] = value.classnames
            }
            record[key] = value.code
        } else if (record[key] == null) {
            record[idKey] = null
            record[nameKey] = null
            if (isTree) {
                record[classesKey] = null
                record[classnamesKey] = null
            }
            record[key] = null
        }
    }
    
    // 特殊字段处理回调函数
    for (let key in record) {
        // 删除系统内置字段
        if (utils.isSysFields(key)) {
            delete record[key]
            continue
        }
        // TREEMODULE类型的值为对象，{id, code, name, classnames}
        if (isTreeType(nameTypeMap[key]) && record[key]) {
            setModuleField(key, record, true)
            continue
        }
        // LISTMODULE类型的值为对象，{id, code, name}
        if (isListType(nameTypeMap[key]) && record[key]) {
            setModuleField(key, record)
            continue
        }
        // 数字类型处理，去掉过长的小数
        if (record[key] && isNumber(nameTypeMap[key]) ) {
            record[key] = utils.toFixedNumber(record[key], 11)
        }
        // 加密密码
        if (`password` === key && record[key] && record[key] !== stateRecord[key]) {
            record[key] = sha1(record[key])
            continue
        }
        // FILE类型处理, 文件id发生变化，删除旧文件
        if (isFileType(nameTypeMap[key])&& stateRecord[key] && record[key] !== stateRecord[key]) {
            let oldFileId = stateRecord[key]
            delById(oldFileId)
        }
        // 将组件中获取的 undefined 值转为 null, 才能保存到数据库
        if (record[key] === undefined) {
            record[key] = null
        }
        // 去掉文本前后空格
        if (typeof record[key] === 'string') {
            record[key] = record[key].trim()
        }
    }
    // 处理分类字段
    if (hasClass && utils.isNotBlank(classModuleName)) {
        let key = classModuleName
        setModuleField(key, record, true)
    }
    return record
}

/**
 * 获取数据传递字段映射
 * @param {*} selectmodule 数据源模块名
 * @param {*} transferModules 数据传递配置列表
    rows: [
        {
            id: "20de3060522311efbef2b75711d3504c",
            value: "产品",
            subList: [
                "产品明细"
            ],
            name: "从产品中选",
            moduleName: "产品",
            // 数据传递字段映射
            mapping: {
                code: "code",
                name: "name",
                "规格": "规格",
                key: "产品",
                "单位_options": "计量单位_name",
                "单位_optionsalias": "计量单位_名称"
            }
            ------------------------------------------------------------
            有子模块时的映射
            mapping: {
                "工艺"： {
                    code: "code",
                }，
                "适用物料": {
                    code: "code",
                }
            }
        }
    ],
 * @returns transferMap
    {
        code: "code",
        name: "name",
        "规格": "规格",
        key: "产品",
        "单位_options": "计量单位_name",
        "单位_optionsalias": "计量单位_名称"
    }
 */
function getTransferMap(selectmodule, transferModules) {
    let row = transferModules.find(item => item.selectmodule === selectmodule)
    if (row == null) {
        return null
    }
    const {mapping, modulename} = row
    // 子模块映射不为空
    if (hasSublistMapping(row)) {
        return mapping[modulename]
    }
    return mapping
}
/**
 * 获取数据传递映射
 * @param {*} transferModule 
 * @returns 
 */
function getTransferMapByTransferModule(transferModule) {
    if (transferModule == null) {
        return null
    }
    const {mapping, modulename} = transferModule
    // 子模块映射不为空
    if (hasSublistMapping(transferModule)) {
        return mapping[modulename]
    }
    return mapping
}

/**
 * 数据传递 ModuleDataTransfer ModuleDataSelect 选择数据 -> 表格
 * @param {*} moduleName 表格模块名称
 * @param {*} tableRecord 表格数据
 * @param {*} selectmodule 选择字段模块名
 * @param {*} tranferRecord ModuleDataSelect 选择回调的数据
 * @param {*} map 数据传递 DataTransfer 配置的字段映射
 * @param {*} fRecord 当前模块的父模块数据
 * @param {*} sublistObj 子模块数据
 */
function transferData(moduleName, tableRecord, selectmodule, tranferRecord, map, fRecord, sublistObj) {
    const data = getTransferData(moduleName, selectmodule, tranferRecord, map, fRecord, sublistObj)
    Object.assign(tableRecord, data)
}

/**
 * 通过模块数据选择组件的数据，不存在来源单据，故删除
 * @param {*} tableRecord 
 */
function deleteSourceCode(tableRecord) {
    delete tableRecord.sourceCode
    delete tableRecord.sourceCode_name
    delete tableRecord.sourceCode_id
    delete tableRecord.sourceModule
}

/**
 * 获取数据传输字段数据， 表格使用
 * @param {*} moduleName 表格模块名称
 * @param {*} selectmodule 选择字段模块名
 * @param {*} tranferRecord ModuleDataSelect 选择回调的数据
 * @param {*} map 
 * @param {*} fRecord 当前模块的父模块数据
 * @param {*} sublistObj 子模块数据
 * @returns 
 */
function getTransferData(moduleName, selectmodule, tranferRecord, map, fRecord, sublistObj) {
    //console.log('getTransferData fRecord', moduleName, selectmodule, fRecord, map)
    //console.log('getTransferData tranferRecord', tranferRecord)
    //console.log('fRecord', fRecord)
    let tableRecord = {}
    const fieldsConfig = getTableConfig(moduleName)
    const selectmoduleConfig = getTableConfig(selectmodule)
    const copykey = (tableFieldKey, recordFieldKey) => {
        //console.log('copykey', tableFieldKey, recordFieldKey)
        tableRecord[tableFieldKey] = tranferRecord[recordFieldKey]
    }
    // 对fRecord的数据进行表达式计算
    const fmodule = utils.getModuleConfig(moduleName).fmodule
    if (fmodule && fRecord) {
        fRecord = callExpr(fRecord, '', getTableConfig(fmodule))
    }
    //console.log('getTransferData map', map)
    for (let key in map) {
        if (key.endsWith('alias') || key === 'key') {
            continue
        }
        // key tableFieldKey
        let fieldConfig = utils.getFieldConfig(fieldsConfig, key)
        // map[key] recordFieldKey
        let recordFieldKey = map[key]
        // 没有字段映射，跳过
        if (utils.isBlank(recordFieldKey)) continue
        const copyExtraKey = suffix => copykey(key + suffix, recordFieldKey + suffix)
        let recordFieldConfig = utils.getFieldConfig(selectmoduleConfig, recordFieldKey)
        // 未找到字段，值为常量或表达式
        if (recordFieldConfig == null) {
            tableRecord[key] = exprValue(recordFieldKey, tranferRecord, fRecord, 
                selectmoduleConfig, '', sublistObj)
            //console.log(moduleName, key, recordFieldKey, tableRecord[key])
        }
        let dataType = recordFieldConfig && recordFieldConfig.dataType
        if (isFFieldType(dataType)) {
            // 获取默认父列的数据类型
            dataType = getFDataType(recordFieldConfig.otherParams)
        }
        // 如果映射选择字段，不管有没有值都传递选项
        if (recordFieldConfig && isSelectType(dataType)) {
            if (tranferRecord[`${recordFieldKey}_options`]) {
                //console.log(tranferRecord[`${recordFieldKey}_options`])
                copyExtraKey('_options')
            }
        }
   
        // 有字段映射，映射字段有值
        if (tranferRecord[recordFieldKey]) {
            // 先传递映射字段，再传递列表和树模型的额外字段
            copykey(key, recordFieldKey)
            if (recordFieldConfig) {
                if (isListType(dataType) || (fieldConfig && isListType(fieldConfig.dataType))) {
                    // 类型一致
                    if (isListType(dataType)) {
                        copyExtraKey('_id')
                        copyExtraKey('_name')
                    } else {
                        // 由 code 向 LISTMODULE 传递值
                        tableRecord[key + '_id'] = tranferRecord.id
                        tableRecord[key + '_name'] = tranferRecord.name
                    }
                } else if (isTreeType(dataType)) {
                    copyExtraKey('_id')
                    copyExtraKey('_name')
                    copyExtraKey('_classes')
                    copyExtraKey('_classnames')
                }
            }
        }
    }
    //console.log(tableRecord)
    return tableRecord
}

/**
 * 获取数据传输字段数据， Form使用
 * @param {*} moduleName 当前页面模块名
 * @param {*} selectmodule 选择字段模块名
 * @param {*} name 出发数据传递的字段名称
 * @param {*} tranferRecord 传输的数据
 * @param {*} fRecord 当前模块的父模块数据
 * @param {*} map 字段映射
 * @param {*} rowKey 表单为空， 表格为id
 * @returns 
 */
function getTransferFormData(moduleName, selectmodule, name, tranferRecord, fRecord, map, rowKey = '') {
    tranferRecord = tranferRecord || {}
    fRecord = fRecord || {}
    //console.log(moduleName, selectmodule, tranferRecord, map)
    let formData = {}
    const selectmoduleConfig = getTableConfig(selectmodule)
    const copykey = (tableFieldKey, recordFieldKey) => {
        //console.log('copykey', tableFieldKey, recordFieldKey)
        formData[tableFieldKey + rowKey] = tranferRecord[recordFieldKey]
    }
    for (let key in map) {
        if ((key === name) || key.endsWith('alias') || key === 'key') {
            continue
        }
        // 表格表单不传递选项数据，选项直接由表格数据渲染
        if (utils.isNotBlank(rowKey) && key.indexOf('_options') !== -1) {
            continue
        }
        // key 目标记录
        // map[key] recordFieldKey
        let recordFieldKey = map[key]
        // 没有字段映射，跳过
        if (utils.isBlank(recordFieldKey)) continue
        const copyExtraKey = suffix => copykey(key + suffix, recordFieldKey + suffix)
        let recordFieldConfig = utils.getFieldConfig(selectmoduleConfig, recordFieldKey)

        // 未找到字段，值为常量或表达式
        if (recordFieldConfig == null) {
            formData[key + rowKey] = exprValue(recordFieldKey, tranferRecord, fRecord, selectmoduleConfig)
        }

        let dataType = recordFieldConfig && recordFieldConfig.dataType
        if (isFFieldType(dataType)) {
            // 获取默认父列的数据类型
            dataType = getFDataType(recordFieldConfig.otherParams)
        }
        // 如果映射选择字段，不管有没有值都传递选项
        if (recordFieldConfig && isSelectType(dataType) && utils.isBlank(rowKey)) {
            if (tranferRecord[`${recordFieldKey}_options`]) {
                //console.log(tranferRecord[`${recordFieldKey}_options`])
                copyExtraKey('_options')
            }
        }
        // 有字段映射，映射字段有值
        if (tranferRecord[recordFieldKey]) {
            // 先传递映射字段，再传递列表和树模型的额外字段
            copykey(key, recordFieldKey)
            if (recordFieldConfig) {
                if (isListTreeType(dataType)) {
                    let idKey = `${recordFieldKey}_id`
                    let nameKey = `${recordFieldKey}_name`
                    let classesKey = `${recordFieldKey}_classes`
                    let classnamesKey = `${recordFieldKey}_classnames`
                    let formValue = { 
                        code: tranferRecord[recordFieldKey], 
                        id: tranferRecord[idKey], 
                        name: tranferRecord[nameKey], 
                        classes: tranferRecord[classesKey], 
                        classnames: tranferRecord[classnamesKey]
                    }
                    formData[key + rowKey] = formValue
                }
            }
        }
    }
    return formData
}

/**
 * 数据传递，传递子模块
 * @param {*} id 传递数据id
 * @param {*} transferModule 数据传递配置
 * @param {*} sublist Fields 表单页面的 子模块实例对象
 * @param {*} fRecord Fields 表单页面的数据
 * @returns 
 */
async function tranferSublist(id, transferModule, sublist, fRecord) {
    if (id == null || fRecord == null || transferModule == null || sublist == null) {
        return
    }
    //console.log('tranferSublist',id, transferModule, sublist, fRecord) 
    const {sublistmapping, mapping, searchsublistmapping} = transferModule
    // 子模块映射为空
    if (sublistmapping == null || utils.equals(sublistmapping, {}) ||
        mapping == null || utils.equals(mapping, {})
    ) {
        return
    }

    // 注入作为查询条件的数据源
    const sublistObj = {}
    // key 数据传递来源子模块名称， value 目标子模块名称
    for (let key in searchsublistmapping) {
        // 目标模块名称
        let sublistModuleName = searchsublistmapping[key]
        //console.log('sublistModuleName', sublistModuleName)
        if (!(sublist[sublistModuleName] && sublist[sublistModuleName].getData)) {
            continue
        }
        // 目标模块数据，取第一条
        const list = sublist[sublistModuleName].getData()
        //console.log(list)
        if (list instanceof Array && list.length > 0) {
            sublistObj[sublistModuleName] = list[0]
        }
    }

    const getMap = (key) => {
        const map = utils.copy(mapping[key])
        // 替换表达式中的子模块变量
        for (let key in map) {
            let value = map[key]
            if (typeof value === 'string') {
                Object.keys(sublistObj).forEach(sublistname => {
                    let str = sublistname + '.'
                    if (value.indexOf(str) !== -1) {
                        map[key] = value.replaceAll(str, 'sublistObj.' + str)
                    }
                })
            }
        }
        return map
    }

    const tranferSublistData = async (key, fRecord, newRecord) => {
        const sublistConfig = utils.getModuleConfig(key).sublist
        if (!(sublistConfig instanceof Array) || sublistConfig.length === 0) {
            return
        }
        // 传递子模块的子模块数据
        for(const moduleConfig of sublistConfig) {
            const moduleName = moduleConfig.moduleName
            const transferModuleName = sublistmapping[moduleConfig.moduleName]
            if (transferModuleName) {
                // 数据传递来源子模块表格数据
                const tranferSubList = await getListAllPromise(transferModuleName, 
                    buildQuery({fid: fRecord.id}))
                const subList = []
                for (let i = 0; i < tranferSubList.length; ++i) {
                    const tranferSubRecord = tranferSubList[i]
                    const subRecord = getNewRecord()
                    subRecord.fid = newRecord.id
                    const map = getMap(moduleName)
                    // 合并传递数据
                    transferData(moduleName, subRecord, transferModuleName, 
                        tranferSubRecord, map, fRecord)
                    await tranferSublistData(moduleName, tranferSubRecord, subRecord)
                    subList.push(subRecord)
                }
                newRecord[sublistPrefix + moduleName] = subList
            }
        }
    }
    
    //console.log('sublistmapping', sublistmapping)
    // 遍历子模块映射
    // key 目标子模块名称， value 数据传递来源子模块名称
    for (let key in sublistmapping) {
        const listInst = sublist[key]
        if (!(listInst && listInst.setData)) {
            continue
        }
        const map = getMap(key)
        //console.log('transfer sublist: ', key, mapping, sublistObj)
        // 数据传递来源子模块名称
        const selectmoduleSublistName = sublistmapping[key]
        // 数据传递来源子模块表格数据
        const tranferList = await getListAllPromise(selectmoduleSublistName, buildQuery({fid: id}))
        //console.log('-----------------------------------------', selectmoduleSublistName)
        //console.log( tranferList)
        const list = []
        for (let i = 0; i < tranferList.length; ++i) {
            const tranferRecord = tranferList[i]
            const newRecord = listInst.getNewRecord(i + 1)
            // 新增时自动生成编码
            listInst.fillClass(newRecord)
            await listInst.fillCode(newRecord)
            // 合并传递数据
            transferData(key, newRecord, selectmoduleSublistName, 
                tranferRecord, map, fRecord, sublistObj)
            // 递归传递子模块数据
            tranferSublistData(key, tranferRecord, newRecord)
            list.push(newRecord)
        }
        console.log(key, list)
        listInst.setData(list)
    }
}

/**
 * 递归删除表格记录子模块数据
 * @param {*} apiArray 
 * @param {*} moduleName 
 * @param {*} ids 
 * @returns 
 */
async function batchDeleteSubListByFid(apiArray, moduleName, ids) {
    if (!(apiArray instanceof Array) || !(ids instanceof Array) || ids.length === 0) {
        return
    }
    const sublistConfig = utils.getModuleConfig(moduleName).sublist
    if (!(sublistConfig instanceof Array) || sublistConfig.length === 0) {
        return
    }
    for (const subConfig of sublistConfig) {
        const subModuleName = subConfig.moduleName
        const list = await getListAllPromise(subModuleName, buildQuery({fid: ids}))
        if (!(list instanceof Array) || list.length === 0) {
            continue
        }
        await batchDeleteSubListByFid(apiArray, subModuleName, list.map(it => it.id))
        apiArray.push(getApiItem(batchDeleteByFid, subModuleName, [ids]))
    }
}

// 递归添加子模块数据
async function saveInnerSubList(apiArray, moduleName, list) {
    if (!(apiArray instanceof Array) || !(list instanceof Array) || list.length === 0) {
        return
    }
    const sublistConfig = utils.getModuleConfig(moduleName).sublist
    if (!(sublistConfig instanceof Array) || sublistConfig.length === 0) {
        return
    }
    for (const subConfig of sublistConfig) {
        const subModuleName = subConfig.moduleName
        for (let record of list) {
            let sublist = record[sublistPrefix + subModuleName]
            if (!(sublist instanceof Array) || sublist.length === 0) {
                continue
            }
            apiArray.push(getApiItem(batchSave, subModuleName, [sublist]))
            await saveInnerSubList(apiArray, subModuleName, sublist)
        }
    }
}

/**
 * 模块名称界面显示值，别名存在则显示别名
 * @param {Object|String} module|modulename
 * @returns 
 */
function showModuleName(module) {
    if (module == null) {
        return null
    }
    if (typeof module === 'string') {
        const moduleConfig = utils.getModuleConfig(module)
        if (utils.isNotBlank(moduleConfig.alias)) {
            return moduleConfig.alias
        }
        return module
    }
    if (utils.isNotBlank(module.alias)) {
        return module.alias
    }
    return module.modulename
}
global.showModuleName = showModuleName

/**
 * 字段名称界面显示值，别名存在则显示别名
 * @param {*} module 
 * @returns 
 */
function showName(field) {
    if (utils.isNotBlank(field.alias)) {
        return field.alias
    }
    return field.name
}

/**
 * 列表模块显示字段是否为编码
 * @param {*} moduleName 
 * @returns 
 */
function isShowCode(moduleName) {
    const moduleConfig = utils.getConfigByModuleName(moduleName)
    const fieldShowName = moduleConfig.selectshowfield
    return fieldShowName === 'code'
}

/**
 * 获取表单中的字段数据
 * @param {String} id 
 * @param {String} moduleName 
 * @param {String} name 
 * @returns value
 */
function getValue(id, moduleName, name) {
    if (utils.isBlank(id)) {
        console.error('getValue id, moduleName, name: ', id, moduleName, name)
    }
    if (utils.isBlank(moduleName) || utils.isBlank(name)) {
        console.error('getValue moduleName, name', moduleName, name)
        return null
    }
    const fieldsInst = forms[id || moduleName]
    if (fieldsInst) {
        const values = fieldsInst.getFieldsValue()
        if (values) {
            return values[name]
        }
    }
    return null
}
/**
 * 获取所有表单数据
 * @param {String} id 
 * @returns values
 */
function getValues(id) {
    if (utils.isBlank(id)) {
        console.error('getValues id null')
    }
    const fieldsInst = forms[id]
    if (fieldsInst) {
        return fieldsInst.getFieldsValue()
    }
    return null
}

/**
 * 修改表单中的字段数据
 * @param {String} id 
 * @param {String} moduleName 
 * @param {String} name 
 * @param {any} value 
 */
function setValue(id, moduleName, name, value) {
    if (utils.isBlank(id)) {
        console.error('setValue id, moduleName, name, value: ', id, moduleName, name, value)
    }
    if (utils.isBlank(moduleName) || utils.isBlank(name)) {
        console.error('setValue', moduleName, name)
        return null
    }
    const fieldsInst = forms[id || moduleName]
    if (fieldsInst) {
        fieldsInst.setFieldsValue({[name]: value})
    }
}
/**
 * 批量修改表单数据
 * @param {String} id 
 * @param {Object} values 
 */
function setValues(id, values) {
    if (utils.isBlank(id)) {
        console.error('setValues id is null, values: ', values)
        return
    }
    if (values == null || utils.equals(values, {})) {
        return
    }
    const fieldsInst = forms[id]
    if (fieldsInst) {
        fieldsInst.setFieldsValue(values)
    }
}

/**
 * 设置当前子模块模块对应父模块中设置的合计字段的值
 * @param {String} moduleName 当前子模块模块名称
 * @param {Number} value 
 * @returns {Array<Object>} sumFieldNames
[
    {fname, name}
]
 */
function getSumFieldNames(moduleName) {
    // 获取当前子模块模块配置
    const moudleConfig = utils.getModuleConfig(moduleName)
    // 获取父模块名称
    const fmodule = moudleConfig && moudleConfig.fmodule
    // 获取父模块字段配置
    const fFieldsConfig = getTableConfig(fmodule)
    const names = []
    for (let config of fFieldsConfig) {
        // 查找合计类型
        if (isSumType(config.dataType)) {
            /**
             * 合计类型参数
            {
                modulename: 子模块名称
                name: 要合计的子模块字段名称
            }
             */
            const otherParams = config.otherParams || {}
            const {name} = otherParams
            // 父模块合计字段子模块和当前模块名称相同，且配置了合计字段
            if (otherParams.modulename === moduleName && utils.isNotBlank(name)) {
                // 添加父模块字段名称, 子模块合计字段名称
                names.push({
                    fname: config.name, 
                    name
                })
            }
        }
    }
    return names
}

/**
 * 修改表单中合计类型的值
 * @param {*} fid 
 * @param {*} oldValues 
 * @param {*} form 
 * @param {*} moduleName 
 * @param {*} rowKey 
 */
function setSumFieldsValue(fid, oldValues, form, moduleName, rowKeys = '') {
    if (!(rowKeys instanceof Array)) {
        rowKeys = [rowKeys]
    }
    // 获取模块配置
    let moduleConfig = utils.getConfigByModuleName(moduleName)
    // 父模块名
    const fmodule = moduleConfig.fmodule
    if (utils.isNotBlank(fmodule)) {
        // 获取要合计的字段
        const names = getSumFieldNames(moduleName)
        //console.log('fmodule,moduleName,names',fmodule,moduleName,names)
        if (names.length > 0) {
            const values = form.getFieldsValue()
            const fOldValues = getValues(fid)
            const newValues = {}
            // 修改父模块表单合计字段的值
            names.forEach(item => {
                const {fname, name} = item
                // 父模块 字段 旧值
                let fOldValue = getNumber(fOldValues[fname])
                for (const rowKey of rowKeys) {
                    fOldValue = newValues[fname] || fOldValue
                    const key = name + rowKey
                    //console.log('key-----------------------------', key)
                    // 子模块 字段 旧值
                    const oldValue = getNumber(oldValues[key])
                    // 子模块 字段 新值
                    const value = getNumber(values[key])
                    //console.log('fOldValue + value - oldValue', fOldValue, value, oldValue)
                    const fValue = fOldValue + value - oldValue
                    //console.log('fmodule, fname, fValue',fmodule, fname, fValue)
                    newValues[fname] = fValue
                }
            })
            setValues(fid, newValues)
            newValues.id = fid
            // 嵌入子模块，修改父模块列表记录
            modifyNestedFRecrod(fmodule, newValues)
        }
    }
}

function setSumValue(fid, fieldName, oldValue, value, moduleName) {
    //console.log('setSumValue',fid, fieldName, oldValue, value, moduleName)
    // 获取模块配置
    let moduleConfig = utils.getConfigByModuleName(moduleName)
    // 父模块名
    const fmodule = moduleConfig.fmodule
    if (utils.isNotBlank(fmodule)) {
        // 获取要合计的字段
        const names = getSumFieldNames(moduleName)
        //console.log('fmodule,moduleName,names',fmodule,moduleName,names)
        if (names.length > 0) {
            const fRecord = {id: fid}
            // 修改父模块表单合计字段的值
            for (let item of names) {
                const {fname, name} = item
                if (fieldName === name) {
                    oldValue = getNumber(oldValue)
                    // 父模块 字段 旧值
                    const fOldValue = getNumber(getValue(fid, fmodule, fname))
                    //console.log('fOldValue + value - oldValue', fOldValue, value, oldValue)
                    const fValue = fOldValue + value - oldValue
                    //console.log('fmodule, fname, fValue',fmodule, fname, fValue)
                    setValue(fid, fmodule, fname, fValue)
                    fRecord[fname] = fValue

                    // 嵌入子模块，修改父模块列表记录
                    modifyNestedFRecrod(fmodule, fRecord)
                    break
                }
            }
        }
    }
}

/**
 * 嵌入子模块，修改父模块列表记录
 * @param {*} fmodule 
 * @param {*} fRecord 
 */
function modifyNestedFRecrod(fmodule, fRecord) {
    // 嵌入子模块，修改父模块列表记录
    const fConfig = utils.getModuleConfig(fmodule)
    if (hasNestedSublist(fConfig)) {
        const path = p.getActiveTabKey()
        const inst = pageInstObj[path]
        const modifyRecord = (listModuleInst) => {
            if (listModuleInst && (listModuleInst.modifyRecord instanceof Function)) {
                listModuleInst.modifyRecord(fRecord)
            }
        }
        // 当前页面为父模块列表，直接修改记录
        if (path === '/' + fmodule) {
            modifyRecord(inst)
        } else if (path.startsWith('/' + fmodule)) {
            // 当前页面为父模块新增或详情页面，不需要操作
        } else if (path.indexOf('/detail?') !== -1 || path.indexOf('/new?') !== -1) {
            // 当前页面为父模块的父模块新增或详情页面， 查找父模块实例，修改记录
            if (inst.fields && inst.fields.sublist && inst.fields.sublist[fmodule]) {
                modifyRecord(inst.fields.sublist[fmodule])
            }
        }
    }
}

/**
 * 修改父模块合计值, 添加行时，合计值加上当前行的值
 * @param {*} fid 
 * @param {*} moduleName 子模块模块名， 当前
 * @param {*} rows 
 */
function sumAdd(fid, moduleName, rows) {
    if (utils.isBlank(fid) || utils.isBlank(moduleName)) {
        console.error('sumAdd error: ', fid, moduleName)
        return
    }
    const config = utils.getModuleConfig(moduleName)
    // 父模块名
    const fmodule = config.fmodule
    if (utils.isNotBlank(fmodule)) {
        // 获取要合计的字段
        const names = getSumFieldNames(moduleName)
        if (names.length > 0) {
            const fRecord = {}
            const oldValues = getValues(fid)
            // 修改父模块表单合计字段的值
            names.forEach(item => {
                const {name, fname} = item
                let fValue = oldValues[fname]
                for (let row of rows) {
                    const value = getNumber(row[name])
                    fValue = getNumber(fValue) + value
                }
                fRecord[fname] = fValue
            })
            setValues(fid, fRecord)
            fRecord.id = fid
            // 嵌入子模块，修改父模块列表记录
            modifyNestedFRecrod(fmodule, fRecord)
        }
    }
}

/**
 * 修改父模块合计值, 删除行时，合计值减去当前行的值
 * @param {*} fid 
 * @param {*} moduleName 子模块模块名， 当前
 * @param {*} rows 
 * @param {*} form 表格form， 手机端不使用表格form
 */
function sumSubtract(fid, moduleName, rows, form) {
    if (utils.isBlank(fid) || utils.isBlank(moduleName) || utils.isBlank(form)) {
        console.error('sumSubtract error: ', fid, moduleName, form)
        return
    }
    const config = utils.getModuleConfig(moduleName)
    // 父模块名
    const fmodule = config.fmodule
    if (utils.isNotBlank(fmodule)) {
        // 获取要合计的字段
        const names = getSumFieldNames(moduleName)
        if (names.length > 0) {
            const fRecord = {id: fid}
            // 修改父模块表单合计字段的值
            names.forEach(item => {
                const {name, fname} = item
                let fValue = getValue(fid, fmodule, fname)
                let formRows = []
                if (global.isPhoneVertical) {
                    for (let row of rows) {
                        if (utils.isBlank(row.id)) {
                            console.error('getSumFieldNames row id is null', row)
                            continue
                        }
                        const fieldsInst = forms[row.id]
                        if (fieldsInst == null) {
                            console.error('getSumFieldNames fieldsInst is null, row: ', row)
                            continue
                        }
                        const values = fieldsInst.getFieldsValue()
                        formRows.push(Object.assign({}, row, values))
                    }
                } else {
                    formRows = getTableList(config.fields_config, form, rows, 'id')
                }
                for (let row of formRows) {
                    const value = getNumber(row[name])
                    fValue = getNumber(fValue) - value
                }
                setValue(fid, fmodule, fname, fValue)
                fRecord[fname] = fValue
            })
            // 嵌入子模块，修改父模块列表记录
            modifyNestedFRecrod(fmodule, fRecord)
        }
    }
}

function getNumber(value) {
    if (isNaN(value) || utils.isBlank(value)) {
        return 0
    }
    return Number(value)
}

/**
 * 获取数据传递全部配置
 * @param {*} modulename 
 */
async function getDatatransferListAll() {
    // 获取全部数据传递配置数据， 同步
    if (datatransferList.length === 0) {
        const list = await getListAllPromise(datatransfer)
        if (list instanceof Array) {
            if (datatransferList.length === 0) {
                datatransferList.push(...list)
            }
        }
    }
    return datatransferList.filter(it => it.isinsert !== 'no')
}

/**
 * 获取数据传递配置
 * @param {*} modulename 
 */
async function getDatatransferList(modulename) {
    const list = await getDatatransferListAll()
    return list.filter(it => it.modulename === modulename)
}

/**
* 查询业务编排生成的全部按钮
*/
async function getOrchestrateButtonAll() {
    // 获取全部业务编排配置数据， 同步
	if (orchestrateList.length === 0) {
        const list = await getListAllPromise(orchestrate)
        if (list instanceof Array) {
            if (orchestrateList.length === 0) {
                orchestrateList.push(...list)
            }
        }
    }
    return orchestrateList.filter(it => it.isinsert !== 'no')
}

/**
 * 按模块查询业务编排生成的按钮
 * @param {*} modulename 
 * @returns 
 */
async function getOrchestrateButton(modulename) {
    const list = await getOrchestrateButtonAll()
    return list.filter(it => it.modulename === modulename)
}

/**
 * 获取表单数据
 * 将undefined 值转为 null, 才能保存到数据库
 * @param {Form} form 
 * @returns 
 */
function getFormValues(form) {
    if (form == null) return {}
    const values = form.getFieldsValue()
    for (const key in values) {
        if (values[key] === undefined) {
            values[key] = null
        }
    }
    return values
}

/**
 * 获取表单数据初始化值
 * @param {*} config 
 * @param {*} data 
 * @param {*} totalElements 列表当前记录总数 + 1
 * @param {*} insertIndex 插入位置
 * @returns 
 */
function getInitialValue(config, data = {}, totalElements, insertIndex) {
    if (config == null) {
        console.error('getInitialValue config is null')
        return null
    }
    
    const otherParams = config.otherParams
    if (otherParams && otherParams.autoincrease === '1' && totalElements != null) {
        // 自增数字
        let step = otherParams.step || 1
        if (insertIndex !== undefined) {
            return step * (insertIndex + 0.5)
        }
        return step * totalElements
    }

    if (utils.isBlank(config.defaultValue)) {
        return null
    }
    
    let initialValue = data[config.name]
    if (initialValue == null) {
        initialValue = config.defaultValue
    }
    if (initialValue != null && isNumber(getRealType(config))) {
        initialValue = getNumber(initialValue) 
    }
    return initialValue
}

/**
 * 获取编辑状态下表格数据
 * @param {*} fieldsConfig oo Config
 * @param {*} form 
 * @param {*} tableList 表格原始数据
 * @param {*} rowKey antd rowKey 默认id
 * @param {*} moduleName 模块名称
 * @returns 
 */
function getTableList(fieldsConfig = [], form, stateList = [], rowKey = 'id', moduleName) {
    // 分类名称
    let catagory = ''
    if (typeof moduleName === 'string') {
        let moduleConfig = utils.getModuleConfig(moduleName)
        if (!(moduleConfig.fields_config instanceof Array && moduleConfig.fields_config.length > 0)) {
            console.error('模块配置未找到，模块名称： ', moduleConfig)
            return []
        }
        catagory = moduleConfig.catagory
        fieldsConfig = moduleConfig.fields_config
    }
    if (form == null || !(fieldsConfig instanceof Array)) {
        return []
    }
    const values = form.getFieldsValue()
    return stateList.map((record, index) => {
        const item = utils.copy(record)
        if (typeof rowKey === 'string') {
            index = record[rowKey]
        } else if (rowKey instanceof Function) {
            index = rowKey(record)
        }
        // 字段配置
        for (const config of fieldsConfig) {
            const name = config.name
            if (values[name + index] !== undefined) {
                item[name] = values[name + index]
            } 
        }
        // 分类
        if (utils.isNotBlank(catagory) && values[catagory + index] !== undefined) {
            item[catagory] = values[catagory + index]
        }
        moduleName && convertData(moduleName, item, record)
        return item
    })
}

async function refreshInnerPage(innerInst, clientId) {
    return await operInnerPage(innerInst, clientId, 'refreshPage')
}

async function saveInnerNewOrDetailPage(innerInst, apiArray) {
    return await operInnerPage(innerInst, apiArray, 'onOk')
}

/**
 * 保存表格内部展开的新增或详情页面
 * operInnerPage
 * @param {Object} innerInst 新增详情页面实例对象 {key: id, value: inst}
 * 使用事务批量串行调用接口
 * @param {Array<ApiItem>} apiArray 
 ApiItem: {
    name: 'update', 函数名称， processStart 流程开始moduleName
    moduleName: 模块名称， processStart 流程开始为空，函数内部固定使用flow
    args: [id, record]， 函数参数列表
 }
 */
async function operInnerPage(innerInst, apiArray, functionName) {
    if (innerInst === null) {
        throw new Error('innerInst must be object')
    }
    if (!(innerInst instanceof Object)) {
        throw new Error('innerInst must be object')
    }
    if (innerInst instanceof Array) {
        throw new Error('innerInst must be object')
    }
    for (let key in innerInst) {
        const inst = innerInst[key]
        // 调用新增或详情页面的方法
        if (inst && inst[functionName] instanceof Function) {
            //console.log('operInnerPage',key, functionName)
            let res = await p.quietly(async () => await inst[functionName](apiArray))
            if (!res) {
                return false
            }
        }
    }
    return true
}

/**
 * 根据模块名称、字段名称获取字段数据类型
 * @param {*} moduleName 
 * @param {*} name 
 * @returns 
 */
const getDataType = (moduleName, name) => {
    const config = utils.getConfig(moduleName, name)
    return getRealType(config)
}
/**
 * 获取默认父列的数据类型
 * @param {*} otherParams 
 * @returns 
 */
function getFDataType(otherParams) {
    if (otherParams == null) {
        console.error('getFDataType: otherParams is null' )
    }
    const {fmodule, name} = otherParams
    const dataType = getDataType(fmodule, name)
    if (utils.isBlank(dataType)) {
        console.error('getFDataType: fmodule, name', fmodule, name)
    }
    return dataType
}

async function refreshSublist(fields) {
    //console.log('refreshSublist-----------------------------------')
    await operSublist(null, fields, 'refreshPage')
}

async function saveSublist(apiArray, fields) {
    return await operSublist(apiArray, fields, 'batchSave')
}

async function operSublist(apiArray, fields, functionName) {
    if (fields == null || fields.sublist == null) {
        return true
    }
    // 遍历子模块引用
    for (let key in fields.sublist) {
        const listModule = fields.sublist[key]
        if (listModule == null) {
            continue
        }
        //console.log('operSublist', functionName, key)
        if (listModule[functionName] instanceof Function) {
            // 批量保存子模块
            let ret = await p.quietly(async () => await listModule[functionName](apiArray))
            if (!ret) {
                return false
            }
        }
    }
    return true
}

/**
 * 批量递归删除子模块
 * @param {*} fids 
 * @param {*} config 
 */
async function delSubList(apiArray, fids, config) {
    const subListModuleNames = getSublistNameArr(config.sublist)
    if (subListModuleNames instanceof Array && subListModuleNames.length > 0) {
        for (const moduleName of subListModuleNames) {
            const subModuleConfig = utils.getModuleConfig(moduleName)
            const query = {
                searchFields: {fid: fids},
                moreFields: [{
                    id: 'fid',
                    dataType: 'ID'
                }]
            }
            const subRecords = await getListAllPromise(moduleName, query)
            if (subRecords instanceof Array && subRecords.length > 0) {
                const subFids = subRecords.map(it => it.id)
                await delSubList(apiArray, subFids, subModuleConfig)
            }
            if (apiArray instanceof Array) {
                apiArray.push(getApiItem(batchDeleteByFid, moduleName, [fids]))
            }
        }
    }
}

/**
 * 复制数据，跳过禁止编辑字段
 * @param {*} record 
 * @param {*} moduleName 
 * @returns 
 */
function copyRecord(record, moduleName) {
    if (record == null) {
        console.error('copyRecord record is null, moduleName: ', moduleName)
        return record
    }
    if (utils.isBlank(moduleName)) {
        return record
    }
    delete record._id
    delete record.id
    const fieldsConfig = getTableConfig(moduleName)
    for (let config of fieldsConfig) {
        if (config.noCopy) {
            delete record[config.name]
        }
    }
    return record
}


/**
 * 递归获取子模块
 * @param {*} id
 * @param {*} record 
 * @param {*} config 
 */
async function mergeSubList(id, record, config) {
    if (config && config.sublist instanceof Array && config.sublist.length > 0) {
        for (let subConfig of config.sublist) {
            let moduleName = subConfig.moduleName || subConfig
            if (subConfig.disabledCopy) {
                continue
            }
            const subModuleConfig = utils.getModuleConfig(moduleName)
            const query = {
                searchFields: {fid: id},
                moreFields: [{
                    id: 'fid',
                    dataType: 'ID'
                }]
            }
            const subRecords = await getListAllPromise(moduleName, query)
            if (subRecords instanceof Array && subRecords.length > 0) {
                for (let subRecord of subRecords) {
                    const subFid = subRecord.id
                    subRecord.id = utils.uuid()
                    subRecord.fid = record.id
                    // 新增标记
                    subRecord.disabled = false
                    subRecord.change = true
                    subRecord.isNew = true 
                    for (let name in subRecord) {
                        const fieldConfig = utils.getFieldConfig(subModuleConfig.fields_config, name)
                        if (fieldConfig && fieldConfig.noCopy) {
                            delete subRecord[name]
                        }
                    }
                    mergeSubList(subFid, subModuleConfig, subRecord)
                }
                record[sublistPrefix + moduleName] = subRecords
            }
        }
    }
}

/**
 * 删除子模块数据， 不向后端保存
 * @param {*} record 
 */
function deleteSublistKey(record) {
    if (record == null) return
    for (let key in record) {
        if (key.startsWith(sublistPrefix)) {
            delete record[key]
        }
    }
}

/**
 * 获取子模块名称数组
 * @param {*} sublist 
 * @returns 
 */
function getSublistNameArr(sublist) {
    let sublistNameArr = []
    if (sublist instanceof Array) {
        for (let item of sublist) {
            if (typeof item === 'string') {
                sublistNameArr.push(item)
                continue
            }
            if (typeof item.moduleName === 'string') {
                sublistNameArr.push(item.moduleName)
            }
        }
    }
    return sublistNameArr
}

/**
 * 获取可以显示的子模块配置
 * @param {*} config 
 * @param {*} data 
 * @returns 
 */
function getVisibleSublist(config, data) {
    let subListConfigArr = []
    if (config && (config.sublist instanceof Array)) {
        for (let item of config.sublist) {
            if (item.disabledConds instanceof Array && item.disabledConds.length > 0 ) {
                if(!condsValue(item.disabledConds, data)) {
                    subListConfigArr.push(item)
                }
            } else {
                subListConfigArr.push(item)
            }
        }
    }
    return subListConfigArr
}
/**
 * 获取可以显示的嵌套表格子模块配置
 * getVisibleNestedSublist
 * @param {*} config 
 * @param {*} data 
 * @returns 
 */
function getVisibleNestedSublist(config, data) {
    return getVisibleSublist(config, data).filter(item => item.innernested)
}
/**
 * 模块中是否存在嵌套子模块
 * hasNestedSublist
 * @param {*} config
 * @returns 
 */
function hasNestedSublist(config) {
    if (config && (config.sublist instanceof Array)) {
        if (config.sublist.find(item => item.innernested)) {
            return true
        }
    }
    return false
}

/**
 * 获取所有嵌入子模块配置
 * @param {*} config 
 * @returns 
 */
function getNestedSublist(config) {
    if (config && (config.sublist instanceof Array)) {
        return config.sublist.filter(item => item.innernested)
    }
    return []
}

function valueToMoment(value){
    try {
        let m = moment(value)
        if (m.isValid()) {
            return m
        }
        return null
    } catch (e) {
        return null
    }
}

/**
 * 计算条件表达式值, 默认true
 * @param {Object | Array} conds 
 * @param {Object} record 
 * @param {String} moduleName 
 * @returns 
 */
function condsValueDefaultTrue(conds, record, moduleName) {
    return condsValue(conds, record, moduleName, true)
}

/**
 * 计算条件表达式值, 默认false
 * @param {Object | Array} conds 
 * @param {Object} record 
 * @param {String} moduleName 
 * @param {Boolean} defaultValue
 * @returns 
 */
function condsValue(conds, record, moduleName, defaultValue = false) {
    let moduleConfig = null
    if (moduleName) {
        moduleConfig = getMergeConfig(moduleName)
    }

    if (!(conds instanceof Array) || conds.length === 0) {
        return defaultValue
    }

    if (record === null || record === undefined) {
        return defaultValue
    }
    
    for (let group of conds) {
        if (!(group instanceof Array)) {
            continue
        }
        let groupRet = true
        let groupRetArray = []
        for (let cond of group) {
            let ret = false
            cond = cond || {}
            let {fieldName, oper, value, type} = cond
            if (utils.isBlank(fieldName)) {
                continue
            }
            let name = null
            let dataType = null
            let label = null
            if (moduleConfig) {
                let fieldConfig = utils.getFieldConfig(moduleConfig.fields_config, fieldName)
                if (fieldConfig) {
                    name = showName(fieldConfig)
                    dataType = getRealType(fieldConfig)
                    if (isAntdSelectType(dataType)) {
                        const options = utils.getOptionArray(fieldConfig.params)
                        label = utils.valueToLabel(value, options)
                    }
                }
            }
            let fieldValue = record[fieldName]
            // 默认值
            let formatValue = value
            // 字段，变量
            if (type === '1') {
                formatValue = record[value]
            }
            if (utils.isBlank(fieldValue)) {
                fieldValue = null
            }
            if (utils.isBlank(formatValue)) {
                formatValue = null
            }
            if (isNumber(dataType)) {
                fieldValue = getNumber(fieldValue)
                formatValue = getNumber(formatValue)
            } else if (isDateTimeType(dataType)) {
                fieldValue = valueToMoment(fieldValue)
                formatValue = valueToMoment(formatValue)
            } else {
                fieldValue = fieldValue && `'${fieldValue}'`
                formatValue = formatValue && `'${formatValue}'`
            }
            let expr = fieldValue + oper + formatValue
            try {
                // eslint-disable-next-line
                ret = eval(expr)
                //console.log(expr, ret)
                if (ret && moduleName) {
                    let operLabal = utils.getLabelByValue(oper, operOptions)
                    groupRetArray.push(`${name}${operLabal}${label || value}`)
                }
            } catch (e) {console.error(e)}
            //console.log(record[fieldName], oper, value, ret)
            if (ret === false) {
                groupRet = false
                break
            }
        }
        let message = groupRetArray.join('且')
        if (groupRet) {
            if (moduleName) {
                return moduleName + ': ' + message
            }
            return true
        }
    }
    return defaultValue
}

/**
 * 下载导入模版
 * @param {*} moduleName 
 * @param {*} fid 
 */
function downloadImportTemplate(moduleName, fid) {
    const config = utils.getModuleConfig(moduleName)
    let url = `${global.getApi().opts.baseURI}/${moduleName}/download/excel?fid=${fid}`
    const oReq = new XMLHttpRequest()
    oReq.open("GET", url, true)
    oReq.setRequestHeader('token', getCookie('token'))
    oReq.responseType = "blob"
    oReq.onload = (() => {
        if (oReq.status !== 200) {
            utils.error('下载导入模板失败', oReq.response)
            return
        }
        const content = oReq.response
        const elink = document.createElement('a')
        elink.download = showModuleName(config) + '导入模板.xlsx'
        elink.style.display = 'none'
        const blob = new Blob([content])
        elink.href = URL.createObjectURL(blob)
        document.body.appendChild(elink)
        elink.click()
        document.body.removeChild(elink)
    })
    oReq.setRequestHeader("Content-Type", "application/json")
    oReq.send(null)
}

/**
 * 导出数据
 * @param {String} moduleName 
 * @param {Object} data 查询条件 { page, pageSize, query, sorter }
 * @param {String} fid 
 */
function exportData(moduleName, data, fid) {
    p.startLoad()
    const config = utils.getModuleConfig(moduleName)
    const query = data.query || {}
    // 处理fid查询条件
    if (global.hasfid(fid)) {
        queryAdd(query, {fid})
    } else {
        queryDelKey(query, 'fid')
    }
    let url = `${global.getApi().opts.baseURI}/${moduleName}/export/excel`
    const oReq = new XMLHttpRequest()
    oReq.open("PUT", url, true)
    oReq.setRequestHeader('token', getCookie('token'))
    oReq.responseType = "blob"
    oReq.onload = (() => {
        if (oReq.status !== 200) {
            utils.error('导出失败', oReq.response)
            p.stopLoad()
            return
        }
        const content = oReq.response
        const elink = document.createElement('a')
        elink.download = showModuleName(config) + '_' + moment().format('YYYYMMDDHHmmss') + '.xlsx'
        elink.style.display = 'none'
        const blob = new Blob([content])
        elink.href = URL.createObjectURL(blob)
        document.body.appendChild(elink)
        elink.click()
        document.body.removeChild(elink)
        p.stopLoad()
    })
    oReq.setRequestHeader("Content-Type", "application/json")
    oReq.send(JSON.stringify(data))
}

/**
 * 数据校验
 * @param {*} config 
 * @param {*} record 
 * @returns 
 */
function dataCheck(config, record) {
    // 数据校验
    const datacheck = config.datacheck
    const res = condsValue(datacheck, record, config.modulename)
    if (res) {
        utils.warning(res)
        return false
    }
    return true
}
/**
 * 数据传递条件
 * @param {*} config 
 * @param {*} record 
 * @param {*} notip 不弹窗提示
 * @returns 
 */
function canTransfer(config, record, notip) {
    // 数据传递条件
    const transferconds = config.transferconds
    const res = condsValue(transferconds, record, config.modulename)
    if (res) {
        !notip && utils.warning(res)
        return false
    }
    return true
}

/**
 * 数据传递查询条件是否关联子模块
 * @param {*} datatransferConfig 
 * @returns 
 */
function hasSublistSearch(datatransferConfig) {
    if (datatransferConfig == null) return false
    const { searchsublistmapping } = datatransferConfig 
    // 查询条件关联子模块和查询映射不为空
    if (searchsublistmapping && !utils.equals(searchsublistmapping, {})) {
        return true
    }
    return false
}

/**
 * 数据传递是否关联子模块
 * @param {*} datatransferConfig 
 * @returns 
 */
function hasSublistMapping(datatransferConfig) {
    if (datatransferConfig == null) return false
    const { sublistmapping } = datatransferConfig 
    // 查询条件关联子模块和查询映射不为空
    if (sublistmapping && !utils.equals(sublistmapping, {})) {
        return true
    }
    return false
}

/**
 * 获取子模块实例
 * @param {*} fieldsInst Fields 页面 this
 * @param {*} moduleName 
 */
function getSublistInst(fieldsInst, moduleName) {
    if (fieldsInst == null || fieldsInst.sublist == null || utils.isBlank(moduleName)) {
        return null
    }
    return fieldsInst.sublist[moduleName]
}

/**
 * 业务编排记录绑定子模块
 * 根据业务编排配置的管道中的数据传递配置关联子模块
 * @param {*} bizflowConfig 
 * @param {*} record 
 * @param {*} fieldsInst
 */
function bizflowRecordBindSublist(bizflowConfig, record, fieldsInst) {
    if (bizflowConfig == null || !(bizflowConfig.pipeline instanceof Array) || 
    record == null || fieldsInst == null) {
        return
    }
    // 遍历管道
    for (let item of bizflowConfig.pipeline) {
        const {moduleName, name, type} = item
        // 业务编排的模块的数据传递的模块相同， 类型为数据传递
        if (bizflowConfig.modulename === moduleName && type === datatransfer) {
            // 获取数据传递配置
            const datatransferConfig = getOneDatatransfer(moduleName, name)
            // 查询条件是否关联子模块
            if (hasSublistSearch(datatransferConfig)) {
                const { searchsublistmapping } = datatransferConfig
                // 遍历查询关联子模块映射
                for(let key in searchsublistmapping) {
                    // 需要关联的子模块模块名称
                    let value = searchsublistmapping[key]
                    // 获取子模块实例
                    const listInst = getSublistInst(fieldsInst, value)
                    if (listInst && listInst.getData) {
                        // 拼接子模块数据
                        record[sublistPrefix + value] = listInst.getData()
                    }
                }
            }
            break
        }
    }
}

/**
 * 模块数据选择组件 组装数据传递的查询条件
 * @param {*} thispage 模块数据选择组件所在页面
 * @param {*} moduleDataSelectModuleName 数据传递组件的模块
 * @returns 
 */
async function buildModuleDataSelectConds(thispage, moduleDataSelectModuleName) {
    if (!(thispage && thispage.sublist)) {
        return null
    }
    const datatransferList = await getDatatransferList(thispage.moduleName)
    const datatransferConfig = datatransferList.find(item => 
        item.selectmodule === moduleDataSelectModuleName)
    if (datatransferConfig == null) {
        return null
    }
    const { searchsublistmapping, searchmapping } = datatransferConfig
    //console.log('searchsublistmapping', searchsublistmapping)
    //console.log('searchmapping', searchmapping)
    // 查询条件关联子模块和查询映射不为空
    if (!(searchmapping && hasSublistSearch(datatransferConfig))) {
        return null
    }
    const moduleDataSelectConds = buildSearchConds(moduleDataSelectModuleName, 
        searchmapping[moduleDataSelectModuleName], thispage.getData())
    const fidSet = new Set()
    for (let key in searchsublistmapping) {
        let sublistModuleName = searchsublistmapping[key]
        //console.log('searchsublistmapping',key, sublistModuleName)
        // 获取子模块数据
        const listInst = thispage.sublist[sublistModuleName]
        if (!(listInst && listInst.getData)) {
            continue
        }
        // 传递模块的子模块数据，作为查询条件数据
        const list = listInst.getData() || []
        //console.log(sublistModuleName, list)
        // 子模块之间的字段映射
        const mapping = searchmapping[key] || {}
        if (list.length === 0 || utils.equals(mapping, {})) {
            continue
        }
        // 子模块查询关联
        for (let record of list) {
            let searchConds = buildSearchConds(key, mapping, record)
            //console.log('searchConds', searchConds)
            const query = buildQuery(searchConds)
            //console.log(query)
            const body = await getListPromise(key, 1, 100, query)
            const list = body.list || []
            //console.log('list',list)
            for (let item of list) {
                if (item.fid) {
                    fidSet.add(item.fid)
                }
            }
        }
    }
    //console.log('fidSet', fidSet)
    if (fidSet.size > 0) {
        moduleDataSelectConds.id = Array.from(fidSet)
    }
    //console.log('moduleDataSelectConds', moduleDataSelectConds)
    return moduleDataSelectConds
}

/**
 * 组装数据传递的查询条件
 * @param {*} datatransferConfig 
 * @param {*} fRecord 
 * @returns 
 */
function buildDatatransferSearchConds(datatransferConfig, fRecord) {
    fRecord = fRecord || {}
    // 查询条件
    const searchConds = {}
    if (datatransferConfig && datatransferConfig.searchmapping && fRecord) {
        // datatransferConfig.modulename 数据传递所在的模块
        // fmodule 数据传递所在的模块的父模块
        // 根据父模块的数据以及查询条件映射生成查询条件
        const fmodule = utils.getModuleConfig(datatransferConfig.modulename).fmodule
        return buildSearchConds(fmodule, datatransferConfig.searchmapping, fRecord)
    }
    return searchConds
}

/**
 * 组装数据传递的查询条件
 * @param {*} moduleName 
 * @param {*} searchmapping 
 * @param {*} record 
 * @returns 
 */
function buildSearchConds(moduleName, searchmapping, record) {
    if (utils.isBlank(moduleName) || 
        searchmapping == null || utils.equals(searchmapping, {}) ||
        record == null || utils.equals(record, {})
    ) {
        return {}
    }
    // 查询条件
    const searchConds = {}
    const fieldsConfig = utils.getModuleConfig(moduleName).fields_config
    for (let key in searchmapping) {
        if (key.endsWith('alias')) {
            continue
        }
        let fieldName = searchmapping[key]
        // 字段存在取字段值，字段不存在则认为是常量，直接赋值
        if (fieldsConfig.findIndex(item => item.name === fieldName) !== -1) {
            searchConds[key] = record[fieldName]
        } else {
            searchConds[key] = fieldName
        }
    }
    return searchConds
}

/**
 * 获取数据传递配置
 * @param {*} modulename 模块名
 * @param {*} name 按钮名称
 * @returns 
 */
function getOneOrchestrate(modulename, name) {
    const list = o.orchestrateList
    if (list instanceof Array) {
        return list.find(item => item.modulename === modulename && item.name === name)
    }
    return null
}
/**
 * 获取数据传递配置
 * @param {*} modulename 模块名
 * @param {*} name 按钮名称
 * @returns 
 */
function getOneDatatransfer(modulename, name) {
    const list = o.datatransferList
    if (list instanceof Array) {
        return list.find(item => item.modulename === modulename && item.name === name)
    }
    return null
}

/**
 * 新增、修改页面业务编排操作成功回调
 * 处理管道的最后一次操作
 * @param {*} buttonInfo 业务编排配置数据
 * @param {*} res 业务编排返回数据
 * @param {*} inst Detail / New 页面的表单页面 Fields 实例
 * @returns 
 */
function pageBizFlowSuccess(buttonInfo, res, inst) {
    const pipelineItem = getPipelineLastOper(buttonInfo)
    if (pipelineItem == null || inst == null) {
        return
    }
    const {moduleName, name, type} = pipelineItem
    if (type === datatransfer) {
        const {
            //fRecord, // 数据传递父模块数据
            records, // 数据传递的数据
        } = res
        const row = getOneDatatransfer(moduleName, name)
        const { selectmodule } = row
        if (records instanceof Array && records.length > 0 && inst.moduleDataChange) {
            inst.moduleDataChange(selectmodule, null, null, null, records[0])
            // 修改模块数据选择组件的值
            for (let config of getTableConfig(moduleName)) {
                if (config.params === selectmodule) {
                    const key = config.name
                    const {id, name, code} = records[0]
                    const value = {id, name, code}
                    inst.setFieldsValue({[key]: value})
                }
            }
        }
    } else if (type === openpage) {

    }
}

/**
 * 列表模型页面业务编排操作成功回调
 * 处理管道的最后一次操作
 * @param {*} buttonInfo 业务编排配置数据
 * @param {*} res 业务编排返回数据
 * @param {*} listPageInst ListModule this
 * @returns 
 */
function listPageBizFlowSuccess(buttonInfo, res, listPageInst) {
    const pipelineItem = getPipelineLastOper(buttonInfo)
    if (pipelineItem == null || listPageInst == null) {
        return
    }
    const {moduleName, name, type} = pipelineItem
    if (type === datatransfer) {
        const {
            fRecord, // 数据传递父模块数据
            records, // 数据传递的数据
        } = res
        const row = getOneDatatransfer(moduleName, name)
        if (listPageInst.moduleName === moduleName) {
            listPageInst.addTransferData(row, records, true)
        } else {
            listPageInst.nestedSublistAddTranferData(fRecord, row, records, true)
        }
    } else if (type === openpage) {

    }
}

/**
 * 获取管道的最后一次操作
 * @param {*} buttonInfo 数据传递或业务编排的配置数据
 */
function getPipelineLastOper(buttonInfo) {
    const pipeline = buttonInfo.pipeline
    if (pipeline instanceof Array && pipeline.length > 0) {
        return pipeline[pipeline.length - 1]
    }
    return null
}

/**
 * exprUtil
 * 表达式工具
 */
const operChars = ' +-*/%=<>?:!~^|&()'

/**
 * getNewExpr
 * 获取拼接数据对象的新表达式
 * @param {*} fieldsConfig 字段配置
 * @param {*} expr 表达式配置
 * @returns 
 */
function getNewExpr(fieldsConfig, expr) {
    // 对字段按照名称长度降序排序，保证名字长的字段先搜索到，替换名称时避免字段名称部分替换
    const names = fieldsConfig.filter(it => !utils.isSysFields(it.name))
    .sort((a, b) => {
        return b.name.length - a.name.length
    })
    .map(it => it.name)
    //console.log(names + '')
    /**
     * 值，位置，类型 数组
    {
        value: findName,  // 字段名称 或 非字段名称字符串
        pos: index,  // 找到字段的索引
        isName: true // 字段名称 为 true， 非字段名称字符串 为 null
    }
    */
    const typePosArray = []
    // 起始搜索位置
    let pos = 0
    // 外层循环条件 搜索位置小于表达式长度
    while(pos < expr.length) {
        // 挨个字段查找，搜索位置等于找到字段索引的最小值
        // 找到字段的索引
        let index = 0
        // 找到字段的名称
        let findName = null
        // 遍历所有字段名
        // 字段名必须按照长度降序排序，否则会出现长字段名称包含短字段名称时，长字段名称被部分替换的情况
        for (let name of names) {
            // 从 pos 位置在表达式中查找字段名称
            let findIndex = expr.indexOf(name, pos)
            if (findIndex === -1) {
                continue
            }
            while(findIndex > 0) {
                //console.log('findIndex', findIndex, name, pos)
                // 判断前一个字符是否为表达式字符
                const prevChar = expr.at(findIndex - 1)
                //console.log('prevChar', findIndex, prevChar)
                if (operChars.indexOf(prevChar) !== -1) {
                    break
                }
                // 如果不是，继续查找
                findIndex = expr.indexOf(name, findIndex + name.length)
                //console.log('findIndex', expr, name, findIndex)
            }
            // 找到字段
            if (findIndex !== -1) {
                // 第一次找到字段
                if (findName === null) {
                    findName = name
                    index = findIndex
                } else if (findIndex < index) {
                    // 第二次及以后找到字段， 索引比上次小替换找到字段名称及索引
                    findName = name
                    index = findIndex
                }
            }
        }
        // 字段名称遍历结束，未在表达式中找到任何字段，结束循环
        if (findName === null) {
            break
        }
        // 如果找到字段的索引不等于搜索的起始位置, 说明起始位置到找到字段索引之间有非字段字符串
        // 先添加起始位置到找到字段索引之间的非字段字符串
        // 再添加字段
        if (index !== pos) {
            // 非字段字符串 值， 位置
            typePosArray.push({
                // 截取起始位置到找到字段索引之间的非字段字符串
                value: expr.substring(pos, index),
                pos: pos,
            })
            // 字段 值，位置， 字段标记
            typePosArray.push({
                value: findName,
                pos: index,
                isName: true // 字段名称标记
            })
        } else {
            // 如果找到字段的索引等于搜索的起始位置, 说明起始位置到找到字段索引之间没有其他字符
            // 加入字段 值，位置，字段标记
            typePosArray.push({
                value: findName,
                pos: index,
                isName: true 
            })
        }
        // 搜索位置更新为找到字段的索引加字段长度
        pos = index + findName.length
    }
    // 循环结束后，如果搜索位置小于表达式长度，说明搜索位置到表达式结束位置之间还有非字段名称字符串
    // 添加到类型位置数组
    if (pos < expr.length) {
        typePosArray.push({
            value: expr.substring(pos),
            pos: pos,
        })
    }
    // 组装替换后的新表达式
    let newExpr = ''
    for (let it of typePosArray) {
        const {value} = it
        // 如果是字段类型，前面拼接 record.
        if (it.isName) {
            newExpr += 'record.' + value
        } else {
            // 非字段直接拼接
            newExpr += value
        }
    }
    //console.log('expr', expr)
    //console.log('newExpr', newExpr)
    return newExpr
}

/**
 * @param {*} values 表单数据
 * @param {*} rowKey 可编辑表格中默认为id， 新增/修改页面为 ''
 * @param {*} fieldsConfig 字段配置
 * @param {*} fRecord 父模块数据, 表达式计算时使用
 */
function callExpr(values, rowKey = '', fieldsConfig, fRecord) {
    if (values == null || !(fieldsConfig instanceof Array)) {
        return {}
    }
    const newValues = {}
    let exprConfig = fieldsConfig.filter(it => it.dataType === 'EXPR')
    const record = {}
    for (let config of fieldsConfig) {
        //console.log('config: ', config)
        let formKey = config.name + rowKey
        let key = config.name
        //console.log("key: ", formKey, key)
        if (isExprSupportConfig(config)) {
            record[key] = getNumber(values[formKey])
        }
    }
    //console.log('record', record)
    // eslint-disable-next-line
    const f = fRecord || {}
    for (let field of exprConfig) {
        let value = null
        try {
            let newExpr = getNewExpr(fieldsConfig, field.params)
            // eslint-disable-next-line
            value = eval(newExpr)
            if (!(typeof value === 'string') && isNaN(value)) {
                value = null
            }
            //console.log('value:', value)
        } catch (e) {
            console.error(e)
            value = null
        }
        let key = field.name + rowKey
        record[field.name] = value
        newValues[key] = value
    }
    return newValues
}

/**
 * exprValue 获取表达式的值
 * @param {*} expr 
 * @param {*} values 
 * @param {*} fRecord 
 * @param {*} fieldsConfig 
 * @param {*} rowKey 
 * @returns 
 */
function exprValue(expr, values, fRecord, fieldsConfig, rowKey, sublistObj) {
    rowKey = rowKey || ''
    sublistObj = sublistObj || {}
    if (values == null || !(fieldsConfig instanceof Array)) {
        return {}
    }
    const record = {}
    for (let config of fieldsConfig) {
        //console.log('config: ', config)
        let formKey = config.name + rowKey
        let key = config.name
        //console.log("key: ", formKey, key)
        if (isExprSupportConfig(config)) {
            record[key] = getNumber(values[formKey])
        }
    }
    //console.log('record', record)
    //console.log('fRecord', fRecord)
    // eslint-disable-next-line
    const f = fRecord || {}

    let value = null
    try {
        let newExpr = getNewExpr(fieldsConfig, expr)
        //console.log('fRecord', f)
        //console.log('record', record)
        //console.log('expr', expr, newExpr)
        // eslint-disable-next-line
        value = eval(newExpr)
        //console.log('value',  value)
        if (!(typeof value === 'string') && isNaN(value)) {
            value = null
        }
        //console.log('value:', value)
    } catch (e) {
        console.error(e)
        value = null
    }

    return value
}

/**
 * 列表添加记录
 * @param {*} list 
 * @param {*} record 
 * @param {*} config newplace 新增记录位置 0,最前面/1,最后面
 */
function listAdd(list, record, config) {
    if (record instanceof Array) {
        if (config && config.newplace === '1' && (list instanceof Array)) {
            list.push(...record)
        } else {
            list.unshift(...record)
        }
    } else {
        if (config && config.newplace === '1' && (list instanceof Array)) {
            list.push(record)
        } else {
            list.unshift(record)
        }
    }
}

/**
 * 组装表格表单数据，给key拼接rowKey
 * @param {*} list 
 * @param {*} rowKey 
 */
function buildTableFormData(list, rowKeyName = 'id') {
    const tableFormValues = {}
    if (list instanceof Array) {
        for (let item of list) {
            for (const key in item) {
                if (key === rowKeyName) {
                    continue
                }
                const rowKey = item[rowKeyName]
                tableFormValues[key + rowKey] = item[key]
            }
        }
    }
    return tableFormValues
}

/**
 * 根据查询条件构造查询请求body， 所有字段按照ID精确查询
 * @param {*} searchConds 
 */
function buildQuery(searchConds) {
    if (searchConds == null || utils.equals(searchConds, {})) {
        return {}
    }
    const query = {searchFields: searchConds, moreFields: []}
    for (let key in searchConds) {
        query.moreFields.push({
            id: key,
            dataType: 'ID'
        })
    }
    return query
}

/**
 * query 添加一个搜索条件
 * @param {*} query 
 * @param {*} searchConds 
 */
function queryAdd(query, searchConds, isLike) {
    query = query || {}
    if (searchConds == null || utils.equals(searchConds, {})) {
        return query
    }
    query.searchFields = query.searchFields || {}
    query.moreFields = query.moreFields || []
    for (let key in searchConds) {
        let value = searchConds[key]
        query.searchFields[key] = value
        // 跳过主查询mainKey
        if (key === 'mainKey') {
            continue
        }
        if (!query.moreFields.find(item => item.id === key)) {
            query.moreFields.push({
                id: key,
                dataType: isLike ? 'STRING' : 'ID'
            })
        }
    }
    return query
}

/**
 * query 移除一个搜索条件
 * @param {*} query 
 * @param {*} key 
 * @returns 
 */
function queryDelKey(query, key) {
    if (key == null || query == null || utils.equals(query, {})) {
        return
    }
    const {searchFields, moreFields} = query
    if (!(searchFields && (moreFields instanceof Array))) {
        return
    }
    delete searchFields[key]
    const index = moreFields.findIndex(it => it.id === key)
    if (index !== -1) {
        moreFields.splice(index, 1)
    }
}

/**
 * TableEx componentChange 组件值格式化
 * @param {*} value 
 * @returns 
 */
function componentChangeValueFormat(value){
    if (utils.isBlank(value) || typeof value === 'string') {
        return value
    }
    try {
        // 如果是日期，转为ISO串
        let m = moment(value)
        if (m.isValid()) {
            return m.toISOString()
        }
        return value
    } catch (e) {
        return value
    }
}

function getNewRecord() {
    let id = utils.uuid()
    return { 
        key: id, 
        id: id, 
        disabled: false, 
        change: true,
        isNew: true 
    }
}

export {
    quanpin, jianpin, addPinyin, getQuanpinKey, getJianpinKey,
    dataTypeArray, numberConfig, stringConfig, flowstatusConfig, operOptions, dataTypeNames, 
    numberTypes,
    getFlowstatusConfig, getStringConfig, getNumberConfig, getConfig, getClassConfig, 
    isIDType, isSysDataType, isSelectType, isSelectOneType, isSelectMultiType, isAntdSelectType, 
    isInputSelectType, isExprSupportType, isNumberMoneyType, isNumberType, isMoneyType, 
    isPercentType, isDateTimeType, isDateType, isTimeType, isCheckboxType, isStringTextType, 
    isTextType, isStringType, isInputType, isPhoneType, isFileType, isExprType, isListTreeType, 
    isListType, isTreeType, isTableType, isSumType, isFFieldType, isOptionType, isIconType, 
    isSubListConfigType, isCondOOTableType, isPipelineType, isMappingType, isTagType, isGroupType, 
    isColorType,
    isDisabledFFieldType, isNumber, isExprSupportConfig, isRowOneType, isHideOnSearchType, 
    isOOTableType, isH2LabelType, isCenteredType, isFieldDecoratorProps, 
    mergeFConfig, mergeSumConfig, getRealType, 
    getMergeConfig, getMergeConfigByConfig, getTableConfig, getFieldOptions, filterDataType, 
    formError, flatObj, convertData, getTransferMap, getTransferMapByTransferModule, transferData, 
    deleteSourceCode, getTransferData, getTransferFormData, tranferSublist, showModuleName, 
    showName, isShowCode, getValue, getValues, setValue, setValues, getSumFieldNames, 
    setSumFieldsValue, setSumValue, modifyNestedFRecrod, sumAdd, sumSubtract, getNumber, 
    getDatatransferListAll, getDatatransferList, getOrchestrateButtonAll, getOrchestrateButton, 
    getFormValues, getInitialValue, getTableList, refreshInnerPage, saveInnerNewOrDetailPage, 
    operInnerPage, getDataType, getFDataType, refreshSublist, saveSublist, operSublist, delSubList,
    copyRecord, mergeSubList, deleteSublistKey, getSublistNameArr, getVisibleSublist, 
    getVisibleNestedSublist, hasNestedSublist, getNestedSublist, valueToMoment, 
    condsValueDefaultTrue, condsValue, downloadImportTemplate, exportData, dataCheck, canTransfer, 
    hasSublistSearch, hasSublistMapping, getSublistInst, bizflowRecordBindSublist, 
    buildModuleDataSelectConds, buildDatatransferSearchConds, buildSearchConds, getOneOrchestrate, 
    getOneDatatransfer, pageBizFlowSuccess, listPageBizFlowSuccess, getPipelineLastOper, 
    getNewExpr, callExpr, exprValue, listAdd, buildTableFormData, buildQuery, queryAdd, queryDelKey,
    componentChangeValueFormat, getNewRecord, batchDeleteSubListByFid, saveInnerSubList,
}