/**
 * 工作流工具方法
 */
import React from 'react'
import Modal from 'antd/lib/modal'
import ModuleDataSelect from '@com/ModuleDataSelect'
import TableEx from '@com/TableEx'
import moment from 'moment'
import { openpage } from '@utils/page'
import { getOneByCodePromise } from '@list/Action'


/**
 * 流程详情
 * @param {*} record 
 */
export async function flowDetail(record = {}) {
    let list = record.passedNodes || []
    list = list.map((item, index) => { 
        item.index = index + 1
        return item
    })
    const nextUsers = (record.next && record.next.nextUsers) || []
    const users = []
    for (let code of nextUsers) {
        let user = await getOneByCodePromise('staff', code)
        users.push(user)
    }
    const columns = [
        {title: '序号', dataIndex: 'index', key: 'index', width: 60},
        {title: '处理人', dataIndex: 'code', key: 'code', width: 80, 
            render: (text, record) => <ModuleDataSelect 
                disabled 
                value={{id: record.id, code: text, name: record.name}} 
                config={{params: "staff"}} 
                moduletype='list' 
            />
        },
        {title: '备注', dataIndex: 'remark', key: 'remark', width: 160},
        {title: '处理时间', dataIndex: 'time', key: 'time', width: 120, 
            render: (text) => moment(text).format('YYYY-MM-DD HH:mm:ss')
        },
    ]
    let table = <TableEx
        columns={columns}
        dataSource={list}
        rowKey={record => record.index}
    />
    let userDiv = <div className='flex-start' style={{marginTop: 16}}>
        <div>下一步处理人：</div>
        <div>{
            users.map((record, index) => <span key={index} style={{marginRight: 16}}>
                <ModuleDataSelect
                    key={index}
                    disabled 
                    value={{id: record.id, code: record.code, name: record.name}} 
                    config={{params: "staff"}} 
                    moduletype='list' 
                />
            </span>)
        }</div>
    </div>
    if (record.status === 'end') {
        userDiv = <div className='flex-start' style={{marginTop: 16, marginBottom: 16}}>
            流程已结束
        </div>
    }
    Modal.info({
        title: '流程详情',
        content: <div>
            {userDiv}
            {table}
        </div>,
        footer: [],
        width: Math.min(global.clientWidth, 800),
    })
}

/**
 * 打开流程图
 * @param {*} record 
 */
export function showFlowGraph(record) {
    console.log('showFlowGraph',record)
    let moduleConfig = global.getConfigByModuleName(record.moduleName)
    let url = `/workflow?id=${moduleConfig.id}&disabled=true&nodeId=${record.next.nodeId}`
    let title = `${record.moduleName}-流程`
    openpage(url, title)
}