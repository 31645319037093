/**
 * @加密模块
 * @author adamhall
 * */
/* global Buffer */
//导入模块
// Includes crypto module 
const crypto = require('crypto'); 
// Difining algorithm 
const algorithm = 'aes-256-cbc'; 
// Defining key 
const key = Buffer.from('oooooooooooooooooooooooooooooooo'); 

/**
 * @aes192加密模块
 * @param str string 要加密的字符串
 * @param secret string 要使用的加密密钥(要记住,不然就解不了密啦)
 * @retrun string 加密后的字符串
 * */
function encrypt(text) {
    // Defining iv 
    const iv = crypto.randomBytes(16)
    let cipher = crypto.createCipheriv(algorithm, key, iv)
     // Updating text 
    let encrypted = cipher.update(text); 
    // Using concatenation 
    encrypted = Buffer.concat([encrypted, cipher.final()]); 
    // Returning iv and encrypted data 
    return iv.toString('hex') + ":" + encrypted.toString('hex'); 
}
/**
 * @aes192解密模块
 * @param str string 要解密的字符串
 * @param secret string 要使用的解密密钥(要和密码的加密密钥对应,不然就解不了密啦)
 * @retrun string 解密后的字符串
 * */
function decrypt(text) {
    let textParts = text.split(':')
    let iv = Buffer.from(textParts.shift(), 'hex')
    let encryptedText = Buffer.from(textParts.join(':'), 'hex')
    let decipher = crypto.createDecipheriv(algorithm, key, iv)
    let decrypted = decipher.update(encryptedText)
    decrypted = Buffer.concat([decrypted, decipher.final()])
    return decrypted.toString()
}
/**
 * @Hmac-sha1加密模块 (每次加密随机,不可逆)
 * @param str string 要加密的字符串
 * @retrun string 加密后的字符串
 * */
function hmac(str) {
    let buf = crypto.randomBytes(16)
    let secret = buf.toString("hex");//密钥加密；
    let Signture = crypto.createHmac("sha1", secret);//定义加密方式
    Signture.update(str)
    let miwen=Signture.digest().toString("base64");//生成的密文后将再次作为明文再通过pbkdf2算法迭代加密；
    return miwen
}
/**
 * @sha1加密模块 (加密固定,不可逆)
 * @param str string 要加密的字符串
 * @retrun string 加密后的字符串
 * */
function sha1(str) {
    let sha1 = crypto.createHash("sha1");//定义加密方式:md5不可逆,此处的md5可以换成任意hash加密的方法名称；
    sha1.update(str)
    let res = sha1.digest("hex");  //加密后的值d
    return res
}

module.exports = {
    encrypt,
    decrypt,
    hmac,
    sha1
}