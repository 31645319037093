import superagent from 'superagent'
import Modal from 'antd/lib/modal'
import {getCookie} from '../cookie'
import * as p from '../page'

const methods = [
	'get',
	'head',
	'post',
	'put',
	'del',
	'options',
	'patch'
]
/**
 * superagent封装restful接口
 */
class _Api {

	constructor(opts) {

		this.opts = opts || { headers: {} }

		if (!this.opts.baseURI)
			throw new Error('baseURI option is required')

		methods.forEach(method =>
			this[method] = (path, { params, data } = {}) => new Promise((resolve, reject) => {
				let url = this.opts.baseURI + path

				const request = superagent[method](url)

				if (params) {
					request.query(params)
				}
				
				if (this.opts.headers) {
					request.set(this.opts.headers)
				}

				request.set({token: getCookie('token')})

				if (data) {
					request.send(data)
				}
				request.end((err, res) => {
					if (err) {
						console.log('error: ', path, err)
						reject(res)
						let message = res && res.body && res.body.message
						if (message && message.startsWith('Error: ')) {
							message = message.substring(7)
						}
						if (path.indexOf('/system/store') !== 0 && path !== '/code') {
							console.log('error: ', path)
							p.stopLoad()
							if (global.notip) {
								global.notip = false
								return
							}
							Modal.error({
								title: message || '未知错误',
								content: message == null ? '访问后端接口失败' : '',
							})
						}
					} else {
						resolve({ body: res.body, status: res.status })
					}
				})
			})
		)
	}
}

const Api = _Api

export default Api
