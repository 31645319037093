/**
 * 模块管理接口
 */
import platformApi from '@utils/platform_api'
const api = platformApi

/**
 * 根据模板更新数据库
 * @param {*} sourceDb 模板数据库名称
 * @param {*} targetDb 目标数据库名称
 */
export async function updateConfig(sourceDbCode, targetDbCode) {
    try {
        const payload = await api.put(`/updateConfig`, { 
            data: { sourceDbCode, targetDbCode }
        })
        return payload.body
    } catch (e) {}
    return null
}
/**
 * 修改用户密码
 * @param {*} username 用户名
 * @param {*} password 密码
 */
export function modifyPassword(username, password, oldPassword, cb, err) {
    api.put(`/modifyPassword`, { 
        data: { username, password, oldPassword }
    }).then(payload => {
        cb && cb(payload.body || {})
    }, () => {
        err && err({})
    })
}

/**
 * 根据模块名称获取一条表格配置
 * @param {*} name 
 * @param {*} cb 
 */
export function getOneByModuleName(name, cb) {
    api.get('/table/byname', {
        params: { name }
    }).then(payload => {
        cb && cb(payload.body || {})
    }, () => {
        cb && cb({})
    })
}

/**
 * 根据id获取一条数据
 * @param {*} id 
 * @param {*} cb 
 */
export function getOne(moduleName, id, cb) {
    api.get(`/${moduleName}`, {
        params: { id }
    }).then(payload => {
        cb && cb(payload.body || {})
    }, () => {
        cb && cb({})
    })
}
/**
 * 根据id获取一条数据
 * @param {*} id 
 */
export async function getOnePromise(moduleName, id) {
    try {
        const payload = await api.get(`/${moduleName}`, {
            params: { id }
        })
        return  (payload && payload.body) || {}
    } catch (e) {
        console.error(e)
        return {}
    }
}

/**
 * 成为用户
 * @param {*} entid 企业id
 * @param {*} data 用户数据
 * @param {*} cb 
 */
export async function becameUser(entid, data, cb, err) {
    console.log(entid, 'entid')
    try {
        const payload = await api.post(`/became/user`, {
            params: {entid},
            data: data
        })
        return payload.body
    } catch(e) {
        console.error(e)
    }
    return null
}

/**
 * 删除用户
 * @param {*} id 
 * @param {*} cb 
 */
export async function deleteUser(id, cb) {
    try {
        await api.del(`/user`, {
            params: { id }
        })
        return true
    } catch (e) {
        console.error(e)
    }
    return false
}

export async function initEnt(ent, cb) {
    return await api.post(`/initEnt`, {
        data: {db: ent.db, server: ent.server, systemtype: ent.systemtype}
    })
}