import React, { Component } from 'react'
import { getFileById } from '../modules/file/Action'
import { openpage } from '@utils/page'

export default class Image extends Component {
    constructor() {
        super()
        this.state = {
            loading: false,
            imageUrl: null,
        }
    }
    

    componentDidMount(props) {
        this.props.value && this.refresh(this.props.value)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.value && nextProps.value !== this.props.value) {
            this.refresh(nextProps.value)
        }
    }

    refresh(id) {
        id && getFileById(id, file => {
            if (file && file.filePath) {
                //console.log(file, 'file')
                let imageUrl = global.getApi().opts.baseURI + file.filePath
                //console.log('imageUrl--------------------', imageUrl)
                this.setState({imageUrl})
            }
        })
    }

    render() {
        const {disabled, value} = this.props
        const { imageUrl } = this.state
        const width = this.props.width || 128
        return <div style={{ width: width, height: width }}>
            {imageUrl &&
                <div
                    onClick={() => !disabled && openpage(`/fullimage?url=${imageUrl}&id=${value}`, '预览')}
                    style={{
                        width: '100%', height: '100%', 
                        backgroundSize: 'cover', // contain  cover
                        backgroundImage: `url("${imageUrl}")`,
                        backgroundPosition: 'center center',
                    }}
                />
            }
        </div>
    }
}