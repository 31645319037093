import Api from './api'
const getBaseUri = () => {
	// 前端运行环境
	let baseURI = `${document.location.protocol}//${window.location.host}/platform`

	// 如果前端本地运行
	global.localFrontEnd = ['localhost', '127.0.0.1'].indexOf(window.location.hostname) !== -1
	if (global.localFrontEnd) {
		// 默认访问服务器nginx，所有请求由nginx代理
		//baseURI = `${document.location.protocol}//192.168.0.10/platform`
		baseURI = `${document.location.protocol}//47.107.127.79/platform`
		//baseURI = `https://adamroberthall.yicp.io/platform`
		
		// 访问本机平台后端
		global.localPlatServer = false
		if (global.localPlatServer) {
			baseURI = `${document.location.protocol}//localhost:32087`
		}
	}
	return baseURI
}

/**
 * json数据格式
 */
const api = new Api({
	getBaseUri: getBaseUri,
	baseURI: getBaseUri(),
	headers: {
		'Accept': 'application/json',
		'Content-Type': 'application/json'
	}
})

export default api


