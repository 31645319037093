/**
 * Antd Select 封装
 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Select from 'antd/lib/select'
import Radio from 'antd/lib/radio'
import Tag from './OOTag'
import Popover from 'antd/lib/popover'
import OOCheckBoxGroup from './OOCheckBoxGroup'
import * as utils from '@utils/index'

export default class OOSelect extends Component {
    constructor() {
        super()
        this.state = {
            selectOptions: []
        }
    }
    componentDidMount() {
        this.init(this.props.options)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.options !== this.props.options) {
            this.init(nextProps.options)
        }
    }
    init(options) {
        options = options || []
        if (typeof options === 'string') {
            options = utils.getOptionArray(options)
        }
        const {valueKey, labelKey} = this.props
        options = options.map(item => {
            let value = null, label = null
            if (typeof item === 'string') {
                value = label = item
            } else if (item instanceof Object) {
                value = item[valueKey || 'value']
                label = item[labelKey || 'label']
            }
            return {value, label}
        })
        this.setState({selectOptions: options})
    }
    onRadioChange(e) {
        this.props.onChange && this.props.onChange(e.target.value)
    }
    render () {
        const {
            showType,
            value, disabled, style, 
            allowClear, showSearch, optionFilterProp, placeholder, 
            valueKey, labelKey, options,
            thispage, oodata, moduleName,
            ...restProps
        } = this.props
        const {selectOptions} = this.state
        if (selectOptions.length === 0) {
            return <span/>
        }
        if (disabled && showType == null) {
            if (utils.isBlank(value)) {
                return <span/>
            } 
            
            if (value instanceof Array) {
                const tags = value.map((item, index) => {
                    const children = utils.valueToLabel(item, selectOptions)
                    return <Tag key={index} moduleName={moduleName} value={item}>{children}</Tag>
                })
                return <Popover
                    content={<div>{tags}</div>}
                >{tags}</Popover>
            }
            const children = utils.valueToLabel(value, selectOptions)
            return <Tag moduleName={moduleName} value={value}>{children}</Tag>
        }

        if (showType === 'radio') {
            return <Radio.Group {...restProps} style={style}
                options={selectOptions}
                disabled={disabled} 
                onChange={this.onRadioChange.bind(this)} 
                value={utils.isBlank(value) ? undefined : value}
            />
        }
        if (showType === 'radioButton') {
            return <Radio.Group {...restProps} style={style}
                size={this.props.size}
                disabled={disabled} 
                onChange={this.onRadioChange.bind(this)} 
                value={utils.isBlank(value) ? undefined : value}
            >
                {selectOptions.map(it => <Radio.Button key={it.value} value={it.value}>{it.label}</Radio.Button>)}
            </Radio.Group>
        }
        if (showType === 'checkbox') {
            return <OOCheckBoxGroup {...restProps} 
                style={style} 
                value={value} 
                options={selectOptions}
            />
        }

        return <Select 
            {...restProps}
            value={utils.isBlank(value) ? undefined : value}
            showSearch={showSearch || true}
            optionFilterProp={optionFilterProp || "label"}
            style={Object.assign({ display: 'inline-block', width: '100%' }, style)}
            placeholder={placeholder || '请选择'}
            allowClear={allowClear || true}
        >
            {selectOptions.map((item, index) => {
                const {value, label} = item
                return <Select.Option key={index} value={value} label={label}>{label}</Select.Option>
            })}
        </Select>
    }
}
OOSelect.propTypes = {
    /**
     * 选项数据
     * 1. 字符串类型
     * 格式1： value/value2， label和value相同
     * 格式2： value,label/value2,label2 
     * 例如： list,列表/tree,树
     * 2. JSON 数组
     * 格式： 
        [
            {value: 'left', label: '左边'}, 
            {value: 'right', label: '右边'},
        ]
     */
    options: PropTypes.any,
    // 默认 value
    valueKey: PropTypes.string,
    // 默认 label
    labelKey: PropTypes.string,
}