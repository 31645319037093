import React, { Component } from 'react'
import TableEx from '@com/TableEx'
import {getList} from './Action'
import * as Action from '@list/Action'
import { getTree } from '@tree/Action'
import { configToColumn, getPagination } from '@com/PageCreator'
import Search from '@com/Search'
import * as dataUtil from '@utils/DataUtil'
import * as utils from '@utils/index'
import * as p from '@utils/page'
import { reportengine } from '@utils/constants'

/* global traverseTree */
export default class Report extends Component {
	constructor(props) {
		super(props)
		this.state = {
			list: [],
			moduleName: '', 
			showFields: [],
			queryFields: [],
			joinModules: [],
			page: 1,
			pageSize: 10, 
			query: {
                searchFields: {},
                mainFields: [],
                moreFields: []
            },
			sorter: {},
		}
	}

	startLoading() {
		p.startLoad()
	}

	stopLoading() {
		p.stopLoad()
	}

	componentDidMount() {
		this.id = global.parseSearchStr().id || this.props.id
		if (this.id === null) {
			return
		}
		Action.getOne(reportengine, this.id, record => {
			utils.parseObjectOrArray(record)
            let { moduleName, showFields, queryFields, joinModules, groups } = record
			if (utils.isBlank(moduleName)) {
				utils.error('主模块未配置')
				return
			}
			showFields = showFields || []
			queryFields = queryFields || []
            joinModules = joinModules || []
            this.setState({ moduleName, showFields, queryFields, joinModules, groups })
			const {query} = this.state
			this.onSearch(query)
			let treeNameMap = {}
			const buildTreeData = (moduleName) => {
				// 查询树组件数据
				dataUtil.getTableConfig(moduleName).forEach(config => {
					if (config.isShowT !== '0' && !treeNameMap[config.params]) {
						if (utils.isNotBlank(config.params) && config.dataType === 'TREEMODULE') {
							treeNameMap[config.params] = true
							getTree(config.params, treeData => this.setState({ [config.params]: { treeData } }))
						}
					}
				})
			}

			// 主模块
			buildTreeData(moduleName)
			// 关联模块
			traverseTree(joinModules, (module) => {
				buildTreeData(module.moduleName)
			}, 'joinModules')
        })
	}

	/**
     * 搜索按钮响应函数
     * @param {*} query 
     */
    onSearch(query) {
        const { pageSize, sorter } = this.state
		// 列表 树 模型使用名称排序
        if (sorter && sorter.column) {
            // 列表模块显示字段是否为编码, 如果显示编码，不转换排序字段
            const isShowCode = dataUtil.isShowCode(sorter.column.params)
            if (!isShowCode && dataUtil.isListTreeType(sorter.column.dataType)) {
                sorter.field += '_name'
            }
        }
		this.refresh(1, pageSize, query, sorter)
    }

	/**
	 * 刷新报表
	 * @param {*} page 
	 * @param {*} pageSize 
	 * @param {*} query 
	 * @param {*} sorter 
	 */
	refresh(page, pageSize, query, sorter) {
		this.startLoading()
		getList(this.id, page, pageSize, query, sorter, body => {
			const { totalElements } = body
			let list = body.list || []
			list = list.map((item, index) => { 
				item.index = index + 1
				return item
			})
			this.setState({page, pageSize, query, sorter, totalElements, list})
			this.stopLoading()
		})
	}

	/**
     * 表格发生变化回调函数
     * @param {*} pagination    分页信息
     * @param {*} filters       过滤条件
     * @param {*} sorter        排序信息
     */
    handleTableChange(pagination, filters, sorter) {
        let page = pagination.current
        let pageSize = pagination.pageSize
        if (pageSize !== this.state.pageSize) {
            page = 1
        }
        if (sorter.field !== this.state.sorter.field || sorter.order !== this.state.sorter.order) {
            page = 1
        }
        let { query } = this.state
        this.refresh(page, pageSize, query, sorter)
    }

	render() {
		const { moduleName, showFields, queryFields, joinModules, groups } = this.state
		/**
		 * 全局计数, 一个分组一个计数
		 */
		let sumArr = []
		if (groups instanceof Array) {
			for (let i = 0; i < groups.length; ++i) {
				sumArr.push({})
			}
		}
		const { page, pageSize, totalElements } = this.state
		let columns = [{title: '序号', dataIndex: 'index', key: 'index', width: 60, fixed: 'left'}]
		let scrollx = 60; // 60为序号列宽度
		let fieldsConfig = []
		const buildColumns = (moduleName, prefix, showFields, queryFields) => {
			showFields = showFields || []
			queryFields = queryFields || []
			let parentPrefix = ''
			if (prefix) {
				parentPrefix = `${prefix}.`
			}
			const configs = dataUtil.getTableConfig(moduleName)
			// 显示
			for (let name of showFields) {
				const configSrc = configs.find(it => it.name === name)
				if (configSrc === undefined) continue
				const config = global.copy(configSrc)
				if (moduleName !== this.state.moduleName) delete config.fixed
				scrollx += global.o2i(config.width, 10)
				config.dataIndex = config.key = `${parentPrefix}${config.name}`
				columns.push(configToColumn(this, config, null, false, {
					moduleName: moduleName,
					state: this.state,              // 当前页面 state
				}))
			}
			// 查询
			for (let name of queryFields) {
				const configSrc = configs.find(it => it.name === name)
				if (configSrc === undefined) continue
				const config = global.copy(configSrc)
				config.dataIndex = config.key = `${parentPrefix}${config.name}`
				// 构造表格搜索组件参数， 无特殊处理
				fieldsConfig.push(config)
			}
		}
		
		// 主模块
		buildColumns(moduleName, '', showFields, queryFields)
		// 关联模块
		traverseTree(joinModules, (module, parent) => {
			let prefix = module.as
			if (parent && parent.as) {
				prefix = `${parent.as}.${module.as}`
			}
			buildColumns(module.moduleName, prefix, module.showFields, module.queryFields)
		}, 'joinModules')

		if (groups instanceof Array && groups.length > 0) {
			const groupColumns = [{title: '序号', dataIndex: 'index', key: 'index', width: 60, fixed: 'left'}]
			const groupFieldsConfig = []
			let parentGroupIdIsNotNull = false
			scrollx = 60; // 60为序号列宽度
			for (let i = groups.length - 1; i >= 0; --i) {
				let group = groups[i]
				if (i >= 0 && i < groups.length - 2 && utils.isNotBlank(groups[i + 1].name)) {
					parentGroupIdIsNotNull = true
				}
				if (group.list instanceof Array) {
					if (i === 0) {
						if (groupColumns.findIndex(col => col.dataIndex === group.name) === -1) {
							// 汇总字段
							const config = dataUtil.getStringConfig(group.name)
							const col = configToColumn(this, config)
							if (1 === groups.length) {
								col.sorter = true
							} else if (i === 0) {
								col.sorter = false
							}
							col.no = group.no
							groupColumns.push(col)
							scrollx += global.o2i(config.width, 10)
							// 构造表格搜索组件参数， 无特殊处理
							groupFieldsConfig.push(config)
						}
					}
					for (let it of group.list) {
						if (groupColumns.find(col => col.dataIndex === it.name)) continue
						// 汇总字段
						let config = dataUtil.getNumberConfig(it.name)
						if (typeof it.value === 'string' && it.value.charAt(0) === '$' && it.formula === '$first' ) {
							config = dataUtil.getStringConfig(it.name)
						}
						const col = configToColumn(this, config, null, false, null, (text, row, index) => {
							row.rowSpan = row.rowSpan || {}
							const obj = {
								children: text,
								props: {
									rowSpan: row.rowSpan[config.name]
								},
							}
							if (obj.props.rowSpan == null) {
								sumArr[i][config.name] = sumArr[i][config.name] || 0
								if (index < sumArr[i][config.name]) {
									row.rowSpan[config.name] = obj.props.rowSpan = 0
								}
								if (index === sumArr[i][config.name]) {
									sumArr[i][config.name] += row[`sum${i}`]
									row.rowSpan[config.name] = obj.props.rowSpan = row[`sum${i}`]
								}
							}
							return obj
						})
						if (utils.isBlank(group.name)) {
							col.sorter = false
						} else if (i === groups.length - 1) {
							col.sorter = true
						} else if (i === 0 || parentGroupIdIsNotNull) {
							col.sorter = false
						}
						col.no = it.no
						groupColumns.push(col)
						
						scrollx += global.o2i(config.width, 10)
						// 构造表格搜索组件参数， 无特殊处理
						groupFieldsConfig.push(config)
					}
				}
			}
			groupColumns.sort(utils.sortByKeyAndType('no'))
			columns = groupColumns
			//console.log(columns)
			fieldsConfig = groupFieldsConfig
		}
		// 表格高度，包含分页区域
        let tableHeight = global.tableHeight
        // 表格高度，不包含分页区域
        let tableContentHeight = tableHeight - global.paginationHeight - 12/*x轴滚动条高度*/
		let pagination = getPagination(page, pageSize, totalElements)
		return <div style={{marginTop: 16}}>
			<Search
				fieldsConfig={fieldsConfig}
				onSearch={this.onSearch.bind(this)}
            />
			<TableEx
				columns={columns}
				dataSource={this.state.list}
				rowKey={record => record.index}
				scroll={{ x: scrollx, y: tableContentHeight}}
				onChange={this.handleTableChange.bind(this)}
				pagination={pagination}
				size='middle'
			/>
		</div>
	}
}
