/**
 * 编码方案接口
 */
import * as utils from '@utils/index'

/**
 * 查询
 * @param {*} searchFields 
 * @param {*} mainSearchFields 
 * @param {*} moreSearchFields 
 */
export function getList(page, pageSize, query, sorter, cb) {
    let data = { page, pageSize, query, sorter }
    global.getApi().put('/code_scheme/list', { data }).then(payload => {
        cb && cb(payload.body || {})
    }, () => {
        cb && cb({})
    })
}

/**
 * 获取一条表格配置
 * @param {*} id 
 * @param {*} cb 
 */
export function getOne(id, cb) {
    global.getApi().get('/code_scheme', {
        params: { id }
    }).then(payload => {
        cb && cb(payload.body || {})
    }, () => {
        cb && cb({})
    })
}

/**
 * 根据编码方案生成编码
 */
export function generateCode(schemename, preCode, cb) {
    if (!utils.getModuleConfig(schemename).autocode) {
        return null
    }
    global.getApi().put('/generate_code', {
        params: { schemename },
        data: {preCode}
    }).then(payload => {
        cb && cb(payload.body && payload.body.code)
    }, err => {
        cb && cb(null)
    })
}

/**
 * 根据编码方案生成编码
 */
export async function generateCodeAsync(schemename, preCode, cb) {
    try {
        if (!utils.getModuleConfig(schemename).autocode) {
            return null
        }
        const payload = await global.getApi().put('/generate_code', {
            params: { schemename },
            data: {preCode}
        })
        return payload && payload.body && payload.body.code
    } catch (e) {
        return null
    }
}