export const allIconNames = [
	'fill-drip',
	'arrows-to-circle',
	'circle-chevron-right',
	'chevron-circle-right',
	'at',
	'trash-can',
	'trash-alt',
	'text-height',
	'user-xmark',
	'user-times',
	'stethoscope',
	'message',
	'comment-alt',
	'info',
	'down-left-and-up-right-to-center',
	'compress-alt',
	'explosion',
	'file-lines',
	'file-alt',
	'file-text',
	'wave-square',
	'ring',
	'building-un',
	'dice-three',
	'calendar-days',
	'calendar-alt',
	'anchor-circle-check',
	'building-circle-arrow-right',
	'volleyball',
	'volleyball-ball',
	'arrows-up-to-line',
	'sort-down',
	'sort-desc',
	'circle-minus',
	'minus-circle',
	'door-open',
	'right-from-bracket',
	'sign-out-alt',
	'atom',
	'soap',
	'icons',
	'heart-music-camera-bolt',
	'microphone-lines-slash',
	'microphone-alt-slash',
	'bridge-circle-check',
	'pump-medical',
	'fingerprint',
	'hand-point-right',
	'magnifying-glass-location',
	'search-location',
	'forward-step',
	'step-forward',
	'face-smile-beam',
	'smile-beam',
	'flag-checkered',
	'football',
	'football-ball',
	'school-circle-exclamation',
	'crop',
	'angles-down',
	'angle-double-down',
	'users-rectangle',
	'people-roof',
	'people-line',
	'beer-mug-empty',
	'beer',
	'diagram-predecessor',
	'arrow-up-long',
	'long-arrow-up',
	'fire-flame-simple',
	'burn',
	'person',
	'male',
	'laptop',
	'file-csv',
	'menorah',
	'truck-plane',
	'record-vinyl',
	'face-grin-stars',
	'grin-stars',
	'bong',
	'spaghetti-monster-flying',
	'pastafarianism',
	'arrow-down-up-across-line',
	'spoon',
	'utensil-spoon',
	'jar-wheat',
	'envelopes-bulk',
	'mail-bulk',
	'file-circle-exclamation',
	'circle-h',
	'hospital-symbol',
	'pager',
	'address-book',
	'contact-book',
	'strikethrough',
	'k',
	'landmark-flag',
	'pencil',
	'pencil-alt',
	'backward',
	'caret-right',
	'comments',
	'paste',
	'file-clipboard',
	'code-pull-request',
	'clipboard-list',
	'truck-ramp-box',
	'truck-loading',
	'user-check',
	'vial-virus',
	'sheet-plastic',
	'blog',
	'user-ninja',
	'person-arrow-up-from-line',
	'scroll-torah',
	'torah',
	'broom-ball',
	'quidditch',
	'quidditch-broom-ball',
	'toggle-off',
	'box-archive',
	'archive',
	'person-drowning',
	'arrow-down-9-1',
	'sort-numeric-desc',
	'sort-numeric-down-alt',
	'face-grin-tongue-squint',
	'grin-tongue-squint',
	'spray-can',
	'truck-monster',
	'w',
	'earth-africa',
	'globe-africa',
	'rainbow',
	'circle-notch',
	'tablet-screen-button',
	'tablet-alt',
	'paw',
	'cloud',
	'trowel-bricks',
	'face-flushed',
	'flushed',
	'hospital-user',
	'tent-arrow-left-right',
	'gavel',
	'legal',
	'binoculars',
	'microphone-slash',
	'box-tissue',
	'motorcycle',
	'bell-concierge',
	'concierge-bell',
	'pen-ruler',
	'pencil-ruler',
	'people-arrows',
	'people-arrows-left-right',
	'mars-and-venus-burst',
	'square-caret-right',
	'caret-square-right',
	'scissors',
	'cut',
	'sun-plant-wilt',
	'toilets-portable',
	'hockey-puck',
	'table',
	'magnifying-glass-arrow-right',
	'tachograph-digital',
	'digital-tachograph',
	'users-slash',
	'clover',
	'reply',
	'mail-reply',
	'star-and-crescent',
	'house-fire',
	'square-minus',
	'minus-square',
	'helicopter',
	'compass',
	'square-caret-down',
	'caret-square-down',
	'file-circle-question',
	'laptop-code',
	'swatchbook',
	'prescription-bottle',
	'bars',
	'navicon',
	'people-group',
	'hourglass-end',
	'hourglass-3',
	'heart-crack',
	'heart-broken',
	'square-up-right',
	'external-link-square-alt',
	'face-kiss-beam',
	'kiss-beam',
	'film',
	'ruler-horizontal',
	'people-robbery',
	'lightbulb',
	'caret-left',
	'circle-exclamation',
	'exclamation-circle',
	'school-circle-xmark',
	'arrow-right-from-bracket',
	'sign-out',
	'circle-chevron-down',
	'chevron-circle-down',
	'unlock-keyhole',
	'unlock-alt',
	'cloud-showers-heavy',
	'headphones-simple',
	'headphones-alt',
	'sitemap',
	'circle-dollar-to-slot',
	'donate',
	'memory',
	'road-spikes',
	'fire-burner',
	'flag',
	'hanukiah',
	'feather',
	'volume-low',
	'volume-down',
	'comment-slash',
	'cloud-sun-rain',
	'compress',
	'wheat-awn',
	'wheat-alt',
	'ankh',
	'hands-holding-child',
	'asterisk',
	'square-check',
	'check-square',
	'peseta-sign',
	'heading',
	'header',
	'ghost',
	'list',
	'list-squares',
	'square-phone-flip',
	'phone-square-alt',
	'cart-plus',
	'gamepad',
	'circle-dot',
	'dot-circle',
	'face-dizzy',
	'dizzy',
	'egg',
	'house-medical-circle-xmark',
	'campground',
	'folder-plus',
	'futbol',
	'futbol-ball',
	'soccer-ball',
	'paintbrush',
	'paint-brush',
	'lock',
	'gas-pump',
	'hot-tub-person',
	'hot-tub',
	'map-location',
	'map-marked',
	'house-flood-water',
	'tree',
	'bridge-lock',
	'sack-dollar',
	'pen-to-square',
	'edit',
	'car-side',
	'share-nodes',
	'share-alt',
	'heart-circle-minus',
	'hourglass-half',
	'hourglass-2',
	'microscope',
	'sink',
	'bag-shopping',
	'shopping-bag',
	'arrow-down-z-a',
	'sort-alpha-desc',
	'sort-alpha-down-alt',
	'mitten',
	'person-rays',
	'users',
	'eye-slash',
	'flask-vial',
	'hand',
	'hand-paper',
	'om',
	'worm',
	'house-circle-xmark',
	'plug',
	'chevron-up',
	'hand-spock',
	'stopwatch',
	'face-kiss',
	'kiss',
	'bridge-circle-xmark',
	'face-grin-tongue',
	'grin-tongue',
	'chess-bishop',
	'face-grin-wink',
	'grin-wink',
	'ear-deaf',
	'deaf',
	'deafness',
	'hard-of-hearing',
	'road-circle-check',
	'dice-five',
	'square-rss',
	'rss-square',
	'land-mine-on',
	'i-cursor',
	'stamp',
	'stairs',
	'i',
	'hryvnia-sign',
	'hryvnia',
	'pills',
	'face-grin-wide',
	'grin-alt',
	'tooth',
	'v',
	'bangladeshi-taka-sign',
	'bicycle',
	'staff-snake',
	'rod-asclepius',
	'rod-snake',
	'staff-aesculapius',
	'head-side-cough-slash',
	'truck-medical',
	'ambulance',
	'wheat-awn-circle-exclamation',
	'snowman',
	'mortar-pestle',
	'road-barrier',
	'school',
	'igloo',
	'joint',
	'angle-right',
	'horse',
	'q',
	'g',
	'notes-medical',
	'temperature-half',
	'temperature-2',
	'thermometer-2',
	'thermometer-half',
	'dong-sign',
	'capsules',
	'poo-storm',
	'poo-bolt',
	'face-frown-open',
	'frown-open',
	'hand-point-up',
	'money-bill',
	'bookmark',
	'align-justify',
	'umbrella-beach',
	'helmet-un',
	'bullseye',
	'bacon',
	'hand-point-down',
	'arrow-up-from-bracket',
	'folder',
	'folder-blank',
	'file-waveform',
	'file-medical-alt',
	'radiation',
	'chart-simple',
	'mars-stroke',
	'vial',
	'gauge',
	'dashboard',
	'gauge-med',
	'tachometer-alt-average',
	'wand-magic-sparkles',
	'magic-wand-sparkles',
	'e',
	'pen-clip',
	'pen-alt',
	'bridge-circle-exclamation',
	'user',
	'school-circle-check',
	'dumpster',
	'van-shuttle',
	'shuttle-van',
	'building-user',
	'square-caret-left',
	'caret-square-left',
	'highlighter',
	'key',
	'bullhorn',
	'globe',
	'synagogue',
	'person-half-dress',
	'road-bridge',
	'location-arrow',
	'c',
	'tablet-button',
	'building-lock',
	'pizza-slice',
	'money-bill-wave',
	'chart-area',
	'area-chart',
	'house-flag',
	'person-circle-minus',
	'ban',
	'cancel',
	'camera-rotate',
	'spray-can-sparkles',
	'air-freshener',
	'star',
	'repeat',
	'cross',
	'box',
	'venus-mars',
	'arrow-pointer',
	'mouse-pointer',
	'maximize',
	'expand-arrows-alt',
	'charging-station',
	'shapes',
	'triangle-circle-square',
	'shuffle',
	'random',
	'person-running',
	'running',
	'mobile-retro',
	'grip-lines-vertical',
	'spider',
	'hands-bound',
	'file-invoice-dollar',
	'plane-circle-exclamation',
	'x-ray',
	'spell-check',
	'slash',
	'computer-mouse',
	'mouse',
	'arrow-right-to-bracket',
	'sign-in',
	'shop-slash',
	'store-alt-slash',
	'server',
	'virus-covid-slash',
	'shop-lock',
	'hourglass-start',
	'hourglass-1',
	'blender-phone',
	'building-wheat',
	'person-breastfeeding',
	'right-to-bracket',
	'sign-in-alt',
	'venus',
	'passport',
	'thumbtack-slash',
	'thumb-tack-slash',
	'heart-pulse',
	'heartbeat',
	'people-carry-box',
	'people-carry',
	'temperature-high',
	'microchip',
	'crown',
	'weight-hanging',
	'xmarks-lines',
	'file-prescription',
	'weight-scale',
	'weight',
	'user-group',
	'user-friends',
	'arrow-up-a-z',
	'sort-alpha-up',
	'chess-knight',
	'face-laugh-squint',
	'laugh-squint',
	'wheelchair',
	'circle-arrow-up',
	'arrow-circle-up',
	'toggle-on',
	'person-walking',
	'walking',
	'l',
	'fire',
	'bed-pulse',
	'procedures',
	'shuttle-space',
	'space-shuttle',
	'face-laugh',
	'laugh',
	'folder-open',
	'heart-circle-plus',
	'code-fork',
	'city',
	'microphone-lines',
	'microphone-alt',
	'pepper-hot',
	'unlock',
	'colon-sign',
	'headset',
	'store-slash',
	'road-circle-xmark',
	'user-minus',
	'mars-stroke-up',
	'mars-stroke-v',
	'champagne-glasses',
	'glass-cheers',
	'clipboard',
	'house-circle-exclamation',
	'file-arrow-up',
	'file-upload',
	'wifi',
	'wifi-3',
	'wifi-strong',
	'bath',
	'bathtub',
	'underline',
	'user-pen',
	'user-edit',
	'signature',
	'stroopwafel',
	'bold',
	'anchor-lock',
	'building-ngo',
	'manat-sign',
	'not-equal',
	'border-top-left',
	'border-style',
	'map-location-dot',
	'map-marked-alt',
	'jedi',
	'square-poll-vertical',
	'poll',
	'mug-hot',
	'car-battery',
	'battery-car',
	'gift',
	'dice-two',
	'chess-queen',
	'glasses',
	'chess-board',
	'building-circle-check',
	'person-chalkboard',
	'mars-stroke-right',
	'mars-stroke-h',
	'hand-back-fist',
	'hand-rock',
	'square-caret-up',
	'caret-square-up',
	'cloud-showers-water',
	'chart-bar',
	'bar-chart',
	'hands-bubbles',
	'hands-wash',
	'less-than-equal',
	'train',
	'eye-low-vision',
	'low-vision',
	'crow',
	'sailboat',
	'window-restore',
	'square-plus',
	'plus-square',
	'torii-gate',
	'frog',
	'bucket',
	'image',
	'microphone',
	'cow',
	'caret-up',
	'screwdriver',
	'folder-closed',
	'house-tsunami',
	'square-nfi',
	'arrow-up-from-ground-water',
	'martini-glass',
	'glass-martini-alt',
	'rotate-left',
	'rotate-back',
	'rotate-backward',
	'undo-alt',
	'table-columns',
	'columns',
	'lemon',
	'head-side-mask',
	'handshake',
	'gem',
	'dolly',
	'dolly-box',
	'smoking',
	'minimize',
	'compress-arrows-alt',
	'monument',
	'snowplow',
	'angles-right',
	'angle-double-right',
	'cannabis',
	'circle-play',
	'play-circle',
	'tablets',
	'ethernet',
	'euro-sign',
	'eur',
	'euro',
	'chair',
	'circle-check',
	'check-circle',
	'circle-stop',
	'stop-circle',
	'compass-drafting',
	'drafting-compass',
	'plate-wheat',
	'icicles',
	'person-shelter',
	'neuter',
	'id-badge',
	'marker',
	'face-laugh-beam',
	'laugh-beam',
	'helicopter-symbol',
	'universal-access',
	'circle-chevron-up',
	'chevron-circle-up',
	'lari-sign',
	'volcano',
	'person-walking-dashed-line-arrow-right',
	'sterling-sign',
	'gbp',
	'pound-sign',
	'viruses',
	'square-person-confined',
	'user-tie',
	'arrow-down-long',
	'long-arrow-down',
	'tent-arrow-down-to-line',
	'certificate',
	'reply-all',
	'mail-reply-all',
	'suitcase',
	'person-skating',
	'skating',
	'filter-circle-dollar',
	'funnel-dollar',
	'camera-retro',
	'circle-arrow-down',
	'arrow-circle-down',
	'file-import',
	'arrow-right-to-file',
	'square-arrow-up-right',
	'external-link-square',
	'box-open',
	'scroll',
	'spa',
	'location-pin-lock',
	'pause',
	'hill-avalanche',
	'temperature-empty',
	'temperature-0',
	'thermometer-0',
	'thermometer-empty',
	'bomb',
	'registered',
	'address-card',
	'contact-card',
	'vcard',
	'scale-unbalanced-flip',
	'balance-scale-right',
	'subscript',
	'diamond-turn-right',
	'directions',
	'burst',
	'house-laptop',
	'laptop-house',
	'face-tired',
	'tired',
	'money-bills',
	'smog',
	'crutch',
	'cloud-arrow-up',
	'cloud-upload',
	'cloud-upload-alt',
	'palette',
	'arrows-turn-right',
	'vest',
	'ferry',
	'arrows-down-to-people',
	'seedling',
	'sprout',
	'left-right',
	'arrows-alt-h',
	'boxes-packing',
	'circle-arrow-left',
	'arrow-circle-left',
	'group-arrows-rotate',
	'bowl-food',
	'candy-cane',
	'arrow-down-wide-short',
	'sort-amount-asc',
	'sort-amount-down',
	'cloud-bolt',
	'thunderstorm',
	'text-slash',
	'remove-format',
	'face-smile-wink',
	'smile-wink',
	'file-word',
	'file-powerpoint',
	'arrows-left-right',
	'arrows-h',
	'house-lock',
	'cloud-arrow-down',
	'cloud-download',
	'cloud-download-alt',
	'children',
	'chalkboard',
	'blackboard',
	'user-large-slash',
	'user-alt-slash',
	'envelope-open',
	'handshake-simple-slash',
	'handshake-alt-slash',
	'mattress-pillow',
	'guarani-sign',
	'arrows-rotate',
	'refresh',
	'sync',
	'fire-extinguisher',
	'cruzeiro-sign',
	'greater-than-equal',
	'shield-halved',
	'shield-alt',
	'book-atlas',
	'atlas',
	'virus',
	'envelope-circle-check',
	'layer-group',
	'arrows-to-dot',
	'archway',
	'heart-circle-check',
	'house-chimney-crack',
	'house-damage',
	'file-zipper',
	'file-archive',
	'square',
	'martini-glass-empty',
	'glass-martini',
	'couch',
	'cedi-sign',
	'italic',
	'table-cells-column-lock',
	'church',
	'comments-dollar',
	'democrat',
	'z',
	'person-skiing',
	'skiing',
	'road-lock',
	'a',
	'temperature-arrow-down',
	'temperature-down',
	'feather-pointed',
	'feather-alt',
	'p',
	'snowflake',
	'newspaper',
	'rectangle-ad',
	'ad',
	'circle-arrow-right',
	'arrow-circle-right',
	'filter-circle-xmark',
	'locust',
	'sort',
	'unsorted',
	'list-ol',
	'list-1-2',
	'list-numeric',
	'person-dress-burst',
	'money-check-dollar',
	'money-check-alt',
	'vector-square',
	'bread-slice',
	'language',
	'face-kiss-wink-heart',
	'kiss-wink-heart',
	'filter',
	'question',
	'file-signature',
	'up-down-left-right',
	'arrows-alt',
	'house-chimney-user',
	'hand-holding-heart',
	'puzzle-piece',
	'money-check',
	'star-half-stroke',
	'star-half-alt',
	'code',
	'whiskey-glass',
	'glass-whiskey',
	'building-circle-exclamation',
	'magnifying-glass-chart',
	'arrow-up-right-from-square',
	'external-link',
	'cubes-stacked',
	'won-sign',
	'krw',
	'won',
	'virus-covid',
	'austral-sign',
	'f',
	'leaf',
	'road',
	'taxi',
	'cab',
	'person-circle-plus',
	'chart-pie',
	'pie-chart',
	'bolt-lightning',
	'sack-xmark',
	'file-excel',
	'file-contract',
	'fish-fins',
	'building-flag',
	'face-grin-beam',
	'grin-beam',
	'object-ungroup',
	'poop',
	'location-pin',
	'map-marker',
	'kaaba',
	'toilet-paper',
	'helmet-safety',
	'hard-hat',
	'hat-hard',
	'eject',
	'circle-right',
	'arrow-alt-circle-right',
	'plane-circle-check',
	'face-rolling-eyes',
	'meh-rolling-eyes',
	'object-group',
	'chart-line',
	'line-chart',
	'mask-ventilator',
	'arrow-right',
	'signs-post',
	'map-signs',
	'cash-register',
	'person-circle-question',
	'h',
	'tarp',
	'screwdriver-wrench',
	'tools',
	'arrows-to-eye',
	'plug-circle-bolt',
	'heart',
	'mars-and-venus',
	'house-user',
	'home-user',
	'dumpster-fire',
	'house-crack',
	'martini-glass-citrus',
	'cocktail',
	'face-surprise',
	'surprise',
	'bottle-water',
	'circle-pause',
	'pause-circle',
	'toilet-paper-slash',
	'apple-whole',
	'apple-alt',
	'kitchen-set',
	'r',
	'temperature-quarter',
	'temperature-1',
	'thermometer-1',
	'thermometer-quarter',
	'cube',
	'bitcoin-sign',
	'shield-dog',
	'solar-panel',
	'lock-open',
	'elevator',
	'money-bill-transfer',
	'money-bill-trend-up',
	'house-flood-water-circle-arrow-right',
	'square-poll-horizontal',
	'poll-h',
	'circle',
	'backward-fast',
	'fast-backward',
	'recycle',
	'user-astronaut',
	'plane-slash',
	'trademark',
	'basketball',
	'basketball-ball',
	'satellite-dish',
	'circle-up',
	'arrow-alt-circle-up',
	'mobile-screen-button',
	'mobile-alt',
	'volume-high',
	'volume-up',
	'users-rays',
	'wallet',
	'clipboard-check',
	'file-audio',
	'burger',
	'hamburger',
	'wrench',
	'bugs',
	'rupee-sign',
	'rupee',
	'file-image',
	'circle-question',
	'question-circle',
	'plane-departure',
	'handshake-slash',
	'book-bookmark',
	'code-branch',
	'hat-cowboy',
	'bridge',
	'phone-flip',
	'phone-alt',
	'truck-front',
	'cat',
	'anchor-circle-exclamation',
	'truck-field',
	'route',
	'clipboard-question',
	'panorama',
	'comment-medical',
	'teeth-open',
	'file-circle-minus',
	'tags',
	'wine-glass',
	'forward-fast',
	'fast-forward',
	'face-meh-blank',
	'meh-blank',
	'square-parking',
	'parking',
	'house-signal',
	'bars-progress',
	'tasks-alt',
	'faucet-drip',
	'cart-flatbed',
	'dolly-flatbed',
	'ban-smoking',
	'smoking-ban',
	'terminal',
	'mobile-button',
	'house-medical-flag',
	'basket-shopping',
	'shopping-basket',
	'tape',
	'bus-simple',
	'bus-alt',
	'eye',
	'face-sad-cry',
	'sad-cry',
	'audio-description',
	'person-military-to-person',
	'file-shield',
	'user-slash',
	'pen',
	'tower-observation',
	'file-code',
	'signal',
	'signal-5',
	'signal-perfect',
	'bus',
	'heart-circle-xmark',
	'house-chimney',
	'home-lg',
	'window-maximize',
	'face-frown',
	'frown',
	'prescription',
	'shop',
	'store-alt',
	'floppy-disk',
	'save',
	'vihara',
	'scale-unbalanced',
	'balance-scale-left',
	'sort-up',
	'sort-asc',
	'comment-dots',
	'commenting',
	'plant-wilt',
	'diamond',
	'face-grin-squint',
	'grin-squint',
	'hand-holding-dollar',
	'hand-holding-usd',
	'bacterium',
	'hand-pointer',
	'drum-steelpan',
	'hand-scissors',
	'hands-praying',
	'praying-hands',
	'arrow-rotate-right',
	'arrow-right-rotate',
	'arrow-rotate-forward',
	'redo',
	'biohazard',
	'location-crosshairs',
	'location',
	'mars-double',
	'child-dress',
	'users-between-lines',
	'lungs-virus',
	'face-grin-tears',
	'grin-tears',
	'phone',
	'calendar-xmark',
	'calendar-times',
	'child-reaching',
	'head-side-virus',
	'user-gear',
	'user-cog',
	'arrow-up-1-9',
	'sort-numeric-up',
	'door-closed',
	'shield-virus',
	'dice-six',
	'mosquito-net',
	'bridge-water',
	'person-booth',
	'text-width',
	'hat-wizard',
	'pen-fancy',
	'person-digging',
	'digging',
	'trash',
	'gauge-simple',
	'gauge-simple-med',
	'tachometer-average',
	'book-medical',
	'poo',
	'quote-right',
	'quote-right-alt',
	'shirt',
	't-shirt',
	'tshirt',
	'cubes',
	'divide',
	'tenge-sign',
	'tenge',
	'headphones',
	'hands-holding',
	'hands-clapping',
	'republican',
	'arrow-left',
	'person-circle-xmark',
	'ruler',
	'align-left',
	'dice-d6',
	'restroom',
	'j',
	'users-viewfinder',
	'file-video',
	'up-right-from-square',
	'external-link-alt',
	'table-cells',
	'th',
	'file-pdf',
	'book-bible',
	'bible',
	'o',
	'suitcase-medical',
	'medkit',
	'user-secret',
	'otter',
	'person-dress',
	'female',
	'comment-dollar',
	'business-time',
	'briefcase-clock',
	'table-cells-large',
	'th-large',
	'book-tanakh',
	'tanakh',
	'phone-volume',
	'volume-control-phone',
	'hat-cowboy-side',
	'clipboard-user',
	'child',
	'lira-sign',
	'satellite',
	'plane-lock',
	'tag',
	'comment',
	'cake-candles',
	'birthday-cake',
	'cake',
	'envelope',
	'angles-up',
	'angle-double-up',
	'paperclip',
	'arrow-right-to-city',
	'ribbon',
	'lungs',
	'arrow-up-9-1',
	'sort-numeric-up-alt',
	'litecoin-sign',
	'border-none',
	'circle-nodes',
	'parachute-box',
	'indent',
	'truck-field-un',
	'hourglass',
	'hourglass-empty',
	'mountain',
	'user-doctor',
	'user-md',
	'circle-info',
	'info-circle',
	'cloud-meatball',
	'camera',
	'camera-alt',
	'square-virus',
	'meteor',
	'car-on',
	'sleigh',
	'arrow-down-1-9',
	'sort-numeric-asc',
	'sort-numeric-down',
	'hand-holding-droplet',
	'hand-holding-water',
	'water',
	'calendar-check',
	'braille',
	'prescription-bottle-medical',
	'prescription-bottle-alt',
	'landmark',
	'truck',
	'crosshairs',
	'person-cane',
	'tent',
	'vest-patches',
	'check-double',
	'arrow-down-a-z',
	'sort-alpha-asc',
	'sort-alpha-down',
	'money-bill-wheat',
	'cookie',
	'arrow-rotate-left',
	'arrow-left-rotate',
	'arrow-rotate-back',
	'arrow-rotate-backward',
	'undo',
	'hard-drive',
	'hdd',
	'face-grin-squint-tears',
	'grin-squint-tears',
	'dumbbell',
	'rectangle-list',
	'list-alt',
	'tarp-droplet',
	'house-medical-circle-check',
	'person-skiing-nordic',
	'skiing-nordic',
	'calendar-plus',
	'plane-arrival',
	'circle-left',
	'arrow-alt-circle-left',
	'train-subway',
	'subway',
	'chart-gantt',
	'indian-rupee-sign',
	'indian-rupee',
	'inr',
	'crop-simple',
	'crop-alt',
	'money-bill-1',
	'money-bill-alt',
	'left-long',
	'long-arrow-alt-left',
	'dna',
	'virus-slash',
	'minus',
	'subtract',
	'chess',
	'arrow-left-long',
	'long-arrow-left',
	'plug-circle-check',
	'street-view',
	'franc-sign',
	'volume-off',
	'hands-asl-interpreting',
	'american-sign-language-interpreting',
	'asl-interpreting',
	'hands-american-sign-language-interpreting',
	'gear',
	'cog',
	'droplet-slash',
	'tint-slash',
	'mosque',
	'mosquito',
	'star-of-david',
	'person-military-rifle',
	'cart-shopping',
	'shopping-cart',
	'vials',
	'plug-circle-plus',
	'place-of-worship',
	'grip-vertical',
	'arrow-turn-up',
	'level-up',
	'u',
	'square-root-variable',
	'square-root-alt',
	'clock',
	'clock-four',
	'backward-step',
	'step-backward',
	'pallet',
	'faucet',
	'baseball-bat-ball',
	's',
	'timeline',
	'keyboard',
	'caret-down',
	'house-chimney-medical',
	'clinic-medical',
	'temperature-three-quarters',
	'temperature-3',
	'thermometer-3',
	'thermometer-three-quarters',
	'mobile-screen',
	'mobile-android-alt',
	'plane-up',
	'piggy-bank',
	'battery-half',
	'battery-3',
	'mountain-city',
	'coins',
	'khanda',
	'sliders',
	'sliders-h',
	'folder-tree',
	'network-wired',
	'map-pin',
	'hamsa',
	'cent-sign',
	'flask',
	'person-pregnant',
	'wand-sparkles',
	'ellipsis-vertical',
	'ellipsis-v',
	'ticket',
	'power-off',
	'right-long',
	'long-arrow-alt-right',
	'flag-usa',
	'laptop-file',
	'tty',
	'teletype',
	'diagram-next',
	'person-rifle',
	'house-medical-circle-exclamation',
	'closed-captioning',
	'person-hiking',
	'hiking',
	'venus-double',
	'images',
	'calculator',
	'people-pulling',
	'n',
	'cable-car',
	'tram',
	'cloud-rain',
	'building-circle-xmark',
	'ship',
	'arrows-down-to-line',
	'download',
	'face-grin',
	'grin',
	'delete-left',
	'backspace',
	'eye-dropper',
	'eye-dropper-empty',
	'eyedropper',
	'file-circle-check',
	'forward',
	'mobile',
	'mobile-android',
	'mobile-phone',
	'face-meh',
	'meh',
	'align-center',
	'book-skull',
	'book-dead',
	'id-card',
	'drivers-license',
	'outdent',
	'dedent',
	'heart-circle-exclamation',
	'house',
	'home',
	'home-alt',
	'home-lg-alt',
	'calendar-week',
	'laptop-medical',
	'b',
	'file-medical',
	'dice-one',
	'kiwi-bird',
	'arrow-right-arrow-left',
	'exchange',
	'rotate-right',
	'redo-alt',
	'rotate-forward',
	'utensils',
	'cutlery',
	'arrow-up-wide-short',
	'sort-amount-up',
	'mill-sign',
	'bowl-rice',
	'skull',
	'tower-broadcast',
	'broadcast-tower',
	'truck-pickup',
	'up-long',
	'long-arrow-alt-up',
	'stop',
	'code-merge',
	'upload',
	'hurricane',
	'mound',
	'toilet-portable',
	'compact-disc',
	'file-arrow-down',
	'file-download',
	'caravan',
	'shield-cat',
	'bolt',
	'zap',
	'glass-water',
	'oil-well',
	'vault',
	'mars',
	'toilet',
	'plane-circle-xmark',
	'yen-sign',
	'cny',
	'jpy',
	'rmb',
	'yen',
	'ruble-sign',
	'rouble',
	'rub',
	'ruble',
	'sun',
	'guitar',
	'face-laugh-wink',
	'laugh-wink',
	'horse-head',
	'bore-hole',
	'industry',
	'circle-down',
	'arrow-alt-circle-down',
	'arrows-turn-to-dots',
	'florin-sign',
	'arrow-down-short-wide',
	'sort-amount-desc',
	'sort-amount-down-alt',
	'less-than',
	'angle-down',
	'car-tunnel',
	'head-side-cough',
	'grip-lines',
	'thumbs-down',
	'user-lock',
	'arrow-right-long',
	'long-arrow-right',
	'anchor-circle-xmark',
	'ellipsis',
	'ellipsis-h',
	'chess-pawn',
	'kit-medical',
	'first-aid',
	'person-through-window',
	'toolbox',
	'hands-holding-circle',
	'bug',
	'credit-card',
	'credit-card-alt',
	'car',
	'automobile',
	'hand-holding-hand',
	'book-open-reader',
	'book-reader',
	'mountain-sun',
	'arrows-left-right-to-line',
	'dice-d20',
	'truck-droplet',
	'file-circle-xmark',
	'temperature-arrow-up',
	'temperature-up',
	'medal',
	'bed',
	'square-h',
	'h-square',
	'podcast',
	'temperature-full',
	'temperature-4',
	'thermometer-4',
	'thermometer-full',
	'bell',
	'superscript',
	'plug-circle-xmark',
	'star-of-life',
	'phone-slash',
	'paint-roller',
	'handshake-angle',
	'hands-helping',
	'location-dot',
	'map-marker-alt',
	'file',
	'greater-than',
	'person-swimming',
	'swimmer',
	'arrow-down',
	'droplet',
	'tint',
	'eraser',
	'earth-americas',
	'earth',
	'earth-america',
	'globe-americas',
	'person-burst',
	'dove',
	'battery-empty',
	'battery-0',
	'socks',
	'inbox',
	'section',
	'gauge-high',
	'tachometer-alt',
	'tachometer-alt-fast',
	'envelope-open-text',
	'hospital',
	'hospital-alt',
	'hospital-wide',
	'wine-bottle',
	'chess-rook',
	'bars-staggered',
	'reorder',
	'stream',
	'dharmachakra',
	'hotdog',
	'person-walking-with-cane',
	'blind',
	'drum',
	'ice-cream',
	'heart-circle-bolt',
	'fax',
	'paragraph',
	'check-to-slot',
	'vote-yea',
	'star-half',
	'boxes-stacked',
	'boxes',
	'boxes-alt',
	'link',
	'chain',
	'ear-listen',
	'assistive-listening-systems',
	'tree-city',
	'play',
	'font',
	'table-cells-row-lock',
	'rupiah-sign',
	'magnifying-glass',
	'search',
	'table-tennis-paddle-ball',
	'ping-pong-paddle-ball',
	'table-tennis',
	'person-dots-from-line',
	'diagnoses',
	'trash-can-arrow-up',
	'trash-restore-alt',
	'naira-sign',
	'cart-arrow-down',
	'walkie-talkie',
	'file-pen',
	'file-edit',
	'receipt',
	'square-pen',
	'pen-square',
	'pencil-square',
	'suitcase-rolling',
	'person-circle-exclamation',
	'chevron-down',
	'battery-full',
	'battery',
	'battery-5',
	'skull-crossbones',
	'code-compare',
	'list-ul',
	'list-dots',
	'school-lock',
	'tower-cell',
	'down-long',
	'long-arrow-alt-down',
	'ranking-star',
	'chess-king',
	'person-harassing',
	'brazilian-real-sign',
	'landmark-dome',
	'landmark-alt',
	'arrow-up',
	'tv',
	'television',
	'tv-alt',
	'shrimp',
	'list-check',
	'tasks',
	'jug-detergent',
	'circle-user',
	'user-circle',
	'user-shield',
	'wind',
	'car-burst',
	'car-crash',
	'y',
	'person-snowboarding',
	'snowboarding',
	'truck-fast',
	'shipping-fast',
	'fish',
	'user-graduate',
	'circle-half-stroke',
	'adjust',
	'clapperboard',
	'circle-radiation',
	'radiation-alt',
	'baseball',
	'baseball-ball',
	'jet-fighter-up',
	'diagram-project',
	'project-diagram',
	'copy',
	'volume-xmark',
	'volume-mute',
	'volume-times',
	'hand-sparkles',
	'grip',
	'grip-horizontal',
	'share-from-square',
	'share-square',
	'child-combatant',
	'child-rifle',
	'gun',
	'square-phone',
	'phone-square',
	'plus',
	'add',
	'expand',
	'computer',
	'xmark',
	'close',
	'multiply',
	'remove',
	'times',
	'arrows-up-down-left-right',
	'arrows',
	'chalkboard-user',
	'chalkboard-teacher',
	'peso-sign',
	'building-shield',
	'baby',
	'users-line',
	'quote-left',
	'quote-left-alt',
	'tractor',
	'trash-arrow-up',
	'trash-restore',
	'arrow-down-up-lock',
	'lines-leaning',
	'ruler-combined',
	'copyright',
	'equals',
	'blender',
	'teeth',
	'shekel-sign',
	'ils',
	'shekel',
	'sheqel',
	'sheqel-sign',
	'map',
	'rocket',
	'photo-film',
	'photo-video',
	'folder-minus',
	'store',
	'arrow-trend-up',
	'plug-circle-minus',
	'sign-hanging',
	'sign',
	'bezier-curve',
	'bell-slash',
	'tablet',
	'tablet-android',
	'school-flag',
	'fill',
	'angle-up',
	'drumstick-bite',
	'holly-berry',
	'chevron-left',
	'bacteria',
	'hand-lizard',
	'notdef',
	'disease',
	'briefcase-medical',
	'genderless',
	'chevron-right',
	'retweet',
	'car-rear',
	'car-alt',
	'pump-soap',
	'video-slash',
	'battery-quarter',
	'battery-2',
	'radio',
	'baby-carriage',
	'carriage-baby',
	'traffic-light',
	'thermometer',
	'vr-cardboard',
	'hand-middle-finger',
	'percent',
	'percentage',
	'truck-moving',
	'glass-water-droplet',
	'display',
	'face-smile',
	'smile',
	'thumbtack',
	'thumb-tack',
	'trophy',
	'person-praying',
	'pray',
	'hammer',
	'hand-peace',
	'rotate',
	'sync-alt',
	'spinner',
	'robot',
	'peace',
	'gears',
	'cogs',
	'warehouse',
	'arrow-up-right-dots',
	'splotch',
	'face-grin-hearts',
	'grin-hearts',
	'dice-four',
	'sim-card',
	'transgender',
	'transgender-alt',
	'mercury',
	'arrow-turn-down',
	'level-down',
	'person-falling-burst',
	'award',
	'ticket-simple',
	'ticket-alt',
	'building',
	'angles-left',
	'angle-double-left',
	'qrcode',
	'clock-rotate-left',
	'history',
	'face-grin-beam-sweat',
	'grin-beam-sweat',
	'file-export',
	'arrow-right-from-file',
	'shield',
	'shield-blank',
	'arrow-up-short-wide',
	'sort-amount-up-alt',
	'house-medical',
	'golf-ball-tee',
	'golf-ball',
	'circle-chevron-left',
	'chevron-circle-left',
	'house-chimney-window',
	'pen-nib',
	'tent-arrow-turn-left',
	'tents',
	'wand-magic',
	'magic',
	'dog',
	'carrot',
	'moon',
	'wine-glass-empty',
	'wine-glass-alt',
	'cheese',
	'yin-yang',
	'music',
	'code-commit',
	'temperature-low',
	'person-biking',
	'biking',
	'broom',
	'shield-heart',
	'gopuram',
	'earth-oceania',
	'globe-oceania',
	'square-xmark',
	'times-square',
	'xmark-square',
	'hashtag',
	'up-right-and-down-left-from-center',
	'expand-alt',
	'oil-can',
	't',
	'hippo',
	'chart-column',
	'infinity',
	'vial-circle-check',
	'person-arrow-down-to-line',
	'voicemail',
	'fan',
	'person-walking-luggage',
	'up-down',
	'arrows-alt-v',
	'cloud-moon-rain',
	'calendar',
	'trailer',
	'bahai',
	'haykal',
	'sd-card',
	'dragon',
	'shoe-prints',
	'circle-plus',
	'plus-circle',
	'face-grin-tongue-wink',
	'grin-tongue-wink',
	'hand-holding',
	'plug-circle-exclamation',
	'link-slash',
	'chain-broken',
	'chain-slash',
	'unlink',
	'clone',
	'person-walking-arrow-loop-left',
	'arrow-up-z-a',
	'sort-alpha-up-alt',
	'fire-flame-curved',
	'fire-alt',
	'tornado',
	'file-circle-plus',
	'book-quran',
	'quran',
	'anchor',
	'border-all',
	'face-angry',
	'angry',
	'cookie-bite',
	'arrow-trend-down',
	'rss',
	'feed',
	'draw-polygon',
	'scale-balanced',
	'balance-scale',
	'gauge-simple-high',
	'tachometer',
	'tachometer-fast',
	'shower',
	'desktop',
	'desktop-alt',
	'm',
	'table-list',
	'th-list',
	'comment-sms',
	'sms',
	'book',
	'user-plus',
	'check',
	'battery-three-quarters',
	'battery-4',
	'house-circle-check',
	'angle-left',
	'diagram-successor',
	'truck-arrow-right',
	'arrows-split-up-and-left',
	'hand-fist',
	'fist-raised',
	'cloud-moon',
	'briefcase',
	'person-falling',
	'image-portrait',
	'portrait',
	'user-tag',
	'rug',
	'earth-europe',
	'globe-europe',
	'cart-flatbed-suitcase',
	'luggage-cart',
	'rectangle-xmark',
	'rectangle-times',
	'times-rectangle',
	'window-close',
	'baht-sign',
	'book-open',
	'book-journal-whills',
	'journal-whills',
	'handcuffs',
	'triangle-exclamation',
	'exclamation-triangle',
	'warning',
	'database',
	'share',
	'mail-forward',
	'bottle-droplet',
	'mask-face',
	'hill-rockslide',
	'right-left',
	'exchange-alt',
	'paper-plane',
	'road-circle-exclamation',
	'dungeon',
	'align-right',
	'money-bill-1-wave',
	'money-bill-wave-alt',
	'life-ring',
	'hands',
	'sign-language',
	'signing',
	'calendar-day',
	'water-ladder',
	'ladder-water',
	'swimming-pool',
	'arrows-up-down',
	'arrows-v',
	'face-grimace',
	'grimace',
	'wheelchair-move',
	'wheelchair-alt',
	'turn-down',
	'level-down-alt',
	'person-walking-arrow-right',
	'square-envelope',
	'envelope-square',
	'dice',
	'bowling-ball',
	'brain',
	'bandage',
	'band-aid',
	'calendar-minus',
	'circle-xmark',
	'times-circle',
	'xmark-circle',
	'gifts',
	'hotel',
	'earth-asia',
	'globe-asia',
	'id-card-clip',
	'id-card-alt',
	'magnifying-glass-plus',
	'search-plus',
	'thumbs-up',
	'user-clock',
	'hand-dots',
	'allergies',
	'file-invoice',
	'window-minimize',
	'mug-saucer',
	'coffee',
	'brush',
	'mask',
	'magnifying-glass-minus',
	'search-minus',
	'ruler-vertical',
	'user-large',
	'user-alt',
	'train-tram',
	'user-nurse',
	'syringe',
	'cloud-sun',
	'stopwatch-20',
	'square-full',
	'magnet',
	'jar',
	'note-sticky',
	'sticky-note',
	'bug-slash',
	'arrow-up-from-water-pump',
	'bone',
	'table-cells-row-unlock',
	'user-injured',
	'face-sad-tear',
	'sad-tear',
	'plane',
	'tent-arrows-down',
	'exclamation',
	'arrows-spin',
	'print',
	'turkish-lira-sign',
	'try',
	'turkish-lira',
	'dollar-sign',
	'dollar',
	'usd',
	'x',
	'magnifying-glass-dollar',
	'search-dollar',
	'users-gear',
	'users-cog',
	'person-military-pointing',
	'building-columns',
	'bank',
	'institution',
	'museum',
	'university',
	'umbrella',
	'trowel',
	'd',
	'stapler',
	'masks-theater',
	'theater-masks',
	'kip-sign',
	'hand-point-left',
	'handshake-simple',
	'handshake-alt',
	'jet-fighter',
	'fighter-jet',
	'square-share-nodes',
	'share-alt-square',
	'barcode',
	'plus-minus',
	'video',
	'video-camera',
	'graduation-cap',
	'mortar-board',
	'hand-holding-medical',
	'person-circle-check',
	'turn-up',
	'level-up-alt',
]