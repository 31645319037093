/**
 * 新增页面
 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
// 取消路由拦截， 影响用户体验
//import { Prompt } from 'react-router-dom'
import Menu from 'antd/lib/menu'
import Dropdown from 'antd/lib/dropdown'
import Affix from 'antd/lib/affix'
import ModalEx from '@com/ModalEx'
import Fields from './Fields'
import * as Action from './Action'
import { deleteFilesByBusinessId } from '../file/Action'
import { callApiBatch, getAddApiItem, getApiItem } from '../layout/Action'
import * as dataUtil from '@utils/DataUtil'
import * as utils from '@utils/index'
import {inner, modal, nestedsublist} from '@utils/page'
import {forms} from '@utils/object'
import {createButton} from '@com/PageCreator'
import c from '@utils/constants'
import { beforeAdd } from './specialModule'

const { apiBizflow, processStart } = c

export default class New extends Component {
    /**
     * 使用全局变量保存页面状态
     */
    constructor(props) {
        super()
        this.state = {
            showModal: false,
            buttonInfoList: [],
            fRecord: {},
        }
        this.id = props.id || global.uuid()
        // 路由上的fid
        this.fidOnRoute = utils.parseSearchStr().fid
        // 父模块id
        this.fid = props.fid || this.fidOnRoute || global.nofid
    }

    componentWillUnmount() {
        this.unmount = true
        const {mode, config} = this.props
        if (!this.saved && mode == null) {
            this.delSubList()
            config.hasattachment  && deleteFilesByBusinessId(this.id)
        }
    }
    async componentDidMount() {
        this.unmount = false
        this.init(this.props)
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        const {config, moduleName, fid, showModal} = this.props
        if (
            // config 变化
            (nextProps.config && nextProps.config.modulename &&
                nextProps.config.modulename !== config.modulename) ||
            // moduleName 变化
            (nextProps.moduleName && nextProps.moduleName !== moduleName) ||
            // fid 变化
            (nextProps.fid && nextProps.fid !== fid) ||
            // showModal 变化
            showModal !== nextProps.showModal
        ) {
            this.init(nextProps)
        }
    }
    async init(props) {
        const {moduleName, config, fid, showModal} = props
        if (utils.isBlank(moduleName) || 
            config == null || utils.isBlank(config.modulename)
        ) {
            return
        }
        // 父模块id
        this.fid = fid || this.fidOnRoute || global.nofid
        this.pageKey = moduleName + '_' + this.id + '_new_' + this.fid
        //console.log('init pageKey', this.pageKey)
        this.moduleName = moduleName
        // 分类模块名称
        this.classModuleName = config.catagory
        // 父模块名称
        this.fModuleName = config.fmodule
        // 模型有分类则获取分类数据
        this.hasClass = utils.isNotBlank(this.classModuleName)
        if (this.hasClass) {
            // 分类模块配置信息
            this.classModuleConfig = utils.getConfigByModuleName(this.classModuleName)
        }
        // 业务编排按钮获取
        const buttonInfoList = await dataUtil.getOrchestrateButton(this.moduleName) || []
        utils.storeData(this, this.pageKey, {buttonInfoList})
        // 查询父模块数据
        await this.getFRecord(this.fid)
        utils.storeData(this, this.pageKey, {showModal: showModal})
    }

    async getFRecord(fid) {
        if (this.props.fRecord) {
            utils.storeData(this, this.pageKey, {fRecord: this.props.fRecord})
            return
        }
        // 查询父模块数据
        if (global.hasfid(fid)) {
            global.notip = true
            const fRecord = await Action.getOnePromise(this.fModuleName, fid)
            global.notip = false
            if (utils.equals(fRecord, {})) {
                fRecord.id = this.fid
            }
            utils.storeData(this, this.pageKey, {fRecord})
        }
    }

    getRecord() {
        if (dataUtil.formError(this.fields)) {
            return null
        }
        const {config, data} = this.props    
        let record = this.fields.getFieldsValue()
        // 转换表单数据
        dataUtil.convertData(config, record)
        // 如果关联父模块，保存父模块id
        if (this.fid !== global.nofid) {
            record.fid = this.fid
            record.fcode = this.state.fRecord.code
        }
        // 新增页面前端生成id
        record.id = this.id
        record = Object.assign({}, data, record)
        return record
    }

    finish() {
        const {mode, close} = this.props
        this.saved = true
        if (mode === modal) {
            utils.storeData(this, this.pageKey, {showModal: false})
            // 回调关闭弹窗方法，通知父组件修改窗口显示状态
            close && close()
            return
        }
        // 默认路由模式关闭页面
        if (mode == null) {
            global.closepage()
        }
    }

    /**
     * 确定
     */
    async onOk(apiArray, onCustom) {
        let record = this.getRecord()
        if (record === null) {
            return false
        }
        
        // 数据校验
        if (!dataUtil.dataCheck(this.props.config, record)) {
            return false
        }

        const {mode, isSub, onDataChange, refreshPage} = this.props
        // 新增保存前注入是否能新增保存逻辑
        if (await beforeAdd(this.moduleName, apiArray, record) === false) {
            return false
        }

        // 保存子模块
        if (!await dataUtil.saveSublist(apiArray, this.fields)) {
            return false
        }

        // 手机端子模块明细或嵌入子模块修改由父组件整体保存
        if ((mode === inner && isSub) || mode === nestedsublist) {
            onDataChange && onDataChange(record)
            return true
        }

        const success = (newData) => {
            if (mode === inner || mode === nestedsublist) {
                onDataChange && onDataChange(newData)
            } else if (mode === modal) {
                refreshPage && refreshPage(null, 1)
            }
            if (onCustom !== true) {
                this.finish()
            }
        }
        if (apiArray instanceof Array) {
            apiArray.push(getAddApiItem(this.moduleName, record, success))
        }
        return true
    }

    /**
     * 提交
     * @returns 
     */
    async commit(apiArray) {
        // 保存子模块
        if (!await dataUtil.saveSublist(apiArray, this.fields)) {
            return false
        }
        let record = this.getRecord()
        if (record === null) {
            return false
        }
        const {mode, onDataChange} = this.props
        const success = (newData) => {
            if (mode === inner || mode === nestedsublist) {
                onDataChange && onDataChange(newData)
            } else {
                this.finish()
            }
        }
        const data = {
            id: this.id,           // 业务记录id
            code: record.code,     // 业务记录编码
            name: record.name,     // 业务记录编码
            moduleName: this.moduleName,   // 业务模块名称
        }
        record.flowstatus = "reviewing"
        if (apiArray instanceof Array) {
            apiArray.push(getApiItem(processStart, this.moduleName, [data]))
            apiArray.push(getAddApiItem(this.moduleName, record, success))
        }
        return true
    }

    /**
     * 弹窗取消
     */
    onCancel() {
        this.delSubList()
        const {config, close} = this.props
        config.hasattachment  && deleteFilesByBusinessId(this.id)
        utils.storeData(this, this.pageKey, {showModal: false})
        close && close()
    }

    /**
     * 删除子模块所有数据
     */
    async delSubList() {
        await dataUtil.delSubList(null, [this.id], this.props.config)
    }

    async onCustomClick(apiArray, buttonInfo) {
        if (!buttonInfo.read) {
            let saveRet = await this.onOk(apiArray, true)
            if (!saveRet) {
                return false
            }
        }
        let success = (res) => {
            dataUtil.pageBizFlowSuccess(buttonInfo, res, this.fields)
            this.finish()
        }
        if (buttonInfo.read) {
            success = (res) => {
                dataUtil.pageBizFlowSuccess(buttonInfo, res, this.fields)
            }
        }
        let record = this.getRecord()
        if (apiArray instanceof Array) {
            // 业务编排记录绑定子模块
            dataUtil.bizflowRecordBindSublist(buttonInfo, record, this.fields)
            apiArray.push(getApiItem(apiBizflow, this.moduleName, [buttonInfo.id, record], 
                success)
            )
        }
        return true
    }

    async onSave() {
        const getApiArray = async apiArray => await this.onOk(apiArray, false)
        await callApiBatch(getApiArray, '保存成功')
    }
    
    /**
     * 重新渲染页面
     */
    reRender() {
        this.setState({update: !this.state.update})
    }
    
    /**
     * 渲染函数
     */
    render() {
        const {showModal, fRecord} = this.state
        const {moduleName, config, mode, isSub, onDataDelete} = this.props
        // 渲染阻断
        if (utils.isBlank(moduleName) || 
            config == null || utils.isBlank(config.modulename)
        ) {
            return mode === modal ? <span/> : <div/>
        }
        // 权限控制
        let moduleOpers = (global.role && global.role.moduleOpers) || []
        // 管理员权限
        const adminOper = global.isAdmin || moduleOpers.indexOf(`${this.moduleName}_oau`) !== -1
        const data = this.props.data || {}
        data.fid = this.fid
        let showCommit = config.hasflow 
        let tableConfig = config.fields_config || []
        // 手机端子模块明细不显示流程和业务编排按钮
        let innerSub = (mode === inner && isSub) || mode === nestedsublist
        // 组装数据父列默认传递的字段段映射
        const fModuleTransferMap = {}
        tableConfig.forEach(config => {
            if (dataUtil.isFFieldType(config.dataType)) {
                const otherParams = config.otherParams || {}
                const fmoduleFieldName = otherParams.name
                if (utils.isNotBlank(fmoduleFieldName)) {
                    fModuleTransferMap[config.name] = fmoduleFieldName
                }
            } 
            const initialValue = dataUtil.getInitialValue(config, data)
            if (initialValue != null) {
                data[config.name] = initialValue
            }
        })
        // 初始化父列默认类型数据
        const fModuleTransferData = dataUtil.getTransferData(this.moduleName, this.fModuleName, fRecord, fModuleTransferMap)
        Object.assign(data, fModuleTransferData)
        // 业务编排
        let buttonList = this.state.buttonInfoList.filter((button, index) => {
            // 禁用条件
            let disable = dataUtil.condsValue(button.disableEditConds, data)
            // 权限控制 
            const operName = `${this.moduleName}_${button.name}`
            const operAuth = adminOper || moduleOpers.indexOf(operName) !== -1
            return !disable && operAuth
        }).map((button, index) => createButton(button.icon, button.name, 
            async () => {
                const getApiArray = async (apiArray) => 
                    await this.onCustomClick(apiArray, button)
                await callApiBatch(getApiArray, `${button.name}成功`)
            }, {key: index, showName: true, type: 'primary', ghost: true})
        )
        // 新增页面无需禁用编辑
        const saveBtn = createButton('save', '保存', this.onSave.bind(this), {type: 'primary'})
        //console.log(this.pageKey, data, config)
        let fieldsDiv = <Fields 
            {...this.props}
            id={this.id}
            data={data}
            fRecord={fRecord}
            ref={(inst) => {
                this.fields = inst
                forms[this.moduleName] = inst
                forms[this.id] = inst
            }} 
            action='new'
        />
        // 弹窗标题区域
        let title = `新增${global.showModuleName(config)}`
        
        if (mode === modal) {
            return <nobr>
                {createButton('plus', '新增', 
                    () => { 
                        this.id = global.uuid()
                        utils.storeData(this, this.pageKey, {showModal: true})
                    }, {type: 'primary'})
                }
                {showModal && 
                // 需要等待父模块数据查询完成
                (!global.hasfid(this.fid) || (global.hasfid(this.fid) && fRecord.id)) && 
                <ModalEx
                    title={title}
                    visible={showModal}
                    onOk={this.onSave.bind(this)}
                    onCancel={this.onCancel.bind(this)}
                    onClose={this.onCancel.bind(this)}
                    maskClosable={true}
                    width={global.modalWidth}
                >
                    {showModal && fieldsDiv}
                </ModalEx>}
            </nobr>
        }
        return <div>
            {mode !== nestedsublist && 
            <Affix offsetTop={0} style={{height: 40}} target={() => global.layoutContainerInst}>
                <div style={{paddingTop: 8, backgroundColor: '#fff'}}>
                    {saveBtn}
                    {!innerSub && showCommit && createButton('arrow-up', '提交', 
                        async () => {
                            const getApiArray = async (apiArray) => await this.commit(apiArray)
                            await callApiBatch(getApiArray, '提交成功')
                        }, {type: 'primary', needConfirm: true, title: '确定要提交吗？'})
                    }{
                        createButton('close', '取消', () => {
                            if(mode === inner || mode === nestedsublist) {
                                onDataDelete && onDataDelete()
                            } else {
                                global.closepage()
                            }
                        })
                    }
                    {!innerSub && buttonList.length > 0 && 
                    (mode !== inner ? 
                        buttonList 
                        : 
                        <Dropdown trigger={global.trigger} 
                            overlay={<Menu>{buttonList.map((button, index) => 
                                <Menu.Item key={index} >{button}</Menu.Item>
                            )}</Menu>}
                        >
                            {createButton('ellipsis-h', '更多')}
                        </Dropdown>
                    )}
                </div>
            </Affix>}
            {// 需要等待父模块数据查询完成
            (!global.hasfid(this.fid) || (global.hasfid(this.fid) && fRecord.id)) && 
            fieldsDiv}
        </div>
    }
}
New.propTypes = {
    // 页面唯一标识
    id: PropTypes.string,
    // 父组传入数据
    data: PropTypes.object,
    // 父组件id
    fid: PropTypes.string,
    // 模块名称
    moduleName: PropTypes.string,
    // 模块配置
    config: PropTypes.object,
    /**
     * 页面展示模式
     * normal: 默认模式，路由
     * inner: 表格行内嵌入新增、详情
     * modal: 弹窗
     * nestedsublist： 嵌入子模块，不显示表单基本信息
     */
    mode: PropTypes.string,
    // 弹出模式显示对话框
    showModal: PropTypes.bool,
    // 回调关闭弹窗方法，通知父组件修改窗口显示状态
    close: PropTypes.func,
    // 父组件为子模块
    isSub: PropTypes.bool,
    // 嵌入页面保存回调函数，由父组件整体保存
    onDataChange: PropTypes.func,
}