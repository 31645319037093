/**
 * 模块字段多选组件复选框形式
 */
import React, { Component } from "react"
import SetTable from "./SetTable"
import * as dataUtil from '@utils/DataUtil'

export default class FieldSelectCheckBox extends Component {
    constructor() {
        super()
        this.state = {
            allKeyLabels: [],
            typeMap: {}
        }
    }

    componentDidMount() {
        this.getFields(this.props.moduleName)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.moduleName !== nextProps.moduleName) {
            this.getFields(nextProps.moduleName)
        }
    }

    getFields(moduleName) {
        const {allKeyLabels, typeMap} = dataUtil.getFieldOptions(moduleName)
        this.setState({allKeyLabels, typeMap})
    }

    onChange(showKeys) {
        let dataTypes = showKeys.map(key => this.state.typeMap[key])
        this.props.onChange && this.props.onChange(showKeys, dataTypes)
    }

    render() {
        const {allKeyLabels} = this.state
        let style = Object.assign({}, global.aStyle, this.props.style)
        return <SetTable
            disabled={this.props.disabled}
            showKeys={this.props.value || []}
            allKeyLabels={allKeyLabels}
            onChange={this.onChange.bind(this)}
            button={<span style={style}>{this.props.buttonName || '显示'}</span>}
        />
    }
}