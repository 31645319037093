/**
 * 列表模型接口
 */

/**
 * 查询
 * @param {*} query.searchFields 
 * @param {*} query.mainFields
 * @param {*} query.moreFields 
 */
export function getList(id, page, pageSize, query, sorter, cb) {
    let data = { page, pageSize, query, sorter }
    global.getApi().put(`/report/query`, {
        params: { id },
        data 
    }).then(payload => {
        cb && cb(payload.body || {})
    }, () => {
        cb && cb({})
    })
}
