import React, { Component } from 'react'
import OORoute from './OORoute'

export default class OOPages extends Component {

    render() {
        const {ooRouteProps, homeRouteProps} = this.props
        const {activeTabKey, tabs} = global
        const ooRouteKeys = Object.keys(ooRouteProps)
		const ooPages = []
        if (ooRouteKeys.length > 0) {
            for (const tab of tabs) {
                const display = activeTabKey === tab.key ? 'block': 'none'
                //console.log(activeTabKey, tab.key)
                if (tab == null || !typeof tab.key === 'string' ) {
                    console.error('tab.key 为空')
                    continue
                }
                if (tab.key === '/') {
                    ooPages.push(<OORoute {...homeRouteProps} key={tab.key} display={display}/>)
                    continue
                }
                let path = tab.key
                const searchParamIndex = tab.key.indexOf('?')
                if (searchParamIndex !== -1) {
                    path = tab.key.substring(0, searchParamIndex)
                }
                const props = ooRouteProps[path]
                if (props) {
                    ooPages.push(<OORoute {...props} key={tab.key} path={tab.key} display={display}/>)
                    continue
                }
                console.error(tab.key, path, '在系统路由中未找到')
            }
        } 

        return <div id='layout-container' ref={(inst) => global.layoutContainerInst = inst} className="layout-container-hashead">
            {ooPages}
        </div>
    }
}