import React, { Component } from 'react'
import Spin from 'antd/lib/spin'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Button from 'antd/lib/button'
import Checkbox from 'antd/lib/checkbox'
// import Dropdown from 'antd/lib/dropdown'
// import List from 'antd/lib/list'
// import Card from 'antd/lib/card'
import Icon from '@com/Icon'
import {setCookie} from '@utils/cookie'
import {login} from './Action'
import {sha1} from '@utils/crypto'
import './login.css'
import * as dataUtil from '@utils/DataUtil'
import * as utils from '@utils/index'
import { photoalbum } from '@utils/constants'
import A from '@com/A'

const firstPage = {
	key: '/',
	title: '首页',
	closable: false
}

class Login extends Component {
    constructor() {
        super()
        this.state = {
            loading: false
        }
    }
    componentDidMount() {
        this.unmount = false
        window && window.addEventListener('resize', this.onWindowResize.bind(this))
    }
    /**
	 * 将要离开组件时
	 */
	componentWillUnmount() {
        this.unmount = true
		window && window.removeEventListener('resize', this.onWindowResize.bind(this))
	}
    /**
     * 回车键响应函数
     * @param {*} e 
     */
    onKeyDown(e) {
        if (e.keyCode === 13) {
            this.handleSubmit(e)
        }
    }
    /**
	 * 窗口大小变化回调函数
	 */
	onWindowResize() {
		global.windowSize()
        if (this.unmount) {
			return
		}
        this.setState({update: !this.state.update})
	}
    handleSubmit(e) {
        e && e.preventDefault()
        if (dataUtil.formError(this.props.form)) return
        let data = this.props.form.getFieldsValue()
        const {username, password} = data
        this.setState({loading: true})
        login({
            username: username.trim(),
            password: sha1(password)
        }, async (data) => {
            const {user, ent, token} = data
            const userid = (user && user.id) || 'oo'
            let entid = (ent && ent.id) || 'platform'
            setCookie('userid', userid)
            setCookie('entid', entid)
            setCookie('username', user.username)
            setCookie('token', token)
            setCookie('password', sha1(password))
            global.tabsKey = `tabs${userid}`
            global.tabKey = `tab${userid}`
            global.activeTabKey = global.activeTabKey || localStorage.getItem(global.tabKey) || '/'
		    global.tabs = global.parseErrorNull(localStorage.getItem(global.tabsKey)) || [firstPage]
            localStorage.setItem('user', JSON.stringify(user))
            localStorage.setItem('ent', JSON.stringify(ent))
            utils.getApi().opts.baseURI = utils.getApi().opts.getBaseUri()
            global.entName = ent && ent.name
            global.systemtype = ent && ent.systemtype
            let homeRoute = global.activeTabKey
            // 相册系统
            if (photoalbum === global.systemtype) {
                homeRoute = '/' + photoalbum
            }
            console.log('homeRoute', homeRoute)
            this.setState({loading: false})
            this.props.history.replace(homeRoute)
        }, () => this.setState({loading: false}))
    }

    tryout(name) {
        if (name === '老板') {
            this.props.form.setFieldsValue({
                username: '张无忌',
                password: 'a88888888'
            })
            this.handleSubmit()
        } else if (name === '销售') {
            this.props.form.setFieldsValue({
                username: '令狐冲',
                password: 'a88888888'
            })
            this.handleSubmit()
        } else if (name === '仓管') {
            this.props.form.setFieldsValue({
                username: '扫地僧',
                password: 'a88888888'
            })
            this.handleSubmit()
        } else if (name === '相册') {
            this.props.form.setFieldsValue({
                username: 'gqh',
                password: 'a'
            })
            this.handleSubmit()
        }
    }

    render() {
        const { getFieldDecorator } = this.props.form
        // const data = [
        //     {
        //         title: '老板',
        //         icon: 'smile'
        //     },
        //     {
        //         title: '销售',
        //         icon: 'dollar'
        //     },
        //     {
        //         title: '仓管',
        //         icon: 'university'
        //     },
        //     {
        //         title: '相册',
        //         icon: 'user'
        //     },
        // ]
        let showBottom = !global.isPhoneLandscape && !global.isPhone && global.clientHeight > 600
        return <Spin spinning={this.state.loading}>
            <div onKeyDown={this.onKeyDown.bind(this)} className={global.isPhoneLandscape ? '' : 'login-main'}>
                <div className={global.isPhone ? 'phone-login-box' : 'login-box'}>
                    {!global.isPhoneLandscape && !global.localFrontEnd && <div className='login-icon'/>}
                    <div className={(global.isPhone || !showBottom) ? 'phone-login-body' : 'login-body'}>
                        <Form onSubmit={this.handleSubmit.bind(this)} style={{height: 40}}>
                            <Form.Item>
                                {getFieldDecorator('username', {
                                    rules: [{ required: true, message: '请输入手机号或用户名!' }],
                                })(
                                    <Input
                                        prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                        placeholder="输入手机号或用户名"
                                    />,
                                )}
                            </Form.Item>
                            <Form.Item>
                                {getFieldDecorator('password', {
                                    rules: [{ required: true, message: '请输入密码!' }],
                                })(
                                    <Input
                                        prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                        type="password"
                                        placeholder="输入密码"
                                    />,
                                )}
                            </Form.Item>
                            <Form.Item>
                                {getFieldDecorator('remember', {
                                    valuePropName: 'checked',
                                    initialValue: true,
                                })(<Checkbox><span>自动登录</span></Checkbox>)}
                                <A style={{float: 'right'}} href="">
                                    <span>忘记密码</span>
                                </A>
                                <Button type="primary" htmlType="submit" style={{width: "100%"}}>
                                    登录
                                </Button>
                                {/* <Dropdown trigger={global.trigger}  
                                    overlay={<div>
                                        <List
                                            grid={{ gutter: 16, column: 4 }}
                                            dataSource={data}
                                            renderItem={item => (
                                            <List.Item>
                                                <Card onClick={this.tryout.bind(this, item.title)} title={item.title}>
                                                    <Icon style={{fontSize: 45}} type={item.icon} />
                                                </Card>
                                            </List.Item>
                                            )}
                                        />
                                    </div> } 
                                >
                                    <A>立即体验</A>
                                </Dropdown> */}
                            </Form.Item>
                        </Form>
                    </div>
                    {showBottom && !global.localFrontEnd && 
                    <div className='bottom-copyright'>
                        <div><span>Copyright©2020-2024 guangtianyun.com 版权所有  广天灵码 官方网站</span></div>
                        <div>
                            <A style={{marginRight: 16}} 
                                onClick={() => global.openBrowser('https://beian.mps.gov.cn/#/query/webSearch')}
                            >
                                <img src="/gongan.png" class="gongan-image" alt="陕公网安备61019002003061号"/>
                                陕公网安备61019002003061号
                            </A>
                            <A onClick={() => global.openBrowser('https://beian.miit.gov.cn/')}
                            >陕ICP备2024048772号-1</A>
                        </div>
                    </div>}
                </div>
            </div>
        </Spin>
    }
}

Login = Form.create()(Login)
export default Login