import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {pageInstObj} from '@utils/object'
export default class OORoute extends Component {

    render() {
        const {display, path, component, moreProps, history} = this.props
        let needCache = false
        if (path.indexOf('/new') !== -1) {
            needCache = true
        } else if (path.indexOf('/detail') !== -1) {
            if (pageInstObj[path] && pageInstObj[path].state.editing) {
                needCache = true
            }
        }
        let needRender = display === 'block' || needCache
        return <div style={{display}}>
            {needRender && React.createElement(component, {
                ...moreProps, 
                history,
                wrappedComponentRef: (inst) => {
                    // 页面实例this指针
                    pageInstObj[path] = inst
                },
                ref: (inst) => {
                    // 页面实例this指针
                    pageInstObj[path] = inst
                }
            })}
        </div>
    }
}
OORoute.propTypes = {
    path: PropTypes.string,
    component: PropTypes.any, 
    moreProps: PropTypes.object,
    history: PropTypes.object,
}