/**
 * antd Drawer Modal代理， 手机从下方弹出， 其他设备从右边弹出
 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Drawer from 'antd/lib/drawer'
import Button from 'antd/lib/button'
import Spin from 'antd/lib/spin'
import {modalInstArray} from '@utils/object'

export default class ModalEx extends Component {
    constructor() {
        super()
        this.state = {
            loading: false
        }
    }
    componentDidMount() {
        this.unmount = false
        if (this.props.visible) {
            if (modalInstArray.indexOf(this) === -1) {
                modalInstArray.push(this)
            }
        }
    }
    async refreshPage(clientId) {
        if (this.props.refreshPage instanceof Function) {
            this.props.refreshPage(clientId)
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.visible !== this.props.visible) {
            const index = modalInstArray.indexOf(this)
            if (nextProps.visible) {
                this.setState({loading: false})
                if (index === -1) {
                    modalInstArray.push(this)
                }
            } else {
                if (index !== -1) {
                    modalInstArray.splice(index, 1)
                }
            }
        }
    }
    componentWillUnmount() {
        this.unmount = true
        const index = modalInstArray.indexOf(this)
        if (index !== -1) {
            modalInstArray.splice(index, 1)
        }
    }
    startLoad() {
        if (!this.unmount) {
            this.setState({loading: true})
        }
    }
    stopLoad() {
        if (!this.unmount) {
            this.setState({loading: false})
        }
    }
    render () {
        let {disabled, visible, onCancel, maskClosable, onOk, 
            title, height, width, onClose, children, ...restProps
        } = this.props
        onClose = onClose || onCancel
        if (!width || width === global.modalWidth || width > global.clientWidth) {
            width = global.clientWidth - 60
        }
        let placement = 'right'
        if (global.isPhoneVertical) {
            height = height || (global.clientHeight - 60)
            placement = 'bottom'
        } else {
            height = height || global.clientHeight
        }
        //console.log('height, global.clientHeight',height, global.clientHeight)
        if (onOk && !disabled) {
            title = <div className='space-between'>
                <div>{title}</div>
                <div>
                    <Button type='primary' style={{marginRight: 16}} 
                        disabled={this.state.loading}
                        onClick={async () => {
                            this.startLoad()
                            await onOk()
                        }
                    }>确定</Button>
                    <Button type='primary' 
                        onClick={onClose} 
                        disabled={this.state.loading}
                    >取消</Button>
                </div>
            </div>
        } else {
            title = <div className='space-between'>
                <div>{title}</div>
                <div><Button type='primary' onClick={onClose}>关闭</Button></div>
            </div>
        }
        const props = {title, width, height, placement, visible, onClose}
        return <Drawer {...restProps}
            {...props}
            closable={false}
            title={title}
            maskClosable={maskClosable || true}
            bodyStyle={{margin: 8, padding: 0}}
            
        >
            <Spin spinning={this.state.loading}>
                {children}
            </Spin>
        </Drawer>
    }
}
ModalEx.propTypes = {
    title: PropTypes.any, 
    height: PropTypes.number, 
    width: PropTypes.number, 
    visible: PropTypes.bool, 
    onCancel: PropTypes.func, 
    onClose: PropTypes.func, 
    maskClosable: PropTypes.bool, 
    onOk: PropTypes.func
}