/**
 * 流程管理 
 * 待办事项，已办事项
 * @module flow
 */
import * as dataUtil from '@utils/DataUtil'
import {
    LISTMODULE, TIME
} from '@utils/constants'

export const moduleConfig = {
    modulename: 'flow',
    alias: '工作流',
    edittype: "form",
    moduletype: "list",
    canview: false,
    canadd: false,
    canedit: false,
    candelete: false,
    cancopy: false,
    fields_config: [
        dataUtil.getStringConfig('moduleName', '模块名称', {width:120}),
        dataUtil.getConfig('obj', '单据', LISTMODULE, {
            params: '$moduleName',
            isDisabled: '1',
            isQuery: '0',
            width:160,
        }),
        dataUtil.getStringConfig('code', '编码', {width:160}),
        dataUtil.getStringConfig('name', '名称', {width:160}),
        dataUtil.getConfig('createtime', '创建时间', TIME, {width:120}),
        dataUtil.getConfig('updatetime', '更新时间', TIME, {width:120}),
    ]
}
