/**
 * OOForm
 * Form封装，根据oo系统规范配置创建Form
 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {configToItemProps, createItems} from './PageCreator'
import Form from 'antd/lib/form'
import * as utils from '@utils/index'

class OOForm extends Component {
    constructor(props) {
        super()
        const {moduleName} = props
        // 字段权限，不显示隐藏字段
        const hideFields = (global.role && global.role.hideFields) || {}
        this.hideKeys = hideFields[moduleName] || []
        if (global.isAdmin) {
            this.hideKeys = []
        }
    }

    render () {
        const fieldsConfig = this.props.fieldsConfig || []
        const data = this.props.data || {}
        const {thisPage, specialItemProps, cols, gutter, disabled, isForSearch} = this.props
        const formItemProps = fieldsConfig.filter(config => config.isShowF !== '0' 
            // 新增，修改页面默认隐藏系统内置字段, 详情页面显示全部字段
            && (disabled || !utils.isSysFields(config.name))
            && this.hideKeys.indexOf(config.name) === -1
        ).map(config => {
            return configToItemProps(thisPage || this, config, data, data[config.name], specialItemProps, isForSearch)
        })
        const {getFieldDecorator} = this.props.form
        const formItems = createItems(getFieldDecorator, 
            cols || utils.getCols(), 
            gutter || 8, 
            formItemProps, 
            disabled, 
            isForSearch)
        return  <Form style={this.props.style}>{formItems}</Form>
    }
}

OOForm.propTypes = {
    fieldsConfig: PropTypes.array,
    data: PropTypes.object,
    specialItemProps: PropTypes.func,
    cols: PropTypes.number,
    disabled: PropTypes.bool,
    isForSearch: PropTypes.bool
}

OOForm = Form.create()(OOForm)
export default OOForm
