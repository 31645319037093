import React, {Component} from "react";
import Chart from "./Chart";
import OOForm from "@/components/OOForm";
import * as dataUtil from '@/utils/DataUtil'
import {createIcon} from '@com/PageCreator'
import A from '@com/A'
import { getOnePromise } from "@list/Action";
import { getFieldConfig, info, isBlank, parseSearchStr } from "@utils/index";
import { chartdesign, COLOR, SELECT } from "@utils/constants";
import { callModifyByApiBatch } from "@m/layout/Action";
import ModalEx from "@com/ModalEx";

const axisParamSuffix = 'Param'
export default class ChartDesign extends Component {
    constructor() {
        super()
        this.state = {
            update: false,
            visible: false,
        }
        this.chartConfig = {}
        this.form = null
        this.basicConfig = [
            {name: '基本参数', dataType: 'GROUP', title: <div className="flex-row">
                <div><span>基本参数</span></div>
                <div className="between" style={{width: 176, marginLeft: 16}}>
                    <A onClick={this.onSave.bind(this)}>保存</A>
                </div>
            </div>},
            {name: 'moduleName', alias: '模块', dataType: 'ModuleSelect', params: 'list'},
            {name: 'type', alias: '图表类型', dataType: SELECT, params: 'line,线柱图/pie，饼图/radar,雷达图', defaultValue: 'line'},
        ]
        this.fieldsConfig = [
            {name: '图表参数', dataType: 'GROUP'},
            {name: 'xorientation', alias: '横轴位置', dataType: SELECT, params: 'bottom,底部/top，顶部', defaultValue: 'bottom'},
            {name: 'yorientation', alias: '纵轴位置', dataType: SELECT, params: 'left,左边/right，右边', defaultValue: 'left'},
            {name: 'xAxis', alias: '横轴', dataType: 'FieldSelect'},
            {name: 'yAxis', alias: '纵轴',
                title: <span>
                    {createIcon('plus', '', () => {
                        this.addYAxis()
                    }, {key: 'yAxis-plus'})}
                    {createIcon('gear', '', () => {
                        this.setAxis('yAxis')
                    }, {key: 'yAxis-gear'})}
                    <span key={'yAxis'}>纵轴</span>
                </span>, 
                dataType: 'FieldSelect', otherParams: { dataType: dataUtil.numberTypes },
            },
        ]
        this.pieFieldsConfig = [
            {name: '图表参数', dataType: 'GROUP'},
            {name: 'xAxis', alias: '名称字段', dataType: 'FieldSelect'},
            {name: 'yAxis', alias: '值字段', dataType: 'FieldSelect', otherParams: { dataType: dataUtil.numberTypes }},
            {name: 'fill', alias: '颜色', dataType: COLOR},
        ]

        this.yAxisFieldsConfig = [
            {name: 'type', alias: '类型', dataType: SELECT, params: 'line,折线/bar，柱状图/area，区域图', defaultValue: 'line'},
            {name: 'fill', alias: '颜色', dataType: COLOR},
        ]
    }
    async componentDidMount() {
        const id = parseSearchStr().id
        const record = await getOnePromise(chartdesign, id)
        this.yCount = record.yCount || 0
        this.chartConfig = record.chartConfig || {}
        this.name = record.name
        const moduleName = this.chartConfig.moduleName
        for (let i = 0; i < this.yCount; ++i) {
            this.fieldsConfig.push(this.buildYAxisConfig(i, moduleName))
        }
        this.setXYAxisModuleName(moduleName)
        this.repaint()
    }

    setXYAxisModuleName(moduleName) {
        if (isBlank(moduleName)) {
            return
        }
        const setModuleName = (fieldsConfig) => {
            for (let config of fieldsConfig) {
                if (config.name.startsWith('xAxis') || config.name.startsWith('yAxis')) {
                    config.otherParams = config.otherParams || {}
                    config.otherParams.moduleName = moduleName
                }
            }
        }
        setModuleName(this.fieldsConfig)
        setModuleName(this.pieFieldsConfig)
    }
    basicSpecialItemProps(formField, props) {
        if (formField.id === 'moduleName') {
            props.onChange = (value) => {
                this.setXYAxisModuleName(value)
                this.chartConfig.moduleName = value
                this.repaint()
            }
        } else if (formField.id === 'type') {
            props.onChange = (value) => {
                this.setXYAxisModuleName(this.chartConfig.moduleName)
                this.chartConfig[formField.id] = value
                this.repaint()
            }
        }
        
    }
    specialItemProps(formField, props) {
        props.onChange = (value) => {
            this.chartConfig[formField.id] = value
            this.repaint()
        }
    }
    repaint() {
        this.setState({update: !this.state.update})
    }

    buildYAxisConfig(yAxisIndex, moduleName) {
        const yAxis = 'yAxis' + yAxisIndex
        const alias = '纵轴' + (yAxisIndex + 1)
        return {name: yAxis,  alias,
            title: <span key={yAxis}>
                {createIcon('minus', '', () => {
                    this.removeYAxis(yAxis)
                }, {key: 'yAxis-minus'})}
                {createIcon('gear', '', () => {
                    this.setAxis(yAxis)
                }, {key: 'yAxis-gear'})}
                <A key={yAxis}>{alias}</A>
            </span>, 
            dataType: 'FieldSelect', otherParams: { 
                moduleName, dataType: dataUtil.numberTypes
            }
        }
    }
    addYAxis(needRepaint = true) {
        console.log('addYAxis', needRepaint)
        const {fieldsConfig, chartConfig} = this
        let moduleName = chartConfig.moduleName
        let yIndex = fieldsConfig.findIndex(config => config.name === 'yAxis')
        let addYAxisIndex = fieldsConfig.length - 1 - yIndex
        if (addYAxisIndex === 10) {
            info('提示', '动态纵轴最多创建10个')
            return
        }
        let yAxis = 'yAxis' + addYAxisIndex
        console.log(addYAxisIndex, yAxis, fieldsConfig)
        if (fieldsConfig.find(config => config.name === yAxis)) {
            return
        }
        fieldsConfig.push(this.buildYAxisConfig(addYAxisIndex, moduleName))
        this.yCount++
        needRepaint && this.repaint()
    }
    removeYAxis(yAxis) {
        const {fieldsConfig, chartConfig} = this
        let removeIndex = parseInt(yAxis.substring(5))
        let lastYAxis = fieldsConfig[fieldsConfig.length - 1].name
        let end = parseInt(lastYAxis.substring(5))
        //console.log('removeYAxis',removeIndex, end)
        for (let i = removeIndex; i < end; ++i) {
            let y = 'yAxis' + i
            let yNext = 'yAxis' + (i + 1)
            chartConfig[y] = chartConfig[yNext]
            chartConfig[y + axisParamSuffix] = chartConfig[yNext + axisParamSuffix]
        }
        delete chartConfig[lastYAxis]
        delete chartConfig[lastYAxis + axisParamSuffix]
        fieldsConfig.splice(fieldsConfig.length - 1, 1)
        this.yCount--
        console.log(chartConfig)
        //console.log(fieldsConfig)
        // 剔除 Param后缀key， 由纵轴表单管理
        let obj = {}
        for (let key in chartConfig) {
            if (!key.endsWith(axisParamSuffix)) {
                obj[key] = chartConfig[key]
            }
        }
        this.form.setFieldsValue(obj)
        this.repaint()
    }
    setAxis(axis) {
        this.axis = axis
        this.setState({visible: true})
    }
    onSave() {
        const values = this.form.getFieldsValue()
        callModifyByApiBatch(chartdesign, parseSearchStr().id, {
            chartConfig: Object.assign(this.chartConfig, values), 
            yCount: this.yCount
        })
    }
    onCancel() {
        this.setState({visible: false})
    }
    onOk() {
        this.chartConfig[this.axis + axisParamSuffix] = this.axisForm.getFieldsValue()
        this.setState({visible: false})
    }
    render() {
        const {basicConfig, chartConfig, fieldsConfig, pieFieldsConfig, yCount, name, yAxisFieldsConfig} = this
        let chartsDivWidth = global.clientWidth - 360
        let chartsDivHeight = global.clientHeight - 50
        const chartProps = {chartsDivWidth, chartsDivHeight, chartConfig, yCount, name}
        const {type} = chartConfig
        let chartFieldsConfig = fieldsConfig
        if (type === 'pie' || type === 'radar') {
            chartFieldsConfig = pieFieldsConfig
        }
        return <div className="flex-row">
            <Chart {...chartProps} />
            <div style={{width: 360, height: chartsDivHeight, padding: 8, paddingRight: 12, backgroundColor:'#fdfdfd'}} >
                <OOForm
                    cols={1}
                    fieldsConfig={basicConfig}
                    data={chartConfig}
                    specialItemProps={this.basicSpecialItemProps.bind(this)}
                />
                <OOForm
                    thisPage={this}
                    wrappedComponentRef={(inst) => 
                        this.form = inst && inst.props && inst.props.form
                    }
                    cols={1}
                    fieldsConfig={chartFieldsConfig}
                    data={chartConfig}
                    specialItemProps={this.specialItemProps.bind(this)}
                />
            </div>
            {this.state.visible && <ModalEx
                title={`${this.axis} - ${getFieldConfig(fieldsConfig, this.axis).alias}`}
                visible={this.state.visible}
                onCancel={this.onCancel.bind(this)}
                onClose={this.onCancel.bind(this)}
                maskClosable={true}
                width={400}
                onOk={this.onOk.bind(this)}
            >
                <OOForm
                    thisPage={this}
                    wrappedComponentRef={(inst) => 
                        this.axisForm = inst && inst.props && inst.props.form
                    }
                    cols={1}
                    fieldsConfig={yAxisFieldsConfig}
                    data={chartConfig[this.axis + axisParamSuffix] || {}}
                />
            </ModalEx>}
        </div>
    }
}