import React, { Component } from 'react'
import ButtonIcon from './ButtonIcon'
import Modal from 'antd/lib/modal'
import QRCode from 'react-qr-code'
import * as utils from '@utils/index'

export default class QRCodeEx extends Component {
    constructor() {
        super()
        this.state = {
            visible: false
        }
    }
    onClose() {
        this.setState({visible: false})
    }
    render() {
        // 树根节点编码root不生成二维码
        if (utils.isBlank(this.props.value) || this.props.value === 'root') {
            return <span>{this.props.value}</span>
        }
        return <span>
            <span onClick={() => this.setState({visible: true})}>
                {this.props.index && <span style={{marginRight: 8}}>{this.props.index}</span>}
                <ButtonIcon type='qrcode'/>
                {this.props.label || this.props.value}
            </span>
            <Modal visible={this.state.visible} title={this.props.value}
                maskClosable
                onCancel={this.onClose.bind(this)}
                onClose={this.onClose.bind(this)}
                footer={[]}
                width={304}
            >
                <QRCode value={this.props.value}/>
            </Modal>
        </span>
    }
}
