import React, { Component } from 'react'
import Button from 'antd/lib/button'
import Modal from 'antd/lib/modal'
import ModalEx from '@com/ModalEx'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Input from 'antd/lib/input'
import Affix from 'antd/lib/affix'
import ModuleSelect from '@com/ModuleSelect'
import FieldSelectCheckBox from '@com/FieldSelectCheckBox'
import FieldSelect from '@com/FieldSelect'
import * as Action from '@list/Action'
import {callModifyByApiBatch} from '../layout/Action'
import Report from './Report'
import InputSelect from '@com/InputSelect'
import GroupFieldExpression from './GroupFieldExpression'
import SelectOne from '@com/SelectOne'
import Icon from '@com/Icon'
import InputNumber from 'antd/lib/input-number'
import * as utils from '@utils/index'
import { reportengine } from '@utils/constants'
import A from '@com/A'

const add$ = name => {
    if (utils.isBlank(name)) {
        return null
    }
    if (name.charAt(0) === '$') {
        return name
    }
    return '$' + name
}

/* global traverseTree */
export default class ReportDesign extends Component {
    constructor() {
        super()
        this.id = global.parseSearchStr().id
        this.moduleName = reportengine
        /* 关联模块数据结构
        {
            localField: '',
            foreignField: '',
            as: '',
            moduleName: '',
            queryFields: [],
            showFields: [],
            joinModules: [],
            visible: false
        }
        */
        this.state = {
            moduleName: '',     // 主模块
            showFields: [],     // 显示字段
            dataTypes: [],      // 显示字段类型
            queryFields: [],    // 查询字段
            joinModules: [],    // 关联模块
            groups: [],         // 分组信息
            names: [],          // 报表显示的所有字段名称, 名称前拼接 $， mongo中$开头标记变量，动态取字段的值
            typeMap: {},        // 字段, 类型 Map
            update: false,      // 无实际意义，修改其是页面渲染
            hideModule: false,  // 显示关联模块区域
            showReport: false,  // 显示报表预览
        }
    }
    componentDidMount() {
        this.load()
    }
    load() {
        Action.getOne(this.moduleName, this.id, record => {
            utils.parseObjectOrArray(record)
            let { name, moduleName, showFields, dataTypes, queryFields, 
                joinModules, groups, names, typeMap } = record
            // 数据库中第一次查询没有值，需要初始化
            moduleName = moduleName || ''
            showFields = showFields || []
            dataTypes = dataTypes || []
            queryFields = queryFields || []
            joinModules = joinModules || []
            groups = groups || []
            names = names || []
            typeMap = typeMap || {}
            this.setState({ name, moduleName, showFields, dataTypes, queryFields, joinModules, groups, names, typeMap })
        })
    }
    onSave() {
        const { 
            moduleName,     // 主模块
            showFields,     // 显示字段
            dataTypes,      // 显示字段类型
            queryFields,    // 查询字段
            joinModules,    // 关联模块
            groups,         // 分组信息
        } = this.state
        // 报表显示的所有字段名称, 名称前拼接 $， mongo中$开头标记变量，动态取字段的值
        const names = []
        const typeMap = {}
        for (let i = 0; i < showFields.length; ++i) {
            let name = add$(showFields[i])
            names.push(name)
            if (dataTypes instanceof Array) {
                typeMap[name] = dataTypes.at(i)
            }
        }
        // 主模块界面校验
        if (utils.isBlank(moduleName)) {
            Modal.warning({ title: '请选择主模块' })
            return
        }
        // 关联模块界面数据校验
        if (joinModules instanceof Array) {
            let ret = traverseTree(joinModules, (module, parent) => {
                let mainModuleName = (parent && parent.moduleName) || moduleName
                if (utils.isBlank(module.moduleName)) {
                    Modal.warning({ title: `请选择本模块, 主模块： ${mainModuleName}` })
                    return false
                }
                if (utils.isBlank(module.localField)) {
                    Modal.warning({ title: `请选择主模块关联字段, 主模块： ${mainModuleName}` })
                    return false
                }
                if (utils.isBlank(module.foreignField)) {
                    Modal.warning({ title: `请选择本模块关联字段, 主模块： ${mainModuleName}` })
                    return false
                }
                return true
            }, 'joinModules')

            if (ret === false) {
                return
            }
        }

        // 递归构建模块与子模块关联的查询条件，并构建显示字段
        let buildLookupStages = (joinModules, parentPrefix) => {
            let lookupStages = []
            if (!(joinModules instanceof Array)) {
                return lookupStages
            }
            for (let module of joinModules) {
                // 关联模块数据在父模块中的字段名称, 父模块字段_关联模块名称
                // localField 父模块字段
                // foreignField 本模块字段
                // moduleName 本模块名称
                let as = `${module.localField}_${module.moduleName}`
                module.as = as
                const prefix = parentPrefix + module.as + '.'
                // 构造显示字段名称  前缀.父模块字段_关联模块名称.子模块字段名称
                if (module.showFields instanceof Array) {
                    for (let i = 0; i < module.showFields.length; ++i) {
                        let name = add$(prefix + module.showFields[i])
                        names.push(name)
                        if (module.dataTypes instanceof Array) {
                            typeMap[name] = module.dataTypes.at(i)
                        }
                    }
                }
                let lookup = {
                    "$lookup": {
                        // 关联模块名称
                        from: module.moduleName,
                        // 父模块字段
                        localField: module.localField,
                        // 关联模块字段
                        foreignField: module.foreignField,
                        // 关联模块数据在父模块中的字段名称
                        as: as
                    }
                }
                lookupStages.push(lookup)
                // 将关联模块数组数据展开为对象
                lookupStages.push({
                    "$unwind":  { path: add$(as), preserveNullAndEmptyArrays: true}
                })
                // 使用管道，递归构建当前模块关联模块的查询条件及显示字段
                if (module.joinModules instanceof Array && module.joinModules.length > 0) {
                    lookup.$lookup.pipeline = buildLookupStages(module.joinModules, prefix)
                }
            }
            return lookupStages
        }
        // 构建入口，从主模块递归构建查询条件
        let aggregateStages = buildLookupStages(joinModules, '')
        
        // 分组信息校验
        if (groups instanceof Array) {
            for (let i = 0; i < groups.length; ++i) {
                const groupNames = []
                const nameExists = (name) => {
                    if (utils.isBlank(name)) return false
                    if (groupNames.indexOf(name) !== -1) {
                        return true
                    }
                    groupNames.push(name)
                    return false
                }
                let group = groups[i]
                // 分组字段名不能不能包含. $（mongodb语法规定）
                if (group.name && group.name.indexOf('.') !== -1) {
                    Modal.warning({ title: `分组${i+1} 分组字段名不能不能包含.` })
                    return
                }
                if (group.name && group.name.indexOf('$') !== -1) {
                    Modal.warning({ title: `分组${i+1} 分组字段名不能不能包含$` })
                    return
                }
                // 第一级分组字段名不能为空， 第二级分组以后分组字段名可以为空，为空时等于汇总全部数据
                if (i === 0) {
                    if (utils.isBlank(group.name)) {
                        Modal.warning({ title: `分组${i+1} 分组字段名不能为空` })
                        return
                    }
                }
                // 分组显示字段列表不能为空
                if (group.list == null || group.list.length === 0) {
                    Modal.warning({ title: `分组${i+1} 分组显示字段列表不能为空` })
                    return
                }
                // 分组显示字段列表校验
                for (let j = 0; j < group.list.length; ++j) {
                    let it = group.list[j]
                    if (utils.isBlank(it.name)) {
                        Modal.warning({ title: `分组${i+1} 第${j+1}行 字段名不能为空` })
                        return
                    }
                    if (it.name.indexOf('.') !== -1) {
                        Modal.warning({ title: `分组${i+1} 第${j+1}行 字段名不能不能包含.` })
                        return
                    }
                    if (nameExists(it.name)) {
                        Modal.warning({ title: `分组${i+1} 第${j+1}行 字段名【${it.name}】已存在，请重新命名` })
                        return
                    }
                    if (utils.isBlank(it.formula)) {
                        Modal.warning({ title: `分组${i+1} 第${j+1}行 公式不能为空` })
                        return
                    }
                    if (utils.isBlank(it.value)) {
                        Modal.warning({ title: `分组${i+1}第${j+1}行 表达式不能为空` })
                        return
                    }
                }
            }
        }
        /**
         *  分组第二层及以后使用下面语法, 反向unwind，replaceRoot， 将分组的数据逐渐合并到第一级分组的记录
        [
            {
                "$group": {
                    _id: "$部门名称",
                    "部门总价": {
                        "$sum": "$人员总价"
                    },
                    record: {
                        "$push": "$$ROOT"
                    }
                }
            }, {
                "$unwind": {
                    "path": "$record",
                }
            }, {
                "$replaceRoot": {
                    newRoot: {
                        "$mergeObjects": ["$$ROOT", "$record"]
                    }
                }
            }
        ]
         */
        // 构建后端分组条件
        const groupStages = []
        // 注入分组id+分组序号， 为后端的排序做准备
        for (let i = 0; i < groups.length; ++i) {
            let group = groups[i]
            let obj = {}
            if (utils.isBlank(group.value)) {
                // 未指定分组字段
                // 汇总全部
                obj._id = null
                // 取第一条，值为null
                obj[`id${i}`] = { "$first": null }
            } else {
                // 指定分组字段
                let value = group.value
                // 日期时间类型格式化
                if ((typeMap[group.value] === 'DATE' || typeMap[group.value] === 'TIME') && group.format) {
                    value = { $dateToString: { format: group.format, date: group.value } }
                }
                obj._id = value
                if (i === 0) {
                    // 第一级分组显示分组字段的第一条数据
                    obj[group.name] = { "$first": value }
                }
                // 取分组字段的第一个值
                obj[`id${i}`] = { "$first": value }
            }
            // 第二级分组直接汇总
            if (i === 1) {
                // 汇总第一级汇总数据行数
                obj.sum1 = {
                    "$sum": 1
                }
            } else if (i > 1) {
                // 第三级分组累加第二级分组的汇总数，依次类推， 最终最后一级分组得到总数
                obj[`sum${i}`] = {
                    "$sum": `$sum${i - 1}`
                } 
            }
            // 构建每一个分组字段
            if (group.list instanceof Array) {
                for (let it of group.list) {
                    let value = it.value
                    // 日期时间类型格式化
                    if ((typeMap[it.value] === 'DATE' || typeMap[it.value] === 'TIME') && it.format) {
                        value = { $dateToString: { format: it.format, date: it.value } }
                    }
                    /**
                    "人员名称": {
                        "$first": "$销售员_人员.姓名"
                    }
                     */
                    obj[it.name] = {
                        [it.formula]: value
                    }
                }
            }
            // 从第二级分组开始，将上一级分组的数据添加到record数组
            if (i > 0) {
                obj.record = {
                    "$push": "$$ROOT"
                }
            }
            // 后端分组条件
            groupStages.push({
                "$group": obj
            })
        }
        
        // 展开合并多级分组记录
        for (let i = 0; i < groups.length - 1; ++i) {
            // 展开record数组
            groupStages.push({
                "$unwind": "$record"
            })
            // 将record合并到主记录
            groupStages.push({
                "$replaceRoot": {
                    newRoot: {
                        "$mergeObjects": ["$$ROOT", "$record"]
                    }
                }
            })
        }
        const record = { 
            moduleName,     // 主模块
            showFields,     // 显示字段
            dataTypes,      // 显示字段类型
            queryFields,    // 查询字段
            joinModules,    // 关联模块
            groups,         // 分组信息
            names,          // 报表显示的所有字段名称, 名称前拼接 $， mongo中$开头标记变量，动态取字段的值
            typeMap,        // 字段, 类型 Map
            aggregateStages,    // 后端 aggregate 查询条件
            groupStages         // 后端 aggregate 分组条件
        }
        for (let key in record) {
            record[key] = utils.toString(record[key])
        }
        // 保存报表引擎数据
        callModifyByApiBatch(this.moduleName, this.id, record, () => {
            this.load()
        })
    }
    reRender() {
        this.setState({update: !this.state.update})
    }
    render() {
        let {
            moduleName,     // 主模块
            showFields,     // 显示字段
            queryFields,    // 查询字段
            joinModules,    // 关联模块
            groups,         // 分组信息
            names,          // 报表显示的所有字段名称, 名称前拼接 $， mongo中$开头标记变量，动态取字段的值
            typeMap,        // 字段, 类型 Map
        } = this.state
        queryFields = queryFields || []
        showFields = showFields || []
        joinModules = joinModules || []
        names = names || []
        groups = groups || []
        const moduleStyle = {width: 208, marginRight: 8}
        const fieldStyle = {width: 140, marginRight: 8}
        
        // 递归构建模块关联界面
        const buildJoinModuleDiv = (moduleName, joinModules) => {
            if (!(joinModules instanceof Array) || joinModules.length === 0) {
                return false
            }
            // 关联模块显示隐藏控制
            const visibleModule = joinModules.find(module => module.visible)
            let subJoinModuleDiv = false
            if (visibleModule) {
                subJoinModuleDiv = <div style={{marginTop: 16}}>
                    <span style={{fontSize: 16}}>本模块： {visibleModule.moduleName}</span>
                    <Button type='primary' style={{marginLeft: 32, marginRight: 16}} onClick={ () => {
                        if (utils.isBlank(visibleModule.moduleName)) {
                            Modal.warning({
                                title: '请选择本模块后再新增关联模块',
                            })
                            return
                        }
                        visibleModule.joinModules = visibleModule.joinModules || []
                        visibleModule.joinModules = visibleModule.joinModules.concat([{}])
                        this.reRender()
                    }}>新增关联模块</Button>
                    {/* 递归构建模块关联界面 */}
                    {buildJoinModuleDiv(visibleModule.moduleName, visibleModule.joinModules)}
                </div>
            }
            // 返回关联模块界面，列表，并排显示
            return <div>
                <div style={{display: 'flex', flexDirection: 'row', marginTop: 8}}>
                {joinModules.map((module, index) => {
                    return <div key={index} style={{width: 310, minWidth: 310, border: 'solid 1px #1890ff', borderRadius: 4, padding: 8, margin: 4}}>
                        <div>
                            <span style={{fontSize: 16}}>主模块： </span>
                            {/* 字段单选组件，选择主模块字段， 外键 */}
                            <FieldSelect style={fieldStyle} 
                                showId
                                moduleName={moduleName} 
                                value={module.localField} 
                                onChange={ (value, dataType) => {
                                    module.localField = value
                                    module.localFieldDataType = dataType
                                    this.reRender()
                                }
                            }/>
                            {/* 删除当前关联模块 */}
                            <A onClick={() => {
                                joinModules.splice(index, 1)
                                this.reRender()
                            }} style={{marginLeft: 8}}>删除</A>
                        </div>
                        <div>
                            {/* 模块选择组件，选择关联模块 */}
                            <ModuleSelect style={{...moduleStyle, marginRight: 16}} value={module.moduleName} onChange={ moduleName => {
                                module.moduleName = moduleName
                                this.reRender()
                            }}/>
                            {/* 字段多选组件，选择关联模块显示字段 */}
                            <FieldSelectCheckBox buttonName='显示' moduleName={module.moduleName} value={module.showFields} onChange={(showFields, dataTypes) => {
                                module.showFields = showFields
                                module.dataTypes = dataTypes
                                this.reRender()
                            }}/>
                            {/* 字段多选组件，选择关联模块查询字段 */}
                            <FieldSelectCheckBox buttonName='查询' style={{marginLeft: 8}} moduleName={module.moduleName} value={module.queryFields} onChange={queryFields => {
                                module.queryFields = queryFields
                                this.reRender()
                            }}/>
                        </div>
                        <div>
                            <span style={{fontSize: 16}}>本模块： </span>
                            {/* 字段单选组件，选择当前关联模块字段， 与主模块外键关联 */}
                            <FieldSelect style={fieldStyle} 
                                showId
                                moduleName={module.moduleName} 
                                value={module.foreignField} 
                                onChange={ (value, dataType) => {
                                    module.foreignField = value
                                    module.foreignFieldDataType = dataType
                                    this.reRender()
                                }
                            }/>
                            {/* 当前模块作为主模块，再关联其他模块 */}
                            <A onClick={() => {
                                if (utils.isBlank(module.moduleName)) {
                                    Modal.warning({
                                        title: '请选择本模块后再关联其他模块',
                                    })
                                    return
                                }
                                module.visible = !module.visible
                                for (let i = 0; i < joinModules.length; ++i) {
                                    if (i !== index) {
                                        joinModules[i].visible = false
                                    }
                                }
                                this.reRender()
                            }} style={{marginLeft: 8}}>{module.visible ? '收起': '关联'}</A>
                        </div>
                    </div>
                })}
                </div>
                {/* 当前模块作为主模块，关联其他模块界面，垂直分布 */}
                {subJoinModuleDiv}
            </div>
        }
        const groupFuncs = [
            {value: '$sum', label: '求和'},
            {value: '$avg', label: '平均值'},
            {value: '$max', label: '最大值'},
            {value: '$min', label: '最小值'},
            {value: '$first', label: '第一个'},
        ]
        const dateFormats = [
            {value: '%Y', label: '年'},
            {value: '%Y-%m', label: '年月'},
            {value: '%Y-%m-%d', label: '年月日'},
        ]

        const colStyle = {paddingLeft: 8, paddingRight: 8, height: 36}
        return <div>
            <Affix offsetTop={0} target={() => global.layoutContainerInst}>
                <div style={{height: 40, marginTop: 16, backgroundColor: '#fff'}}>
                    <Button type='primary' style={{marginRight: 16}} onClick={this.onSave.bind(this)}>保存</Button>
                    <Button style={{marginRight: 64}} onClick={() => this.setState({showReport: true})}>报表预览</Button>
                    <span style={{fontSize: 16}}>主模块： </span>
                    {/* 模块选择组件，选择主模块 */}
                    <ModuleSelect style={moduleStyle} value={moduleName} onChange={moduleName => this.setState({ moduleName, joinModules: [] })} />
                    {/* 字段多选组件，选择主模块显示字段 */}
                    <FieldSelectCheckBox buttonName='显示' moduleName={moduleName} value={showFields} onChange={(showFields, dataTypes) => this.setState({ showFields, dataTypes }) } />
                    {/* 字段多选组件，选择主模块查询字段 */}
                    <FieldSelectCheckBox buttonName='查询' style={{marginLeft: 8}} moduleName={moduleName} value={queryFields} onChange={queryFields => this.setState({ queryFields })} />
                    <Button type='primary' style={{marginLeft: 32, marginRight: 16}} onClick={ () => {
                        if (utils.isBlank(moduleName)) {
                            Modal.warning({
                                title: '请选择主模块后再新增关联模块',
                            })
                            return
                        }
                        this.setState({joinModules: joinModules.concat([{}])})
                    }}>新增关联模块</Button>
                    <Button type='primary' ghost style={{marginRight: 16}} onClick={() => {
                        let index = groups.length + 1
                        this.setState({groups: groups.concat([{
                            key: 'group' + index,
                            name: '',
                            expr: '',
                            format: '',
                            list: [],
                        }])})
                    }}>新增分组</Button>
                </div>
            </Affix>
            <div>
                {/** 模块关联区域 */}
                <div>
                    <h3 style={{display: 'inline-block', marginRight: 16}}>模块关联</h3>
                    <Button onClick={() => this.setState({hideModule: !this.state.hideModule})}>
                        <Icon type={!this.state.hideModule ? 'caret-up' : "caret-down"}/>
                    </Button>
                </div>
                {/** 主模块关联模块区域 */}
                {!this.state.hideModule && <div style={{overflowX: 'auto', overflowY: 'auto', margin: 8, marginBottom: 16, padding: 8, border: 'solid 1px #efefef', borderRadius: 8}}>
                    { buildJoinModuleDiv(moduleName, joinModules) }
                </div>}
                {/** 分组区域 */}
                {groups.map((group, groupIndex) => {
                    if (groupIndex > 0) {
                        group.names = groups[groupIndex - 1].list.map(it => add$(it.name))
                    }
                    return <div style={{marginTop: 32}} key={groupIndex}>
                        <h3 style={{display: 'inline-block', marginRight: 16}}>分组{groupIndex + 1}</h3>
                        <Button style={{marginRight: 32}} onClick={() => {
                            group.hide = !group.hide
                            this.reRender()
                        }}>
                            <Icon type={!group.hide ? 'caret-up' : "caret-down"}/>
                        </Button>
                        <Button type='primary' onClick={() => {
                            group.list.push({})
                            this.reRender()
                        }}>新增汇总字段</Button>
                        <Button style={{marginLeft: 32}} type='danger' onClick={() => {
                            groups.splice(groupIndex, 1)
                            this.setState({groups})
                        }}>删除分组</Button>
                        <div style={{display: group.hide ? 'none' : 'block', margin: 8, padding: 8, border: 'solid 1px #efefef', borderRadius: 8}}>
                            <Row style={{marginTop: 16, marginBottom: 16}}>
                                {groupIndex === 0 && <Col span={2} style={colStyle}>
                                    <h4>序号</h4>
                                </Col>}
                                <Col span={13} style={colStyle}>
                                    <h4>分组表达式</h4>
                                </Col>
                                <Col span={2} style={colStyle}>
                                    <h4>日期格式化</h4>
                                </Col>
                                {groupIndex === 0 && <Col span={6} style={colStyle}>
                                    <h4>分组字段名</h4>
                                </Col>}
                                {groupIndex === 0 && <Col span={2} style={colStyle}>
                                    <InputNumber value={group.no} onChange={value => {
                                        group.no = value
                                        this.reRender()
                                    }} />
                                </Col>}
                                <Col span={13} style={colStyle}>
                                    <InputSelect value={group.value} dataSource={group.names || names} 
                                    onChange={value => {
                                        group.value = value
                                        this.reRender()
                                    }}/>
                                </Col>
                                <Col span={2} style={colStyle}>
                                    <SelectOne placeholder='请选择' dataSource={dateFormats} 
                                    disabled={!(typeMap[group.value] === 'DATE' || typeMap[group.value] === 'TIME')}
                                    value={group.format} 
                                    onChange={value => {
                                        group.format = value
                                        this.reRender()
                                    }}/>
                                </Col>
                                {groupIndex === 0 && <Col span={6} style={colStyle}>
                                    <Input value={group.name} onChange={e => {
                                        group.name = e.target.value
                                        this.reRender()
                                    }} />
                                </Col>}
                            </Row>
                            <Row>
                                <Col span={2} style={colStyle}>
                                    <h4>序号</h4>
                                </Col>
                                <Col span={6} style={colStyle}>
                                    <h4>字段名</h4>
                                </Col>
                                <Col span={4} style={colStyle}>
                                    <h4>公式</h4>
                                </Col>
                                <Col span={7} style={colStyle}>
                                    <h4>表达式</h4>
                                </Col>
                                <Col span={2} style={colStyle}>
                                    <h4>日期格式化</h4>
                                </Col>
                                <Col span={3} style={colStyle}>
                                    <h4>操作</h4>
                                </Col>
                            </Row>
                            {group.list.map((it, index) => { return <Row key={index}>
                                <Col span={2} style={colStyle}>
                                    <InputNumber value={it.no} onChange={value => {
                                        it.no = value
                                        this.reRender()
                                    }} />
                                </Col>
                                <Col span={6} style={colStyle}>
                                    <Input value={it.name} onChange={e => {
                                        it.name = e.target.value
                                        this.reRender()
                                    }} />
                                </Col>
                                <Col span={4} style={colStyle}>
                                    <SelectOne placeholder='请选择公式' dataSource={groupFuncs} 
                                    value={it.formula} onChange={value => {
                                        it.formula = value
                                        this.reRender()
                                    }}/>
                                </Col>
                                <Col span={7} style={colStyle}>
                                    <InputSelect dataSource={group.names || names} 
                                    value={it.value && global.toString(it.value)} 
                                    onChange={value => {
                                        it.value = value
                                        this.reRender()
                                    }}/>
                                </Col>
                                <Col span={2} style={colStyle}>
                                    <SelectOne placeholder='请选择' dataSource={dateFormats} 
                                    disabled={!(typeMap[it.value] === 'DATE' || typeMap[it.value] === 'TIME')}
                                    value={it.format} onChange={value => {
                                        it.format = value
                                        this.reRender()
                                    }}/>
                                </Col>
                                <Col span={3} style={colStyle}>
                                    <GroupFieldExpression style={{marginRight: 16}} 
                                        groupFuncs={groupFuncs}
                                        dataSource={group.names || names} value={it} 
                                        onChange={obj => {
                                            it.value = obj.value
                                            this.reRender()
                                        }}
                                    />
                                    <A onClick={() => {
                                        group.list.splice(index, 1)
                                        this.reRender()
                                    }}>删除</A>
                                </Col>
                            </Row>})}
                        </div>
                    </div>
                })}
                {this.state.showReport && <ModalEx
                    title={this.state.name}
                    visible={this.state.showReport}
                    onCancel={() => this.setState({showReport: false})}
                    onClose={() => this.setState({showReport: false})}
                    maskClosable={true}
                    width={global.modalWidth}
                    footer={[]}
                >
                    {/** 报表引擎生成的报表展示组件 */}
                    <Report id={this.id} cols={this.props.cols} />
                </ModalEx>}
            </div>
        </div>
    }
}