/**
 * 树模型接口
 */
import {treeDataObj} from '@utils/object'

/**
 * 查询
 */
export function getTree(moduleName, cb) {
    if (treeDataObj[moduleName + 'update'] === false) {
        cb && cb(treeDataObj[moduleName] || [])
        return
    }
    global.getApi().put(`/${moduleName}/tree`).then(payload => {
        treeDataObj[moduleName + 'update'] = false
        treeDataObj[moduleName] = payload.body || []
        cb && cb(payload.body || [])
    }, () => {
        cb && cb([])
    })
}

export async function getTreePromise(moduleName) {
    if (treeDataObj[moduleName + 'update'] === false) {
        return treeDataObj[moduleName] || []
    }
    try {
        const payload = await global.getApi().put(`/${moduleName}/tree`)
        treeDataObj[moduleName + 'update'] = false
        treeDataObj[moduleName] = payload.body || []
        return treeDataObj[moduleName]
    } catch(e) {
        console.error(e)
    }
    return []
}
