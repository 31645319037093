import React, { Component } from 'react'
import ModalEx from '@com/ModalEx'
import {getOnePromise, getListAll} from '@list/Action'
import { callModifyByApiBatch } from '../modules/layout/Action'
import ListModule from '@list/ListModule'
import * as utils from '@utils/index'
import * as p from '@utils/page'
import { auth, staff } from '@utils/constants'
import { createIcon } from '@com/PageCreator'

class AuthManage extends Component {
    /**
     * 构造函数
     */
    constructor() {
        super()
        this.state = {
            visible: false,
            selectedRowKeys: [],
            selectedRows: [],
            dataSource: [],
        }
    }

    /**
     * 组件加载时
     */
    refresh() {
        let id = this.props.data.id
        p.wait(async () => {
            let body = await getOnePromise(staff, id)
            //console.log('body', body)
            body = body || {}
            let {authList} = body
            authList = authList || []
            //console.log('authList', authList)
            this.setState({selectedRowKeys: authList})
        })
        getListAll(auth, {}, null, list => {
            this.setState({dataSource: list})
        })

    }
    /**
     * 取消
     */
    onCancel() {
        this.setState({visible: false})
    }
    /**
     * 确定
     */
    onOk() {
        let id = this.props.data.id
        const {selectedRowKeys} = this.state
        if (selectedRowKeys.length === 0) {
            utils.warning('提示', '未选择权限！')
            return
		}

        callModifyByApiBatch(staff, id, { authList: selectedRowKeys }, () => {
            this.setState({visible: false})
            // 修改权限后重新登录或强刷生效
        })
        this.setState({selectedRowKeys: [], selectedRows: []})
    }
    /**
     * 复选框回掉函数
     * @param {*} selectedRowKeys 
     */
    onSelectChange(selectedRowKeys, selectedRows) {
        //console.log(selectedRowKeys, 'selectedRowKeys')
		this.setState({
			selectedRowKeys: selectedRowKeys,
            selectedRows: selectedRows,
		})
    }
    render() {
        const {data} = this.props
        //console.log(data, 'data')
        const config = global.getConfigByModuleName(auth)
        return <span>
            {createIcon('user-gear', '权限管理', () => {
                //console.log('权限管理-------------------------')
                setTimeout(() => this.setState({visible: true}), 200)
                this.refresh()
            })}
            {this.state.visible && <ModalEx
                title={`${data.name}权限管理`}
                visible={this.state.visible}
                onCancel={this.onCancel.bind(this)}
                onClose={this.onCancel.bind(this)}
                onOk={this.onOk.bind(this)}
                maskClosable={false}
                width={global.modalWidth}
            >
                <div>
                    {config && <ListModule
                        {...this.props}
                        disabled
                        config={config}
                        selectedRowKeys={this.state.selectedRowKeys}
                        onSelectChange={this.onSelectChange.bind(this)}
                    />}
                </div>
            </ModalEx>}
        </span>
    }
}
export default AuthManage