/**
 * OOTableEx
 * OOTable增强版，可作为Form表单内部组件使用
 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import OOTable from './OOTable'
import * as utils from '@utils/index'
import * as dataUtil from '@utils/DataUtil'

export default class OOTableEx extends Component {
    constructor() {
        super()
        this.state = {
            list: [],
            sorter: {},
        }
    }

    componentDidMount() {
        this.init()
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.props.value) {
            this.init(nextProps)
        }
    }

    init(props = this.props) {
        const {value} = props
        if (value instanceof Array) {
            this.setState({list: value})
        }
    }

    onOOTableChange(list, newRecord, key) {
        for (let data of list) {
            // 编辑状态
            delete data.disabled
            // 新增状态
            delete data.isNew
            // 数据变化标记
            delete data.change
        }
        // 修改单元格中的组件会回调该方法，在此调用setState重新渲染组件会造成输入卡顿
        //this.setState({ list })
        this.props.onChange && this.props.onChange(list, newRecord, key)
    }

    /**
     * 表格发生变化回调函数
     * @param {*} pagination    分页信息 （OOTable不分页）
     * @param {*} filters       过滤条件
     * @param {*} sorter        排序信息
     */
    handleTableChange(pagination, filters, sorter) {
        //console.log(sorter)
        if (sorter && (sorter.field !== this.state.sorter.field || sorter.order !== this.state.sorter.order)) {
            let list = this.getList()
            let dataType = this.getFeildDataType(sorter.field)
            list.sort(utils.sortByKeyAndType(sorter.field, dataType, sorter.order))
            this.setState({sorter, list})
            this.props.onChange && this.props.onChange(list)
        }
    }

    getFeildsConfig() {
        let {params, moduleName, fieldsConfig} = this.props
        moduleName = params || moduleName
        if (utils.isNotBlank(moduleName)) {
            let moduleConfig = utils.getModuleConfig(moduleName).fields_config
            if (moduleConfig.fields_config.length > 0) {
                fieldsConfig = moduleConfig.fields_config
            }
        }
        if (fieldsConfig instanceof Array) {
            return fieldsConfig
        }
        return []
    }

    getFeildDataType(name) {
        const fieldsConfig = this.getFeildsConfig()
        let config = fieldsConfig.find(item => item.name === name)
        return dataUtil.getRealType(config)
    }

    formError() {
        return this.table && this.table.formError && this.table.formError()
    }

    getList() {
        return (this.table && this.table.getList && this.table.getList()) || []
    }

    render() {
        const {isShowAdd, isShowDel, 
            onChange, params, moduleName, alwaysEdit,
            ...restProps
        } = this.props
        const {list} = this.state
        return <OOTable 
            {...restProps} 
            ref={inst => this.table = inst}
            list={list} 
            moduleName={params || moduleName}
            alwaysEdit={alwaysEdit || true}
            isShowAdd={isShowAdd || true}
            isShowDel={isShowDel || true}
            onChange={this.props.disabled ? undefined : this.handleTableChange.bind(this)}
            onOOTableChange={this.onOOTableChange.bind(this)}
        />
    }
}

OOTableEx.propTypes = {
    // 数据
    value: PropTypes.array,
    // 数据变化回调
    onChange: PropTypes.func,
    /**
     * OOTable 参数
     */
    // 模块名称 params 或 moduleName
    params: PropTypes.string,
    moduleName: PropTypes.string,
    // 配置
    fieldsConfig: PropTypes.array,
    // 数据
    list: PropTypes.array,
    // 数据变化回调
    onOOTableChange: PropTypes.func,
    // configToColumn 其它参数
    specialColumn: PropTypes.func,
    props: PropTypes.any,
    renderWrapper: PropTypes.func,
    specialItemProps: PropTypes.func,
    isShowAdd: PropTypes.bool,
    addBtnName: PropTypes.string,
}