import React from 'react'
import Auth from '../../extension/Auth'
import { auth } from '@utils/constants'

const moduleName = auth

export function iconOperation(text, record) {
    let moduleOpers = (global.role && global.role.moduleOpers) || []
    let operAuth = global.isAdmin || moduleOpers.indexOf(`${moduleName}_oaa`) !== -1
    if (operAuth) {
        return <Auth key={text + 'auth_oaa'} data={record} />
    }
    return null
}
