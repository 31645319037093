import React, { Component } from 'react'
import Popover from 'antd/lib/popover'
import Fields from '@list/Fields'
import { getOneByCode } from '@list/Action'

export default class ListFieldsPopover extends Component {
    constructor() {
        super()
        this.state = {
            data: null
        }
    }
    componentDidMount() {
        const {value, config} = this.props
        // value === '0 暂时屏蔽 ListFieldsPopover
        if (value === '0' && value && value.code && config && config.modulename) {
            getOneByCode(config.modulename, value.code, data => this.setState({data}))
        }
    }
    render () {
        const {config, children} = this.props
        const {data} = this.state
        // config !== '0' 暂时屏蔽 ListFieldsPopover
        if (config !== '0' || data == null || data.id == null) {
            return children
        }
        const moduleName = config && config.modulename
        return <Popover
        content={<div style={{marginTop: 10, width: global.modalWidth}}>
            {config && data && <Fields 
                config={config} 
                moduleName={moduleName} 
                action='detail'
                id={data && data.id}
                data={data}
            />}
        </div>}
    >
        {children}
    </Popover>
    }
}