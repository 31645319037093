const iconWidth = 18;
const lineMarginLeft = 8;
export function defaultTreeStyle() {
    return {
        tree: {
            padding: 10,
            marginTop: 8,
            background: '#FAFAFA',
        },
        node: {
            paddingTop: 10
        },
        item: {
            flexDirection: 'row',
        },
        children: {
            paddingLeft: iconWidth,
        },
        icon: {
            width: iconWidth,
            alignSelf: 'center'
        },
        leftLine: {
            marginLeft: lineMarginLeft,
            paddingLeft: 0,//iconWidth - lineMarginLeft - 1,
            borderLeftStyle: 'solid',
            borderLeftWidth: '1px',
            borderLeftColor: '#d9d9d9'
        },
        nodeSpan: {
            borderRadius: '2px',
            margin: 0,
            cursor: 'pointer',
            textDecoration: 'none',
            verticalAlign: 'top',
            color: '#666',
            transition: 'all 0.3s'
        },
        checkedBG: {
            backgroundColor: '#108ee9',
            borderColor: '#108ee9'
        },
        box: {
            position: 'relative',
            top: 3,
            left: 0,
            display: 'inline-block',
            width: '14px',
            height: '14px',
            border: '1px solid #d9d9d9',
            borderColor: '#d9d9d9',
            borderRadius: '2px',
            backgroundColor: '#fff',
            transition: 'all .3s',
        },
        checked: {
            transform: 'rotate(45deg) scale(1)',
            position: 'absolute',
            left: '4px',
            display: 'inline-block',
            top: '1px',
            width: '5px',
            height: '8px',
            borderBottom: '2px solid #fff',
            borderRight: '2px solid #fff',
            borderColor: '#fff',
            borderTop: 0,
            borderLeft: 0,
            transition: 'all .2s cubic-bezier(.12, .4, .29, 1.46) .1s'
        },
        checkPart: {
            position: 'relative',
            left: 2,
            right: 2,
            top: 5,
            width: 8,
            height: 2,
            borderColor: '#fff',
            borderBottom: '2px solid #fff',
            borderLeft: 0,
            borderRight: 0
        }
    }
} 