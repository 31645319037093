/**
 * 模块管理接口
 */


/**
 * 查询表配置，分页
 * @param {*} page 
 * @param {*} pageSize 
 * @param {Query} query 
Query： {
    searchFields，
    mainSearchFields，
    moreSearchFields
}
 * @param {*} sorter 
 * @param {*} cb 
 */
export function getList(page, pageSize, query, sorter, cb) {
    let data = { page, pageSize, query, sorter }
    global.getApi().put('/tables_config/list', { data }).then(payload => {
        cb && cb(payload.body || {})
    }, () => {
        cb && cb({})
    })
}
export async function getListPromise(page, pageSize, query, sorter) {
    try {
        let data = { page, pageSize, query, sorter }
        const payload = await global.getApi().put('/tables_config/list', { data })
        return payload.body || {}
    } catch(e) {
    }
    return {}
}

/**
 * 查询表配置，全部
 */
export function getListAll(cb) {
    global.getApi().put('/tables_config/listAll').then(payload => {
        cb && cb(payload.body || [])
    }, () => {
        cb && cb([])
    })
}

/**
 * 查询表配置，全部， 仅基本数据
 */
export function getListBasicAll(cb) {
    global.getApi().put('/table_basic_all').then(payload => {
        cb && cb(payload.body || [])
    }, () => {
        cb && cb([])
    })
}
export async function getListBasicAllPromise() {
    try {
        const payload = await global.getApi().put('/table_basic_all')
        return payload.body || []
    } catch(e) {
    }
    return []
}

/**
 * 获取一条表格配置
 * @param {*} id 
 * @param {*} cb 
 */
export function getOne(id, cb) {
    global.getApi().get('/tables_config', {
        params: { id }
    }).then(payload => {
        cb && cb(payload.body || {})
    }, () => {
        cb && cb({})
    })
}

/**
 * 根据模块名称获取一条表格配置
 * @param {*} name 
 * @param {*} cb 
 */
export function getOneByModuleName(name, cb) {
    global.getApi().get('/table/byname', {
        params: { name }
    }).then(payload => {
        cb && cb(payload.body || {})
    }, () => {
        cb && cb({})
    })
}
