/**
 * 页面实例工具
 */

import {pageInstObj, modalInstArray} from './object'
import * as utils from './index'
import {getCookie} from './cookie'
import { showModuleName } from './DataUtil'
import { code_scheme } from './constants'

let needLoading = true

export function init() {
    if (global.init instanceof Function) {
        global.init()
    }
}

export function openpage(path, title) {
    if (global.openpage instanceof Function) {
        global.openpage(path, title)
    }
}

export function closepage() {
    if (global.closepage instanceof Function) {
        global.closepage()
    }
}

export function startLoad() {
    if (modalInstArray.length > 0) {
        modalInstArray[modalInstArray.length - 1].startLoad()
    } else if (global.startLoad instanceof Function) {
        needLoading && global.startLoad()
    }
}

export function stopLoad() {
    if (modalInstArray.length > 0) {
        modalInstArray[modalInstArray.length - 1].stopLoad()
    } else if (global.stopLoad instanceof Function) {
        global.stopLoad()
    }
}

export function refreshModalPage(clientId) {
    if (modalInstArray.length > 0) {
        modalInstArray[modalInstArray.length - 1].refreshPage(clientId)
    }
}

/**
 * 跳转到新增页面
 * @param {*} moduleName 
 * @param {*} fid 
 * @param {*} id 复用记录id
 * @param {*} name 复用记录名称
 */
export function toNew(moduleName, fid, id, name) {
    const config = utils.getModuleConfig(moduleName)
    //console.log('toNew', id)
    let url = `/${moduleName}/new`
    const paramArray = []
    if (fid) {
        paramArray.push(`fid=${fid}`)
    }
    if (id) {
        paramArray.push(`id=${id}`)
    }
    if (paramArray.length > 0) {
        url += `?${paramArray.join('&')}`
    }
    let title = `${showModuleName(config)}-新增`
    if (id) {
        title = `${showModuleName(config)}-复用${name}`
    }
    openpage(url, title)
}

/**
 * 跳转到详情页面
 * @param {*} moduleName 
 * @param {*} id 
 * @param {*} record 
 */
export function toDetail(moduleName, id, record) {
    //console.log('toDeatil', id)
    const url = `/${moduleName}/detail?id=${id}`
    const config = utils.getModuleConfig(moduleName)
    let title = `${config.menuname || showModuleName(config)}-${utils.getTitle(record)}`
    if (moduleName === code_scheme) {
        const codeSchemeConfig = utils.getModuleConfig(record.schemename)
        title = `${showModuleName(config)}-${showModuleName(codeSchemeConfig)}`
    }
    openpage(url, title)
}

/**
 * 静默加载
 * @param {*} cb 执行函数
 */
export async function quietly(cb) {
    needLoading = false
    let res = null
    if (cb instanceof Function) {
        res = await cb()
    }
    needLoading = true
    return res
}

/**
 * 等待过程使用Spinning
 * @param {*} cb 
 */
export async function wait(cb) {
    if (!(cb instanceof Function)) {
        return
    }
    startLoad()
    await cb()
    stopLoad()
}

/**
 * 获取当前激活页签path
 * @returns 
 */
export function getActiveTabKey() {
    const userid = getCookie('userid')
    if (utils.isBlank(userid)) {
        return null
    }
    const tabKey = `tab${userid}`
    return localStorage.getItem(tabKey)
}

/**
 * 系统配置更新
 */
export function updateSystem() {
    const tableConfiPageKey = '/tables_config'
    const tableConfigPage = pageInstObj[tableConfiPageKey]
    if (tableConfigPage) {
        quietly(() => {
            tableConfigPage.refreshPage()
            global.getMenuTree && global.getMenuTree()
        })
    } else {
        quietly(() => global.getMenuTree && global.getMenuTree())
    }
}

/**
 * 重新渲染所有页面
 */
export function reRenderAllPage() {
    for (let key in pageInstObj) {
        const inst = pageInstObj[key]
        if (inst && (inst.reRender instanceof Function)) {
            inst.reRender()
        }
    }
}

/**
 * 刷新列表页面
 * @param {*} clientId 
 * @param {*} moduleName 
 * @param {*} page 新增页面刷新第一页，更新时此参数为null
 * @returns 
 */
export function refreshListPage(clientId, moduleName, page) {
    const path = '/' + moduleName
    for (let key in pageInstObj) {
        if (key === path || key.startsWith(path + '?')) {
            const inst = pageInstObj[key]
            if (inst == null) {
                return
            }
            if (inst.refreshPage instanceof Function) {
                quietly(async () => inst.refreshPage(clientId, page))
            }
        }
    }
}

/**
 * 按id刷新所有详情页面
 * @param {*} clientId 
 * @param {*} moduleName 
 * @param {*} ids 
 * @returns 
 */
export function refreshAllDetailPage(clientId, moduleName, ids) {
    if (!(ids instanceof Array) || ids.length === 0) {
        return
    }
    for (const id of ids) {
        const path = '/' + moduleName + '/detail?id=' + id
        const inst = pageInstObj[path]
        if (inst == null) {
            continue
        }
        if (inst.refreshPage instanceof Function) {
            quietly(() => inst.refreshPage(clientId))
        }
    }
}

/**
 * 删除页面通知
 * @param {*} clientId 
 * @param {*} moduleName 
 * @param {*} ids 
 * @returns 
 */
export function deleteAllPage(clientId, moduleName, ids) {
    if (!(ids instanceof Array) || ids.length === 0) {
        return
    }
    refreshListPage(clientId, moduleName)
    for (const id of ids) {
        const targetPagePath = '/' + moduleName + '/detail?id=' + id
        const inst = pageInstObj[targetPagePath]
        if (inst == null) {
            continue
        }

        if (inst.deletePage instanceof Function) {
            inst.deletePage(clientId)
        }
    }
}

/**
 * 刷新页面
 * @param {*} clientId oows 客户端id
 * @param {*} moduleName 模块名称
 * @param {*} ids 更新的数据id数组
 */
export async function refreshPage(clientId, moduleName, ids) {
    if (utils.isBlank(moduleName)) {
        console.error('refreshPage moduleName is null')
        return
    }
    refreshListPage(clientId, moduleName)
    refreshAllDetailPage(clientId, moduleName, ids)
}

/**
 * 刷新父模块
 * @param {*} moduleName 
 */
export async function refreshFModulePage(clientId, moduleName) {
    // 刷新父模块
    const fmodule = utils.getModuleConfig(moduleName).fmodule
    if (utils.isNotBlank(fmodule)) {
        for (let key in pageInstObj) {
            if (key.startsWith('/' + fmodule)) {
                const inst = pageInstObj[key]
                if (inst && (inst.refreshPage instanceof Function)) {
                    if (inst.refreshPage instanceof Function) {
                        quietly(() => inst.refreshPage(clientId))
                    }
                }
            }
        }
    }
}

/**
 * 刷新业务编排页面
 */
export async function refreshFlowPage() {
    for (let key in pageInstObj) {
        if (key.startsWith('/bizflow')) {
            const inst = pageInstObj[key]
            if (inst && (inst.refreshPage instanceof Function)) {
                inst.refreshPage()
            }
        }
    }
}

export function clientWidth() {
    return global.clientWidth
}
export function clientHeight() {
    return global.clientHeight
}
export function isPhone() {
    return global.isPhone
}
export function isPhoneLandscape() {
    return global.isPhoneLandscape
}
export function isPhoneVertical() {
    return global.isPhoneVertical
}
export function modalHeight() {
    return global.modalHeight
}
export function headerHeight() {
    return global.headerHeight
}
export function contentHeight() {
    return global.contentHeight
}
export function tableHeight() {
    return global.tableHeight
}

/**
 * 页面展示模式
 * normal: 默认模式
 * inner: 表格行内嵌入
 * modal: 弹窗
 * nestedsublist： 嵌入子模块，不显示表单基本信息
 */
export const inner = 'inner'
export const modal = 'modal'
export const nestedsublist = 'nestedsublist'
export const normal = 'normal'

/**
 * 按钮展示类型
 * button: 普通按钮 默认
 * icon: 图标
 * a: <A/> 文本链接
 */
export const icon = 'icon'
export const a = 'a'