import { openpage } from '@utils/page'
import { createIcon } from '@com/PageCreator'


export function iconOperation(text, record) {
    return createIcon('code-merge', '业务编排', () => {
        const {id, code, name, modulename} = record
        let url = `/bizflow?id=${id}&code=${code}&name=${name}`
        let title = `${modulename}-${name}`
        openpage(url, title)
    }, { key: text + 'code-merge' })
}