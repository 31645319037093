import React, { Component } from 'react'
import * as ListAction from '@list/Action'
import {callModifyByApiBatch} from '../layout/Action'
import * as dataUtil from '@utils/DataUtil'
import * as utils from '@utils/index'
import Mapping from '@com/Mapping'
import * as p from '@utils/page'
import { orchestrate } from '@utils/constants'

const controlNodeType = [
	'process-start', 'process-end', 'process-branch', 
	'process-async-start', 'process-async-end',
	'loop-start', 'loop-end'
]

export default class FlowMapping extends Component {
	constructor(props) {
		super(props)
		this.id = global.parseSearchStr().id
		this.type = props.type
		if (this.type === 'line') {
			this.lineId = global.parseSearchStr().lineId
			this.from = this.lineId.split('_')[0]
			this.to = this.lineId.split('_')[1]
		} else {
			this.nodeId = global.parseSearchStr().nodeId
		}
		this.state = {
			line: {},
			nodeList: [],
			lineList: [],
			value: {},
			leftConfig: {},
			rightConfig: [],
		}
	}
	componentDidMount() {
		if (utils.isBlank(this.id)) {
			return
		}
		this.buildTreeData()
	}
	/**
	 * 构造数据
	 */
	async buildTreeData() {
		const record = await ListAction.getOnePromise(orchestrate, this.id)
		let bizflow = record.bizflow || {}
		let nodeList = bizflow.nodeList || []
		let lineList = bizflow.lineList || []
		let mapping = bizflow.mapping || {}
		let inConfig = dataUtil.getMergeConfig(record.modulename)
		inConfig.key = 'n0'
		inConfig.nodeKey = ''
		inConfig.name = dataUtil.showModuleName(record.modulename)
		let nodesBefore = []
		let nodeConfig = {}
		let line = {}
		// 连线条件映射
		if (this.type === 'line') {
			this.getNodesBefore(nodesBefore, this.from, lineList, nodeList)
			line = lineList.find(line => line.from === this.from && line.to === this.to)
			let node = nodeList.find(item => item.id === this.from)
			if (node) {
				nodesBefore.unshift(node)
			}
		} else {
			// 普通业务节点
			// 获取该节点之前的所有节点
			this.getNodesBefore(nodesBefore, this.nodeId, lineList, nodeList)
			// 获取打开映射节点数据
			let node = nodeList.find(node => node.id === this.nodeId)
			// 获取打开映射节点配置
			nodeConfig = this.getNodeConfig(node)
		}
		let nodeBeforeConfigs = []
		for (let node of nodesBefore) {
			// 控制节点
			if (controlNodeType.indexOf(node.type) !== -1) {
				// 循环开始
				if (node.type === 'loop-start') {
					let loopNode = nodeList.find(item => item.id === node.loopId)
					if (loopNode) {
						let loopNodeConfig = this.getNodeConfig(loopNode)
						loopNodeConfig.loopKey = loopNodeConfig.key
						loopNodeConfig.key = node.id
						loopNodeConfig.type = node.type
						loopNodeConfig.name = `${node.name}【${loopNodeConfig.name}】`
						nodeBeforeConfigs.push(loopNodeConfig)
					}
				}
				continue
			}
			// 跳过查询节点，查询节点结果为数组，由循环开始节点承载其数据
			if (node.key.split('_')[0] !== '查询') {
				nodeBeforeConfigs.push(this.getNodeConfig(node))
			}
		}
		nodeBeforeConfigs.unshift(inConfig)
		if (nodeBeforeConfigs instanceof Array) {
			for (let config of nodeBeforeConfigs) {
				// '统计', '生成编码' 返回数字，字符串，没有子属性， 返回单值
				const isSingleValue = ['统计', '生成编码'].indexOf(config.nodeKey.split('_')[0]) !== -1
				config.isSingleValue = isSingleValue
			}
		}
		// 生成编码不需要参数
		if (this.type !== 'line' && '生成编码' === nodeConfig.nodeKey.split('_')[0]) {
			nodeConfig = null
			nodeBeforeConfigs = []
		}
		this.setState({
			line,
			lineList,
			nodeList,
			mapping,
			leftConfig: nodeConfig, 
			rightConfig: nodeBeforeConfigs, 
			value: this.type === 'line' ? line.cond : (mapping[this.nodeId] || {})
		})
	}

	/**
	 * 组装根节点数据
	 * @param {*} node 
	 * @returns 
	 */
	getNodeConfig(node) {
		let moduleName = node.key.split('_')[1]
		let config = dataUtil.getMergeConfig(moduleName)
		const {
			id, // 节点编号
			key, // 模块名称+操作 key 后端使用
			name, // 显示名称
		} = node
		//console.log(id, key, name)
		config.key = id
		config.nodeKey =key
		config.name = name
		return config
	}

	/**
	 * 获取当前节点之前的所有节点
	 * @param {*} nodesBefore 
	 * @param {*} nodeId 
	 * @param {*} lineList 
	 * @param {*} nodeList 
	 * @returns 
	 */
	getNodesBefore(nodesBefore, nodeId, lineList, nodeList) {
		if (!(nodesBefore instanceof Array && lineList instanceof Array && nodeList instanceof Array)) {
			return
		}
		for (let line of lineList) {
			if (line.to === nodeId) {
				let node = nodeList.find(item => item.id === line.from)
				if (node) {
					nodesBefore.unshift(node)
					this.getNodesBefore(nodesBefore, line.from, lineList, nodeList)
					break
				} 
			}
		}
	}

	/**
	 * 保存
	 */
	onSave(value) {
		ListAction.getOne(orchestrate, this.id, (record) => {
			record.bizflow = record.bizflow || {}
			if (this.type === 'line') {
				record.bizflow.lineList = record.bizflow.lineList || []
				for (let line of record.bizflow.lineList) {
					if (line.from === this.state.line.from && line.to === this.state.line.to) {
						line.cond = value
						break
					}
				}
			} else {
				record.bizflow.mapping = record.bizflow.mapping || {}
				record.bizflow.mapping[this.nodeId] = value
			}
			callModifyByApiBatch(orchestrate, this.id, record)
			p.refreshFlowPage()
		})
	}

	render() {
		if (utils.isBlank(this.id)) {
			return <div/>
		}
		const {value, leftConfig, rightConfig} = this.state
		const props = {
			type: this.type,
			value, leftConfig, rightConfig,
			flow: true,
			notModal: true,
			onChange: this.onSave.bind(this)
		}
		return <Mapping {...props} />
	}
}