import React, { Component } from 'react'
import { Route } from 'react-router-dom'
import Menu from 'antd/lib/menu'
import Icon from '@com/Icon'
import Spin from '@com/Spin'
import Dropdown from 'antd/lib/dropdown'
import BackTop from 'antd/lib/back-top'
import Popover from 'antd/lib/popover'
import ModuleList from '@m/module_config/ModuleList'
import * as tableAction from '@m/module_config/Action'
import { callModifyByApiBatch } from '@m/layout/Action'
import ListModule from '@list/ListModule'
import New from '@list/New'
import Detail from '@list/Detail'
import TreeModule from '@tree/TreeModule'
import Home from './Home'
import Login from './Login'
import FullImage from '@com/FullImage'
import ModifyPassword from '@/extension/ModifyPassword'
import {getCookie, delCookie} from '@utils/cookie'
import {sha1} from '@utils/crypto'
import Personal from '@/extension/Personal'
import {getOnePromise, getListAllPromise, getListAll} from '@list/Action'
import { getTreePromise } from '@tree/Action'
import { initGlobal } from '@utils/common/store'
import Flow from '@m/orchestrate/Flow'
import Todo from '@m/workflow/Todo'
import Done from '@m/workflow/Done'
import FlowMapping from '@m/orchestrate/FlowMapping'
import ReportDesign from '@m/report/ReportDesign'
import Report from '@m/report/Report'
import ParamSetting from '@/setting/ParamSetting'
import * as utils from '@utils/index'
import * as dataUtil from '@utils/DataUtil'
import QRScanner from '@com/QRScanner'
import wsClient from '@utils/wsClient'
import * as p from '@utils/page'
import * as c from '@utils/constants'
import {isNoTokenRoute} from '@/config/route'
import * as o from '@utils/object'
import * as tablesConfig from '@m/module_config/Config'
import * as codesConfig from '@m/code_scheme/Config'
import OOHead from './OOHead'
import OOPages from './OOPages'
import ChartsTest from '@/charts/ChartsTest'
import A from '@com/A'
import ChartDesign from '@/charts/ChartDesign'
import Chart from '@/charts/Chart'

const { setting, datatransferList, orchestrateList, reportengineList, chartdesignList } = o
const { firstPage } = utils
const {SubMenu} = Menu

class Dashboard extends Component {
	/**'
	 * 构造函数
	 */
	constructor() {
		super()
		this.pageKey = this.moduleName = 'Dashboard'
		global[this.pageKey] = global[this.pageKey] || {}
		if (global.localFrontEnd) {
			document.title = ''
		}
		// 页面状态
		const state = {
			update: false,
			cols: 1,
			loading: true,
			ooRouteProps: {},
		}
		global.activeTabKey = null
		const path = decodeURI(window.location.pathname + (window.location.search || ''), "UTF-8") || '/'
		if (path !== '/' && path !== '/login') {
			global.activeTabKey = path
		}
		global.tabs = []
		let userid = getCookie('userid')
		if (userid) {
			global.tabsKey = `tabs${userid}`
			global.tabKey = `tab${userid}`
			global.activeTabKey = global.activeTabKey || localStorage.getItem(global.tabKey) || '/'
			global.tabs = global.parseErrorNull(localStorage.getItem(global.tabsKey)) || [firstPage]
		}
		this.state = state
		// 企业信息
		let entJsonstr = localStorage.getItem('ent')
		let ent = {}
		if (getCookie('username') !== 'oo' && utils.isNotBlank(entJsonstr)) {
			ent = global.parse(entJsonstr)
		}	
		global.entName = (ent && ent.name) || '广天灵码平台'
		global.systemtype = ent && ent.systemtype

		// 当前登录用户在企业中的人员信息
		global.emp = {}
		// 个人相关菜单
		this.personalMenus = [
			<Menu.Item key="1" onClick={() => {
					global.openpage('/personal', '个人资料')
				}}>
				<div className='centered-ch'>
					<Icon className='menu-icon' type='contact-book'/>
					<span>个人资料</span>
				</div>
			</Menu.Item>,
			<Menu.Item key="2" onClick={() => {
					global.openpage('/modify_password', '修改密码')
				}}>
				<div className='centered-ch'>
					<Icon className='menu-icon' type='user-edit'/>
					<span>修改密码</span>
				</div>
			</Menu.Item>,
			<Menu.Item key="3" onClick={() => {
					global.openBrowser('/广天灵码软件使用手册.docx')
				}}>
				<div className='centered-ch'>
					<Icon className='menu-icon' type='book-open'/>
					<span>用户手册</span>
				</div>
			</Menu.Item>,
			<Menu.Item key='4' onClick={this.logout.bind(this)}>
				<div className='centered-ch'>
					<Icon className='menu-icon' type='sign-out-alt'/>
					<span>退出</span>
				</div>
			</Menu.Item>
		]
		// 模块权限 名称数组
		this.moduleNames = []
		// 报表权限 名称数组
		this.reportNames = []
	}

	/**
	 * 组件加载时
	 */
	async componentDidMount() {
		global.init = this.init.bind(this)
		global.startLoad = this.startLoad.bind(this)
		global.stopLoad = this.stopLoad.bind(this)
		global.getMenuTree = this.getMenuTree.bind(this)
		const devhosts = ['localhost', '127.0.0.1', '192.168.0.10']
		if (window.location.protocol !== 'https:' && devhosts.indexOf(window.location.hostname) === -1) {
			// 重定向到 HTTPS 协议
			window.location.href = 'https://' + window.location.hostname +
				window.location.pathname + window.location.search
			return
		}
		this.unmount = false
		await this.init()
	}

	/**
	 * 将要离开组件时
	 */
	componentWillUnmount() {
		this.unmount = true
		global.startLoad = null
		global.stopLoad = null
		global.getMenuTree = null
		window && window.removeEventListener('resize', this.onWindowResize.bind(this))
	}

	async UNSAFE_componentWillReceiveProps(nextProps) {
		if (this.props.location.pathname !== nextProps.location.pathname ||  
			this.props.location.search !== nextProps.location.search
		) {
			// 从登录页面跳转到Dashboard后初始化页面
			if (this.props.location.pathname === '/login') {
				// 页面状态
				let userid = getCookie('userid')
				if (userid) {
					global.tabsKey = `tabs${userid}`
					global.tabKey = `tab${userid}`
					global.activeTabKey = global.activeTabKey || localStorage.getItem(global.tabKey) || '/'
					global.tabs = global.parseErrorNull(localStorage.getItem(global.tabsKey)) || [firstPage]
				}
				await this.init(nextProps)
			}
		}
	}

	logout() {
		// 清空路由数据
		this.setState({ooRouteProps: {}})
		// 清除激活页签
		global.activeTabKey = null
		// 跳转到登录页面
		this.props.history.replace('/login')
		// 清空 当前登录用户在企业中的人员信息
		global.emp = {}
		// 延迟0.5秒清理缓存，等待当前页面离开前发送完后端请求
		setTimeout(() => {
			delCookie('userid')
			delCookie('entid')
			delCookie('username')
			delCookie('token')
			delCookie('password')
			localStorage.removeItem('user')
			localStorage.removeItem('ent')
			utils.clearGlobalData()
		}, 500)
		// 关闭websocket
		global.closews && global.closews(global.ws)
	}

	/**
	 * 获取系统菜单和系统配置
	 */
	async getMenuTree() {
		p.startLoad()
		// 获取全部表配置数据, 异步， 加载路由
		tableAction.getListAll(async moduleConfigs => {
			const tablesConfigModule = moduleConfigs.find(it => it.modulename === c.tables_config)
			const codeSchemaModule = moduleConfigs.find(it => it.modulename === c.code_scheme)
			if (tablesConfigModule == null || codeSchemaModule == null) {
				utils.error('系统配置损坏，请联系系统管理员')
				p.startLoad()
				return
			}
			// 挂载系统配置和编码方案字段配置
			tablesConfigModule.fields_config = tablesConfig.tableConfig
			codeSchemaModule.fields_config = codesConfig.tableConfig
			global.moduleConfigs = moduleConfigs
			const routes = await this.buildRouteAll(moduleConfigs)
			const ooRouteProps = {}
			for (const route of routes) {
				ooRouteProps[route.path] = route
			}
			this.setState({ooRouteProps})
		})
		// 获取菜单数据， 同步， 先渲染菜单
		const treeData = await getTreePromise('menu')
		await this.getModuleConfigs(treeData)
		p.stopLoad()
	}

	/**
	 * 获取系统配置
	 */
	async getModuleConfigs(treeData) {
		treeData = global.copy(treeData)
		if (!(treeData instanceof Array) || treeData.length !== 1) {
			utils.error(`菜单数据损坏，请联系管理员`)
			treeData = [{}]
		}
		treeData[0].children = treeData[0].children || []
		// 获取模块配置，只获取基本信息
		const moduleConfigs = await tableAction.getListBasicAllPromise()
		const reportList = await this.getReportAll()
		const chartList = await this.getChartAll()
		this.allReportNames = reportList.concat(chartList).map(it => it.name)
		// 然后构建系统配置菜单数据， 报表引擎、图表引擎和系统配置需要一起获取才能排序
		const addToMenuTree = (list, isChart) => {
			for (let it of list) {
				if (utils.isBlank(it.menu)) continue
				let id = it.menu_id
				let node = global.searchTreeNode(id, treeData, 'value')
				if (node) {
					node.children = node.children || []
					it.modulename = it.name
					if (isChart) {
						it.chart = true
					} else {
						it.report= true
					}
					node.children.push(it)
				}
			}
		}
		addToMenuTree(reportList)
		addToMenuTree(chartList, true)

		for(let module of moduleConfigs) {
			if (module.createmenu !== 'yes' && module.createmenu !== true) {
				continue
			}
			if (utils.isBlank(module.menu)) {
				treeData[0].children.push(module)
			} else {
				let text = module.menu_id
				let node = global.searchTreeNode(text, treeData, 'value')
				if (null == node) {
					treeData[0].children.push(module)
				} else {
					node.children = node.children || []
					node.children.push(module)
				}
			}
		}
		let menuTreeData = treeData[0].children
		let sortTree = (tree) => {
			if (tree instanceof Array) {
				tree.sort(utils.sortByKeyAndType('no', 'NUMBER'))
				for (let node of tree) {
					sortTree(node.children)
				}
			}
		}
		sortTree(menuTreeData)
		this.moduleMenus = this.buildMenuTree(menuTreeData)
		this.reRender()
	}

	/**
	 * 初始化
	 */
	async init(props = this.props) {
		if (isNoTokenRoute(props.location.pathname)) {	
			p.stopLoad()
			return
		}
		if (!getCookie('username')) {
			props.history.replace('/login')
			p.stopLoad()
			return
		}
		if (getCookie('password') === sha1('88888888')) {
			global.openpage('/modify_password', '修改密码')
		}
		window && window.addEventListener('resize', this.onWindowResize.bind(this))
		utils.storeData(this, this.pageKey, { cols: global.cols})
		p.startLoad()
		// 初始化角色
		let user = global.parse(localStorage.getItem('user'))
		let empid = user['staff_id']
		global.isAdmin = false
		if (utils.isNotBlank(empid)) {
			const body = await getOnePromise("staff", empid)
			global.emp = body
			let authList = body.authList || []
			if (authList instanceof Array) {
				if (authList.length === 0) {
					utils.error(`人员${body.name}没有权限，请联系管理员添加权限`,
						'', this.logout.bind(this))
					p.stopLoad()
					return
				}
				let roles = []
				for (let id of authList) {
					let role = await getOnePromise("auth", id)
					roles.push(role)
				}
				global.roles = roles
				//console.log(roles, 'roles')
				global.role = roles.find(item => item.name === body.roleName)
				//console.log( global.role, 'global.role dashbaord page----------------------------')
				if (global.role === undefined) {
					global.role = roles[0]
					await callModifyByApiBatch('staff', empid, {
						roleCode: roles[0].code,
						roleName: roles[0].name,
					}, null, null, true)
				}
				if (global.role.code === 'admin') {
					global.isAdmin = true
				}
				// 模块权限 名称数组
				this.moduleNames = (global.role && global.role.moduleNames) || []
				// 报表权限 名称数组
				this.reportNames = (global.role && global.role.reportNames) || []
				if (global.isAdmin) {
					this.reportNames = this.allReportNames || []
				}
			} else {
				utils.error(`人员${body.name}没有权限，请联系管理员添加权限`,
					'知道了', this.logout.bind(this) )
				p.stopLoad()
				return
			}
		} else {
			global.isAdmin = true
			global.roles = []
		}
		//console.log('init-----------------------------', props.location.pathname)
		// 初始化菜单
		await this.getMenuTree()
		p.stopLoad()
		// 初始化全局变量
		initGlobal()
		// 参数设置
		const list = await getListAllPromise(c.setting)
		if (list instanceof Array && list.length === 1) {
			Object.assign(setting, list[0])
		}
		// 获取全部数据传递配置数据， 异步
		if (datatransferList.length === 0) {
			getListAll(c.datatransfer, {}, null, list => {
				if (list instanceof Array) {
					if (datatransferList.length === 0) {
						datatransferList.push(...list)
					}
				}
			})
		} 
		// 获取全部业务编排配置数据， 异步
		if (orchestrateList.length === 0) {
			getListAll(c.orchestrate, {}, null, list => {
				if (list instanceof Array) {
					if (orchestrateList.length === 0) {
						orchestrateList.push(...list)
					}
				}
			})
		}

		const path = decodeURI(props.location.pathname + (props.location.search || ''), "UTF-8") || '/'
		let closable = true
		if ('/' === path) {
			closable = false
		}
		// 页签系统不打开登录路由
		if (['/login'].indexOf(path) === -1) {
			const pathname = decodeURI(props.location.pathname, "UTF-8")
			let title = ''
			if (pathname.startsWith('/')) {
				let index = pathname.indexOf('/', 1)
				let modulename
				if (index === -1) {
					modulename = pathname.substring(1)
				} else {
					modulename = pathname.substring(1, index)
				}
				title = dataUtil.showModuleName(modulename)
			}
			global.openpage(path, title, closable, true)
		}
		console.log('init complete-----------------------------------------------')
		wsClient(utils.getApi().opts.baseURI)
	}
	startLoad() {
        this.setState({loading: true})
    }
    stopLoad() {
        this.setState({loading: false})
    }

	/**
	 * 窗口大小变化回调函数
	 */
	onWindowResize() {
		global.windowSize()
		this.renderHead()
		if (this.unmount) {
			return
		}
		let data = { cols: global.cols }
		utils.storeData(this, this.pageKey, data)
	}
	
	// 路由构造器
	buildRoute(path, component, moreProps){
		const {history} = this.props
		return { path, component, moreProps, history }
	}
	// 有权限再添加路由 系统配置
	add(routes, path, component, moreProps) {
		// 固定路由覆盖系统默认路由
		// 路由已经存在，直接返回
		if (routes.find(it => it.path === path)) {
			return
		}
		let str = path.substring(1)
		if (global.isAdmin || this.moduleNames.some(name => str.startsWith(name))) {
			routes.push(this.buildRoute(path, component, moreProps))
		}
	}

	/**
	 * 构造系统固定路由
	 * @param {*} routes 
	 * @returns 
	 */
	buildFixedRoutes(routes) {
		// 固定路由
		routes.push(this.buildRoute('/fullimage', FullImage))
		this.add(routes, '/tables_config', ModuleList)
		// 修改密码
		routes.push(this.buildRoute('/modify_password', ModifyPassword))
		// 个人信息
		routes.push(this.buildRoute('/personal', Personal))
		// 待办列表
		this.add(routes, '/todo', Todo)
		// 已办列表
		this.add(routes, '/done', Done)
		// 工作流
		routes.push(this.buildRoute('/workflow', Flow, {type: 'workflow'}))
		// 业务编排
		routes.push(this.buildRoute('/bizflow', Flow, {type: 'bizflow'}))
		// 节点数据映射
		routes.push(this.buildRoute('/mapping/node', FlowMapping, {type: 'node'}))
		// 连线数据映射
		routes.push(this.buildRoute('/mapping/line', FlowMapping, {type: 'line'}))
		// 报表设计
		routes.push(this.buildRoute('/report/design', ReportDesign))
		// 图表设计
		routes.push(this.buildRoute('/chart/design', ChartDesign))
		// 参数设置
		this.add(routes, '/setting', ParamSetting)
		return routes
	}

	/**
	 * 构造系统模块路由
	 * @param {*} routes 
	 * @param {*} moduleConfigs 
	 */
	buildOOModuleRoute(routes, moduleConfigs) {
		// 构造动态路由
		for(let config of moduleConfigs) {
			if (config.moduletype === 'tree') {
				this.add(routes, `/${config.modulename}`, TreeModule, {config, moduleName: config.modulename})
			} else {
				const singlePageProps = { config, moduleName: config.modulename }
				const listModuleProps = { config, moduleName: config.modulename }
				if (config.modulename === c.code_scheme) {
					listModuleProps.hideSelect = true
				} else if ([c.orchestrate, c.datatransfer].indexOf(config.modulename) !== -1) {
					listModuleProps.searchUrlParams = true
				}
				this.add(routes, `/${config.modulename}`, ListModule, listModuleProps)
				this.add(routes, `/${config.modulename}/new`, New, singlePageProps)
				this.add(routes, `/${config.modulename}/detail`, Detail, singlePageProps)
			}
		}
	}

	/**
	 * 获取报表
	 */
	async getReportAll() {
		let list = reportengineList
		// 获取报表引擎数据
		if (reportengineList.length === 0) {
			list = await getListAllPromise(c.reportengine)
			if (reportengineList.length === 0) {
				reportengineList.push(...list)
			}
		}
		return list
	}

	/**
	 * 获取图表
	 */
	async getChartAll() {
		let list = chartdesignList
		// 获取报表引擎数据
		if (chartdesignList.length === 0) {
			list = await getListAllPromise(c.chartdesign)
			if (chartdesignList.length === 0) {
				chartdesignList.push(...list)
			}
		}
		return list
	}

	/**
	 * 构建报表路由
	 */
	buildReportRoute(routes, list) {
		for (let config of list) {
			if (global.isAdmin || this.reportNames.indexOf(config.name) !== -1) {
				routes.push(this.buildRoute(`/report/${config.name}`, Report))
			}
		}
	}
	/**
	 * 构建图表路由
	 */
	buildChartRoute(routes, list) {
		for (let config of list) {
			if (global.isAdmin || this.reportNames.indexOf(config.name) !== -1) {
				routes.push(this.buildRoute(`/chart/${config.name}`, Chart))
			}
		}
	}

	/**
	 * 构建系统所有路由
	 * @param {*} moduleConfigs 系统配置
	 * @returns 
	 */
	async buildRouteAll(moduleConfigs) {
		const routes = []
		this.buildFixedRoutes(routes)
		this.buildOOModuleRoute(routes, moduleConfigs)
		this.buildReportRoute(routes, await this.getReportAll())
		this.buildChartRoute(routes, await this.getChartAll())
		return routes
	}
	// 构造菜单树
	buildMenuTree(menuTreeData) {
		let menu = []
		for (let config of menuTreeData) {
			// 菜单树上的菜单分类, 有value
			if (config.value) {
				let menuItems = this.buildMenuTree(config.children || [])
				// 添加个人信息到 我的【CD0002】 菜单
				if (config.code === 'CD0002') {
					menuItems.push(this.personalMenus)
				}
				if (menuItems.length > 0) {
					menu.push(<SubMenu 
						key={config.key} 
						title={<div className='centered-ch'>
							{config.icon && <Icon className='menu-icon' type={config.icon}/>}
							<span>{config.name}</span>
						</div>}
					>
						{menuItems}
					</SubMenu>)
				}
			} else {
				// 具体的模块，无value
				let title = dataUtil.showModuleName(config)
				// 自定义菜单名称
				if (utils.isNotBlank(config.menuname)) {
					title = config.menuname
				}
				// 系统配置中的菜单
				if (global.isAdmin || this.moduleNames.indexOf(config.modulename) !== -1) {
					let pathPrefix = ''
					if (config.report) {
						pathPrefix = '/report'
					} else if (config.chart) {
						pathPrefix = '/chart'
					}
					let param = (config.report || config.chart) ? `?id=${config.id}` : ''
					menu.push(<Menu.Item key={`/${config.modulename}${param}`} onClick={() => {
						global.openpage(`${pathPrefix}/${config.modulename}${param}`, title)
					}}>
						<div className='centered-ch' >
							{config.icon && <Icon className='menu-icon' type={config.icon}/>}
							<span>{title}</span>
						</div>
					</Menu.Item>)
				}
			}
		}
		return menu
	}

	reRender() {
        this.setState({update: !this.state.update})
    }
	renderHead() {
		this.head && this.head.reRender()
	}

	/**
	 * 渲染函数
	 */
	render() {
		//this.i = this.i || 0
		//console.log('Dashboard render', this.i++)
		const {history, location} = this.props
		const {ooRouteProps} = this.state
		const noTokenRoute = isNoTokenRoute(this.props.location.pathname)
		const {activeTabKey} = global
		
		// mode = 'vertical' | 'vertical-left' | 'vertical-right' | 'horizontal' | 'inline';
		// theme = 'light' | 'dark';
		const menu = 
		<Menu theme="light" selectedKeys={[activeTabKey]}>
			<Menu.Item>
				<Popover
					content={<div>
						<span>{global.entName}</span>
					</div>}
				>
					<div className='centered' style={{width: 170}}>
						<div className='ellipsis' style={{maxWidth: '100%'}}>
							<span style={global.h2}>{global.entName}</span>
						</div>
					</div>
				</Popover>
			</Menu.Item>
			{this.moduleMenus}
		</Menu>
		const dropdownMenu = 
		<Dropdown trigger={global.trigger} overlay={menu}>
			<A><Icon type={'indent'}  style={{ fontSize: 24 }} /></A>
		</Dropdown>
		const homeRouteProps = this.buildRoute('/', Home)

		const spinning = this.state.loading || (global.roles === undefined && !noTokenRoute)
		//console.log(spinning, 'spinning')
		return <Spin spinning={spinning}>
			<Route path='/login' exact render={props => <Login {...props}/>}/>
			<Route path='/qrcode-scan' exact render={() => 
				<QRScanner />
			}/>
			<Route path='/charts-test' exact render={props => <ChartsTest {...props}/>}/>
			<div style={{height: global.clientHeight, overflowY: "hidden"}}>
				<div className="layout-main">
					<OOHead 
						ref={inst => this.head = inst}
						reRender={this.reRender.bind(this)}
						menu={dropdownMenu} 
						personalMenus={this.personalMenus}
						history={history}
						location={location}
					/>
					{!noTokenRoute && 
					<OOPages 
						homeRouteProps={homeRouteProps}
						ooRouteProps={ooRouteProps}
					/>}
				</div>
			</div>
			{global.layoutContainerInst && !noTokenRoute && <BackTop target={() => global.layoutContainerInst}/>}
		</Spin>
	}
}

export default Dashboard
