import React, { Component } from 'react'
import OOSelect from '@com/OOSelect'
import Detail from '@list/Detail'
import {getOneByModuleName} from './PlatformAction'
import * as utils from '@utils/index'
import { callModifyByApiBatch } from '../modules/layout/Action'
import {getCookie} from '@utils/cookie'
import * as p from '@utils/page'

const { firstPage, personalPage } = utils

export default class Personal extends Component {
    constructor() {
        super()
        this.pageKey = 'Dashboard'
        this.state = {
            config: null,
        }
    }

    componentDidMount() {
        getOneByModuleName('user', (config) => {
            let fields_config = global.copy(config.fields_config || [])
            // 隐藏"password", "enterprise"字段
            fields_config = fields_config.filter(
                it => ["password", "enterprise"].indexOf(it.name) === -1 && 
                !utils.isSysFields(it.name)
            )
            config.fields_config = fields_config
            this.setState({config: config})
        })
    }

    render() {
        let userJsonStr = localStorage.getItem('user')
        let user = global.parse(userJsonStr)
        //console.log(user, 'user')
        return this.state.config && <div>
            <Detail
                {...this.props}
                platform={true}
                config={this.state.config} 
                id={user.id}
                moduleName={'user'} 
            />
            <div style={{margin: 16}}>
                <h2>切换角色</h2>
                <OOSelect showType='radio'
                    onChange={async value => {
                        let roleName = value
                        global.role = global.roles.find(item => item.name === roleName)
                        const roleCode = global.role.code
                        //console.log(global.role, 'global.role')
                        //console.log(roleName, 'roleName')
                        await callModifyByApiBatch('staff', global.emp.id, {
                            roleCode, roleName
                        }, null, null, true)
                        let userid = getCookie('userid')
                        if (userid) {
                            const tabsKey = `tabs${userid}`
                            const tabs = [firstPage, personalPage]
                            global.tabs = tabs
                            localStorage.setItem(tabsKey, JSON.stringify(tabs))
                        }
                        p.init()
                    }}
                    value={global.role && global.role.name}
                    valueKey='name'
                    labelKey='name'
                    options={global.roles}
                />
			</div>
        </div>
    }
}