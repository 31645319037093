/**
 * OptionOOTable
 * 选项组类型编辑器
 */
import React, { Component } from 'react'
import OOTableEx from './OOTableEx'
import * as utils from '@utils/index'
import * as dataUtil from '@utils/DataUtil'
import { NUMBER } from '@utils/constants'

export default class OptionOOTable extends Component {
    constructor(props) {
        super()
        this.state = {
            list: [],
        }
        const {valueType} = props
        this.fieldsConfig = [
            valueType === NUMBER ? 
            dataUtil.getNumberConfig('value', '值', {width: 80, isRequire: '1'}) 
            :
            dataUtil.getStringConfig('value', '值', {width: 80, isRequire: '1'}),
            dataUtil.getStringConfig('label', '显示名称', {width: 80, isRequire: '1'}),
        ]
    }

    componentDidMount() {
        this.init()
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.props.value) {
            this.init(nextProps)
        }
    }

    init(props = this.props) {
        const {value} = props
        let list = []
        if (value instanceof Array) {
            list = value
            if (list.length > 0 && typeof list[0].value === 'string') {
                this.fieldsConfig[1].dataType = 'STRING'
            }
        } else if (typeof value === 'string') {
            list = utils.getOptionArray(value)
            this.fieldsConfig[1].dataType = 'STRING'
        }
        this.setState({list})
    }
    formError() {
        return this.ootable && this.ootable.formError && this.ootable.formError()
    }
    render() {
        return <OOTableEx 
            {...this.props} 
            ref={(inst) => this.ootable = this.ootable || inst}
            value={this.state.list}
            fieldsConfig={this.fieldsConfig}
        />
    }
}