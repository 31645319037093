/**
 * <span/> 封装，可以接收form表单数据
 */
import React, { Component } from 'react'
export default class ExprSpan extends Component {
    render () {
        let {value, dataType, decimalplaces} = this.props
        // 表达式格式化为数字或金额
        if ('NUMBER' === dataType) {
            value = global.toFixedNumber(value, decimalplaces)
        } else if ('MONEY' === dataType) {
            value = global.toFixedMoney(value, decimalplaces)
        } else {
            return <span className='autobr'>{value}</span>
        }
        return  <span>{value}</span>
    }
}